import axios from 'axios';
import qs from 'qs';
import { BASE_URL, DEV } from './variables';
axios.defaults.withCredentials = true;
const client = axios.create({
    headers: {
        post: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        // withCredentials: true,
    },
    paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
    },
    baseURL: DEV === 'beta' ? `https://beta.server.withmate.kr` : DEV ? '/' : BASE_URL,
});

client.interceptors.request.use(
    config => {
        // const token = localStorage.getItem('accessToken');
        // config.headers = {
        //     Authorization: `Bearer ${token}`,
        // };
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);
// 인터셉터 설정
client.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.request && error.request.status === 403) {
            localStorage.clear();
            window.location.reload();
        }
        return error;
    },
);

export default client;
