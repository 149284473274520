import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import StyledMateContainer from './mate.styled';
import Mate from './mate';
import { MATE } from '../../../../Lib/variables';

import {
    acceptCall,
    cancelCall,
    getUserInfo,
    editUserForm,
    modifyUserInfo,
    completeCall,
    // getMateReserveList,
    // getContractList,
} from '../../../../Reducer/Modules/app';
import { editAuth, editPasswordForm, modifyPassword, withdrawlUser } from '../../../../Reducer/Modules/auth';

class MatePage extends React.PureComponent {
    componentDidMount = () => {
        const mateId = localStorage.getItem('id');
        const userType = localStorage.getItem('userType');

        if (mateId) {
            if (userType !== MATE) {
                window.location.href = '/';
            } else {
                this.props.getUserInfo({ id: mateId, userType });
            }
        } else {
            this.props.history.push('/user/login');
        }
    };

    render() {
        if (this.props.auth.userType !== MATE) {
            return <div></div>;
        }

        return (
            <StyledMateContainer>
                <Mate {...this.props} />
            </StyledMateContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});
const mapDispatchToProps = dispatch => ({
    getUserInfo: ({ id, userType }) => dispatch(getUserInfo({ id, userType })),
    onEdit: ({ type, value }) => dispatch(editUserForm({ type, value })),
    modify: ({ id, userType, info }) => dispatch(modifyUserInfo({ id, userType, info })),
    modifyPassword: ({ id, userType, password, newPassword }) => dispatch(modifyPassword({ id, userType, password, newPassword })),
    editAuth: ({ type, value }) => dispatch(editAuth({ type, value })),
    editPassword: ({ type, value }) => dispatch(editPasswordForm({ type, value })),

    withdrawlUser: ({ id, userType, password }) => dispatch(withdrawlUser({ id, userType, password })),
    cancelCall: ({ callId }) => dispatch(cancelCall({ callId })),
    completeCall: ({ callId }) => dispatch(completeCall({ callId })),
    acceptCall: ({ callId, mateId }) => dispatch(acceptCall({ callId, mateId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatePage);
