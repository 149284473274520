import React from 'react';

class SensitiveComponent extends React.PureComponent {
    render() {
        return (
            <div className='terms-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>[선택/민감] (주)블루카멜 개인정보 수집 및 이용 동의 확인서</h2>
                    </div>

                    <div className='context-block'>
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ width: '55%' }}>수집 및 이용 항목</td>
                                    <td>수집 및 이용 목적</td>
                                    <td>수집 및 이용 목적</td>
                                </tr>
                            </thead>
                            <tbody style={{ border: '1px solid gray' }}>
                                <tr>
                                    <td>기타 요청 및 전달사항</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>온라인 예약하기</td>
                                </tr>
                                <tr>
                                    <td>의료진 이름</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>후기 작성하기</td>
                                </tr>
                                <tr>
                                    <td>기타 요청 및 전달사항</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>마이페이지(예약내역)</td>
                                </tr>
                                <tr>
                                    <td>기타 요청 및 전달사항</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>관리자페이지(예약/수행이력)</td>
                                </tr>
                                <tr>
                                    <td>기타 요청 및 전달사항</td>
                                    <td>서비스 품질 개선 및 신규 서비스 개발</td>
                                    <td>서비스 품질 개선 및 신규 서비스 개발</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='info'>
                            <p className='title' />
                            <p className='title'>
                                <b>[ 개인정보의 수집 및 이용 동의 거부 시 불이익 ]</b> <br />
                                정보주체는 개인정보 수집 및 이용에 동의를 거부할 권리가 있습니다. 단, 필수항목에 대해 거부할 경우,
                                ‘(주)블루카멜(개인정보처리자)’이 제공하는 서비스의 일부 또는 전부를 이용할 수 없습니다.
                            </p>
                            <p className='title'>
                                <b>[ 선택항목 입력 시 처리 동의로 간주 ]</b> <br />
                                ‘(주)블루카멜(개인정보처리자)’이 정한 선택항목은 정보주체가 서비스 이용 과정에서 해당 항목을 입력하지 않는다면 수집되지
                                않습니다. 선택항목 처리에 동의를 거부하더라도, 서비스 이용 중 정보주체가 해당 항목을 입력한다면 처리에 대해 동의한 것으로
                                간주합니다.
                            </p>
                            <p className='title'>
                                <b>[ 개인정보의 보유 및 이용 기간 ]</b> <br />
                                ① 위에서 제시한 처리 목적과 관련한 개인정보는 수집 및 이용에 대한 동의일부터 회원 탈퇴 후 2년까지
                                ‘(주)블루카멜(개인정보처리자)’이 보유 및 처리합니다. <br />
                                ② 다음과 같은 경우에는 ‘(주)블루카멜(개인정보처리자)’이 개인정보를 제1항에서 정한 기간과 달리 보유 및 처리할 수 있습니다. <br />
                                &ensp; 1. 다음과 같이 관련 법령에 의하여 보존할 필요성이 있는 경우 <br />
                                &emsp; 1) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 <br />
                                &emsp; 2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 <br />
                                &emsp; 3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년 <br />
                                &emsp; 4) 계약 또는 청약철회 등에 관한 기록 : 5년 <br />
                                &emsp; 5) 표시/광고에 관한 기록 : 6개월 <br />
                                &ensp; 2. 정보주체로부터 사전에 동의를 받은 경우 : 정보주체가 사전 동의한 기간
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SensitiveComponent;
