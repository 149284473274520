// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import samplePDF from '../PDFfile/withmate_service_manual.pdf';
// import './servicePDF.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// export default function ServicePDF() {
//     const [numPages, setNumPages] = useState(null);

//     function onDocumentLoadSuccess({ numPages }) {
//         setNumPages(numPages);
//     }

//     return (
//         <div style={{ height: '1000px', overflow: 'auto', overflowX: 'hidden' }}>
//             <Document
//                 className={'PDFDocument'}
//                 file={samplePDF}
//                 options={{ workerSrc: "/pdf.worker.js" }}
//                 onLoadSuccess={onDocumentLoadSuccess}
//             >
//                 {Array.from(new Array(numPages), (el, index) => (
//                     <Page className={'PDFPage'} key={`page_${index + 1}`} pageNumber={index + 1} width={1000}/>
//                 ))}
//             </Document>
//         </div>
//     );
// }

import React, { Component } from "react";

export default class ServicePDF extends Component {
  constructor() {
    super();
    this.state = {
      iFrameHeight: "0px"
    };
  }

  render() {
    return (
      <div>
        <iframe
          style={{
            position: "absolute",
            top: '60px',
            left: 0,
            width: "100%",
            height: "95%"
          }}
          src={`https://drive.google.com/file/d/141x-SJYLk8W5ix4vjVWKi3738Osux2bT/preview`}
          frameBorder="0"
        />
      </div>
    );
  }
}
