import React from 'react';
import { notification, Input, Button, message } from 'antd';
import { USER, MATE } from '../../../../Lib/variables';

class Find extends React.PureComponent {
    state = {
        alert: '',
    };

    onEdit = ({ type, value }) => {
        this.props.editFindForm({ type, value });
    };

    onFind = () => {
        const { email, userType } = this.props.auth.findForm;

        this.props
            .findPassword({ id: email, userType })
            .then(resolve => {
                if (resolve === 'not exist') {
                    return message.info('존재하지 않는 사용자 아이디입니다.');
                }

                if (resolve === 'success') {
                    message.info('이메일로 임시 비밀번호가 전송되었습니다.');
                    setTimeout(() => {
                        this.props.history.push('/user/login');
                    }, 1200);
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    render() {
        const { findForm } = this.props.auth;
        return (
            <div className='find-container'>
                <div className='inner-container'>
                    <div className='login-form'>
                        <div className='login-title'>
                            <p>비밀번호 찾기</p>
                        </div>
                        <div className='form-user-type'>
                            <div
                                onClick={() => {
                                    this.onEdit({ type: 'userType', value: USER });
                                }}
                                className={`type ${findForm && findForm.userType === USER ? 'active' : undefined}`}
                            >
                                <p>일반</p>
                            </div>
                            <div
                                onClick={() => {
                                    this.onEdit({ type: 'userType', value: MATE });
                                }}
                                className={`type ${findForm && findForm.userType === MATE ? 'active' : undefined}`}
                            >
                                <p>메이트</p>
                            </div>
                        </div>
                        <div className='form'>
                            <label>이메일</label>
                            <Input
                                type='email'
                                placeholder='ex) korea@mail.com'
                                value={findForm.email}
                                onChange={ev => {
                                    this.onEdit({ type: 'email', value: ev.target.value });
                                }}
                            />
                            <Button type='primary' onClick={this.onFind}>
                                이메일 전송하기
                            </Button>

                            <div className='link-wrapper'>
                                <span
                                    className='link'
                                    onClick={() => {
                                        this.props.history.push('/user/login');
                                    }}
                                >
                                    로그인
                                </span>
                                <span
                                    className='link'
                                    onClick={() => {
                                        this.props.history.push('/user/register');
                                    }}
                                >
                                    회원가입
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Find;
