import React from 'react';
import { Input, Button, InputNumber, message } from 'antd';
import { mainColor } from '../../../../theme';
import { UNEMER, ASSIST, MATE, DEV } from '../../../../Lib/variables';
import { currencyFormatter } from '../../../../Lib/utils';
import moment from 'moment';

export default class BuyTicket extends React.PureComponent {
    onEdit = ({ type, value }) => {
        this.props.edit({ type, value });
    };

    componentWillUnmount = () => {
        this.onEdit({ type: 'clear', value: '' });
    };

    payment = () => {
        const { id, name, userType } = this.props.auth;
        const { service, count } = this.props.app.buyForm;

        if (userType === MATE) return message.info('해당 서비스는 일반 사용자만 이용가능합니다.');
        if (!id) return this.props.history.push('/logout');

        this.props
            .getServiceInfo({ code: service })
            .then(resolve => {
                if (resolve && Array.isArray(resolve) && resolve.length > 0) {
                    const { _id, defaultPrice } = resolve[0];
                    const discount = count >= 10 ? count * defaultPrice * 0.1 : count >= 4 ? count * defaultPrice * 0.05 : 0;

                    const totalPrice = defaultPrice * count - discount;

                    const IMP = window.IMP; // 생략해도 괜찮습니다.
                    IMP.init('imp47625929'); // "imp00000000" 대신 발급받은 "가맹점 식별코드"를 사용합니다.

                    // IMP.request_pay(param, callback) 호출
                    IMP.request_pay(
                        {
                            // param
                            pg: 'inicis',
                            pay_method: 'card',
                            merchant_uid: _id + '-' + moment().valueOf(),
                            name: service === UNEMER ? '병원 동행 Pro 이용권' : '병원 동행 Basic 이용권',
                            amount: totalPrice,
                            buyer_email: '',
                            buyer_name: name,
                            buyer_tel: '',
                            m_redirect_url: `https://server.withmate.kr/v1/service/buy/ticket/${count}/${id}/${service}/mobile`,
                        },
                        function(rsp) {
                            // callback
                            if (rsp.success === true) {
                                const productInfo = {
                                    productCode: service,
                                    totalPrice: rsp.paid_amount,
                                    productId: _id,
                                    name: rsp.name,
                                    merchantUid: rsp.merchant_uid,
                                    count,
                                    impUid: rsp.imp_uid,
                                    payMethod: rsp.pay_method,
                                    unit: rsp.currency,
                                    paidStatus: rsp.paid === 'paid' ? true : false,
                                    cardName: rsp.card_name,
                                    bankName: rsp.bank_name,
                                    cardQuota: rsp.card_quota, // 할부 월수
                                    buyerEmail: rsp.buyer_email,
                                };

                                this.props
                                    .buyTicket({ userId: id, info: productInfo })
                                    .then(resolve => {
                                        if (resolve && resolve !== 'success') {
                                            return message.error('결제가 정상적으로 수행되지 않았습니다. 새로고침 후 다시 시도해주세요.');
                                        }

                                        if (resolve && resolve === 'success') {
                                            message.success('상품 구매를 완료하였습니다. 서비스를 예약해보세요.');
                                            return this.props.history.push('/');
                                        }
                                    })
                                    .catch(error => {
                                        message.error('상품 결제이력 등록 중 오류가 발생하였습니다. 관리자에게 문의하여 주시기 바랍니다.');
                                    });
                            } else {
                                return message.error('결제 시도 중 오류가 발생하였습니다. 다시 시도해주세요.');
                            }
                        },
                    );
                } else {
                    return message.info('현재 판매 마감된 상품입니다. 다른 상품을 고매해주세요.');
                }
            })
            .catch(error => {
                return message.error('결제 시도 중 오류가 발생하였습니다. 다시 시도해주세요.');
            });
    };

    render() {
        const { service, count } = this.props.app.buyForm;
        const discount =
            count >= 10 ? count * (service === UNEMER ? 120000 : 18000) * 0.1 : count >= 4 ? count * (service === UNEMER ? 120000 : 18000) * 0.05 : 0;
        return (
            <div className='buy-container'>
                <div className='inner-container'>
                    <div className='order-menu-wrapper'>
                        <div className='order-title'>
                            <p>서비스 이용을 위해</p>
                            <p>이용권을 구매해주세요</p>
                        </div>
                        <div className='service'>
                            <p className='info'>
                                <img
                                    src='/assets/images/info-icon.png'
                                    alt='info'
                                    style={{ width: '12px', height: '12px', marginRight: '4px', color: mainColor }}
                                />
                                서비스 선택
                            </p>
                            <div className='service-tab'>
                                <div
                                    className={`tab ${service === UNEMER ? 'active' : ''}`}
                                    onClick={() => {
                                        this.onEdit({ type: 'service', value: UNEMER });
                                        this.onEdit({ type: 'count', value: 1 });
                                    }}
                                >
                                    <span>비응급 동행</span>
                                </div>
                                <div
                                    className={`tab ${service === 'assist' ? 'active' : ''}`}
                                    onClick={() => {
                                        this.onEdit({ type: 'service', value: ASSIST });
                                        this.onEdit({ type: 'count', value: 1 });
                                    }}
                                >
                                    <span>위드 어시스트</span>
                                </div>
                            </div>
                        </div>

                        <div className='service'>
                            <p className='info'>이용권 구매 수량</p>
                            <div>
                                <InputNumber
                                    min={1}
                                    max={30}
                                    value={count}
                                    onChange={val => {
                                        if (typeof val !== 'number') return null;

                                        this.onEdit({ type: 'count', value: val });
                                    }}
                                    formatter={() => {
                                        return `${service === UNEMER ? '병원 동행 Pro x ' : '병원 동행 Basic x '}${count}`;
                                    }}
                                />
                            </div>
                        </div>

                        <div className='fee-wrapper'>
                            <p className='info'>{service === UNEMER ? '병원 동행 Pro' : '병원 동행 Basic'} 이용권 비용</p>
                            <p className='fee'>{service === UNEMER ? '120,000 KRW' : '18,000 KRW'}</p>
                        </div>

                        <div className='fee-wrapper'>
                            <p className='info'>구매 수량</p>
                            <p className='fee'>x {count}</p>
                        </div>

                        {count >= 4 ? (
                            <div className='fee-wrapper'>
                                <p className='info'>할인액</p>
                                <p className='fee'>
                                    {currencyFormatter(discount)}
                                    KRW ({count >= 10 ? 10 : count >= 4 ? 5 : 0}%)
                                </p>
                            </div>
                        ) : null}

                        <div className='total-wrapper'>
                            <p className='info'>Total</p>
                            <p className='fee'>{currencyFormatter(count * (service === UNEMER ? 120000 : 18000) - discount)} KRW</p>
                        </div>

                        <div className='button-wrapper'>
                            <Button style={{ width: '100%', height: '40px' }} type='primary' onClick={this.payment}>
                                구매하기
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
