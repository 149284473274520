import React from 'react';
import { Table, Input, Button, message, Modal, Select } from 'antd';
import axios from '../../../../Lib/axios';

// components
import { MateCallStatusModal } from '../../../../Components/modal';
import { NewHandleReportModal } from '../../../../Components/newModal';
import { InputFilter, CalleList } from '../../../../Components/User/Mate/auth';

// styled
import { GuideRowWrapper2, UserPhoto } from './mate.styled';
import {
    GuideRowWrapper,
    GuideDescriptionSection,
    StudyButton,
    GuideDescriptionSectionTitle,
    GuideDescriptionSectionContent,
    AlertMessage,
    GuideContainer,
} from './mate.styled';
// utils
import moment from 'moment';
import { ASSIST, MATE, UNEMER } from '../../../../Lib/variables';
import { WithmateLabel, WithmateInput, WithmateSelect, DivisionLine } from '../../../../Components/Common/input';
import { NoReportModal } from '../../../../Components/newModal';

class Mate extends React.PureComponent {
    state = {
        active: 'list', // list, info, guide
        reservedFilter: 'none', // none, reserved, matched, complete
        modal: false,

        onRowModal: false,
        rowDetail: null,
        handleReportModal: false,
        report: '',
        keyword: '',
        callList: [],
    };

    componentDidMount = () => {
        this.syncStep();
        this.fetchList();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.active !== this.state.active) {
            this.props.history.push(`/user/mate/mypage/${this.state.active}`);
        }
    };

    syncStep = () => {
        const { params } = this.props.match;
        const { active } = this.state;

        if (params.tab && active !== params.tab) {
            this.setState({ active: params.tab });
        } else if (!params.tab) {
            this.props.history.push(`/user/mate/mypage/list`);
        }
    };

    fetchList = () => {
        const { userType, id } = this.props.auth;
        if (id && userType === MATE) {
            axios.get('/v2/service/mate/call/list', { params: { mateId: id } }).then(resolve => {
                const { data: callList } = resolve;
                if (callList && Array.isArray(callList)) {
                    this.setState({ callList }, () => {
                        const noReports = callList.filter(d => d.call && !d.call.reportId && d.call.status === '서비스 종료').length;
                        if (noReports > 0) {
                            this.setState({
                                noReportModal: true,
                            });
                        }
                    });
                }
            });
        }
    };

    updateBasicInfo = () => {
        const { id, userType } = this.props.auth;
        const { userInfo } = this.props.app;
        const { newPhoto, name, contact } = userInfo;

        if (!name || !contact) return message.info('필수정보인 이름과 연락처를 입력해주세요.');
        if (name.length > 15) return message.info('이름이 너무 깁니다. 이름은 15자 이내로 설정해주세요.');

        const info = { name, contact };
        if (newPhoto) info.photo = newPhoto;

        this.props.modify({ id, userType, info }).then(resolve => {
            if (resolve && resolve.name) {
                message.success('정보를 업데이트 하였습니다.');

                localStorage.setItem('name', resolve.name);
                localStorage.setItem('photo', resolve.photo);

                this.props.editAuth({ type: 'photo', value: resolve.photo });
                this.props.editAuth({ type: 'name', value: resolve.name });
                this.props.editAuth({ type: 'contact', value: resolve.contact });
                this.props.onEdit({ type: 'photo', value: resolve.photo });
                this.props.onEdit({ type: 'newPhoto', value: '' });
            }
        });
    };

    modifyPassword = () => {
        const { passwordForm, id, userType } = this.props.auth;
        const { password, newPassword, reNewPassword } = passwordForm;

        if (password === newPassword) return message.info('새 비밀번호는 기존 비밀번호와 달라야합니다.');
        if (reNewPassword !== newPassword) return message.info('새 비밀번호를 확인해주세요.');

        this.props.modifyPassword({ id, userType, password, newPassword }).then(resolve => {
            if (resolve && resolve === 'wrong password') {
                return message.error('비밀번호가 일치하지 않습니다.');
            }

            if (resolve && resolve === 'not exist') {
                message.error('유효하지 않은 계정입니다. 다시 로그인해주세요.');
                return this.props.history.push('/user/logout');
            }

            if (resolve && resolve === 'success') {
                message.success('비밀번호를 변경하였습니다.');
                this.props.editPassword({ type: 'clear', value: '' });
            }
        });
    };

    withdrawl = () => {
        const { id, userType } = this.props.auth;
        const { password } = this.state.temp;

        if (!password) return message.info('비밀번호를 입력해주세요.');

        this.props.withdrawlUser({ id, userType, password }).then(resolve => {
            if (resolve && resolve === 'wrong password') {
                return message.info('잘못된 비밀번호입니다.');
            }

            if (resolve && resolve === 'success') {
                message.info('회원탈퇴를 완료하였습니다. 이용해주셔서 감사합니다.');
                this.setState({ modal: false, temp: null }, () => {
                    setTimeout(() => {
                        this.props.history.push('/user/logout');
                    }, 500);
                });
            }
        });
    };

    tabHandler = type => {
        this.setState({ active: type });
    };

    onNextProcess = async () => {
        const { rowDetail } = this.state;

        const {
            _id,
            callId,
            service,
            call: { status, reportId },
            reserveDate,
        } = rowDetail;

        if (moment(reserveDate).startOf('day').valueOf() > moment().startOf('day').valueOf()) {
            message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
            return null;
        }

        if (!_id || !callId || !service) {
            return message.error('해당 예약정보는 유효하지 않습니다. 새로고침 후 다시 시도해주세요.');
        }

        if (status && status === '예약접수') {
            return message.warning('아직 고객님이 예약을 확정짓지 않아 진행이 불가능합니다.');
        }

        if (service === UNEMER) {
            if (status === '예약완료') return this.unemergencyService().doReceipt();
            else if (status === '접선') return this.unemergencyService().doStart();
            else if (status === '병원 출발') return this.unemergencyService().doArrive();
            else if (status === '병원 도착') return this.unemergencyService().doCare();
            else if (status === '진료 시작') return this.unemergencyService().doCareDone();
            else if (status === '진료 종료') return this.unemergencyService().doBackStart();
            else if (status === '자택 출발') return this.unemergencyService().doComplete();
            else if (status === '서비스 종료') {
                if (reportId) return message.warning('이미 종료된 서비스입니다.');
                else this.openReportForm();
            }
        }

        if (service === ASSIST) {
            if (status === '예약완료') return this.assistService().doReceipt();
            else if (status === '접선') return this.assistService().doCare();
            else if (status === '진료 시작') return this.assistService().doCareDone();
            else if (status === '진료 종료') return this.assistService().doComplete();
            else if (status === '서비스 종료') {
                if (reportId) return message.warning('이미 종료된 서비스입니다.');
                else this.openReportForm();
            }
        }
    };

    unemergencyService = () => {
        const { id } = localStorage.getItem('id');
        const { rowDetail } = this.state;
        const { _id, callId } = rowDetail;

        const doReceipt = async () => {
            axios.put('/v2/service/receipt/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "접선" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('접선 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doStart = async () => {
            axios.put('/v2/service/start/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "병원 출발" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('병원 출발 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doArrive = async () => {
            axios.put('/v2/service/arrive/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "병원 도착" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('병원 도착 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doCare = async () => {
            axios.put('/v2/service/care/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "진료 시작" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('진료 시작 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doCareDone = async () => {
            axios.put('/v2/service/caredone/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "진료 종료" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('진료 종료 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doBackStart = async () => {
            axios
                .put('/v2/service/goback/mate/call', { reserveId: _id, callId })
                .then(resolve => {
                    const { data } = resolve;
                    if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                    if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                    if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                    if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                    if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                    if (data === 'already done') return message.warning('이미 "자택 출발" 처리 하였습니다.');
                    if (data === 'success') {
                        message.success('자택 출발 처리 완료');
                        this.setState({ onRowModal: false });
                        this.fetchList();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        };

        const doComplete = async () => {
            axios.put('/v2/service/complete/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "서비스 종료" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('서비스 종료 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        return {
            doReceipt,
            doStart,
            doArrive,
            doCare,
            doCareDone,
            doBackStart,
            doComplete,
        };
    };

    assistService = () => {
        const { id } = localStorage.getItem('id');
        const { rowDetail } = this.state;
        const { _id, callId } = rowDetail;

        const doReceipt = async () => {
            axios.put('/v2/service/receipt/normal/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "접선" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('접선 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doCare = async () => {
            axios.put('/v2/service/care/normal/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "진료 시작" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('진료 시작 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doCareDone = async () => {
            axios.put('/v2/service/caredone/normal/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "진료 종료" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('진료 종료 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        const doComplete = async () => {
            axios.put('/v2/service/complete/normal/mate/call', { reserveId: _id, callId }).then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'check parameters') return message.warning('죄송합니다. 요청에 실패하였습니다.');
                if (data === 'not yet') return message.warning('진료 예약일 전에는 예약을 진행할 수 없습니다.');
                if (data === 'invalid call') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                if (data === 'already done') return message.warning('이미 "서비스 종료" 처리 하였습니다.');
                if (data === 'success') {
                    message.success('서비스 종료 처리 완료');
                    this.setState({ onRowModal: false });
                    this.fetchList();
                }
            });
        };

        return { doReceipt, doCare, doCareDone, doComplete };
    };

    onAddReport = () => {
        const { report } = this.state;
        const { description, care, doctorComment } = report;

        if (!description) message.warning('서비스 진행 중 특이사항을 작성해주세요.');
        if (!care) message.warning('진료 및 치료 내용을 작성해주세요.');
        if (!doctorComment) message.warning('의료 기관 및 의사 평가을 작성해주세요.');

        axios
            .post('/v2/review/add/report', { ...report, mateId: localStorage.getItem('id') })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                if (data === 'check parameters') return message.warning('입력하지 않은 내용이 있는지 확인해주세요.');
                if (data === 'already done') return message.warning('이미 리포트 등록을 완료하였습니다. 새로고침하여 확인해주세요.');
                if (data === 'invalid call') return message.warning('유효하지 않은 수행이력입니다. 새로고침 후 다시 시도해주세요.');
                if (data === 'unmatched mate') return message.warning('잘못된 시도입니다. 다른 메이트에게 배정된 예약입니다.');

                message.success('사후리포트 등록 완료');
                this.setState({ report: null, handleReportModal: false });
                this.fetchList();
            })
            .catch(error => {
                return message.error('요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
            });
    };

    inputHandler = ({ type, value }) => {
        this.setState(prevState => ({
            report: {
                ...prevState['report'],
                [type]: value,
            },
        }));
    };

    openReportForm = () => {
        const { rowDetail } = this.state;

        this.setState({
            onRowModal: false,
            rowDetail: null,
            handleReportModal: true,
            report: {
                description: '',
                care: '',
                medicine: '',
                nextSchedule: '',
                mateComment: '',
                doctorComment: '',
                callId: rowDetail.callId,
            },
        });
    };

    render() {
        const { auth } = this.props;
        const { active, reservedFilter, callList, keyword } = this.state;

        const { password, newPassword, reNewPassword } = auth.passwordForm;
        const { list, userInfo } = this.props.app;

        const filteredList = keyword
            ? callList.filter(reserve => {
                  return (
                      (reserve && reserve.reserveCode && reserve.reserveCode.toLowerCase().includes(keyword.toLowerCase())) ||
                      (reserve && reserve.patient && reserve.patient.toLowerCase().includes(keyword.toLowerCase()))
                  );
              })
            : callList;

        return (
            <div className='auth-container'>
                <div className='navigation-container'>
                    <div className='inner-container'>
                        <div className='user-info-container'>
                            <div className='default-info'>
                                <div>{auth.photo ? <img src={auth.photo} alt='user-profile' /> : <span>no image</span>}</div>
                                <p>
                                    <span>{auth.name}님,</span>
                                    <br />
                                    <span>안녕하세요</span>
                                </p>
                            </div>
                            <div className='reserve-info'>
                                <div className='title'>
                                    <p>
                                        수행 통계{' '}
                                        {/* <a href='https://www.notion.so/FAQ-cfe01db37c67472086b414a044ed6370' target='_blank' style={{ fontSize: '12px' }}>
                                            (메이트 교육 자료 바로가기)
                                        </a> */}
                                    </p>
                                </div>
                                <div className='value-wrapper'>
                                    <div className='value-block'>
                                        <div className='title'>
                                            <img src='/assets/images/unemergency-small.png' alt='small-icon' />
                                            <span>병원 동행 Pro</span>
                                        </div>
                                        <div className='value'>
                                            <p>
                                                {callList && callList.length > 0
                                                    ? callList.filter(m => m.service === UNEMER && m.call.status === '서비스 종료').length
                                                    : '0'}
                                                회 수행
                                            </p>
                                        </div>
                                    </div>

                                    <div className='value-block'>
                                        <div className='title'>
                                            <img src='/assets/images/withassist-small.png' alt='small-icon' />
                                            <span>병원 동행 Basic</span>
                                        </div>
                                        <div className='value'>
                                            <p>
                                                {callList && callList.length > 0
                                                    ? callList.filter(m => m.service !== UNEMER && m.call.status === '서비스 종료').length
                                                    : '0'}
                                                회 수행
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='navigator'>
                            <div className='nav-tab'>
                                <span
                                    onClick={() => {
                                        this.tabHandler('list');
                                    }}
                                    className={`name ${active === 'list' ? 'active' : ''}`}
                                >
                                    예약 및 접수내역
                                </span>
                                <span className={`underline ${active === 'list' ? 'active' : ''}`}></span>
                            </div>

                            <div className='nav-tab'>
                                <span
                                    onClick={() => {
                                        this.tabHandler('info');
                                    }}
                                    className={`name ${active === 'info' ? 'active' : ''}`}
                                >
                                    개인정보 관리
                                </span>
                                <span className={`underline ${active === 'info' ? 'active' : ''}`}></span>
                            </div>

                            <div className='nav-tab'>
                                <span
                                    onClick={() => {
                                        this.tabHandler('guide');
                                    }}
                                    className={`name ${active === 'guide' ? 'active' : ''}`}
                                >
                                    메이트 온라인 학습
                                </span>
                                <span className={`underline ${active === 'guide' ? 'active' : ''}`}></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='usage-info-container'>
                    <div className='inner-container'>
                        {active === 'list' && (
                            <>
                                <div className='filter-wrapper'>
                                    <Filter
                                        filter={reservedFilter}
                                        value='none'
                                        name='전체'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'none' });
                                        }}
                                    />
                                    <Filter
                                        filter={reservedFilter}
                                        value='reserved'
                                        name='예약접수'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'reserved' });
                                        }}
                                    />

                                    <Filter
                                        filter={reservedFilter}
                                        value='matched'
                                        name='예약완료 (진행중)'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'matched' });
                                        }}
                                    />
                                    <Filter
                                        filter={reservedFilter}
                                        value='complete'
                                        name='서비스 종료'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'complete' });
                                        }}
                                    />
                                </div>

                                <InputFilter
                                    keyword={this.state.keyword}
                                    keywordHandler={value => {
                                        this.setState({ keyword: value });
                                    }}
                                />

                                <CalleList
                                    list={
                                        reservedFilter === 'none'
                                            ? filteredList
                                            : reservedFilter === 'reserved'
                                            ? filteredList.filter(p => p.call && p.call.status === '예약접수')
                                            : reservedFilter === 'matched'
                                            ? filteredList.filter(
                                                  p =>
                                                      p.call &&
                                                      (p.call.status === '예약완료' ||
                                                          p.call.status === '접선' ||
                                                          p.call.status === '병원 출발' ||
                                                          p.call.status === '병원 도착' ||
                                                          p.call.status === '진료 시작' ||
                                                          p.call.status === '진료 종료' ||
                                                          p.call.status === '자택 출발'),
                                              )
                                            : reservedFilter === 'complete'
                                            ? filteredList.filter(p => p.call && p.call.status === '서비스 종료')
                                            : []
                                    }
                                    onRow={info => {
                                        this.setState({ rowDetail: info, onRowModal: true });
                                    }}
                                />
                            </>
                        )}

                        {active === 'info' && (
                            <>
                                <div className='user-info-wrapper'>
                                    <div className='default-info wrapper'>
                                        <div className='title-wrapper'>
                                            <p>프로필 수정</p>
                                        </div>
                                        <div className='service' style={{ marginBottom: '40px' }}>
                                            <WithmateLabel>프로필 사진</WithmateLabel>
                                            <div>
                                                <input
                                                    id='mate-profile'
                                                    hidden
                                                    type='file'
                                                    accept='image/x-png,image/gif,image/jpeg'
                                                    onChange={ev => {
                                                        this.props.onEdit({ type: 'newPhoto', value: ev.target.files[0] });
                                                    }}
                                                />
                                                <UserPhoto
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        document.getElementById('mate-profile').click();
                                                    }}
                                                >
                                                    <img src={userInfo.newPhoto ? URL.createObjectURL(userInfo.newPhoto) : userInfo.photo} alt='user-profile' />
                                                </UserPhoto>
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>이름</WithmateLabel>
                                            <WithmateInput
                                                placeholder='성함을 입력해주세요.'
                                                value={userInfo.name}
                                                onChange={ev => {
                                                    this.props.onEdit({ type: 'name', value: ev.target.value });
                                                }}
                                            />
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>연락처</WithmateLabel>
                                            <WithmateInput
                                                placeholder='연락처를 입력해주세요.'
                                                value={userInfo.contact}
                                                onChange={ev => {
                                                    this.props.onEdit({ type: 'contact', value: ev.target.value });
                                                }}
                                            />
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>
                                                성별 <span style={{ fontSize: '11px' }}>(변경은 1:1 문의로 인증 후 가능)</span>
                                            </WithmateLabel>
                                            <WithmateInput placeholder='성별을 입력해주세요.' value={userInfo.sex} disabled />
                                        </div>

                                        <div className='service' style={{ marginBottom: '0px' }}>
                                            <Button onClick={this.updateBasicInfo} style={{ backgroundColor: '#484848' }}>
                                                수정된 프로필 저장
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='privacy-info wrapper'>
                                        <div className='title-wrapper'>
                                            <p>비밀번호 변경</p>
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>현재 비밀번호</WithmateLabel>
                                            <div>
                                                <WithmateInput
                                                    type='password'
                                                    placeholder='현재 비밀번호를 입력해주세요.'
                                                    value={password}
                                                    onChange={ev => {
                                                        this.props.editPassword({ type: 'password', value: ev.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>새 비밀번호</WithmateLabel>
                                            <div>
                                                <WithmateInput
                                                    type='password'
                                                    placeholder='새 비밀번호를 입력해주세요.'
                                                    value={newPassword}
                                                    onChange={ev => {
                                                        this.props.editPassword({ type: 'newPassword', value: ev.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <WithmateLabel>새 비밀번호 확인</WithmateLabel>
                                            <div>
                                                <WithmateInput
                                                    type='password'
                                                    placeholder='새 비밀번호를 확인해주세요.'
                                                    value={reNewPassword}
                                                    onChange={ev => {
                                                        this.props.editPassword({ type: 'reNewPassword', value: ev.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <Button onClick={this.modifyPassword} style={{ backgroundColor: '#ffffff', color: '#606060', marginBottom: '0px' }}>
                                                비밀번호 변경
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='etc-info wrapper'>
                                        <div className='title-wrapper'>
                                            <p>기타</p>
                                        </div>

                                        <div className='service'>
                                            <div className='flex-wrapper'>
                                                <p
                                                    onClick={() => {
                                                        this.props.history.push('/policy/privacy');
                                                    }}
                                                >
                                                    개인정보 처리방침 <b>{'>'}</b>
                                                </p>
                                                <p
                                                    onClick={() => {
                                                        this.props.history.push('/policy/terms');
                                                    }}
                                                >
                                                    서비스 이용약관 <b>{'>'}</b>
                                                </p>
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <Button
                                                style={{ backgroundColor: '#b7b7b7' }}
                                                onClick={() => {
                                                    this.props.history.push('/user/logout');
                                                }}
                                            >
                                                로그아웃
                                            </Button>
                                        </div>

                                        <div className='service' style={{ marginBottom: '0px' }}>
                                            <Button
                                                onClick={() => {
                                                    this.setState({ modal: true, temp: { password: '' } });
                                                }}
                                                style={{ color: '#a7a7a7', height: '30px' }}
                                            >
                                                회원탈퇴
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='clearfix' />
                                </div>
                            </>
                        )}

                        {active === 'guide' && (
                            <>
                                {userInfo.doProducts.includes('ITEM_mIagil546753836') ? (
                                    <GuideContainer>
                                        <GuideBlock2
                                            title='메이트 교육영상 시청'
                                            content='메이트 서비스를 활동하기 위한 온라인 영상 교육입니다. 서비스 수행 시 필요한 지침과 규칙을 준수하고 책임을 질 수 있는 서비스 건수를 매칭하기 위해 병원동행서비스의 개념과 수행 방법을 동영상 교육 자료를 통해 학습할 수 있습니다 '
                                            link='/mateEducation/manual'
                                        />
                                    </GuideContainer>
                                ) : null}
                                <GuideContainer>
                                    <GuideBlock
                                        title='서비스 매뉴얼 보기'
                                        content='메이트 서비스를 수행하기 위한 교육 자료입니다. 위드메이트가 제공하는 종류 및 타입별 병원 동행 서비스의 개념과 수행방법, 그리고 주의사항에 대해 학습할 수 있습니다.'
                                        // link='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_service_manual.pdf'
                                        link='/service/manual'
                                    />
                                    <GuideBlock
                                        title='플랫폼 이용 방법 보기'
                                        content='위드메이트 온라인 플랫폼 이용 방법을 설명하는 교육 자료입니다. 플랫폼의 효율적인 사용 방법과 정상적인 병원 동행 서비스 제공을 위한 필수 기능들에 대해 학습할 수 있습니다.'
                                        // link='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_platform_manual.pdf'
                                        link='/platform/manual'
                                    />
                                </GuideContainer>
                                <StudyButton
                                    onClick={() => {
                                        window.open('https://www.notion.so/FAQ-cfe01db37c67472086b414a044ed6370', '_blank');
                                    }}
                                >
                                    메이트 FAQ 바로가기
                                    <img src='/assets/images/w_copy.png' />
                                </StudyButton>

                                <AlertMessage>
                                    *해당 페이지에서 제공되는 모든 교육 자료를 학습하고 숙지한 뒤 서비스 수행이 가능합니다. 서비스 매뉴얼 및 플랫폼 이용 방법
                                    미숙지로 인해 발생하는 피해에 대한
                                    <br />
                                    모든 책임은 메이트 본인에게 있습니다*
                                </AlertMessage>
                            </>
                        )}
                    </div>
                </div>

                {this.state.modal && (
                    <Modal
                        visible={this.state.modal}
                        title='회원탈퇴'
                        footer={null}
                        onCancel={() => {
                            this.setState({ modal: false, temp: null });
                        }}
                    >
                        <WithmateInput
                            type='password'
                            placeholder='회원탈퇴를 위해 비밀번호를 입력해주세요.'
                            value={this.state.temp.password}
                            onChange={ev => {
                                const value = ev.target.value;
                                this.setState(prevState => ({ temp: { password: value } }));
                            }}
                        />
                        <DivisionLine />
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ flex: 1, marginRight: '8px', height: '40px' }}
                                type='danger'
                                onClick={() => {
                                    this.setState({ modal: false, temp: null });
                                }}
                            >
                                취소
                            </Button>
                            <Button style={{ flex: 1, height: '40px' }} type='primary' onClick={this.withdrawl}>
                                확인
                            </Button>
                        </div>
                    </Modal>
                )}

                {this.state.noReportModal && (
                    <NoReportModal
                        visible={this.state.noReportModal}
                        visibleHandler={() => {
                            this.setState({ noReportModal: false, reservedFilter: 'complete' });
                        }}
                    />
                )}

                {this.state.onRowModal ? (
                    <MateCallStatusModal
                        visibleHandler={() => {
                            this.setState({ onRowModal: false, rowDetail: null });
                        }}
                        info={this.state.rowDetail}
                        onNext={this.onNextProcess}
                    />
                ) : null}

                {this.state.handleReportModal ? (
                    <NewHandleReportModal
                        data={this.state.report}
                        visible={this.state.handleReportModal}
                        visibleHandler={() => {
                            this.setState({ handleReportModal: false, report: null });
                        }}
                        onSave={this.onAddReport}
                        inputHandler={this.inputHandler}
                    />
                ) : null}
            </div>
        );
    }
}
export default Mate;

const GuideBlock = ({ title, content, link }) => (
    <GuideRowWrapper>
        <GuideDescriptionSectionTitle
            onClick={() => {
                window.open(link, '_blank');
            }}
        >
            {title} <img src={'/assets/images/w_right_arrow.png'} alt='링크' />
        </GuideDescriptionSectionTitle>
        <GuideDescriptionSection>
            <GuideDescriptionSectionContent>{content}</GuideDescriptionSectionContent>
        </GuideDescriptionSection>
    </GuideRowWrapper>
);

const GuideBlock2 = ({ title, content, link, history }) => (
    <GuideRowWrapper2>
        <GuideDescriptionSectionTitle
            onClick={() => {
                window.open(link, '_blank');
            }}
        >
            {title} <img src={'/assets/images/w_right_arrow.png'} alt='링크' />
        </GuideDescriptionSectionTitle>
        <GuideDescriptionSection>
            <GuideDescriptionSectionContent>{content}</GuideDescriptionSectionContent>
        </GuideDescriptionSection>
    </GuideRowWrapper2>
);

const Filter = ({ filter, value, onClick, name }) => {
    return (
        <span className={`filter ${filter === value ? 'active' : ''}`} onClick={onClick}>
            {name}
        </span>
    );
};
