import React, { Component } from 'react';
import Styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import KakaoLoginImg from './o2.png';
import KakaoLoginImgM from './o.png';
import { KAKAO, USER } from '../../../../Lib/variables';
import { notification } from 'antd';

const { Kakao } = window;

class KakaoLogin extends Component {
    onEdit = ({ type, value }) => {
        this.props.editRegisterForm({ type, value });
    };

    checkUser = async ({ email }) => {
        const { userType, type } = this.props.auth.registerForm;
        const resolve = await this.props.checkUserId({ id: email, userType: USER, type: KAKAO });
        console.log(resolve);
        return resolve;
    };

    register = async ({ email, gender }) => {
        const { photo, contact, marketing, connection, birth, message, userType, type, name } = this.props.auth.registerForm;
        this.onEdit({ type: 'type', value: KAKAO });
        this.onEdit({ type: 'email', value: email });
        this.onEdit({ type: 'sex', value: gender === 'male' ? '남' : '녀' });
        this.props.history.push('/user/register');
        // const resolve = await this.props.register({ id: email, userType: USER, name, type: KAKAO, photo, contact, marketing, connection, birth, sex: gender });
        // if (resolve && resolve.message === 'success') {
        // }

        // if (resolve === 'check parameters') {
        //   notification.open({
        //     message: '회원가입에 실패하였습니다.',
        //     description: '누락된 정보가 없는지 확인해주세요.',
        //     type: 'success',
        //   });
        // }
    };

    login = async ({ email }) => {
        const { userType, type } = this.props.auth.registerForm;
        const resolve = await this.props.login({ id: email, userType: USER, type: KAKAO });
        if (resolve === 'not exist') {
            return this.setState({
                modal: true,
                modalTitle: '이메일 로그인',
                modalInfo: '존재하지 않는 사용자의 아이디입니다. 아이디를 확인해주세요.',
            });
        }

        if (resolve === 'not allowed') {
            return this.setState({
                modal: true,
                modalTitle: '이메일 로그인',
                modalInfo: '관리자로부터의 가입 승인을 대기중입니다. ',
            });
        }

        if (resolve === 'wrong password') {
            return this.setState({
                modal: true,
                modalTitle: '이메일 로그인',
                modalInfo: '잘못된 비밀번호입니다. 확인 후 다시 시도해주세요. ',
            });
        }
    };

    loginWithKakao = () => {
        // scope : 수집할 사용자 정보 명시(동의항목)
        const scope = 'profile_nickname, gender, account_email';

        const that = this;
        // const { photo, contact, marketing, connection, birth, message } = this.props.auth.registerForm;

        Kakao.Auth.login({
            scope,
            // success는 인증 정보를 응답(response) 받는다.
            success: function (response) {
                //카카오 SDK에 사용자 토큰을 설정한다.
                window.Kakao.Auth.setAccessToken(response.access_token);
                // console.log(`AccessToken 정보 : ${window.Kakao.Auth.getAccessToken()}`);

                window.Kakao.API.request({
                    url: '/v2/user/me',
                    success: async function ({ kakao_account }) {
                        //어떤 정보 넘어오는지 확인
                        // console.log(kakao_account);
                        const { email, gender } = kakao_account;

                        // console.log(email)
                        const resolve = await that.checkUser({ email });
                        console.log(resolve);
                        if (resolve === true) {
                            that.login({ email });
                        } else {
                            that.register({ email, gender });
                        }
                    },
                    fail: function (error) {
                        console.log(error);
                    },
                });
            },
            fail: function (error) {
                console.log(error);
            },
        });
    };
    render() {
        const { location } = this.props.history;

        let viewToShow;
        if (location.pathname.includes('/login')) {
            viewToShow = <ButtonWrap onClick={this.loginWithKakao}>카카오 로그인</ButtonWrap>;
        } else if (location.pathname.includes('/mainRegister')) {
            viewToShow = (
                <ButtonWrap2 onClick={this.loginWithKakao}>
                    <span className='visually-hidden'>카카오</span>
                </ButtonWrap2>
            );
        }

        return <div className='KakaoIdLogin'>{viewToShow}</div>;
    }
}

export default withRouter(KakaoLogin);

const ButtonWrap = Styled.div`
  background-image: url(${KakaoLoginImg});
  background-repeat: no-repeat;
  margin: -10px auto;
  padding-top: 12px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 45px;
  border-radius: 12px;

    @media (max-width: 768px) {
      background-image: url(${KakaoLoginImgM});
      width: 100%;
      height: 45px;
    }
`;

const ButtonWrap2 = Styled.div`
  background-image: url(${'/assets/images/icons/kakaotalk.png'});
  background-repeat: no-repeat;
  margin: -10px auto;
  text-align: center;
  width: 50px;
  height: 50px;
  overflow: hidden;

  @media (max-width: 768px) {
    background-image: url(${'/assets/images/icons/kakaotalk.png'});
    width: 100%;
    height: 45px;
  }

  span.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }
`;
