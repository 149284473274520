import React from 'react';
import styled from 'styled-components';
import { Input, message, notification } from 'antd';
import axios from '../../../Lib/axios';

import { sizeHandler, mainColor } from '../../../theme';
import { colorParserByStatus } from '../../../Lib/utils';
import { ASSIST, UNEMER, HAPPYCARE } from '../../../Lib/variables';

import moment from 'moment';
export const InputFilter = ({ keyword, keywordHandler }) => (
    <FilterWrapper>
        <Input
            value={keyword}
            onChange={ev => {
                keywordHandler(ev.target.value);
            }}
            placeholder='예약번호, 담당자 성함 등을 검색해보세요.'
        />
    </FilterWrapper>
);

const FilterWrapper = styled.div`
    margin-bottom: 12px;
`;

export const ReserveList = ({ list, onDetailModal, onMateModal, onReport, onCancel }) => {
    if (!list || list.length === 0) {
        return (
            <FullContainer>
                <Inner>
                    <EmptyList>이용내역이 없습니다.</EmptyList>
                </Inner>
            </FullContainer>
        );
    }

    return (
        <ListConatiner>
            {list.map((item, index) => {
                const serviceType =
                    !item.productInfo || !item.productInfo.usePro || !item.productInfo.useBasic ? '' : item.service === 'unemergency' ? 'Pro' : 'Basic';
                const serviceName = (item.productInfo && item.productInfo.name && item.service && item.productInfo.name + serviceType) || '-';

                return (
                    <ReserveItem
                        key={item._id + index + 'service-list'}
                        reserveCode={item.reserveCode}
                        service={serviceName}
                        serviceType={item.service}
                        reserveDate={moment(item.reserveDate).format('MM월 DD일 dddd HH시mm분')}
                        destination={item.destination}
                        starting={item.starting}
                        status={item.call.status}
                        onReport={() => {
                            onReport(item);
                        }}
                        onDetail={() => {
                            onDetailModal(item._id);
                        }}
                        onMate={() => {
                            if (item.call.status === '접수취소') {
                                return message.info('접수취소된 서비스입니다.');
                            }

                            if (!item.call.mate || !item.call.mate) {
                                return message.info('아직 담당자가 배정되지 않았습니다.');
                            }

                            onMateModal({
                                ...item.call.mate,
                                service: item.service,
                                status: item.call.status,
                                callId: item.call._id,
                                reserveId: item._id,
                                mateId: item.call.mateId,
                            });
                        }}
                        onCancel={() => {
                            const confirmCancel = window.confirm('예약을 취소하시겠습니까?');
                            if (confirmCancel) {
                                axios
                                    .put('/v2/service/cancel/reserve/by/user', {
                                        reserveId: item._id,
                                        callId: item.callId,
                                    })
                                    .then(resolve => {
                                        const { data } = resolve;
                                        if (!data) return message.error('요청 실패');
                                        else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                                        else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                                        else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                                        else if (data === 'success') {
                                            notification.success({
                                                message: '예약 취소',
                                                description: '예약 취소가 완료 되었습니다.',
                                            });
                                            // setTimeout(() => {
                                            //     window.location.reload();
                                            // }, 3000);
                                            return null;
                                        }
                                    });
                            }
                        }}
                    />
                );
            })}
        </ListConatiner>
    );
};

const ReserveItem = ({ reserveCode, service, serviceType, reserveDate, destination, starting, status, onReport, onDetail, onMate, onCancel }) => (
    <ItemContainer>
        <TitleSection>
            <ItemColumn>
                <ItemTitle>서비스 명</ItemTitle>
                <ItemContents style={{ color: mainColor }}>{service}</ItemContents>
            </ItemColumn>
        </TitleSection>

        <ContentSection>
            <ItemColumn>
                <ItemTitle>예약 번호</ItemTitle>
                <ItemContents>{reserveCode}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>진행 상황</ItemTitle>
                <ItemContents style={{ color: colorParserByStatus(status) }}>{status}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>예약 날짜</ItemTitle>
                <ItemContents>{reserveDate}</ItemContents>
            </ItemColumn>
            {serviceType === 'unemergency' && (
                <ItemColumn>
                    <ItemTitle>자택 주소</ItemTitle>
                    <ItemContents>{starting}</ItemContents>
                </ItemColumn>
            )}
            <ItemColumn>
                <ItemTitle>병원 위치</ItemTitle>
                <ItemContents>{destination}</ItemContents>
            </ItemColumn>
        </ContentSection>
        <ItemColumn>
            <DetailButton
                onClick={() => {
                    if (status === '서비스 종료') {
                        onReport();
                    } else {
                        onMate();
                    }
                }}
            >
                <span>{status === '서비스 종료' ? '서비스 리포트' : '담당자 확인'}</span>
            </DetailButton>
        </ItemColumn>
        <ItemColumn>
            {status === '접수취소' ? null : (
                <ReserveButton
                    onClick={() => {
                        if (status === '서비스 종료') {
                            return null;
                        } else {
                            onCancel();
                        }
                    }}
                >
                    <span>예약 취소</span>
                </ReserveButton>
            )}
        </ItemColumn>
        <ItemColumn>
            <ReserveButton
                onClick={() => {
                    onDetail();
                }}
            >
                <span>예약 확인</span>
            </ReserveButton>
        </ItemColumn>
    </ItemContainer>
);
const TitleSection = styled.div``;

const EmptyList = styled.p`
    font-size: 20px;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 16px;
    position: relative;
    text-align: center;
`;

const FullContainer = styled.div`
    width: 100%;
    min-height: 300px;
`;

const Inner = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding-top: 40px;

    @media (max-width: 1080px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (max-width: 768px) {
        padding-top: 40px;
    }
`;
const ContentSection = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    overflow-y: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const ListConatiner = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div:nth-child(3n) {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        > div:nth-child(3n) {
            margin-right: 16px;
        }

        > div:nth-child(2n) {
            margin-right: 0px;
        }
    }

    @media (max-width: 480px) {
        > div:nth-child(3n) {
            margin-right: 0px;
        }

        flex-direction: column;
        justify-content: center;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 340px;
    height: 430px;
    overflow: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    padding: 20px;
    margin-bottom: 16px;
    margin-right: 16px;

    border-radius: 4px;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        width: 100%;
        max-width: initial;
        margin-right: 0px;
    }
`;

const ItemTitle = styled.p`
    color: #a7a7a7;
    font-size: ${sizeHandler(12)};
    font-weight: bold;
    margin: 0px;
    padding: 0px;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(11)};
    }
`;
const ItemContents = styled.p`
    font-size: ${sizeHandler(14)};
    font-weight: bold;
    color: #606060;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(12)};
        margin-bottom: 4px;
    }
`;

const ItemColumn = styled.div`
    width: 100%;
    margin-bottom: 6px;

    @media (max-width: 768px) {
        display: ${props => (props.hiddenMobile ? 'none' : 'block')};
        width: 100%;
    }
`;

const DetailButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 44px;

    border-radius: 4px;
    border: 1px solid ${mainColor};
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    span {
        font-size: ${sizeHandler(14)};
        font-weight: bold;
        color: ${mainColor};
        text-align: center;

        @media (max-width: 430px) {
            font-size: ${sizeHandler(12)};
        }
    }

    &:hover {
        background-color: ${mainColor};
        span {
            color: white;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 34px;
        font-size: ${sizeHandler(12)};
    }
`;

const ReserveButton = styled(DetailButton)`
    border: 1px solid #e9e9e9;
    color: #565656;

    > span {
        color: #565656;
    }
`;
