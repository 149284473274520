import React from "react";
import Modal from "react-modal";
import styled from 'styled-components';
import { Button, Checkbox, notification, Select, message, Radio } from 'antd';

import { DivisionLine, WithmateSelect, WithmateInput, WithamteInputWithButton, WithmateLabel, WithmateTextarea } from "../../../../Components/Common/input";
import { HalfWidthButton } from '../../../../Components/Common/button';
import ProfileStyledContainer from "./profile.styled";
import { MobileBr } from "../../../Guide/guide.styled";
import { addPatient } from "../../../../Lib/Api/auth";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(15, 15, 15, 0.79)",
    },
    content: {
        position: "absolute",
        top: "30px",
        height: "90%",
        border: "1px solid #ccc",
        background: "#ffffff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
    },
};

class NewProfileComponent extends React.PureComponent {
    state = {
        modalInfo: '',
        modalTitle: '',
    };

    onEdit = ({ type, value }) => {
        this.props.editAddPatientForm({ type, value });
    };

    onStarting = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.onEdit({ type: 'shortStarting', value: `${sido} ${sigungu} ${bname}` });
                this.onEdit({ type: 'starting', value: `${roadAddress} (${ buildingName })` });
            },
        }).open();
    };

    onProfile = () => {
        const {
            name,
            relate,
            sex,
            age,
            contact,
            starting,
            environment,
            caution
        } = this.props.auth.addPatientForm;

        if (!name) {
            return message.warning('성함을 입력해주세요.');
        }

        if (!relate) {
            return message.warning('관계를 선택해주세요.');
        }

        if (!sex) {
            return message.warning('성별을 선택해주세요.');
        }

        if (!age || age.length > 3) {
            return message.warning('나이를 입력해주세요.');
        }

        if (!contact) {
            return message.warning('연락처를 입력해주세요.');
        }

        if (!starting) {
            return message.warning('주소(자택, 출발지)를 입력해주세요.');
        }

        if (!environment) {
            return message.warning('거주환경을 입력해주세요.');
        }

        if (!caution) {
            return message.warning('메이트와 동행할 때 주의해야할 점을 선택해주세요.');
        }

        this.props
            .addPatient({
                id: this.props.auth.id, name, relate, sex, age, contact, starting, environment, caution
            })
            .then(resolve => {
                // console.log(resolve)
                if (resolve.message === 'success') {
                    this.props.onClose();
                    this.onEdit({ type: 'clear', value: '' });
                    notification.open({
                        message: '환자 프로필 등록이 완료되었습니다.',
                        description: '등록된 환자 프로필을 서비스 예약시 편리하게 이용하실 수 있습니다.',
                        type: 'success'
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    }

    formCompleteStatus = () => {
        let valid = true;
        if (!this.props.auth || !this.props.auth.addPatientForm) return false;

        const { name, relate, sex, age, contact, starting, environment, caution } = this.props.auth.addPatientForm;
        if (!name) valid = false;
        if (!relate) valid = false;
        if (!sex) valid = false;
        if (!age) valid = false;
        if (!contact) valid = false;
        if (!starting) valid = false;
        if (!environment) valid = false;
        if (!caution) valid = false;

        return valid;
    };

    render() {
        const { addPatientForm } = this.props.auth;
        const status = this.formCompleteStatus();
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <ProfileStyledContainer>
                    <div className='inner-container'>
                        <div className='profile-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>환자 프로필 등록</WideModalTitle>
                                    <AlertText>
                                        *위드메이트 서비스를 사용하실 프로필을 등록할 수 있습니다.
                                    </AlertText>
                                </TitleSection>

                                <br />

                                <WithmateLabel required>환자 이름</WithmateLabel>
                                <WithmateInput
                                    type='text'
                                    placeholder='ex. 홍길동'
                                    value={addPatientForm.name || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'name', value: ev.target.value.trim() });
                                    }}
                                />

                                <DivisionLine />

                                <WithmateLabel required>본인-{addPatientForm.name}님 간 관계</WithmateLabel>
                                <WithmateSelect
                                    style={{ height: '48px', width: '100%' }}
                                    placeholder='환자 프로필을 등록하는 대상의 관계를 선택해주세요.'
                                    value={addPatientForm.relate || undefined}
                                    onChange={value => {
                                        this.onEdit({ type: 'relate', value });
                                    }}
                                >
                                    <Select.Option value='본인'>본인</Select.Option>
                                    <Select.Option value='부모'>부모</Select.Option>
                                    <Select.Option value='자매'>자매</Select.Option>
                                    <Select.Option value='친구'>친구</Select.Option>
                                    <Select.Option value='지인'>지인</Select.Option>
                                    <Select.Option value='기타'>기타</Select.Option>
                                </WithmateSelect>

                                <DivisionLine />

                                <WithmateLabel required>{addPatientForm.name}님 성별</WithmateLabel>
                                <div className='type-button-wrapper'>
                                    <div
                                        className={`type-button ${addPatientForm.sex && addPatientForm.sex === '남자' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.onEdit({ type: 'sex', value: '남자' });
                                        }}
                                    >
                                        남자
                                    </div>
                                    <div
                                        className={`type-button ${addPatientForm.sex && addPatientForm.sex === '여자' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.onEdit({ type: 'sex', value: '여자' });
                                        }}
                                    >
                                        여자
                                    </div>
                                </div>

                                <DivisionLine />

                                <WithmateLabel required>{addPatientForm.name}님 나이</WithmateLabel>
                                <WithmateInput
                                    type='text'
                                    maxLength='2'
                                    placeholder=''
                                    name='age'
                                    suffix='세'
                                    value={addPatientForm.age || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'age', value: ev.target.value.trim() });
                                    }}
                                />

                                <DivisionLine />

                                <WithmateLabel required>{addPatientForm.name}님 연락처</WithmateLabel>
                                <WithmateInput
                                    type='text'
                                    maxLength={11}
                                    placeholder='숫자만 입력해주세요.(ex. 01012345678)'
                                    value={addPatientForm.contact || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'contact', value: ev.target.value.trim() });
                                    }}
                                />

                                <DivisionLine />

                                <WithmateLabel required>{addPatientForm.name}님의 주소(자택 혹은 출발지)를 입력해주세요.</WithmateLabel>
                                <WithamteInputWithButton
                                    inputConfig={{
                                        type: 'text',
                                        placeholder: 'ex) 서울특별시 도산대로  40, OO 아파트',
                                        name: 'starting',
                                        disabled: true,
                                        value: addPatientForm.starting || ''
                                    }}
                                    buttonName='검색'
                                    onButton={() => { this.onStarting() }}
                                />
                                <DivisionLine />

                                <WithmateLabel required>{addPatientForm.name}님은 외출하실 때 '꼭' 계단을 이용하셔야 하나요?</WithmateLabel>
                                <Radio
                                    checked={addPatientForm.environment === '네'}
                                    onClick={() => {
                                        this.onEdit({ type: 'environment', value: '네' });
                                    }}
                                >
                                    네
                                </Radio>
                                <Radio checked={addPatientForm.environment === '아니요, 엘레베이터가 있어요'}
                                    onClick={() => {
                                        this.onEdit({ type: 'environment', value: '아니요, 엘레베이터가 있어요' })
                                    }}
                                >
                                    아니요, 엘레베이터가 있어요
                                </Radio>
                                <Radio checked={addPatientForm.environment === '1층이라 괜찮아요'}
                                    onClick={() => {
                                        this.onEdit({ type: 'environment', value: '1층이라 괜찮아요' })
                                    }}
                                >
                                    1층이라 괜찮아요
                                </Radio>
                                <DivisionLine />

                                <WithmateLabel required>메이트가 {addPatientForm.name}님과 동행할 때 주의해야 할 점이 있을까요?</WithmateLabel>
                                <WithmateInput
                                    type='text'
                                    placeholder='메이트 도움 필요 소요(승하차 어려움, 보행 어려움, 의료진 설명 이해 어려움 등) 및 메이트 주의 사항'
                                    value={addPatientForm.caution || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'caution', value: ev.target.value });
                                    }}
                                />
                                <Checkbox checked={addPatientForm.caution === '없어요'}
                                    onClick={() => {
                                        this.onEdit({ type: 'caution', value: '없어요' })
                                    }}
                                >
                                    없어요
                                </Checkbox>

                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ marginRight: '8px', color: '#afb0b2' }} bg='#e6e6e6'
                                        onClick={this.props.onClose}
                                    >
                                        닫기
                                    </HalfWidthButton>
                                    <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff'
                                        onClick={() => {
                                            this.onProfile()
                                        }}
                                        style={!status ? { backgroundColor: '#E6E6E6', border: 0, cursor: 'not-allowed' } : {}}
                                    >
                                        프로필 등록
                                    </HalfWidthButton>
                                </ButtonWrap>

                                <MobileBr />
                            </div>
                        </div>
                    </div>
                </ProfileStyledContainer >
            </Modal >
        );
    }
};

export default NewProfileComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;

const Agree = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 8px;

    span {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.7);
    }

    span.point {
        color: #3bc1ff;
    }

    a.point {
        color: #3bc1ff;
        cursor: pointer;
        text-decoration: none;
    }

    .ant-checkbox-wrapper {
        margin-bottom: 0px !important;
    }
`;

const Alert = styled.p`
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.15px;
    color: #ff5500;
`;