import React from 'react';
import styled from 'styled-components';
import { mainColor } from '../../../theme';

export const Container = styled.div`
    width: 100vw;
    height: auto;
    padding-top: 55px;
`;

export const TopWrapper = styled.div`
    width: 100%;
    height: auto;

    // background-image: url('/assets/images/main-background.png');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #80abff;
`;

export const BottomWrapper = styled.div`
    width: 100%;
    background-color: white;
`;

export const InnerContainer = styled.div`
    max-width: 1080px;
    margin: 0 auto;

    padding: 80px 0px;
    position: relative;

    img.bg {
        width: auto;
        height: 100%;

        position: absolute;
        top: 0px;
        right: 0px;
    }

    @media (max-width: 1080px) {
        padding: 80px 16px;
        img.bg {
            display: none;
        }

        br {
            display: block;
        }
    }

    @media (max-width: 768px) {
        padding: 80px 16px;

        br {
            display: block;
        }
    }
`;

export const Logo = styled.img`
    display: inline-block;
    width: 200px;
    object-fit: contain;
`;

export const Title = styled.p`
    margin-bottom: 24px;

    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
`;

export const MediumText = styled.p`
    margin-bottom: 8px;

    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.7px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.7px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
        border-bottom: 1px solid #000000;
    }
`;

export const Noti = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    word-break: keep-all;
`;

export const ApplyButton = styled.span`
    padding: 14px 28px;
    background-color: #3bc1ff;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.1px;
    text-align: center;
    color: #ffffff;

    border-radius: 4px;
    min-width: 230px;
    overflow: hidden;

    display: inline-block;
    margin-top: 42px;
    cursor: pointer;

    &:hover {
        transition: all 0.5s;
        background-color: ${mainColor};
        color: white;
    }
`;

export const LargeText = styled.p`
    margin-bottom: 0px;

    font-weight: bold;
    color: #ffffff;
    font-size: 32px;

    padding: 2.5px;
    border-bottom: 4px solid #ffffff;
    display: inline-block;
`;

export const FlexWrapper = styled.div`
    display: flex;

    > div {
        flex: 1;
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const FlexTitle = styled.p`
    display: inline-block;

    font-size: 16px;
    font-weight: bold;
    color: #161616;

    padding: 12px;
    border-bottom: 0.5px solid #161616;

    margin-bottom: 20px;
`;

export const Contact = styled.p`
    margin-bottom: 8px;

    font-weight: bold;
    color: #161616;
    font-size: 20px;
`;

export const ContactTime = styled.p`
    font-weight: bold;
    color: #161616;
    font-size: 12px;
`;

export const Link = styled.a`
    display: inline-block;
    margin-bottom: 8px;

    color: #565656;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
`;
