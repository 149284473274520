import React from 'react';

class TrdBlock extends React.PureComponent {
    render() {
        return (
            <div className='trd-section'>
                <div className='inner-container'>
                    <div className='text-block'>
                        <p className='title'>암 치료 동행 전문 기업</p>
                        <p className='content'>
                            위드메이트는 자타공인 국내 최고 수준의 암 치료 동행 서비스를 제공합니다. 위드메이트는 청담동과 하남에 위치한 암 전문 요양 병원과
                            <br />
                            국립 암센터와의 협업 시스템 구축을 통해 항암 치료 동행과 관련된 다양한 경험과 데이터를 축적했습니다.
                            <br />
                            이를 통해 위드메이트는 단순한 동행이 아닌, 전문적인 수준의 암 치료 동행 서비스를 제공합니다.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
export default TrdBlock;
