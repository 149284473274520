import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ASSIST, UNEMER } from '../../../Lib/variables';
import ReviewPageHeader from '../../../Components/Templates/Review/header';
import {
    FullContainer,
    TableContainer,
    TableHeader,
    TableTitle,
    PaddingDiv,
    TableBody,
    TableSubTitle,
    TableAlert,
    TableRow,
    TableField,
    TableButton,
    EmptyWrapper,
    EmptyText,
    EmptyImage,
} from './mylist.styled';

export default class MyReviewList extends React.PureComponent {
    onReserve = _id => {
        this.props.history.push('/review/edit/' + _id);
    };

    render() {
        const { reserveList } = this.props.app;
        const done_list = reserveList.filter(reserve => reserve.call.length > 0 && reserve.call[0].status === '서비스 종료' && reserve.review.length === 0);

        return (
            <div>
                <ReviewPageHeader title='이용 후기 쓰기' desc={`후기 작성 시 서비스 요금 2,000원\n 할인이 적용됩니다.`}>
                    <PaddingDiv style={{ paddingTop: '120px' }} />
                </ReviewPageHeader>

                <FullContainer>
                    <TableContainer className='inner-container'>
                        <TableHeader>
                            <TableTitle>이용 내역 선택</TableTitle>
                            <TableSubTitle>후기를 작성하실 이용 내역을 선택해주세요</TableSubTitle>
                        </TableHeader>

                        <TableBody>
                            <TableRow>
                                <TableField title style={{ width: '40px' }}>
                                    서비스
                                </TableField>
                                <TableField title style={{ width: '64px' }}>
                                    환자 이름
                                </TableField>
                                <TableField title style={{ width: '110px' }}>
                                    예약 병원
                                </TableField>
                                <TableField title style={{ width: '200px' }}>
                                    예약 시간
                                </TableField>
                                <TableField title style={{ width: '120px' }}>
                                    신청 날짜
                                </TableField>
                                <TableField title style={{ width: '70px' }}>
                                    진행 현황
                                </TableField>
                                <TableField></TableField>
                            </TableRow>
                            {done_list.length > 0 ? (
                                done_list.map((reserve, index) => {
                                    return (
                                        <TableRow key={reserve._id.substr(4, 8) + index.toString()}>
                                            <TableField style={{ width: '40px' }}>{reserve.service === ASSIST ? 'BASIC' : 'PRO'}</TableField>
                                            <TableField style={{ width: '64px' }}>{reserve.patient}님</TableField>
                                            <TableField style={{ width: '110px' }}>{reserve.destination || '-'}</TableField>
                                            <TableField style={{ width: '200px' }}>{moment(reserve.reserveDate).format('YYYY년 MM월 DD일 - HH:mm')}</TableField>
                                            <TableField style={{ width: '120px' }}>{moment(reserve.createdAt).format('YYYY년 MM월 DD일')}</TableField>
                                            <TableField style={{ width: '70px' }}>{reserve.call.length > 0 ? reserve.call[0].status : '-'}</TableField>
                                            <TableField>
                                                <TableButton
                                                    onClick={() => {
                                                        this.onReserve(reserve._id);
                                                    }}
                                                >
                                                    작성 하기
                                                </TableButton>
                                            </TableField>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <EmptyList />
                            )}
                            {/* 
                            {reserveList
                                .map((reserve, index) => {
                                    return (
                                        <TableRow key={reserve._id.substr(4, 8) + index.toString()}>
                                            <TableField style={{ width: '40px' }}>{reserve.service === ASSIST ? 'BASIC' : 'PRO'}</TableField>
                                            <TableField style={{ width: '64px' }}>{reserve.patient}님</TableField>
                                            <TableField style={{ width: '86px' }}>{reserve.destination || '-'}</TableField>
                                            <TableField style={{ width: '170px' }}>{moment(reserve.reserveDate).format('YYYY년 MM월 DD일 - hh:mm')}</TableField>
                                            <TableField style={{ width: '120px' }}>{moment(reserve.creaetedAt).format('YYYY년 MM월 DD일')}</TableField>
                                            <TableField style={{ width: '70px' }}>{reserve.call.length > 0 ? reserve.call[0].status : '-'}</TableField>
                                            <TableField>
                                                {reserve.call.length > 0 && reserve.call[0].status === '서비스 종료' && reserve.review.length === 0 ? (
                                                <TableButton
                                                    onClick={() => {
                                                        this.onReserve(reserve._id);
                                                    }}
                                                >
                                                    작성 하기
                                                </TableButton>
                                            ) : (
                                                <TableButton className='disabled'>작성 불가</TableButton>
                                            )}
                                            </TableField>
                                        </TableRow>
                                    );
                                })} */}
                        </TableBody>

                        {/* <TableAlert>* 최근 2주내의 이용 내역에 대한 리뷰만 작성이 가능합니다.</TableAlert> */}
                    </TableContainer>
                </FullContainer>
            </div>
        );
    }
}

const EmptyList = () => (
    <EmptyWrapper>
        <EmptyImage src='/assets/images/main_logo.png' alt='logo' />
        <EmptyText>후기를 작성가능한 이용내역이 없습니다.</EmptyText>
    </EmptyWrapper>
);
