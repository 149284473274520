import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Collapse, Icon } from 'antd';
import { mainColor } from '../../../theme';
const { Panel } = Collapse;

export default class FAQ extends React.PureComponent {
    render() {
        const { hash } = this.props.location;
        return (
            <div className='faq-container'>
                <div className='faq-header'>
                    <div className='inner-container'>
                        <div className='flex-wrapper'>
                            <div className='faq-main-title'>
                                <p className='title'>위드메이트 FAQ</p>
                                <p className='description'>고객님의 궁금증을 해소하세요!</p>
                            </div>
                            <div className='img-section'>
                                <img src={'/assets/images/faq_bg2.png'} alt='faq-img' />
                            </div>
                            <div className='faq-nav'>
                                <div className='nav-tab'>
                                    <HashLink className='name' to='/360F/faq/#common' scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                        공통 FAQ
                                    </HashLink>
                                    <span className={`underline ${hash === '#common' ? 'active' : undefined}`}></span>
                                </div>
                                <div className='nav-tab'>
                                    <HashLink
                                        className='name'
                                        to='/360F/faq/#emergency'
                                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                                    >
                                        병원 동행 Pro FAQ
                                    </HashLink>
                                    <span className={`underline ${hash === '#emergency' ? 'active' : undefined}`}></span>
                                </div>
                                <div className='nav-tab'>
                                    <HashLink className='name' to='/360F/faq/#assist' scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                        병원 동행 Basic FAQ
                                    </HashLink>
                                    <span className={`underline ${hash === '#assist' ? 'active' : undefined}`}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='faq-contents'>
                    <div className='inner-container'>
                        <h1 id='common'>공통 FAQ</h1>
                        <div>
                            <Collapse
                                expandIconPosition='right'
                                bordered={false}
                                expandIcon={({ isActive }) => (
                                    <Icon type='right' rotate={isActive ? 90 : -90} style={{ color: isActive ? mainColor : '#a7a7a7' }} />
                                )}
                            >
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-1' title='동행인의 신원은 확실한가요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            현재 위드메이트는 적격 자격을 갖춘 인원들을 선별하고 세밀한 채용 단계를 거친 후, 영업 배상 책임 보험 가입과 함께
                                            엄격한 자체 교육을 실시하며 서비스 인력을 모집하고 있습니다.
                                            <br />
                                            이와 동시에 위드메이트가 진행하는 주기적인 교육과 점검을 통해 서비스를 제공하는 인력 모두가 건강한 긴장감을 놓치지
                                            않도록 철저하게 관리하고 있습니다.
                                        </p>
                                    </PanelContent>
                                </Panel>

                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-2' title='서비스 신청 방법에 대해 알려주세요.' />}
                                >
                                    <PanelContent>
                                        <p>
                                            위드메이트 서비스는 웹사이트를 통해 신청이 가능합니다. 웹사이트 내 서비스 신청 모듈을 통해 서비스 신청을 해주시면
                                            됩니다. 회원 가입 진행을 완료하신 분이라면 전화 상담을 통한 예약 신청 또한 가능합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-3' title='서비스 신청 후 절차가 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            예약 접수가 완료되면, 위드메이트 소속의 전문 동행인들은 예약 발생 알림을 받고 검토를 진행하게 됩니다. 이후 동행인이
                                            예약을 접수하면 고객님께 ‘예약 완료’ 알림이 가고, 고객님께서 해당 동행인의 접수를 수락하시면 최종 ‘매칭’이
                                            이루어집니다. 이후 예약 일정에 맞춰 동행인과 함께 병원에 방문하시면 됩니다. 고객님께서 동행인의 접수를 거절하시면
                                            처음 단계로 돌아가 다른 동행인의 접수를 대기하게 됩니다.
                                            <br />
                                            만약 동행인 접수가 불가한 경우 고객님께 예약 불가 알림이 가고, 추후 서비스 재신청을 통해 서비스를 받을 수 있습니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-4' title='위드메이트 서비스 이용 가능 지역은 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            위드메이트의 서비스는 현재 수도권 내에서 이용 가능합니다. 빠른 시일 내 경기도를 포함한 타 지역 서비스 확장을 계획
                                            중에 있습니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-5' title='예약 변경 및 취소 수수료는 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>고객님께서 정상적으로 매칭된 병원 동행 서비스 계약을 취소 및 변경하는 경우에는 아래와 같이 수수료가 발생합니다.</p>
                                        <div className='panel-contents-wrapper'>
                                            <div className='panel-contents'>
                                                <p className='panel-content-subtitle'>서비스 예약 날짜 기준 48시간 이내 변경 및 취소</p>
                                                <p className='panel-content-subcontents'>수수료 없이 변경 & 취소 가능</p>
                                                <div className='clearfix' />
                                            </div>
                                            <div className='panel-contents'>
                                                <p className='panel-content-subtitle'>서비스 예약 전날 18:00 이전 변경 및 취소</p>
                                                <p className='panel-content-subcontents'>
                                                    취소 시 수수료 10,000원 발생. 단, 메이트와 변경 협의 성공 시에는 수수료 부과 X
                                                </p>
                                                <div className='clearfix' />
                                            </div>
                                            <div className='panel-contents'>
                                                <p className='panel-content-subtitle'>당일 예약 변경 및 취소</p>
                                                <p className='panel-content-subcontents'>
                                                    취소 시 수수료 15,000원 발생. 단, 메이트와 변경 협의 성공 시에는 수수료 부과 X
                                                </p>
                                                <div className='clearfix' />
                                            </div>
                                            <div className='panel-contents'>
                                                <p className='panel-content-subtitle'>서비스 시작 2시간 전 이내 변경 및 취소</p>
                                                <p className='panel-content-subcontents'>
                                                    취소 시 수수료 20,000원 발생. 단, 메이트와 변경 협의 성공 시에는 수수료 부과 X
                                                </p>
                                                <div className='clearfix' />
                                            </div>
                                            <div className='panel-contents'>
                                                <p className='panel-content-subtitle'>노쇼(No-Show)의 경우</p>
                                                <p className='panel-content-subcontents'>회원 자격 정지 및 민/형사상 책임 소지를 물을 수 있음</p>
                                                <div className='clearfix' />
                                            </div>
                                        </div>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-6' title='회사측의 과실로 예약일정을 소화 못하면 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            회사측의 과실로 예약된 서비스를 이용하지 못한 경우 서비스 요금을 전액 배상해드리며, 상황에 따라 서비스 무료 이용권
                                            등의 추가 배상을 진행합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-7' title='예약 장소에서 담당 동행인을 어떻게 찾나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            예약 접수 후 동행인 매칭이 완료되면 ‘마이페이지’ 내에서 동행인의 정보를 확인하실 수 있으며, 해당 정보를 통해
                                            동행인과 서비스 관련 소통을 진행하시면 됩니다.
                                        </p>
                                    </PanelContent>
                                </Panel>

                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-8' title='예약한 시간보다 진료나 귀가가 늦어져 서비스 시간이 추가되면 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            위드메이트 서비스는 후불제이기 때문에 예약한 서비스 시간을 넘어 추가 서비스가 이루어지는 경우, 해당 시간만큼의 추가
                                            비용이 발생합니다. 서비스 종료 후 추가된 금액이 포함된 최종 요금을 결제하시면 됩니다. 추가 비용은 서비스 시간 15분
                                            미만 초과 시 발생하지 않으며, 15분을 초과할 시 30분 단위의 요금이 발생합니다.
                                            <br />
                                            (Ex. 2시간 예약 서비스가 최종 2시간 14분으로 종료 > 추가 요금 발생 X)
                                            <br />
                                            (Ex. 2시간 예약 서비스가 최종 2시간 16분으로 종료 > 추가 요금 발생 O)
                                        </p>
                                    </PanelContent>
                                </Panel>

                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='1-9' title='위드메이트 서비스는 사용 가능 시간이 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            위드메이트의 모든 서비스는 주 5일 (월~금), 1일 10시간 (오전 9시 ~ 오후 7시) 동안 이용 가능합니다. 주말 및 공휴일에는
                                            서비스 제공을 하지 않습니다. 하지만 특수 예약에 대해서는 해당 시간 외에도 서비스를 제공할 수 있습니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                            </Collapse>
                        </div>
                        <h1 id='emergency'>병원 동행 Pro FAQ</h1>
                        <div>
                            <Collapse
                                expandIconPosition='right'
                                bordered={false}
                                expandIcon={({ isActive }) => (
                                    <Icon type='right' rotate={isActive ? 90 : -90} style={{ color: isActive ? mainColor : '#a7a7a7' }} />
                                )}
                            >
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-1' title='병원 동행 Pro는 정확히 어떤 경우에 신청할 수 있나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            병원 동행 Pro는 응급 상황에 처하지는 않았으나 정기적, 혹은 비정기적으로 의료 기관에 방문해 의료 조치를 받아야 하는
                                            경우에 신청 가능합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-2' title='서비스 요금은 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            병원 동행 Pro 서비스 요금은 시간 당 25,000원입니다. 또, 고객 소유의 차량을 메이트가 운행하여 동행할 경우 20,000원이
                                            추가됩니다 (택시 등 대중 교통을 이용할 경우 추가 요금은 없으나 해당 교통비 개별 부담). 모든 서비스 비용은 VAT 포함
                                            가격입니다.
                                            <br />
                                            (Ex. 2시간 예약 + 고객 소유 차량 운행 선택 시 > 기본 요금 50,000원 + 차량 운행비 20,000원 = 70,000원)
                                        </p>
                                    </PanelContent>
                                </Panel>

                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-3' title='동행인의 성별은 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>남성과 여성 인력이 모두 대기 중입니다.</p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-4' title='서비스는 어떤 형식으로 이루어지나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            예약일과 시간에 맞춰 위드메이트 소속의 동행인이 병원 출발지(고객님 자택 등)로 도착합니다. 이후 서비스 신청에 따라
                                            고객 소유의 차량을 통해 이동할 경우 동행인이 차량을 운행하고 병원까지 이동하며(택시 등의 대중 교통 이용 시 해당 비용
                                            자가 부담), 병원 내에서 이루어지는 모든 활동에 동행합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>

                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-5' title='동행인은 어떤 사람들인가요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            동행인은 철저하게 선별되고 영업배상책임보험에 가입되어 있으며, 위드메이트가 자체적으로 실시하는 엄격한 서비스 교육을
                                            모두 이수하고 통과한 전문가입니다. 또, 위드메이트의 동행인은 국립 암센터에서 실시하는 주기적인 암 환자 관리 교육 및
                                            세미나에 지속적으로 참여해 서비스 품질과 전문성이 보장된 인력들입니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='2-6' title='중증 또는 휠체어 등의 의료 기구 대동이 필요한 환자도 이용 가능한가요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            중증 또는 휠체어 등의 의료 기구 대동이 필요한 환자에 대한 비응급 의료 동행 서비스는 준비 중에 있습니다. 현
                                            서비스로는 해당 인원과의 동행은 불가하며, 예약이 접수된 경우 개별적인 연락을 통해 예약 불가를 알려드립니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={
                                        <PanelHeader
                                            rowIndex='2-7'
                                            title='단발성이 아니라 수회에 걸쳐서 정기적으로 위드메이트 서비스를 이용하고 싶은데 이 같은 경우 예약을 어떻게 하나요?'
                                        />
                                    }
                                >
                                    <PanelContent>
                                        <p>
                                            방사선이나 암 치료와 같이 주기적으로 내원이 필요한 경우 개별 상담을 통해 예약이 가능합니다. 위드메이트 서비스를 장기
                                            예약할 경우 특별 할인 혜택 또한 제공되고 있습니다. 서비스 센터로 문의 주실 경우 위드메이트의 전문 상담원이
                                            안내해드립니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                            </Collapse>
                        </div>
                        <h1 id='assist'>병원 동행 Basic FAQ</h1>
                        <div>
                            <Collapse
                                expandIconPosition='right'
                                bordered={false}
                                expandIcon={({ isActive }) => (
                                    <Icon type='right' rotate={isActive ? 90 : -90} style={{ color: isActive ? mainColor : '#a7a7a7' }} />
                                )}
                            >
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='3-1' title='병원 동행 Basic은 정확히 어떤 경우에 신청할 수 있나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            병원 동행 Basic은 주로 수술의 범주에는 속하지 않지만 마취 혹은 단순 보호자가 필요한 수면 내시경 등을 포함한 모든
                                            종류의 시술을 해야 할 경우에 신청합니다. 물론 Pro 서비스를 받아야 하지만 자택에서 병원, 다시 병원에서 자택까지의
                                            동행 과정을 필요로 하지 않는 상황에서도 신청이 가능합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='3-2' title='서비스 요금은 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            병원 동행 Basic 요금은 시간 당 15,000원입니다. (Ex. 2시간 예약 시 30,000원)
                                            <br /> 모든 서비스 비용은 VAT 포함 가격입니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='3-3' title='동행인은 어떤 사람들인가요?' />}
                                >
                                    <PanelContent>
                                        <p>병원 동행 Basic 서비스 동행인은 Pro 서비스와 동일한 인력으로 구성되어 있습니다.</p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='3-4' title='동행인의 성별은 어떻게 되나요?' />}
                                >
                                    <PanelContent>
                                        <p>남성과 여성 인력이 모두 대기 중입니다.</p>
                                    </PanelContent>
                                </Panel>
                                <Panel
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={<PanelHeader rowIndex='3-5' title='서비스는 어떤 형식으로 이루어지나요?' />}
                                >
                                    <PanelContent>
                                        <p>
                                            고객님께서 지정하신 병원에서 동행자와 접선 후, 동행자는 예약자의 진료 접수부터 종료 시점까지 병원 내부에서
                                            대기합니다. 진료 종료 후, 동행자는 예약자의 의사를 확인한 후 업무를 마치고 복귀합니다.
                                        </p>
                                    </PanelContent>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const PanelHeader = ({ rowIndex, title }) => {
    return (
        <div className='panel-header'>
            <p className='row-index'>{rowIndex}</p>
            <p className='title'>{title}</p>
        </div>
    );
};

const PanelContent = ({ children }) => {
    return <div className='panel-content'>{children}</div>;
};
