import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AOS from 'aos';
import { message, Spin } from 'antd';
import Modal from 'react-modal';

import ScrollTop from './Lib/utils';
import axios from './Lib/axios';

// styles
import './Styles';
import 'aos/dist/aos.css';
// antd-styles > set in /config-overrides.js
// import 'antd/dist/antd.css';

// components
import Home from './Pages/Home';
import Auth from './Pages/Auth';

// 개인정보 수집 및 이용 동의 약관
import Privacy from './Pages/Policy/Privacy';
import Terms from './Pages/Policy/Terms';
import Marketing from './Pages/Policy/Marketing';
import EssentialNormal from './Pages/Policy/Essential/Normal';
import EssentialSensitive from './Pages/Policy/Essential/Sensitive';
import SelectiveNormal from './Pages/Policy/Selective/Normal';
import SelectiveSensitive from './Pages/Policy/Selective/Sensitive';
import Advertisement from './Pages/Policy/Advertisement';

import Company from './Pages/Company';
import FAQ from './Pages/Faq';
import Product from './Pages/Product';

import LongTermCare from './Pages/LongtermCare/longTermCare';
import Success from './Pages/Success/success';
import SManual from './Pages/Manual/Service/service';
import PManual from './Pages/Manual/Platform/platform';
import EduVideo from './Pages/Manual/MateEduVideo/eduVideo';

import NewsEvent from './Pages/NewsEvent';

import ReviewAdd from './Pages/Review/Add';
import ReviewList from './Pages/Review/List';
import ReviewMylist from './Pages/Review/MyList';
import MateReady from './Pages/Apply/Ready';
import MateApplyForm from './Pages/Apply/Form';
import MateApplyForm_v2 from './Pages/Apply/MateApplyForm';
import Nemt from './Pages/Guide';
import Guide from './Pages/Nemt';
import Reserve from './Pages/Reserve';

// MetLife
import MetLife from './Pages/MetLife/MetLifeLanding';
// import MetLifeReserve from './Pages/MetLife/Reserve';
import MetLifeNemt from './Pages/MetLife/MetLifeGuide';
import MetLifeReviewList from './Pages/MetLife/Review';
import MetLifeNewsEvent from './Pages/MetLife/NewsEvent';
import MetLifeFAQ from './Pages/MetLife/Faq';
import MetLifePrivacy from './Pages/MetLife/MetLifePolicy/Privacy';
import MetLifeTerms from './Pages/MetLife/MetLifePolicy/Terms';

import Department from './Pages/Big5Department/department';

import NotFound from './Pages/NotFound';
// import Test from './Pages/Test';
// import { FreeModal } from './Components/modal';
import { CardPopup, EventPopup, ManagerPopup, MetlifePopup, NoticePopup } from './Components/modal';

// templates
import Nav from './Components/Templates/Nav';
import MetLifeNav from './Components/Templates/MetLifeNav';
import Footer from './Components/Templates/Footer';

import { editAuth, checkAuth } from './Reducer/Modules/auth';
import { MATE } from './Lib/variables';
Modal.setAppElement('#root');

class App extends React.PureComponent {
    componentDidMount = async () => {
        AOS.init();

        const id = localStorage.getItem('id');
        const userType = localStorage.getItem('userType');

        if (id && userType) {
            const { data } = await axios.get(`/v1/user/${id}/${userType}`);

            if (data && data._id) {
                this.props.editAuth({ type: 'id', value: data._id });
                this.props.editAuth({ type: 'userType', value: userType });
                this.props.editAuth({ type: 'name', value: data.name });
                this.props.editAuth({ type: 'photo', value: data.photo });
                this.props.editAuth({ type: 'contact', value: data.contact });
                this.props.checkAuth({ id, userType });

                localStorage.setItem('id', data._id);
                localStorage.setItem('photo', data.photo);
                localStorage.setItem('name', data.name);
                localStorage.setItem('contact', data.contact);
            }
        }
    };

    render() {
        const { location } = this.props.history;
        const { userType, allowed } = this.props.auth;

        if (userType === MATE && allowed === false) {
            if (location.pathname === '/user/mate/mypage' || location.pathname === '/user/mate/mypage/history' || location.pathname === '/')
                this.props.history.push('/user/mate/pending');
        }

        return (
            <div id='app-container' className='app-container'>
                {location.pathname.startsWith('/360F') ? <MetLifeNav {...this.props} /> : <Nav {...this.props} />}
                <ScrollTop>
                    <div className='main-area'>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/main/step1' component={Home} />
                            <Route exact path='/main/step2' component={Home} />
                            <Route exact path='/main/step3' component={Home} />
                            <Route exact path='/reserve' component={Reserve} />
                            <Route path='/reserve/:step' component={Reserve} />
                            <Route path='/user' component={Auth} />
                            <Route path='/product' component={Product} />
                            <Route exact path='/faq' component={FAQ} />
                            <Route exact path='/company' component={Company} />

                            <Route exact path='/policy/privacy' component={Privacy} />
                            <Route exact path='/policy/terms' component={Terms} />
                            <Route exact path='/policy/marketing' component={Marketing} />
                            <Route exact path='/policy/essential/normal' component={EssentialNormal} />
                            <Route exact path='/policy/essential/sensitive' component={EssentialSensitive} />
                            <Route exact path='/policy/selective/normal' component={SelectiveNormal} />
                            <Route exact path='/policy/selective/sensitive' component={SelectiveSensitive} />
                            <Route exact path='/policy/advertisement' component={Advertisement} />

                            <Route exact path='/mate/apply' component={MateReady} />
                            <Route exact path='/apply/form' component={MateApplyForm_v2} />
                            <Route exact path='/mateApply/form' component={MateApplyForm} />

                            <Route exact path='/review/list' component={ReviewList} />
                            <Route exact path='/review/own/usage/list' component={ReviewMylist} />
                            <Route exact path='/review/edit/:reserveId' component={ReviewAdd} />

                            <Route exact path='/longTermCare' component={LongTermCare} />
                            <Route exact path='/success' component={Success} />
                            <Route exact path='/service/manual' component={SManual} />
                            <Route exact path='/platform/manual' component={PManual} />
                            <Route exact path='/mateEducation/manual' component={EduVideo} />

                            <Route path='/NewsEvent' component={NewsEvent} />

                            {/* MetLife */}
                            <Route exact path='/360F' component={MetLife} />
                            <Route path='/360F/nemt/service' component={MetLifeNemt} />
                            <Route exact path='/360F/review/list' component={MetLifeReviewList} />
                            <Route path='/360F/NewsEvent' component={MetLifeNewsEvent} />
                            <Route exact path='/360F/faq' component={MetLifeFAQ} />
                            <Route exact path='/360F/policy/privacy' component={MetLifePrivacy} />
                            <Route exact path='/360F/policy/terms' component={MetLifeTerms} />

                            <Route exact path='/department' component={Department} />

                            {/* <Route path='/service/guide' component={Guide} /> */}
                            <Route path='/nemt/service' component={Nemt} />

                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </ScrollTop>
                <Footer />
                {this.props.app.loading ? (
                    <div id='loading'>
                        <Spin size='large' />
                    </div>
                ) : null}
                {/* <FreeModal /> */}
                {/* <NoticePopup /> */}
                {/* <CardPopup /> */}
                {/* <ManagerPopup /> */}
                {/* {location.pathname === '/' ? <EventPopup /> : null} */}
                {location.pathname === '/360F' ? <MetlifePopup /> : null}
            </div>
        );
    }
}

const mapStateToProps = ({ auth, app }) => ({
    auth,
    app,
});

const mapDispatchToProps = dispatch => ({
    editAuth: ({ type, value }) => dispatch(editAuth({ type, value })),
    checkAuth: ({ id, userType }) => dispatch(checkAuth({ id, userType })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
