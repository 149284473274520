import React from 'react';
import styled from 'styled-components';
import { WithmateLabel } from '../../../../Components/Common/input';

export const EduVideo4 = () => {
    return (
        <VideoSectionContainer>
            <VideoSectionInnerContainer>
            <WithmateLabel style={{ fontSize: '15px' }}>병원동행 매니저 교육 4: 메이트 학습 매뉴얼</WithmateLabel>
                <VideoArea>
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://youtube.com/embed/S9daiT1kMmE?modestbranding=1?cc_load_policy=1&controls=0&rel=0&disablekb=1"
                        title="withmate YouTube video player4"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loop={true}
                        controls={false}
                    ></iframe>
                </VideoArea>
            </VideoSectionInnerContainer>
        </VideoSectionContainer>
    );
};

const VideoSectionContainer = styled.div`
  padding-top: 105px;
  padding-bottom: 30px;

  width: 100%;

  @media (max-width: 768px) {
    // padding-top: 62px;
    padding-top: 10px;
    // padding-bottom: 0px;
    padding-bottom: 10px;
  }
`;

const VideoSectionInnerContainer = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
`;

const VideoSectionTitle = styled.h4`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  margin-top: 0px;
  margin-bottom: 105px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 68px;
  }
`;

const VideoArea = styled.div`
  width: 100%;
  height: calc(1120px / 16 * 9);
  background-color: #000000;

  > iframe,
  video {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1120px) {
    height: calc(100vw / 16 * 9);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100vw / 16 * 9);
    background-color: #000000;

    display: flex;
    align-items: center;
    justify-content: center;

    > iframe,
    video {
      width: 100%;
      height: 100%;
    }
  }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
