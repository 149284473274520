import React from 'react';

export default class SthSection extends React.PureComponent {
    render() {
        return (
            <div className='sth-container' data-aos='fade-up' data-aos-anchor-placement='top-bottom' data-aos-duration='2000'>
                <div className='inner-container'>
                    <div className='img-wrapper'>
                        <img src='/assets/images/safetyservice.png' alt='smile' />
                    </div>
                    <div className='text-wrapper'>
                        <p className='title'>안전한 병원 동행의 시작</p>
                        <p className='content'>
                            위드메이트는 병원 동행 서비스의 본질을 ‘안전’이라고 생각합니다. 이에 위드메이트는 전직원의 <br />
                            정기적인 교육 및 검열, 전문 자격증 취득 및 보유 여부 상시 점검, 영업배상책임 보험 가입 등 안전한
                            <br />
                            병원 동행 서비스의 제공을 위해 최선을 다하고 있습니다.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
