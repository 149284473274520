import React from 'react';

class FthBlock extends React.PureComponent {
    render() {
        return (
            <div className='fth-section' data-aos='fade-up' data-aos-anchor-placement='top-bottom' data-aos-duration='2000'>
                <div className='inner-container'>
                    <div className='title-block'>
                        <p>통계로 보는 위드메이트</p>
                    </div>
                    <div className='items-block'>
                        <Item name='5,000건 ↑' value='총 서비스 수행 건수' img='/assets/images/daily-count.png' />
                        <Item name='3,500건 ↑' value='암 치료 병원 동행 건수' img='/assets/images/satisfied.png' />
                        <Item name='50건 ↑' value='일일 서비스 수행 건수' img='/assets/images/daily-count.png' />
                        <Item name='12 곳' value='협력 기관' img='/assets/images/services.png' />
                        <Item name='81%' value='서비스 재사용률' img='/assets/images/unsatisfied.png' />
                        <Item name='1억 2천만원' value='책임 보험' img='/assets/images/shield.png' />
                        <div className='clearfix' />
                    </div>
                </div>
            </div>
        );
    }
}

const Item = ({ name, value, img }) => {
    return (
        <div className='item'>
            <div className='img-wrapper'>
                <img src={img} alt='value-icon' />
            </div>
            <div className='text-wrapper'>
                <p className='name'>{name}</p>
                <p className='value'>{value}</p>
            </div>
            <div className='clearfix' />
        </div>
    );
};
export default FthBlock;
