import React from 'react';
import styled from 'styled-components';

// components
import { GuideUsingContainer, InnerContainer, GuideConceptTitle, GuideUsingSubFlexWrapper, GuideUsageFlexbox, MobileBr, DesktopBr } from '../guide.styled';

export const GuideUsage = () => {
    return (
        <GuideUsingContainer id='usage'>
            <InnerContainer>
                <GuideConceptTitle>이런 분께 추천해요</GuideConceptTitle>
                <GuideUsingSubFlexWrapper>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_legache.png' />
                        </div>
                        <span>
                            병원에 가시는 <DesktopBr />
                            <MobileBr />
                            어르신
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_wheelchair.png' />
                        </div>
                        <span>
                            거동이 <DesktopBr />
                            <MobileBr />
                            힘드신 분
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_x-ray.png' />
                        </div>
                        <span>
                            항암 / 방사선 치료를 <DesktopBr />
                            <MobileBr />
                            받으시는 분
                        </span>
                    </GuideUsageFlexbox>
                </GuideUsingSubFlexWrapper>

                <GuideUsingSubFlexWrapper>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_injection.png' />
                        </div>
                        <span>
                            수면내시경 등 <DesktopBr />
                            <MobileBr />
                            마취가 동반되는 <DesktopBr />
                            <MobileBr />
                            치료를 받으시는 분
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_round_trip.png' />
                        </div>
                        <span>
                            입원 / 퇴원
                            <DesktopBr />
                            <MobileBr />
                            하시는 분
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_eyecare.png' />
                        </div>
                        <span>
                            안과 진료/시술을 <DesktopBr />
                            <MobileBr />
                            받으시는 분
                        </span>
                    </GuideUsageFlexbox>
                </GuideUsingSubFlexWrapper>

                <GuideUsingSubFlexWrapper>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_detailcheck.png' />
                        </div>
                        <span>
                            각종 검진을 <DesktopBr />
                            <MobileBr />
                            받으시는 분
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_pregnant.png' />
                        </div>
                        <span>
                            임산부, 산모, <DesktopBr />
                            <MobileBr />및 소아
                        </span>
                    </GuideUsageFlexbox>
                    <GuideUsageFlexbox>
                        <div>
                            <img src='/assets/images/icon_blood.png' />
                        </div>
                        <span>혈액 투석 환자</span>
                    </GuideUsageFlexbox>
                </GuideUsingSubFlexWrapper>
            </InnerContainer>
        </GuideUsingContainer>
    );
};
