import React from 'react';
import { connect } from 'react-redux';
import StyledListContainer from './list.styled';
import List from './list';
import { addNewsEvent, fetchNewsEvent, getNewsEventInfo } from '../../../../Reducer/Modules/newsEvent';

class InfoContainer extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchNewsEvent();
    };

    render() {
        return (
            <StyledListContainer>
                <List {...this.props} />
            </StyledListContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, newsEvent }) => ({
    app,
    auth,
    newsEvent,
});

const mapDispatchToProps = dispatch => ({
    fetchNewsEvent: () => dispatch(fetchNewsEvent()),
    addNewsEvent: info => dispatch(addNewsEvent(info)),
    getNewsEventInfo: ({ id, newsEventId }) => dispatch(getNewsEventInfo({ id, newsEventId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer);
