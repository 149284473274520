import React from 'react';

class SndBlock extends React.PureComponent {
    render() {
        return (
            <div className='snd-section'>
                <div className='inner-container'>
                    <div className='block fst'>
                        <div className='img-block'>
                            <img alt='something img' src='/assets/images/why.png' />
                        </div>
                        <div className='text-block snd'>
                            <div className='title'>
                                <p>등장배경</p>
                            </div>
                            <div className='content'>
                                <p>
                                    날로 분주해지는 현대 사회 속에서, 우리는 매일 직장과 육아라는 전투를 치릅니다. <br />
                                    살아남기 위한 발버둥을 치기에도 버거운 순간, 떨어져 있는 가족들에 대한 걱정과 <br />
                                    자기 자신을 돌보기 위한 시간을 내는 것이 힘겹기만 합니다.
                                </p>
                                <p>
                                    과연 그 누가 물리적인 거리와 시간적인 제약, 그리고 직장과 육아가 주는 압박감을 <br />
                                    떨쳐내고 가족과 자신을 위해 선뜻 움직일 수 있을까요? 그렇다면 우리는 언제까지 <br />
                                    이런 막막한 상황을 견디기만 해야 할까요?
                                </p>
                                <p>이를 해결하기 위한 믿을 수 있는 서비스를 합리적인 가격으로 제공받을 수는 없는 걸까요?</p>
                            </div>
                        </div>
                    </div>
                    <div className='block'>
                        <div className='text-block trd'>
                            <div className='title'>
                                <p>위드메이트의 시작</p>
                            </div>
                            <div className='content'>
                                <p>
                                    2015년 설립된 위드메이트는 고령화 및 1인 가구 시대에 접어든 <br />
                                    대한민국의 현 상황에 적합한 의료 동행 서비스는 무엇인지 연구했습니다.
                                </p>
                                <p>
                                    이후 위드메이트는 탄탄한 신뢰와 안정된 서비스 시스템을 기반으로 <br />
                                    유수의 병원 동행 서비스를 수행하며 축적된 데이터와 <br />
                                    실전 경험을 바탕으로 차별화된 병원 동행 Pro/Basic 서비스를 <br />
                                    런칭하게 되었습니다.
                                </p>
                            </div>
                        </div>
                        <div className='img-block right fth'>
                            <img alt='something img' src='/assets/images/withtaxi.png' style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SndBlock;
