import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// component
import NemtPage from './nemt';
import { MATE } from '../../Lib/variables';

// styles
import StyledContainer from './nemt.styled';

// actions
import {} from '../../Reducer/Modules/app';

class NemtContainer extends React.PureComponent {
    componentDidMount = () => {};

    render() {
        return (
            <StyledContainer>
                <NemtPage {...this.props} />
            </StyledContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapStateToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapStateToDispatch)(NemtContainer);
