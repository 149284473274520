import React from 'react';
import { connect } from 'react-redux';
import StyledNormalContainer from './normal.styled';
import Normal from './normal';
import { USER } from '../../../../Lib/variables';
import {
    // getPayHistory,
    // getReserveUsage,
    // getReserveList,
    getUserInfo,
    editUserForm,
    modifyUserInfo,
    cancelReserve,
    modifyReserve,
} from '../../../../Reducer/Modules/app';
import { editAuth, editPasswordForm, modifyPassword, withdrawlUser, withdrawlKakao,
    editAddPatientForm, addPatient, fetchPatient, deletePatient, modifyPatient, getPatientInfo, 
    fetchCard, editAddCardForm, addCard, getCardInfo, modifyCard, deleteCard, checkCouponCode } from '../../../../Reducer/Modules/auth';

class NormalPage extends React.PureComponent {
    componentDidMount = () => {
        const userId = localStorage.getItem('id');
        const userType = localStorage.getItem('userType');
        const type = localStorage.getItem('type');

        if (userId) {
            if (userType !== USER) {
                window.location.href = '/';
            } else {
                this.props.getUserInfo({ id: userId, userType, type });
                this.props.fetchPatient({ id: userId });
                this.props.fetchCard({ id: userId });
            }
        } else {
            this.props.history.push('/user/login');
        }
    };

    render() {
        if (this.props.auth.userType !== USER) {
            return <div></div>;
        }

        return (
            <StyledNormalContainer>
                <Normal {...this.props} />
            </StyledNormalContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchPatient: ({ id }) => dispatch(fetchPatient({ id })),
    fetchCard: ({ id }) => dispatch(fetchCard({ id })),

    getUserInfo: ({ id, userType, type }) => dispatch(getUserInfo({ id, userType, type })),
    onEdit: ({ type, value }) => dispatch(editUserForm({ type, value })),
    modify: ({ id, userType, info }) => dispatch(modifyUserInfo({ id, userType, info })),
    modifyPassword: ({ id, userType, password, newPassword }) => dispatch(modifyPassword({ id, userType, password, newPassword })),
    editAuth: ({ type, value }) => dispatch(editAuth({ type, value })),
    editPassword: ({ type, value }) => dispatch(editPasswordForm({ type, value })),
    
    withdrawlUser: ({ id, userType, password }) => dispatch(withdrawlUser({ id, userType, password })),
    withdrawlKakao: ({ id, userType }) => dispatch(withdrawlKakao({ id, userType })),

    cancelReserve: ({ callId }) => dispatch(cancelReserve({ callId })),
    modifyReserve: ({ reserveId, info }) => dispatch(modifyReserve({ reserveId, info })),
    
    editAddPatientForm: ({ type, value }) => dispatch(editAddPatientForm({ type, value })),
    addPatient: (info) => dispatch(addPatient(info)),
    deletePatient: ({ patientId }) => dispatch(deletePatient({ patientId })),
    modifyPatient: ({ id, patientId, info }) => dispatch(modifyPatient({ id, patientId, info })),
    getPatientInfo: ({ id, patientId }) => dispatch(getPatientInfo({ id, patientId })),

    editAddCardForm: ({ type, value }) => dispatch(editAddCardForm({ type, value })),
    addCard: (info) => dispatch(addCard(info)),
    getCardInfo: ({ id, cardId }) => dispatch(getCardInfo({ id, cardId })),
    modifyCard: ({ id, cardId, info }) => dispatch(modifyCard({ id, cardId, info })),
    deleteCard: ({ cardId }) => dispatch(deleteCard({ cardId })),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(NormalPage);
