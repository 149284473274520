import React from 'react';
import { MATE, USER } from '../../../../Lib/variables';
import KakaoLogin from '../Login/KakaoLogin';

class MainComponent extends React.PureComponent {
    render() {
        const { userType } = this.props.auth;

        return (
            <div className='login-container'>
                <div className='inner-container'>
                    <div className='register-form'>
                        <div className='register-title'>
                            <img src='/assets/images/new_logo_img.png' alt='위드메이트 로고' style={{ width: '45px', height: 'auto' }} />
                            <p style={{ display: 'inline-block', verticalAlign: '-10px', paddingLeft: '10px' }}>회원가입</p>
                            <br />
                            <h5 style={{ color: 'gray' }}>위드메이트의 서비스를 이용하기 위해 회원가입을 해주세요.</h5>
                        </div>

                        <div>
                            <div className='form-user-type'>
                                <div
                                    onClick={() => {
                                        this.props.history.push('/user/register');
                                    }}
                                    className={`type ${userType === USER ? 'active' : undefined}`}
                                >
                                    <p>일반 고객으로 가입하기</p>
                                    <h5>병원 동행서비스를 이용하고 싶어요!</h5>
                                </div>
                            </div>
                            <div className='form-user-type'>
                                <div
                                    onClick={() => {
                                        this.props.history.push('/mate/apply');
                                    }}
                                    className={`type ${userType === MATE ? 'active' : undefined}`}
                                >
                                    <p>메이트 지원하기</p>
                                    <h5>병원 동행서비스를 수행하는 메이트로 활동하고 싶어요!</h5>
                                </div>
                            </div>
                        </div>

                        {/* <div className='link-wrapper'>
                            <span className='text'>SNS계정으로 간편하게 회원가입 </span>
                            <div style={{ paddingLeft: '15px', paddingBottom: '15px', borderRadius: '20px', width: 'auto', height: 'auto' }}>
                                <KakaoLogin {...this.props} checkUserId={this.props.checkUserId} />
                            </div>
                        </div> */}
                        <div className='link-wrapper'>
                            <span className='text'>이미 계정이 있으신가요? </span>
                            <span
                                onClick={() => {
                                    this.props.history.push('/user/login');
                                }}
                                className='link'
                            >
                                로그인
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainComponent;
