import React from 'react';
import styled from 'styled-components';

import Pallete from '../../theme';

const FullWidthButton = styled.a`
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;

    cursor: pointer;

    font-size: 13px;
    font-weight: normal;
    text-align: center;
    font-weight: bold;

    ${props =>
        props.border
            ? `
        border: 1px solid ${Pallete.dark50};
        color: ${Pallete.blue50}
    `
            : ''}

    background-color: ${props => (props.bg ? props.bg : Pallete.blue50)};
    color: #ffffff;
`;

const HalfWidthButton = styled(FullWidthButton)`
    width: calc(50% - 4px);
`;

const RadiusFullWidthButton = styled(FullWidthButton)`
    max-width: 280px;
    height: 72px;
    line-height: 72px;
    border-radius: 50vh;

    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.5px;
`;

const RadiusHalfWidthButton = styled(RadiusFullWidthButton)`
    max-width: calc(50% - 4px);
    margin-right: 8px;

    @media (max-width: 480px) {
        width: 100%;
        max-width: 280px;

        height: 55px;
        line-height: 55px;
        font-size: 16px;
        letter-spacing: -0.1px;

        margin-right: 0px;
        margin-bottom: 8px;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;

    ${props =>
        props.half
            ? `
        > a {
            width: calc(50% - 4px);
        }
        > a:nth-child(1) {
            margin-right: 8px;
        }
    `
            : ''}
`;

export { FullWidthButton, HalfWidthButton, ButtonWrapper, RadiusFullWidthButton, RadiusHalfWidthButton };
