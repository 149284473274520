import { message } from 'antd';
import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2';
import MainNumber from './MainNumber';
import Popup from './Popup';
import { ManagerPopup } from '../../../../Components/modal';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { DivisionLine } from '../../../../Components/Common/input';

SwiperCore.use([Navigation, Pagination, Autoplay])

export const UserMain = ({ history }) => {
    return (
        // <Swiper
        //     direction={'horizontal'} // 슬라이드의 방향을 수직으로 설정합니다.
        //     spaceBetween={50}
        //     slidesPerView={1}
        //     pagination={{ clickable: true }} // 우측의 점을 클릭했을 때, 클릭한 슬라이드로 이동하게 됩니다.
        //     navigation
        //     modules={[Pagination]} // 페이지네이션, 마우스휠등을 사용하려면 모듈을 import해줘야 합니다.
        //     allowTouchMove // 터치 동작을 허용합니다.
        //     className="main_slider"
        //     threshold={20} // 터치 감도를 조정합니다. 숫자가 클수록 터치에 반응하지 않습니다.
        //     autoplay={{ delay: 4000 }}
        //     loop={true}
        //     speed={1000} // 슬라이드가 넘어가는 속도를 조정합니다. 단위는 ms입니다.
        // >
        //     <SwiperSlide>
                <UserMainContainer>
                    <UserMainInnerContainer>
                        {/* <TitleSticker>위드메이트를 사용하는 것, 일상을 돌려받는 것</TitleSticker> */}
                        <TitleSticker>병원동행매니저가 <span>케어</span>부터 <span>이동</span>까지 다 해드립니다</TitleSticker>
                        <WithmateSpecRow>
                            {/* 전국민 통원치료 <span>해결사</span> */}
                            환우 가족들이 <span>직접 추천</span>하는
                        </WithmateSpecRow>
                        <WithmateSpecRow>
                            <span>병원동행서비스</span>
                        </WithmateSpecRow>
                        <br />
                        <WithmateSpecRow>
                            <ButtonWrapper>
                                <GooglePlayButton
                                    onClick={() => {
                                        window.open('https://play.google.com/store/apps/details?id=com.bluecamel.withmate')
                                    }}
                                >
                                    <img src='/assets/images/icons/google_play_logo.png' alt='구글플레이' style={{ marginRight: '12px' }}/>
                                    Google Play
                                </GooglePlayButton>
                            </ButtonWrapper>
                        </WithmateSpecRow>

                        <BottomSection>
                            {/* <HighLight>
                                <MainNumber />
                                <Popup />
                            </HighLight> */}

                            <HighLight>
                                병원동행매니저가 케어부터 이동까지 <br />
                                위드메이트가 함께합니다.
                            </HighLight>
                            <ButtonWrapper>
                                <ReserveButton
                                    onClick={() => {
                                        if (!localStorage.getItem('id')) {
                                            message.warning('병원 동행서비스 예약을 이용하시려면 로그인 후 이용해주세요.');
                                            return history.push('/user/login');
                                        }

                                        history.push('/reserve/items');
                                    }}
                                >
                                    온라인 예약하기
                                    <img src='/assets/images/main_right_blue.png' alt='방향표' />
                                </ReserveButton>
                                <CallButton
                                    onClick={() => {
                                        swal.fire({
                                            title: '위드메이트 고객센터',
                                            text: '☎ 070-4300-9075',
                                            icon: 'info',
                                            confirmButtonText: '전화걸기',
                                            cancelButtonText: '확인',
                                            showCancelButton: true,
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                (
                                                    document.location.href = 'tel:070-4300-9075'
                                                )
                                            }
                                        });
                                    }}
                                >
                                    전화 상담하기
                                    <img src='/assets/images/main_right_white.png' alt='방향표' />
                                </CallButton>
                            </ButtonWrapper>
                        </BottomSection>
                    </UserMainInnerContainer>
                </UserMainContainer>
        //     </SwiperSlide>
        // </Swiper>
    );
};

const UserMainContainer = styled.div`
    width: 100%;
    height: 628px;

    background: linear-gradient(to top, #3bc1ff, #5b62f5);

    @media (max-width: 768px) {
        // height: 598px;
        height: 650px;
    }
`;

const UserMainContainer2 = styled.div`
    width: 100%;
    height: 628px;

    background: #f8f8fa;

    @media (max-width: 768px) {
        height: 598px;
    }
`;

const UserMainInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;

    background-image: url('/assets/images/w_main.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: 72px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding-top: 52px;
        align-items: center;
        background: transparent;
    }
`;

const UserMainInnerContainer2 = styled.div`
    width: 100%;
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;

    background-image: url('/assets/images/collaboration/withmate_dlive.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: 72px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding-top: 52px;
        align-items: center;
        background: transparent;
    }
`;

const TitleSticker = styled.span`
    height: 36px;

    padding: 8px 24px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    border-radius: 50vh;
    background-color: #3bc1ff;

    margin-bottom: 34px;

    @media (max-width: 768px) {
        margin-bottom: 34px;
    }
`;

const WithmateSpecRow = styled.div`
    display: flex;
    align-items: flex-end;

    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    line-height: 1.48;
    letter-spacing: -0.7px;
    text-align: left;
    color: #ffffff;

    > span {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.7px;
        text-align: left;
        color: #ffffff;

        margin-left: 4px;
    }

    > span.big {
        font-size: 64px;
        line-height: 1.2;
        position: relative;
        margin-right: 6px;

        > d {
            font-size: 28px;
            position: absolute;
            top: -16px;
            left: calc(50% - 12px);
        }
    }

    > span.normal {
        font-size: 40px;
        line-height: 1.2;
        position: relative;
        margin-right: 6px;

        > d {
            font-size: 18px;
            position: absolute;
            top: -16px;
            left: calc(50% - 12px);
        }
    }

    margin-bottom: 4px;

    @media (max-width: 768px) {
        margin-bottom: 8px;

        font-size: 22px;

        > span {
            font-size: 22px;
        }

        > span.big {
            font-size: 48px;
            line-height: 1.2;
            position: relative;
            margin-right: 4px;

            > d {
                font-size: 20px;
                position: absolute;
                top: -16px;
                left: calc(50% - 8px);
            }
        }
    }
`;

const BottomSection = styled.div`
    margin-top: 32px;

    @media (max-width: 768px) {
        margin-top: 43px;
    }
`;

const HighLight = styled.p`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.2px;
    text-align: left;
    color: #ffffff;

    margin-bottom: 64px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        > div:first-child {
            margin-bottom: 8px;
        }
    }
`;

const ButtonCommon = styled.div`
    display: flex;
    padding: 20px 24px;
    width: 210px;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 4px 10px 24px -4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

    @media (max-width: 768px) {
    }
`;

const GPButtonCommon = styled.div`
    display: flex;
    padding: 20px 24px;
    width: 210px;
    align-items: center;
    // justify-content: space-between;

    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 4px 10px 24px -4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

    @media (max-width: 768px) {
    }
`;

const ReserveButton = styled(ButtonCommon)`
    background-color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
    }
`;

const CallButton = styled(ButtonCommon)`
    border: 1px solid #ffffff;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: #ffffff;

    @media (max-width: 768px) {
    }
`;

const GooglePlayButton = styled(GPButtonCommon)`
    background-color: #ffffff;
    background-color: rgba( 255, 255, 255, 0.1 );
    border-color: #ffffff;
    border-style: solid;
    font-size: 20px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    // color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    height: 60px;

    @media (max-width: 768px) {
    }
`;

const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;
