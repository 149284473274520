import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'antd';
// lib
import { USER } from '../../../Lib/variables';

export default class NavComponent extends React.PureComponent {
    state = {
        menu: false,
    };

    onClose = () => {
        const { pathname } = this.props.location;
        if (pathname === '/' && window.innerWidth <= 768) {
            const main = document.querySelector('.order-menu-wrapper');
            main.style.display = 'none';
        }
    };

    componentDidUpdate = prevProps => {
        const { pathname: prevPath } = prevProps.location;
        const { pathname } = this.props.location;
        if (prevPath !== pathname) {
            this.setState({ menu: false });
        }
    };

    // payment test
    onMobileMenuHandler = () => {
        this.setState({ menu: !this.state.menu });
    };

    render() {
        // const { id, name, userType, ticket } = this.props.auth;
        // const { name, userType } = this.props.auth;
        const id = localStorage.getItem('id');
        const userType = localStorage.getItem('userType');

        return (
            <div className='nav-container'>
                <div className='nav-inner'>
                    <div className='logo'>
                        <NavLink exact to='/' activeClassName='active' lang='en' onClick={this.onClose}>
                            <img src='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_new_logo.png' alt='logo' />
                        </NavLink>
                    </div>
                    <div className='nav'>
                        <div className='mobile-menu'>
                            <span onClick={this.onMobileMenuHandler}>
                                <i className='fas fa-bars' />
                            </span>
                        </div>
                        <ul className={`nav-items ${this.state.menu ? 'active' : ''}`}>
                            <li className='item'>
                                <NavLink lang='kr' exact to='/' className='nav-item' activeClassName='active'>
                                    메인
                                    <span className='underline'></span>
                                </NavLink>
                            </li>
                            <li className='item'>
                                <NavLink lang='kr' exact to='/nemt/service' className='nav-item' activeClassName='active'>
                                    위드메이트 소개
                                    <span className='underline'></span>
                                </NavLink>
                            </li>
                            <li className='item'>
                                <NavLink lang='en' exact to='/review/list' className='nav-item' activeClassName='active'>
                                    이용후기
                                    <span className='underline'></span>
                                </NavLink>
                            </li>
                            <li className='item'>
                                <NavLink lang='en' exact to='/newsEvent/list' className='nav-item' activeClassName='active'>
                                    소식 보기
                                    <span className='underline'></span>
                                </NavLink>
                            </li>
                            <li className='item'>
                                <NavLink lang='en' exact to='/faq' className='nav-item' activeClassName='active'>
                                    FAQ
                                    <span className='underline'></span>
                                </NavLink>
                            </li>
                            {!id ? (
                                <>
                                    <li className='item item-login'>
                                        <NavLink lang='kr' exact to='/user/login' className='nav-item block login'>
                                            로그인
                                            <span className='underline'></span>
                                        </NavLink>
                                    </li>
                                    <li className='item item-register'>
                                        <NavLink lang='kr' exact to='/user/mainRegister' className='nav-item block register'>
                                            회원가입
                                            <span className='underline'></span>
                                        </NavLink>
                                    </li>
                                    <li className='item item-apply'>
                                        <NavLink lang='kr' exact to='/mate/apply' className='nav-item block apply'>
                                            메이트 지원
                                            <span className='underline'></span>
                                        </NavLink>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className='item item-name'>
                                        <NavLink
                                            lang='kr'
                                            to={userType === USER ? '/user/mypage' : '/user/mate/mypage'}
                                            className='nav-item'
                                            activeClassName='active'
                                        >
                                            마이페이지
                                            <span className='underline'></span>
                                        </NavLink>
                                    </li>

                                    <li className='item item-logout'>
                                        <NavLink lang='kr' exact to='/user/logout' className='nav-item' activeClassName='active'>
                                            로그아웃
                                            <span className='underline'></span>
                                        </NavLink>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
