import React from 'react';

// section
import FstBlock from './Section/fst';
import SndBlock from './Section/snd';
import TrdBlock from './Section/trd';
import FthBlock from './Section/fth';
import FifBlock from './Section/fifth';
import SthBlock from './Section/sth';

class Company extends React.PureComponent {
    render() {
        return (
            <div className='company-container'>
                <FstBlock />
                <FthBlock />
                <SndBlock />
                <TrdBlock />
                <FifBlock />
                <SthBlock />
            </div>
        );
    }
}
export default Company;
