import React from 'react';
import styled from 'styled-components';
import { message as Message } from 'antd';

// components
import {
    Container,
    InnerContainer,
    NemtTitleWrapper,
    NemtTitle,
    NemtServiceWrapper,
    NemtBox,
    NemtColumnBox,
    NemtBoxTitle,
    NemtServiceFlexWrapper,
    NemtServiceImageWrapper,
    ReserveButton,
    NemtInfobox,
    NemtDescription,
    MobileBr,
    DesktopBr,
} from './nemt.styled';

// utils
import axios from '../../Lib/axios';

// modal
import { EmergencyCorpSearchModal, ShareCarRequestModal } from '../../Components/modal';

class NemtPage extends React.PureComponent {
    state = {
        activeTab: 'concept',
        reserveModal: false,
        searchModal: false,
        emergencyList: [],
        emergencyKeyword: '',
        requestModal: false,
        requestInfo: {
            corp: '',
            address: '',
            manager: '',
            contact: '',
            message: '',
        },
    };

    componentDidMount = async () => {
        const { data } = await axios.get(`/v1/corp/list`);
        if (!data) return null;

        if (data && Array.isArray(data)) {
            this.setState({
                emergencyList: data,
            });
        }
    };

    onRoute = path => {
        this.props.history.push(path);
    };

    onShareRequest = async () => {
        const {
            requestInfo: { corp, address, manager, contact, message },
        } = this.state;
        if (!corp || !address || !manager || !contact || !message) return Message.info('정보를 기입해주세요.');

        const { data } = await axios.post('/v1/share/add', {
            corp,
            address,
            manager,
            contact,
            message,
        });

        if (!data) {
            return Message.error('일시적인 서버장애가 발생했습니다. 다시 시도해주세요.');
        }

        if (data === 'check parameters') {
            return Message.warning('정보를 모두 기입해주세요.');
        }

        if (data === 'success') {
            Message.success('셔틀 셰어링 서비스를 요청하였습니다.');

            this.setState({
                requestModal: false,
                requestInfo: {
                    corp: '',
                    address: '',
                    manager: '',
                    contact: '',
                    message: '',
                },
            });
        }
    };

    render() {
        const { requestInfo, emergencyKeyword, emergencyList } = this.state;

        return (
            <Container>
                <InnerContainer>
                    <NemtTitleWrapper>
                        <NemtTitle>서비스 안내</NemtTitle>
                    </NemtTitleWrapper>

                    <NemtServiceWrapper>
                        <NemtBox>
                            <NemtServiceImageWrapper className='desktop-visible'>
                                <img src='/assets/images/service_around_desktop.png' />
                            </NemtServiceImageWrapper>
                            <NemtServiceImageWrapper className='mobile-visible'>
                                <img src='/assets/images/service_around_mobile.png' />
                            </NemtServiceImageWrapper>

                            <NemtInfobox>
                                <ReserveButton
                                    onClick={() => {
                                        this.props.history.push('/nemt/service');
                                    }}
                                >
                                    <img src='/assets/images/icon_calendar.png' />
                                    예약하기
                                </ReserveButton>

                                <NemtBoxTitle style={{ color: '#236037' }}>
                                    위드메이트
                                    <DesktopBr />
                                    <MobileBr />
                                    <span>병원 동행 서비스</span>
                                </NemtBoxTitle>

                                <NemtDescription>
                                    병원 동행 서비스는 응급 상황에 처하지는 않았으나 정기적, 혹은 비정기적으로 <DesktopBr />
                                    의료 기관에 방문해 적절한 의료 조치를 받아야 하는 분들을 위한 서비스입니다. <DesktopBr />
                                    <MobileBr />
                                    자세한 설명은{' '}
                                    <span
                                        onClick={() => {
                                            this.props.history.push('/nemt/service');
                                        }}
                                    >
                                        병원동행 서비스 NEMT 페이지
                                    </span>
                                    에서 확인이 가능합니다.
                                </NemtDescription>
                            </NemtInfobox>
                        </NemtBox>
                    </NemtServiceWrapper>

                    <NemtServiceFlexWrapper>
                        <NemtColumnBox id='shuttle-service'>
                            <NemtServiceImageWrapper>
                                <img src='/assets/images/shuttle.png' />
                            </NemtServiceImageWrapper>

                            <NemtInfobox>
                                <ReserveButton
                                    onClick={() => {
                                        this.setState({ requestModal: true });
                                    }}
                                >
                                    <img src='/assets/images/icon_message.png' />
                                    문의하기
                                </ReserveButton>

                                <NemtBoxTitle style={{ color: '#1D5DA2' }}>
                                    위드메이트
                                    <DesktopBr />
                                    <MobileBr />
                                    <span>셔틀 셰어링 서비스</span>
                                </NemtBoxTitle>

                                <NemtDescription>
                                    요양 시설 운영비 중 차량 유지 비용은 주유비, 운행 직원 급여, 식대 등이 <DesktopBr />
                                    더해질 경우 막대한 비중을 차지합니다. 하지만 노후 차량을 사용하거나, <DesktopBr />
                                    운행 직원 수를 줄일 경우 입소 환자 안전에 문제가 생기기 때문에 <DesktopBr />
                                    요양 시설이 차량 유지 비용을 줄이는 방법은 사실상 없습니다.
                                    <DesktopBr />
                                    <DesktopBr />
                                    이에 위드메이트는 요양 시설이 차량 유지 비용을 절감할 수 있는
                                    <DesktopBr />
                                    ‘셔틀 셰어링’ 서비스를 도입했습니다. A와 B라는 두 곳의 요양원이 <DesktopBr />
                                    위드메이트가 제공하는 셔틀과 기사를 공유하며 잉여 시간과 좌석을 함께 <DesktopBr />
                                    사용하는 방법입니다. A 병원의 남는 좌석을 B 병원이 이용하거나, <DesktopBr />
                                    B 병원의 환자가 치료를 받는 동안 A 병원의 환자가 차량을 이용하는 방식이죠.
                                    <DesktopBr />
                                    <DesktopBr />
                                    위드메이트의 셔틀 쉐어링 서비스가 필요한 기관은 <DesktopBr />
                                    문의 접수 버튼을 클릭하세요!
                                </NemtDescription>
                            </NemtInfobox>
                        </NemtColumnBox>

                        <NemtColumnBox id='search-service'>
                            <NemtServiceImageWrapper>
                                <img src='/assets/images/emergency_car.png' />
                            </NemtServiceImageWrapper>

                            <NemtInfobox>
                                <ReserveButton
                                    onClick={() => {
                                        this.setState({ searchModal: true });
                                    }}
                                >
                                    <img src='/assets/images/icon_search.png' />
                                    검색하기
                                </ReserveButton>

                                <NemtBoxTitle style={{ color: '#B85465' }}>
                                    위드메이트
                                    <DesktopBr />
                                    <MobileBr />
                                    <span>사설 구급 업체 검색 서비스 </span>
                                </NemtBoxTitle>

                                <NemtDescription>
                                    '구급’이라는 단어가 들어간 탓에, 흔히 사설 구급차를 떠올렸을 때 응급 상황이 <DesktopBr />
                                    아닌 이상 사용할 일이 없을 것이라고 생각이 듭니다. 하지만 사설 구급차는 <DesktopBr />
                                    요양 시설 입소 환자의 병원 이송, 대형 행사 진행 중 비상 대기, 정상 거동이 <DesktopBr />
                                    불가한 환자 이송 등 일상 생활의 다양한 분야에 필요한 서비스입니다.
                                    <DesktopBr />
                                    <DesktopBr />
                                    위드메이트는 플랫폼 이용자에게 무료로 각 지역의 사설 구급 업체를 <DesktopBr />
                                    검색할 수 있는 서비스를 제공하고 있습니다. 지역별로 나눠 편리하게 <DesktopBr />
                                    사설 구급 업체를 검색해보세요!
                                </NemtDescription>
                            </NemtInfobox>
                        </NemtColumnBox>
                    </NemtServiceFlexWrapper>
                </InnerContainer>

                {this.state.searchModal ? (
                    <EmergencyCorpSearchModal
                        data={emergencyList}
                        keyword={emergencyKeyword}
                        inputHandler={value => {
                            this.setState({ emergencyKeyword: value });
                        }}
                        visibleHandler={() => {
                            this.setState({ searchModal: false });
                        }}
                    />
                ) : null}
                {this.state.requestModal ? (
                    <ShareCarRequestModal
                        data={requestInfo}
                        visibleHandler={() => {
                            this.setState({ requestModal: false });
                        }}
                        inputHandler={({ type, value }) => {
                            this.setState(prevState => ({
                                requestInfo: {
                                    ...prevState.requestInfo,
                                    [type]: value,
                                },
                            }));
                        }}
                        onSave={this.onShareRequest}
                    />
                ) : null}
            </Container>
        );
    }
}

export default NemtPage;
