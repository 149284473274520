import axios from '../axios';

export const addReview = ({ reserveId, userId, title, review, score, reserveDate }) => {
    return axios.post(`/v1/review/add`, { reserveId, userId, title, review, score, reserveDate });
};

export const getReviewList = ({ page }) => {
    return axios.get(`/v1/review/list`, { page });
};

export const replyReview = ({}) => {
    return axios.post(`/v1/reply/review`, {});
};

export const deleteReview = ({ reviewId }) => {
    return axios.put(`/v1/delete/review`, { reviewId });
};
