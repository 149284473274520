import React from 'react';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"


export const patientColumn = ({ onModify, onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '58px',
        },
        {
            title: '이름',
            dataIndex: 'name',
            align: 'center'
        },
        {
            title: '나이',
            dataIndex: 'age',
            align: 'center'
        },
        {
            title: '수정',
            dataIndex: 'valid',
            align: 'center',
            width: '58px',
            render: (valid, info) =>
                <EditOutlined
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onModify(info);
                    }}
                />
        },
        {
            title: '삭제',
            dataIndex: 'createdAt',
            align: 'center',
            width: '58px',
            render: (createdAt, { _id }) =>
                <DeleteOutlined
                    onClick={(e) => {
                        console.log('_id: ', _id)
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
        },
    ]
}

export const cardColumn = ({ onModify, onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '58px',
        },
        {
            title: '카드명',
            dataIndex: 'cardName',
            align: 'center'
        },
        {
            title: '카드번호',
            dataIndex: 'cardNum',
            align: 'center',
            render: cardNum => <span>**** - **** - **** - {cardNum.slice(15, 19)}</span>
        },
        {
            title: '수정',
            dataIndex: 'valid',
            align: 'center',
            width: '58px',
            render: (valid, info) =>
                <EditOutlined
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onModify(info);
                    }}
                />
        },
        {
            title: '삭제',
            dataIndex: 'createdAt',
            align: 'center',
            width: '58px',
            render: (createdAt, { _id }) =>
                <DeleteOutlined
                    onClick={(e) => {
                        console.log('_id: ', _id)
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
        },
    ]
};