import React from 'react';
import { connect } from 'react-redux';

// component
import TermsStyledContainer from './terms.styled';
import Terms from './terms';

class TermsContainer extends React.PureComponent {
    render() {
        return (
            <TermsStyledContainer component={this.props.isComponent || undefined}>
                <Terms {...this.props} />
            </TermsStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TermsContainer);
