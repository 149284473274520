import React from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
    width: 100%;
    height: 48px;

    border: 1px solid #dddddd;
    border-radius: 6px;
    overflow: hidden;

    display: flex;
    align-items: center;
`;

const Tab = styled.div`
    flex: 1;
    height: 100%;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => (props.active ? '#3bc1ff' : '#ffffff')};

    color: ${props => (props.active ? '#ffffff' : '#3bc1ff')};
    font-size: 14px;
    font-weight: bold;

    transition: all 0.5s;
`;

export { TabContainer, Tab };
