import jwt from 'jsonwebtoken';
import * as authAPI from './Api/auth';

export function createReduxThunk(type, request) {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return params => async dispatch => {
        dispatch({ type: 'app/LOADING_ON' });
        if (!request) {
            return dispatch({ type });
        }

        try {
            const response = await request(params);

            if (response.data === 'expired token' || response.data === 'invalid token') {
                const refreshToken = localStorage.getItem('refreshToken');
                const decoded = jwt.decode(refreshToken);
                if (decoded && decoded.id) {
                    try {
                        const result = await authAPI.checkAuth({ id: decoded.id, refreshToken });
                        if (result.data === 'invalid') {
                            localStorage.clear();
                            window.location.href = '/';
                            return null;
                        }

                        localStorage.setItem('accessToken', result.data.accessToken);
                        // 재요청
                        try {
                            const response = await request(params);
                            dispatch({
                                type: SUCCESS,
                                payload: response.data,
                                meta: params,
                            });
                            dispatch({ type: 'app/LOADING_OFF' });
                            return response.data;
                        } catch (e) {
                            dispatch({
                                type: FAILURE,
                                payload: e,
                                error: true,
                            });
                            dispatch({ type: 'app/LOADING_OFF' });
                            throw e;
                        }
                    } catch (error) {
                        console.log('error: ', error);
                    }
                }
            }

            dispatch({
                type: SUCCESS,
                payload: response.data,
                meta: params,
            });
            dispatch({ type: 'app/LOADING_OFF' });
            return response.data;
        } catch (e) {
            dispatch({
                type: FAILURE,
                payload: e,
                error: true,
            });
            dispatch({ type: 'app/LOADING_OFF' });
            throw e;
        }
    };
}
