import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import NotFound from '../NotFound';
import Login from './AuthPages/Login';
import Main from './AuthPages/MainRegister';
import Register from './AuthPages/Register';
import Logout from './AuthPages/Logout';
import Find from './AuthPages/Find';
import Mate from './AuthPages/Mate';
import Normal from './AuthPages/Normal';
import Pending from './AuthPages/Pending';

// import Example from './AuthPages/Example';

// component
export default class AuthPage extends React.PureComponent {
    render() {
        const { url } = this.props.match;
        // console.log(url);
        return (
            <Switch>
                <Route exact path={`${url}/login`} component={Login} />
                <Route exact path={`${url}/logout`} component={Logout} />

                <Route exact path={`${url}/register?url_source=dlive`} component={Register} />
                <Route exact path={`${url}/register?url_source=metlife`} component={Register} />

                <Route exact path={`${url}/register`} component={Register} />
                <Route exact path={`${url}/mainRegister`} component={Main} />

                {/* <Route exact path={`${url}/example`} component={Example} />  경로 입력 시 /user/example로 해야 화면출력*/}

                <Route exact path={`${url}/find/password`} component={Find} />
                <Route exact path={`${url}/mypage`} component={Normal} />
                <Route exact path={`${url}/mypage/:tab`} component={Normal} />
                <Route exact path={`${url}/mate/pending`} component={Pending} />
                <Route exact path={`${url}/mate/mypage`} component={Mate} />
                <Route exact path={`${url}/mate/mypage/:tab`} component={Mate} />
                {/* <Route exact path={`${url}/withdrawl`} component={Register} /> */}
                <Route component={NotFound} />
            </Switch>
        );
    }
}
