import React from 'react';
import styled from 'styled-components';

import {
    WithmateContainer,
    InnerContainer,
    WithmateTitle,
    MeritCardDesc,
    FlexDivWrapper,
    BigSideBox,
    SmallSideBox,
    WithmateBg,
    GuideConceptTitle,
    MobileBr,
    DesktopBr,
} from '../guide.styled';
export const Withmate = () => {
    return (
        <WithmateContainer id='withmate'>
            <InnerContainer>
                <FlexDivWrapper top>
                    <SmallSideBox>
                        <WithmateTitle>
                            <img src='/assets/images/new_logo_img.png' />
                            <span>위드메이트란?</span>
                        </WithmateTitle>
                        <Block bigMargin>
                            위드메이트는 <span>온라인 병원 동행 서비스 매칭 플랫폼</span>을<MobileBr /> 시작으로,
                            <DesktopBr /> 대한민국에서{' '}
                            <span>
                                사회적 의미의 ‘시니어’라는 <MobileBr />
                                단어를 없애기 위해
                            </span>{' '}
                            설립된 <DesktopBr />
                            <span>
                                시니어 라이프 스타일 <MobileBr />
                                혁신 기업
                            </span>
                            입니다.
                        </Block>
                        <Title>위드메이트의 시작</Title>
                        <Block>
                            <span>2015년</span>에 설립된 위드메이트는 <span>초고령화 </span>및 <MobileBr />
                            <span>1인 가구 시대</span>에 <DesktopBr />
                            접어든 대한민국의 현 상황에 적합한
                            <MobileBr />
                            <span> 시니어 라이프 스타일 혁신</span>
                            <DesktopBr />
                            <span>서비스를 개발합니다.</span>
                        </Block>
                        <Block noMargin>
                            이를 위해 위드메이트는 <MobileBr />
                            <span>국내 최초로 병원 동행 서비스를 런칭해</span>
                            <MobileBr />
                            <DesktopBr />
                            <span>탄탄한 신뢰</span>와 <span>안정된 서비스</span> 시스템을 기반으로 <MobileBr />
                            축적되는 <DesktopBr />
                            데이터와 실전 경험을 바탕으로{' '}
                            <span>
                                다양한 <MobileBr />
                                신규 서비스를 연구
                            </span>
                            하여 <DesktopBr />
                            <span>
                                시니어 라이프 스타일 <MobileBr />
                                혁신 기업
                            </span>
                            의 길을 걷고 있습니다.
                        </Block>
                    </SmallSideBox>
                    <BigSideBox mHidden>
                        <WithmateBg bg='/assets/images/guide_withmate_main1.png' />
                    </BigSideBox>
                </FlexDivWrapper>
            </InnerContainer>
        </WithmateContainer>
    );
};

const Title = styled(GuideConceptTitle)`
    margin-bottom: 24px;
    text-align: left;
`;

const Block = styled(MeritCardDesc)`
    font-size: 16px;
    margin-bottom: 24px;
    ${props => props.noMargin && `margin-bottom: 0px;`}
    ${props => props.bigMargin && `margin-bottom: 74px;`}

    @media (max-width: 768px) {
        word-break: keep-all;
    }
`;
