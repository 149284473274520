import React from 'react';

// components
import {
    GuideConceptContainer,
    InnerContainer,
    GuideConceptTitle,
    GuideConceptImageWrapper,
    GuideConceptImage,
    GuideConceptImageMobile,
    GuideConceptTextWrapper,
    GuideConceptTextBlock,
    MobileBr,
    DesktopBr,
} from '../guide.styled';

export const GuideConcept = ({}) => {
    return (
        <GuideConceptContainer id='concept'>
            {/* <InnerContainer>
                <GuideConceptTitle>병원 동행이란?</GuideConceptTitle>
                <GuideConceptImageWrapper>
                    <GuideConceptImage src='/assets/images/with_concept_new.png' />
                    <GuideConceptImageMobile src='/assets/images/with_concept_mobile_new.png' />
                </GuideConceptImageWrapper>
                <GuideConceptTextWrapper>
                    <GuideConceptTextBlock>
                        <span>‘비응급 의료 동행(Non-Emergency-Medical-Transportation)’</span>으로
                        <DesktopBr /> 불리는 ‘병원 동행’ 서비스는 응급 상황에 처하지는 않았으나 정기적, 혹은
                        <DesktopBr /> 비정기적으로 의료 기관에 방문해 적절한 의료 조치를 받아야 하는 사람을
                        <DesktopBr /> 위한 <span>헬스케어 서비스</span>
                        입니다. 이미 미국과 캐나다, 일본 등의 선진국에서는
                        <DesktopBr /> 국가 주도의 복지 서비스로 정착했으며, 이를 넘어 국가 차원의 병원 동행
                        <DesktopBr /> 전문 기업 육성에 힘을 쏟고 있는 상황입니다.
                    </GuideConceptTextBlock>
                    <GuideConceptTextBlock>
                        위드메이트는 <span>국내 최초</span>로 ‘비응급 의료 동행 서비스’의 개념을 구축했으며,
                        <DesktopBr /> 유수의 기관과 많은 개인에게 병원 동행 서비스를 제공했습니다.
                        <DesktopBr />
                        <MobileBr />
                        <DesktopBr /> 위드메이트 플랫폼이 제공하는 병원 동행 서비스는 다음과 같습니다.
                    </GuideConceptTextBlock>
                </GuideConceptTextWrapper>
            </InnerContainer> */}
        </GuideConceptContainer>
    );
};
