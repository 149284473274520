import React from 'react';
import { connect } from 'react-redux';
import StyledFindContainer from './find.styled';
import Find from './find';
import { editFindPasswordForm, findPassword } from './../../../../Reducer/Modules/auth';

class FindPage extends React.PureComponent {
    render() {
        return (
            <StyledFindContainer>
                <Find {...this.props} />
            </StyledFindContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapDispatchToProps = dispatch => ({
    editFindForm: ({ type, value }) => dispatch(editFindPasswordForm({ type, value })),
    findPassword: ({ id, userType }) => dispatch(findPassword({ id, userType })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindPage);
