import React from 'react';
import { Collapse, Icon, Pagination, message } from 'antd';
import moment from 'moment';

import CommentPageHeader from '../../../Components/Templates/Review/header';
import {
    HeaderButton,
    CollapseContainer,
    CollapseHeader,
    CollapseTab,
    CollapseTabOnlyDesktop,
    CountDisplay,
    PanelHeader,
    PanelField,
    Star,
    ReviewTextWrapper,
    ReviewText,
    ReviewMobileInfo,
    ReviewerName,
    MyReview,
} from './list.styled';

import axios from '../../../Lib/axios';

const { Panel } = Collapse;

const PerPage = 20;

export default class CommentList extends React.PureComponent {
    state = {
        tab: 'basic',
        total: 0,
        list: [],
        page: 1,
    };

    componentDidMount = () => {
        this.fetchList();
    };

    fetchList = () => {
        const { page } = this.state;
        axios.get('/v2/review/list', { params: { page, pageCnt: PerPage } }).then(resolve => {
            const { data } = resolve;
            if (data && data.total) {
                this.setState({ total: data.total || 0, list: data.list || [] });
            }
        });
    };

    renderStar = score => {
        return (
            <>
                {new Array(score).fill(0).map((star, index) => (
                    <Star key={'active-star=' + index.toString()} src='/assets/images/icon_active_star.png' alt='active-star' />
                ))}
                {new Array(5 - score).fill(0).map((star, index) => (
                    <Star key={'active-star=' + index.toString()} src='/assets/images/icon_star.png' alt='active-star' />
                ))}
            </>
        );
    };

    getAutoTitle = ({ title, rate }) => {
        if (title) return title;
        if (rate === 5) return '서비스 매우 만족';
        if (rate >= 4) return '서비스 만족';
        if (rate >= 3) return '서비스 보통';
        if (rate >= 2) return '서비스 불만족';
        if (rate >= 1) return '서비스 매우 불만족';
    };

    render() {
        const { tab, list, page, total } = this.state;
        const { id, userType } = this.props.auth;

        return (
            <div>
                <CommentPageHeader title='이용 후기' desc={`고객님의 소중한 후기를 통해 더욱 발전하는\n 위드메이트가 되겠습니다.`}>
                    <HeaderButton
                        onClick={() => {
                            if (userType && userType === 'user') {
                                this.props.history.push('/review/own/usage/list');
                            } else {
                                message.warning('비회원과 메이트는 작성할 수 없습니다.');
                            }
                        }}
                    >
                        후기 작성하기
                    </HeaderButton>
                </CommentPageHeader>

                <CollapseContainer className='inner-container'>
                    <CountDisplay>
                        <span>{total}</span>건
                    </CountDisplay>

                    <CollapseHeader>
                        <CollapseTab style={{ width: '40px' }}>번호</CollapseTab>
                        <CollapseTab style={{ flex: 1 }}>서비스</CollapseTab>
                        <CollapseTab style={{ flex: 1 }}>제목</CollapseTab>
                        <CollapseTabOnlyDesktop style={{ width: '80px' }}>작성자</CollapseTabOnlyDesktop>
                        <CollapseTabOnlyDesktop style={{ width: '120px' }}>별점</CollapseTabOnlyDesktop>
                        <CollapseTabOnlyDesktop style={{ width: '152px' }}>작성일</CollapseTabOnlyDesktop>
                    </CollapseHeader>
                    <Collapse
                        key={'item-key-frefresh'}
                        expandIconPosition='right'
                        bordered={false}
                        expandIcon={({ isActive }) => <Icon type='right' rotate={isActive ? 90 : -90} style={{ color: isActive ? '#3bc1ff' : '#a7a7a7' }} />}
                    >
                        {list &&
                            list.map((info, index) => (
                                <Panel
                                    key={'comment-order-' + index.toString() + 'item'}
                                    style={{ backgroundColor: '#ffffff', borderColor: '#e6e6e6' }}
                                    header={
                                        <PanelHeader>
                                            <PanelField style={{ width: '40px' }} index>
                                                {total - ((page - 1) * PerPage + index)}
                                            </PanelField>
                                            <PanelField style={{ width: '80px' }}>{info.reserve.service === 'assist' ? 'Basic' : 'Pro'}</PanelField>
                                            <PanelField className='title' style={{ flex: 1 }}>
                                                {this.getAutoTitle({ title: info.title, rate: info.score })}
                                            </PanelField>
                                            <PanelField style={{ width: '80px' }} onlyDesktop>
                                                {id === info.user._id ? <MyReview>내후기</MyReview> : info.user.name}
                                            </PanelField>
                                            <PanelField style={{ width: '120px' }} onlyDesktop>
                                                {info.score ? this.renderStar(parseInt(info.score, 10)) : null}
                                            </PanelField>
                                            <PanelField style={{ width: '152px' }} onlyDesktop>
                                                {moment(info.createdAt).format('YYYY-MM-DD')}
                                            </PanelField>
                                        </PanelHeader>
                                    }
                                >
                                    <ReviewTextWrapper>
                                        <ReviewMobileInfo>
                                            {id === info.user._id ? (
                                                <MyReview style={{ marginRight: '17px' }}>내후기</MyReview>
                                            ) : (
                                                <ReviewerName>{info.user.name}</ReviewerName>
                                            )}
                                            <span style={{ marginRight: '18px' }}>{info.score ? this.renderStar(parseInt(info.score, 10)) : null}</span>
                                            <ReviewerName>{moment(info.createdAt).format('YYYY-MM-DD')}</ReviewerName>
                                        </ReviewMobileInfo>
                                        {info.review && (
                                            <ReviewText>
                                                {info.review.split('\n').map((text, index) => (
                                                    <span key={info._id + index.toString() + tab}>
                                                        {text}
                                                        <br />
                                                    </span>
                                                ))}
                                            </ReviewText>
                                        )}
                                        <ReviewText style={{ marginTop: '8px' }}>
                                            (담당 메이트: {info.reserve && info.reserve.call && info.reserve.call.mate ? info.reserve.call.mate.name : '-'})
                                        </ReviewText>

                                        <ReviewText style={{ marginTop: '15px', paddingLeft: '15px' }}>
                                            {info.reply === '' ? (
                                                ''
                                            ) : (
                                                <>
                                                    <a style={{ color: '#3bc1ff' }}>➔&ensp;</a>
                                                    <b>위드메이트</b>: {info.reply ? info.reply : ''}
                                                </>
                                            )}
                                        </ReviewText>
                                    </ReviewTextWrapper>
                                </Panel>
                            ))}
                    </Collapse>
                    <Pagination
                        pageSize={PerPage}
                        total={this.state.total}
                        current={this.state.page}
                        onChange={page => {
                            this.setState({ page, list: [] }, () => {
                                this.fetchList();
                            });
                        }}
                    />
                </CollapseContainer>
            </div>
        );
    }
}
