import React from 'react';
import {
    Container,
    TopWrapper,
    BottomWrapper,
    InnerContainer,
    Logo,
    MediumText,
    LargeText,
    Title,
    ApplyButton,
    FlexWrapper,
    FlexTitle,
    Contact,
    ContactTime,
    Link,
    Noti,
} from './ready.styled';

class ReadyApplyPage extends React.PureComponent {
    render() {
        return (
            <Container>
                <TopWrapper>
                    <InnerContainer>
                        {/* <Logo src='/assets/images/main_logo.png' alt='withmate' /> */}
                        <Title>위드메이트 동행 매니저, ‘메이트’</Title>
                        <MediumText>병원 동행 서비스</MediumText>
                        <MediumText>새로운 수익 창출의 시작</MediumText>
                        <Noti>개인 일정에 맞춰 동행 서비스 접수 내역에</Noti>
                        <Noti>매칭 신청을 하고 수익을 창출하세요!</Noti>
                        <Noti>*단, 해외 여행에 결격 사유가 없는 자에 한해 지원이 가능합니다*</Noti>
                        <Noti>* 지원 3일 이내 채용 담당자가 연락을 드립니다.</Noti>
                        <div>
                            <ApplyButton
                                onClick={() => {
                                    this.props.history.push('/apply/form');
                                }}
                            >
                                메이트 지원하기
                            </ApplyButton>
                        </div>
                        <img className='bg' src={'/assets/images/art_clap.png'} alt='일러스트' />
                    </InnerContainer>
                </TopWrapper>
                <BottomWrapper>
                    <InnerContainer>
                        <FlexWrapper>
                            <div>
                                <Title style={{ color: '#161616' }}>위드메이트</Title>
                            </div>
                            <div>
                                <FlexTitle>지원관련 상담</FlexTitle>
                                <Contact>070-4300-9075</Contact>
                                <ContactTime>상담 가능시간 / 오전 9시 ~ 오후 5시</ContactTime>
                            </div>
                            <div>
                                <FlexTitle>안내사항</FlexTitle>
                                <br />
                                <Link href='https://www.notion.so/FAQ-cfe01db37c67472086b414a044ed6370' target='_blank'>
                                    메이트 가이드
                                </Link>
                                <br />
                                <Link href='https://www.notion.so/01a7a20704d14289a4f6b93de6c56a04?v=b137c8389c66455eb89361fc99291539' target='_blank'>
                                    메이트 FAQ
                                </Link>
                                {/* <br />
                                <Link href='/service/guide' target='_blank'>
                                    회사소개
                                </Link> */}
                            </div>
                        </FlexWrapper>
                    </InnerContainer>
                </BottomWrapper>
            </Container>
        );
    }
}
export default ReadyApplyPage;
