import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Button, Input, message, Table } from 'antd';

import ProfileStyledContainer from '../Auth/AuthPages/Profile/profile.styled';
import { HalfWidthButton } from '../../Components/Common/button';
import { DivisionLine } from '../../Components/Common/input';
import { getMateInfo } from '../../Lib/Api/auth';
import axios from '../../Lib/axios';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(15, 15, 15, 0.79)',
    },
    content: {
        position: 'absolute',
        top: '30px',
        height: '90%',
        border: '1px solid #ccc',
        background: '#ffffff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
    },
};

const mateListColumn = ({ onCheck }) => {
    return [
        {
            title: '이름',
            dataIndex: 'name',
            align: 'center',
            width: '100px',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        // {
        //     title: '성별',
        //     dataIndex: 'sex',
        //     align: 'center',
        //     width: '100px',
        //     render: sex => <span>{sex === '남' ? '남자' : '여자'}</span>
        // },
        {
            title: '사진',
            dataIndex: 'photo',
            align: 'center',
            width: '100px',
            render: photoURL => <img src={photoURL} style={{ width: '100px', height: 'auto' }} />,
        },
        // {
        //     title: '수행 이력',
        //     dataIndex: 'review',
        //     align: 'center',
        //     width: '100px',
        //     render: review => <span>Pro 서비스 이력: {(review && review.unemergency) || 0}건
        //         <br />Basic 서비스 이력: {(review && review.assist) || 0}건</span>,
        // },
        {
            title: '선택',
            align: 'center',
            width: '100px',
            dataIndex: '_id',
            render: (_id, data) => (
                <Button
                    style={{ width: '70px', height: '30px' }}
                    onClick={() => {
                        onCheck(data);
                    }}
                >
                    선택
                </Button>
            ),
        },
    ];
};

class PatientProfileListComponent extends React.PureComponent {
    state = {
        mateInfo: {},
        mateList: [],
        mateId: '',
        keyword: '',
        review: null,
    };

    componentWillUnmount = () => {
        this.setState({ mateInfo: null });
    };

    onChoose = () => {
        const { mateInfo, mateId } = this.state;
        console.log(mateInfo, mateId);

        getMateInfo({ mateId: mateInfo._id }).then(resolve => {
            console.log(resolve);
            const { data } = resolve;
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ mateInfo: resolve.data.data });
            } else {
                message.info('선택하신 메이트가 존재하지 않습니다.');
            }
        });
    };

    getList = () => {
        const { keyword } = this.state;

        const list = this.props.list.filter(item => item && item.name && item.name.toLowerCase().includes(keyword));

        return list;
    };

    render() {
        const list = this.getList();
        // console.log(this.props)
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <ProfileStyledContainer>
                    <div className='inner-container'>
                        <div className='mate-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>메이트 선택하기</WideModalTitle>
                                    <AlertText>* 서비스 수행을 함께할 메이트를 선택하실 수 있습니다.</AlertText>
                                </TitleSection>

                                <br />

                                <DivisionLine />

                                <div className='mateList'>
                                    <Input
                                        value={this.state.keyword}
                                        onChange={ev => {
                                            this.setState({ keyword: ev.target.value });
                                        }}
                                        placeholder='메이트 이름으로 검색하세요.'
                                        style={{ marginBottom: '24px' }}
                                    />
                                    <Table
                                        dataSource={list}
                                        columns={mateListColumn({
                                            onCheck: data => {
                                                this.props.onUpdateMate(data);
                                                this.props.onClose();
                                            },
                                        })}
                                        rowKey='_id'
                                        pagination={{
                                            pageSize: 5,
                                            hideOnSinglePage: true,
                                            size: 'small',
                                        }}
                                    />
                                </div>

                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff' onClick={this.props.onClose}>
                                        닫기
                                    </HalfWidthButton>
                                </ButtonWrap>
                            </div>
                        </div>
                    </div>
                </ProfileStyledContainer>
            </Modal>
        );
    }
}

export default PatientProfileListComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;
