import React from 'react';
import { AutoComplete, Button } from 'antd';
import styled from 'styled-components';
import { DivisionLine } from '../../Components/Common/input';
import Background from './login_bg.png';
import Checkpoint from './checkpoint.png';

class SuccessComponent extends React.PureComponent {
    render() {
        return (
            <div className='success-container' style={{ backgroundImage: 'url('+Background+')' }}>
                <div className='inner-container' style={{ display: 'flex', justifyContent: 'center'}}>
                    <div className='success-form' style={{ border: '1px solid lightgray', borderRadius: '7px', width: '550px', marginTop: '40px', paddingTop: '80px', maxWidth: '480px' , backgroundColor: 'white' }}>
                        <div className='success-title'>
                            <img class="successImg" src={Checkpoint} style={{ width: '70px', height: '70px', display: 'block', margin: '0px auto' }}/>
                            <DivisionLine />
                            <h1 style={{ textAlign: 'center' }}>회원가입이 <b>완료</b> 되었습니다.</h1>
                        </div>

                        <DivisionLine /><DivisionLine />

                        <div className='form-success-type' style={{ textAlign: 'center' }}>
                            <div>
                                <h4>※ 위드메이트의 서비스를 더욱 편리하게 사용할 수 있도록 <br/>
                                    <b>환자 프로필</b>을 등록하실 수 있습니다.</h4>
                            </div>
                        </div>
                        
                        <DivisionLine />
                        <hr style={{ textAlgn: 'center', width: '320px', color: 'gray' }}/>

                        <div className='form'  style={{ textAlign: 'center' }}>
                            <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px', backgroundColor: 'gray' }} 
                                    onClick={() => {
                                        this.props.history.push('/user/mypage/profile');
                                    }}
                            >
                                프로필 등록
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px' }} 
                                    onClick={() => {
                                        this.props.history.push('/');
                                    }}
                            >
                                다음에 하기
                            </Button>
                        </div>
                        <DivisionLine />
                    </div>
                </div>
            </div>
        );
    }
}
export default SuccessComponent;