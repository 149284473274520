import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { mainColor } from '../../../theme';

export const FormContainer = styled.div`
    // background-color: ${mainColor};
    width: 100vw;

    padding-top: 55px;
`;

export const InnerContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 0px;

    .type-button-wrapper {
        width: 100%;
        height: 48px;

        background-color: white;
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        display: flex;
        align-items: center;

        > .type-button {
            width: 50%;
            height: 100%;
            line-height: 48px;

            text-align: center;
            cursor: pointer;

            font-size: 16px;
            letter-spacing: -0.2px;
            color: rgba(0, 0, 0, 0.2);
        }

        > .type-button.active {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 4px;
            color: #f9f9f9;
        }
    }

    @media (max-width: 1080px) {
        padding: 80px 16px;
    }

    @media (max-width: 768px) {
        padding: 80px 16px;
    }
`;

export const LargeText = styled.p`
    margin-bottom: 0px;

    font-weight: bold;
    color: #ffffff;
    font-size: 32px;

    padding: 2.5px;
    border-bottom: 4px solid #ffffff;
    display: inline-block;
`;

export const InputWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    background-color: #ffffff;
    padding: 16px;
    border-left: 8px solid ${mainColor};

    margin-bottom: 12px;
    overflow: hidden;
`;

export const InputLabel = styled.div`
    font-size: 17px;
    margin-bottom: 8px;

    > span {
        font-size: 12px;
    }
`;

export const StyledInput = styled(Input.TextArea)`
    font-size: 14px;
    &::placeholder {
        font-size: 14px;
    }
`;

export const ApplyButton = styled.span`
    padding: 10px 16px;
    background-color: ${mainColor};

    color: #ffffff;
    font-size: 16px;
    font-weight: bold;

    width: 100%;
    min-width: 200px;
    max-width: 360px;

    border-radius: 40px;
    overflow: hidden;

    display: inline-block;
    margin-top: 40px;
    cursor: pointer;

    &:hover {
        transition: all 0.5s;
        background-color: #ffffff;
        border: 1px solid ${mainColor};
        color: ${mainColor};
    }
`;

export const FormImgWrapper = styled.div`
    > img {
        padding-bottom: 10px;
        width: 150px;
        object-fit: contain;
    }
`;

export const SpinWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0px;
    left: 0px;

    z-index: 9999;

    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.6);
`;
