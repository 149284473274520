import React from 'react';
import { connect } from 'react-redux';

// component
import RegisterStyledContainer from './register.styled';
import Register from './register';
import { register, editRegisterForm, checkUserId, confirmEmailAuth } from '../../../../Reducer/Modules/auth';

class RegisterPage extends React.PureComponent {
    render() {
        return (
            <RegisterStyledContainer>
                <Register {...this.props} />
            </RegisterStyledContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapDispatchToProps = dispatch => ({
    editRegisterForm: ({ type, value }) => dispatch(editRegisterForm({ type, value })),
    register: info => dispatch(register(info)),
    checkUserId: ({ id, userType, type }) => dispatch(checkUserId({ id, userType, type })),
    confirmEmailAuth: verificationCode => dispatch(confirmEmailAuth(verificationCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);