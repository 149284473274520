export const gray = ['#f8f9fa', '#f1f3f5', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd', '#868e96', '#495057', '#343a40', '#212529'];
export const cyan = ['#e3fafc', '#c5f6fa', '#99e9f2', '#66d9e8', '#3bc9db', '#22b8cf', '#15aabf', '#1098ad', '#0c8599', '#0b7285'];
export const red = ['#fff5f5', '#ffe3e3', '#ffc9c9', '#ffa8a8', '#ff8787', '#ff6b6b', '#fa5252', '#f03e3e', '#e03131', '#c92a2a'];
export const yellow = ['#fff9db', '#fff3bf', '#ffec99', '#ffe066', '#ffd43b', '#fcc419', '#fab005', '#f59f00', '#f08c00', '#e67700'];
export const blue = ['#e7f5ff', '#d0ebff', '#a5d8ff', '#74c0fc', '#4dabf7', '#339af0', '#228be6', '#1c7ed6', '#1971c2', '#1864ab'];
export const grape = ['#f8f0fc', '#f3d9fa', '#eebefa', '#e599f7', '#da77f2', '#cc5de8', '#be4bdb', '#ae3ec9', '#9c36b5', '#862e9c'];
export const borderColor = '#e8e8e8';

export const fontSize = {
    b8: '8px',
    b10: '10px',
    b12: '12px',
    b14: '14px',
    b16: '16px',
    b18: '18px',
    b20: '20px',
    b22: '22px',
    b24: '24px',
    b26: '26px',
    b28: '28px',
    b30: '30px',
    b32: '32px',
    b34: '34px',
};

export const sizeHandler = size => {
    return size + 'px';
};
export const mainColor = '#3bc1ff';
export const mainBoldColor = '#4dabf7';
export const bgDarkColor = '#212529';

const ColorPallete = {
    blue50: '#3bc1ff',
    blue100: '#4dabf7',
    green100: '#49a938',
    dark100: '#161616',
    dark80: 'rgba(0,0,0,0.8)',
    dark70: 'rgba(0,0,0,0.7)',
    dark60: '#a6a6a6',
    dark50: '#e9e9e9',
    dark30: '#ececec',
    dark20: '#dddddd',
    dark10: '#f5f5f5',
};

export default ColorPallete;
