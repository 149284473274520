import { message } from 'antd';
import React from 'react';
import PlatformManual from './platformPDF';

class ManualComponent extends React.PureComponent {
    componentDidMount = () => {
        if (!localStorage.getItem('id')) {
            message.warning('플랫폼 매뉴얼을 보시려면 로그인 후 이용해주세요.');
            return this.props.history.push('/user/login');
        }
        this.props.history.push('/platform/manual');
    }

    render() {
        return (
            <div className='pdf-form'>
                <PlatformManual />
            </div>
        );
    }
}
export default ManualComponent;