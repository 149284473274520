import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { notification, Select, message } from 'antd';

import { DivisionLine, WithmateSelect, WithmateInput, WithmateLabel } from '../../../../Components/Common/input';
import { HalfWidthButton } from '../../../../Components/Common/button';
import CardStyledContainer from './card.styled';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(15, 15, 15, 0.79)',
    },
    content: {
        position: 'absolute',
        top: '30px',
        width: '350px',
        height: '37%',
        border: '1px solid #ccc',
        background: '#ffffff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
    },
};

class NewProfileComponent extends React.PureComponent {
    state = {
        modalInfo: '',
        modalTitle: '',
    };

    onEdit = ({ type, value }) => {
        this.props.editAddCardForm({ type, value });
    };

    onCard = () => {
        const { cardName, cardNum, year, month } = this.props.auth.addCardForm;

        if (!cardName) {
            return message.warning('카드명을 입력하세요');
        }

        if (!cardNum) {
            return message.warning('카드번호를 입력하세요');
        }

        if (!year) {
            return message.warning('유효 연도를 선택하세요');
        }

        if (!month) {
            return message.warning('유효 월을 선택하세요');
        }

        this.props
            .addCard({
                id: this.props.auth.id,
                userName: this.props.auth.name,
                cardName,
                cardNum,
                year,
                month,
            })
            .then(resolve => {
                // console.log(resolve)
                if (resolve.message === 'success') {
                    this.props.onClose();
                    this.onEdit({ type: 'clear', value: '' });
                    notification.open({
                        message: '결제카드 등록이 완료되었습니다.',
                        description: '등록된 결제카드정보로 서비스 이용결제시 편리하게 이용하실 수 있습니다.',
                        type: 'success',
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    formCompleteStatus = () => {
        let valid = true;
        if (!this.props.auth || !this.props.auth.addCardForm) return false;

        const { cardName, cardNum, year, month } = this.props.auth.addCardForm;
        if (!cardName) valid = false;
        if (!cardNum) valid = false;
        if (!year) valid = false;
        if (!month) valid = false;

        return valid;
    };

    render() {
        // console.log(this.props.auth);
        const { addCardForm } = this.props.auth;
        const status = this.formCompleteStatus();
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <CardStyledContainer>
                    <div className='inner-container'>
                        <div className='profile-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>결제카드정보 등록</WideModalTitle>
                                </TitleSection>

                                <br />

                                <WithmateLabel required>결제 카드사</WithmateLabel>
                                <WithmateSelect
                                    name='cardName'
                                    placeholder='카드사를 선택하세요'
                                    value={addCardForm.cardName || undefined}
                                    onChange={value => {
                                        this.onEdit({ type: 'cardName', value });
                                    }}
                                >
                                    <Select.Option value='KB국민카드'>KB국민카드</Select.Option>
                                    <Select.Option value='삼성카드'>삼성카드</Select.Option>
                                    <Select.Option value='현대카드'>현대카드</Select.Option>
                                    <Select.Option value='우리카드'>우리카드</Select.Option>
                                    <Select.Option value='BC카드'>BC카드</Select.Option>
                                    <Select.Option value='신한카드'>신한카드</Select.Option>
                                    <Select.Option value='롯데카드'>롯데카드</Select.Option>
                                    <Select.Option value='NH농협카드'>NH농협카드</Select.Option>
                                </WithmateSelect>

                                <DivisionLine />

                                <WithmateLabel required>카드번호</WithmateLabel>
                                <WithmateInput
                                    type='phone'
                                    placeholder='11자리 또는 12자리의 카드번호를 입력하세요'
                                    value={addCardForm.cardNum || ''}
                                    maxLength='19'
                                    onChange={ev => {
                                        this.onEdit({
                                            type: 'cardNum',
                                            value: ev.target.value
                                                .replace(/[^0-9]/g, '')
                                                .replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3-$4')
                                                .replace(/(\-{1,2})$/g, ''),
                                        });
                                    }}
                                />
                                <DivisionLine />

                                <WithmateLabel required>유효기간(MM/YY)</WithmateLabel>
                                <YMWrapper withHalf>
                                    <div style={{ flex: 1 }}>
                                        <WithmateSelect
                                            style={{ width: '150px' }}
                                            placeholder='MM'
                                            value={addCardForm.month || undefined}
                                            onChange={value => {
                                                this.onEdit({ type: 'month', value });
                                            }}
                                        >
                                            <Select.Option value='1'>1</Select.Option>
                                            <Select.Option value='2'>2</Select.Option>
                                            <Select.Option value='3'>3</Select.Option>
                                            <Select.Option value='4'>4</Select.Option>
                                            <Select.Option value='5'>5</Select.Option>
                                            <Select.Option value='6'>6</Select.Option>
                                            <Select.Option value='7'>7</Select.Option>
                                            <Select.Option value='8'>8</Select.Option>
                                            <Select.Option value='9'>9</Select.Option>
                                            <Select.Option value='10'>10</Select.Option>
                                            <Select.Option value='11'>11</Select.Option>
                                            <Select.Option value='12'>12</Select.Option>
                                        </WithmateSelect>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <WithmateInput
                                            style={{ width: '150px' }}
                                            type='text'
                                            placeholder='YY'
                                            maxLength='2'
                                            value={addCardForm.year || ''}
                                            onChange={ev => {
                                                this.onEdit({ type: 'year', value: ev.target.value });
                                            }}
                                        />
                                    </div>
                                </YMWrapper>
                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ marginRight: '8px', color: '#afb0b2' }} bg='#e6e6e6' onClick={this.props.onClose}>
                                        닫기
                                    </HalfWidthButton>
                                    <HalfWidthButton
                                        style={{ margin: '0 auto' }}
                                        bg='#3bc1ff'
                                        onClick={() => {
                                            this.onCard();
                                        }}
                                        style={!status ? { backgroundColor: '#E6E6E6', border: 0, cursor: 'not-allowed' } : {}}
                                    >
                                        카드정보 등록
                                    </HalfWidthButton>
                                </ButtonWrap>
                            </div>
                        </div>
                    </div>
                </CardStyledContainer>
            </Modal>
        );
    }
}

export default NewProfileComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const YMWrapper = styled.div`
    display: flex;
    align-items: center;

    ${props =>
        props.withHalf
            ? `
        > div, p {
            width: calc(50% - 4px) !important;
            margin-right: 8px;
        }
    `
            : ''}

    > div:last-child {
        margin-right: 0px;
    }
`;
