import styled from 'styled-components';
import { mainColor, sizeHandler } from '../../../../theme';
const StyledBuyContainer = styled.div`
    margin-top: 55px;

    .buy-container {
        min-height: 100vh;
        position: relative;
        width: 100%;
        background-color: #ebf9ff;
        background-image: url('/assets/images/main-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 768px) {
            background-image: none;
        }

        .order-menu-wrapper {
            width: 50%;
            position: relative;
            max-width: 440px;
            min-width: 370px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 1px 2px 3.5px #e6e6e6;
            padding: 32px;
            // overflow: hidden;

            .mobile-close-button {
                display: none;
            }

            .ant-input-number {
                width: 100%;
                height: 48px;

                .ant-input-number-input-wrap {
                    height: 100%;

                    input {
                        height: 100%;
                    }
                }

                .ant-input-number-handler-wrap {
                    opacity: 1;
                    width: 48px;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            position: relative;
                            margin-top: 0px;
                            left: 0px;
                            top: 0px;
                            bottom: 0px;
                            right: 0px;
                            font-size: 16px;
                        }
                    }
                    .anticon.anticon-up.ant-input-number-handler-up-inner {
                        color: ${mainColor};
                    }
                    .anticon.anticon-down.ant-input-number-handler-down-inner {
                        color: ${mainColor};
                    }
                }
            }

            @media (max-width: 768px) {
                margin: 0px auto;
                width: 100%;
            }

            .order-title {
                margin-bottom: 20px;

                p {
                    margin: 0px;
                    padding: 0px;
                    font-size: ${sizeHandler(28)};
                    font-weight: bold;
                    letter-spacing: -0.7px;
                    color: rgba(0, 0, 0, 0.7);
                }
            }

            .fee-wrapper {
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                p {
                    margin: 0px;
                    padding: 0px;

                    &.info {
                        flex: 1;
                        font-size: 14px;
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    &.fee {
                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        text-align: right;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }

            .total-wrapper {
                display: flex;
                align-items: center;
                padding: 20px 0px;
                margin-top: 16px;
                margin-bottom: 32px;
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #e6e6e6;

                p {
                    margin: 0px;
                    padding: 0px;

                    &.info {
                        flex: 1;
                        font-size: ${sizeHandler(16)};
                        font-weight: bold;
                        letter-spacing: -0.5px;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    &.fee {
                        font-size: ${sizeHandler(16)};
                        font-weight: bold;
                        letter-spacing: -0.5px;
                        text-align: right;
                        color: #3bc1ff;
                    }
                }
            }

            .service {
                margin-bottom: 20px;

                .service-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #ddd;
                    border-radius: 6px;

                    .tab {
                        flex: 1;
                        height: 48px;
                        border-right: 1px solid #ddd;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:last-child {
                            border: 0px;
                        }

                        span {
                            font-size: ${sizeHandler(14)};
                            letter-spacing: -0.2px;
                            font-weight: bold;
                            color: ${mainColor};
                        }
                    }

                    .tab.active {
                        background-color: ${mainColor};

                        span {
                            color: white;
                        }
                    }
                }

                .info-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        flex: 1;
                        margin: 0px;
                        padding: 0px;
                        text-align: left;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }

                .info-input-tab {
                    display: flex;
                    align-items: center;
                    // justify-content: center;

                    > div {
                        flex: 1;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }

                    .input {
                        display: flex;
                        border-radius: 4px;
                        border: solid 1px #e6e6e6;
                        height: 36px;
                        line-height: 36px;
                        text-indent: 12px;
                        align-items: center;
                        font-size: ${sizeHandler(12)};
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.8);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        background-color: #f5f5f5;
                        cursor: not-allowed;

                        // .ant-input {
                        //     color: rgba(0, 0, 0, 0.8);
                        // }

                        &.phone {
                            flex: 0.5;

                            @media (max-width: 768px) {
                                flex: 1;
                            }
                        }

                        &.input-with-icon {
                            p {
                                height: 100%;
                                flex: 1;
                            }

                            div {
                                height: 100%;
                                width: 36px;
                                border-left: 1px solid #e6e6e6;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span {
                                    text-align: center;
                                    text-indent: 0px;
                                    opacity: 0.3;
                                    font-size: ${sizeHandler(12)};
                                    letter-spacing: -0.3px;
                                    color: rgba(0, 0, 0, 0.8);
                                }
                            }
                        }
                    }
                }

                .service-fee-wrapper {
                    margin-bottom: 32px;

                    .service {
                        display: flex;
                        align-items: center;
                        padding-top: 12px;
                        margin-bottom: 0px;

                        .name {
                            flex: 1;
                            font-size: ${sizeHandler(14)};
                            letter-spacing: -0.3px;
                            color: rgba(0, 0, 0, 0.7);
                        }

                        .fee {
                            font-size: ${sizeHandler(14)};
                            font-weight: bold;
                            text-align: right;
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }

                input {
                    height: 36px;
                    display: inline-block;
                    width: 100%;
                }

                .select {
                    display: inline-block;
                    height: 36px;
                    width: 100%;

                    .ant-select-selection--single {
                        height: 100%;
                    }

                    .ant-select-selection__rendered {
                        line-height: 36px;
                    }
                }

                .info {
                    font-size: ${sizeHandler(12)};
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 4px;
                    letter-spacing: -0.15px;
                }

                .ant-select-selection__rendered {
                    margin-right: 34px;
                    border-right: 1px solid #e6e6e6;
                }

                .ant-select-arrow {
                    color: ${mainColor};
                }

                .image-with-text {
                    display: flex;
                    align-items: center;
                    img {
                        height: 18px;
                        border: 0px;
                    }

                    p {
                        flex: 1;
                        margin: 0px;
                        padding: 0px;
                        margin-left: 12px;
                        font-size: ${sizeHandler(18)};
                        font-weight: bold;
                        color: #3bc1ff;
                    }
                }
            }

            .side-summary-tab {
                position: absolute;
                top: 0px;
                right: -380px;
                width: 360px;
                height: 100%;
                background-color: white;
                border-radius: 8px;
                box-shadow: 1px 2px 3.5px #e6e6e6;
                padding: 32px;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                @media (max-width: 768px) {
                    width: 100%;
                    height: auto;
                    border-radius: 0px;
                    box-shadow: none;
                    padding: 0px;
                    position: relative;
                    top: 55px;
                    left: 0px;
                    background-color: transparent;
                }

                .refund-info-wrapper {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;

                    .info-wrapper {
                        width: 100%;

                        .title {
                            font-size: ${sizeHandler(12)};
                            font-weight: bold;
                            color: #a7a7a7;
                            margin-bottom: 8px;
                        }

                        .info {
                            font-size: ${sizeHandler(10)};
                            line-height: ${sizeHandler(16)};
                            color: rgba(0, 0, 0, 0.7);
                        }

                        @media (max-width: 768px) {
                            margin-top: 36px;
                            padding-bottom: 20px;

                            .info {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
`;
export default StyledBuyContainer;
