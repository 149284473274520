import React from 'react';
import axios from '../../../Lib/axios';
import styled from 'styled-components';
import moment from 'moment';
import { Button, message, Table } from 'antd';
import { CollapseContainer } from '../../Review/List/list.styled';

import { InfoBlock } from '../../../Components/Common/block';

export default class Event extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        // const { state } = this.props.history.location;
        // if (!state || !state.id) return this.props.history.goBack();
        const { id } = this.props.match.params;

        this.fetchInfo(id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/newsEvent/list/info', {
            params: {
                newsEventId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid newEvent') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    render() {
        const { url, url2, urlSource, urlSource2 } = this.state;

        return (
            <div className='faq-container'>
                <div className='faq-header'>
                    <div className='inner-container'>
                        <div className='flex-wrapper'>
                            <div className='faq-main-title'>
                                <p className='title'>위드메이트 소식</p>
                                <p className='description'>위드메이트의 다양한 소식을 확인할 수 있습니다.</p>
                            </div>
                            <div className='img-section'>
                                <img src={'/assets/images/comment_main.png'} style={{ width: '200px', height: 'auto', marginRight: '100px' }} alt='faq-img' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info-contents'>
                    <CollapseContainer className='inner-container' style={{ width: '80%', margin: 'auto' }}>
                        <>
                            <ValueBox>
                                <Label style={{ fontSize: '25px' }}>{this.state.title}</Label>
                            </ValueBox>

                            <ValueBox style={{ textAlign: 'right' }}>
                                <InfoBlock label='작성일' value={moment(this.state.createdAt).format('YYYY년 MM월 DD일 dddd')} />
                            </ValueBox>

                            <ValueBox>
                                {this.state.photo && (
                                    <div className='img-section' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            style={{ objectFit: 'contain' }}
                                            src={typeof this.state.photo === 'string' ? this.state.photo : URL.createObjectURL(this.state.photo)}
                                            alt='사진'
                                        />
                                    </div>
                                )}
                            </ValueBox>

                            <ValueBox>
                                <InfoBlock label='내용'>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{this.state.content} </p>
                                </InfoBlock>
                                {this.state.url ? (
                                    <InfoBlock label='링크'>
                                        {url ? (
                                            <a href={this.state.url} target='_blank' style={{ fontSize: '15px', color: '#3bc1ff' }}>
                                                ㆍ{urlSource}
                                            </a>
                                        ) : (
                                            ''
                                        )}{' '}
                                        <br />
                                        {url2 ? (
                                            <a href={this.state.url2} target='_blank' style={{ fontSize: '15px', color: '#3bc1ff' }}>
                                                ㆍ{urlSource2}
                                            </a>
                                        ) : (
                                            ''
                                        )}
                                    </InfoBlock>
                                ) : null}
                            </ValueBox>

                            <ValueBox>
                                <Button
                                    className='white'
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                    style={{ float: 'right' }}
                                >
                                    목록으로
                                </Button>
                            </ValueBox>
                        </>
                    </CollapseContainer>
                </div>
            </div>
        );
    }
}

export const ValueBox = styled.div`
    margin-bottom: 16px;
`;

export const Label = styled.p`
    text-align: left;
    font-size: 11px;
    margin-bottom: 4px;
    color: #565656;
`;
