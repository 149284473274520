import React from 'react';
import { connect } from 'react-redux';
import { KAKAO } from '../../../../Lib/variables';
import { logout } from '../../../../Reducer/Modules/auth';

class LogoutPage extends React.PureComponent {
    componentDidMount = () => {
        const { id, userType, type } = this.props.auth;
        if (!id || !userType) {
            localStorage.clear();
            return (window.location.href = '/');
        }
        if( type === KAKAO && window.Kakao) {
            window.Kakao.Auth.logout()
        }
        this.props.logout({ id, userType });
    };

    render() {
        return <div></div>;
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});
const mapDispatchToProps = dispatch => ({
    logout: ({ id, userType }) => dispatch(logout({ id, userType })),
});
export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
