import React from 'react';
import { connect } from 'react-redux';

// component
import Auth from './auth';

// styles
import AuthStyledContainer from './auth.styled';

class AuthPages extends React.PureComponent {
    render() {
        return (
            <AuthStyledContainer>
                <Auth {...this.props} />
            </AuthStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});

const mapStateToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapStateToDispatch)(AuthPages);
