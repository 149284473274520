import React from 'react';
import styled from 'styled-components';
import StarRatingComponent from 'react-star-rating-component';
import { message, notification, Checkbox } from 'antd';
import moment from 'moment';

import { ASSIST } from '../../../Lib/variables';
import axios from '../../../Lib/axios';
import CommentPageHeader from '../../../Components/Templates/Review/header';
import { WithmateLabel, DivisionLine, WithmateInput, WithmateTextarea } from '../../../Components/Common/input';

import {
    WritePaper,
    FormHeader,
    StarImg,
    FormTitle,
    FormSubtitle,
    FormTypeWrapper,
    FormTypeBlock,
    CheckboxGroup,
    FormTypeDesc,
    FormRowTitle,
    FormDescription,
    FormTitleRowWrapper,
    FormButtonWrapper,
    FormButton,
} from './add.styled';

export default class AddComment extends React.PureComponent {
    state = {
        star: 0,
        title: '',
        review: '',
        useAgain: null,
        corpSatisfied: null,
        corpSatisfiedReason: '',
        doctorSatisfied: null,
        doctorSatisfiedReason: '',
        hope: '',
    };

    componentDidMount = () => {
        this.checkReserveValidation();
    };

    checkReserveValidation = async () => {
        const id = localStorage.getItem('id');
        const { params } = this.props.match;

        if (!params.reserveId) {
            message.info('올바르지않은 시도입니다. 다시 시도해주세요.');
            return this.props.history.goBack();
        }
        const { data: reviewStatus } = await axios.get('/v1/review/check/validation', { params: { reserveId: params.reserveId } });
        if (!reviewStatus) {
            message.info('올바르지않은 시도입니다. 다시 시도해주세요.');
            return setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }

        if (reviewStatus === 'already registered') {
            notification.error({
                message: '작성 완료 알림',
                description: `이미 해당 이용에 대한 후기를 작성하였습니다. 목록으로 돌아가 다시 시도해주세요.`,
            });

            return setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }

        if (reviewStatus === 'invalid reserve') {
            notification.error({
                message: '유효하지 않은 이용내역',
                description: `올바르지않은 시도입니다. 다시 시도해주세요.`,
            });
            return setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }

        axios.get('/v2/service/user/reserve/info', { params: { userId: id, reserveId: params.reserveId } }).then(resolve => {
            const { data: info } = resolve;
            if (!info) return message.error('죄송합니다. 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
            if (info === 'invalid reserve') return message.warning('유효하지않은 예약입니다.');

            const serviceType =
                !info.productInfo || !info.productInfo.usePro || !info.productInfo.useBasic ? '' : info.service === 'unemergency' ? 'Pro' : 'Basic';
            const serviceName = (info.productInfo && info.productInfo.name && info.service && info.productInfo.name + serviceType) || '-';

            this.setState({
                reserveId: info._id,
                callId: info.call._id,
                service: info.service,
                reserveDate: info.reserveDate,
                serviceName,
            });
        });
    };

    starHandler = (nextValue, prevValue, name) => {
        this.setState({ star: nextValue });
    };

    inputHandler = ({ type, value }) => {
        this.setState({
            [type]: value,
        });
    };

    onAddReview = () => {
        // 리뷰 추가
        const userId = localStorage.getItem('id');
        const { star, reserveId, title, review, useAgain, corpSatisfied, corpSatisfiedReason, doctorSatisfied, doctorSatisfiedReason, hope, reserveDate } = this.state;

        const info = { score: star };

        if (title) info.title = title;
        if (reserveDate) info.reserveDate = reserveDate;
        if (review) info.review = review;
        if (typeof useAgain === 'boolean') info.useAgain = useAgain;
        if (typeof corpSatisfied === 'boolean') info.corpSatisfied = corpSatisfied;
        if (corpSatisfiedReason) info.corpSatisfiedReason = corpSatisfiedReason;
        if (typeof doctorSatisfied === 'boolean') info.doctorSatisfied = doctorSatisfied;
        if (doctorSatisfiedReason) info.doctorSatisfiedReason = doctorSatisfiedReason;
        if (hope) info.hope = hope;

        if (!star) return message.warning('별점을 매겨주세요.');

        axios
            .post('/v2/review/add', { info, reserveId, userId })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('죄송합니다. 일시적인 서버 장애로 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                else if (data === 'check parameters') return message.warning('요청에 실패하였습니다.');
                else if (data === 'invalid') return message.warning('잘못된 요청입니다. 재 로그인 후 시도해주세요.');
                else if (data === 'not exist reserve') return message.warning('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.');
                else if (data === 'already registered') return message.warning('이미 리뷰 등록을 완료한 예약입니다.');
                else if (data === 'success') {
                    message.success('리뷰 등록 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            })
            .catch(error => {
                return message.error('죄송합니다. 일시적인 서버 장애로 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
            });
    };

    render() {
        const { star, title, serviceName, reserveDate, review, useAgain, corpSatisfied, corpSatisfiedReason, doctorSatisfied, doctorSatisfiedReason, hope } =
            this.state;

        return (
            <div>
                <CommentPageHeader title='서비스 이용 후기 작성' desc={`후기 작성 시 최대 3,000포인트를\n 지급해드립니다.`}>
                    <PaddingDiv style={{ paddingTop: '90px' }} />
                </CommentPageHeader>

                <FullContainer>
                    <div className='inner-container'>
                        <WritePaper>
                            <FormHeader>
                                <FormTitle>후기 작성</FormTitle>
                                <FormSubtitle>후기를 작성하실 이용 내역을 선택해주세요</FormSubtitle>
                                <FormTypeWrapper>
                                    <FormTypeBlock>
                                        <WithmateLabel>이용하신 서비스</WithmateLabel>
                                        <FormTypeDesc>{serviceName}</FormTypeDesc>
                                    </FormTypeBlock>
                                    <FormTypeBlock>
                                        {/* <WithmateLabel>이용 날짜</WithmateLabel>
                                        <FormTypeDesc>{moment(reserveDate).format('YYYY년 MM월 DD일')}</FormTypeDesc>
                                    </FormTypeBlock> */}
                                        <WithmateLabel>서비스 이용 날짜</WithmateLabel>
                                        <FormTypeDesc>{moment(reserveDate).format('YYYY년 MM월 DD일')}</FormTypeDesc>
                                    </FormTypeBlock>
                                </FormTypeWrapper>
                            </FormHeader>

                            <DivisionLine big />

                            <WithmateLabel required>
                                별점
                                <FormDescription>(작성 시 500포인트 지급)</FormDescription>
                            </WithmateLabel>
                            <StarRatingComponent
                                starCount={5}
                                value={star}
                                renderStarIcon={(index, value) => {
                                    return <StarImg src={`/assets/images/icon_${index <= value ? 'active_star' : 'star'}.png`} />;
                                }}
                                onStarClick={this.starHandler}
                            />

                            <DivisionLine big />

                            <WithmateLabel>
                                제목
                                <FormDescription>(제목 + 이용후기 작성 시 500포인트 지급)</FormDescription>
                            </WithmateLabel>
                            <WithmateInput
                                value={title}
                                maxLength={30}
                                onChange={ev => {
                                    this.inputHandler({ type: 'title', value: ev.target.value });
                                }}
                                placeholder='제목을 입력해주세요.'
                            />

                            <DivisionLine big />

                            <WithmateLabel>이용 후기</WithmateLabel>
                            <WithmateTextarea
                                value={review || undefined}
                                placeholder='고객님의 소중한 후기는 위드메이트 서비스 개선에 많은 도움이 됩니다. 감사합니다.'
                                onChange={ev => {
                                    this.inputHandler({ type: 'review', value: ev.target.value });
                                }}
                            />

                            <DivisionLine big />
                            <DivisionLine big />

                            <WithmateLabel>
                                추가 선택 사항
                                <FormDescription>(선택사항까지 작성 완료 시 2000포인트 추가 지급)</FormDescription>
                            </WithmateLabel>

                            <DivisionLine small />

                            <FormTitleRowWrapper>
                                <FormRowTitle>
                                    다음 진료에도 위드메이트를 사용하실건가요?
                                    <CheckboxGroup>
                                        <Checkbox
                                            value={true}
                                            checked={useAgain === true}
                                            onClick={() => {
                                                this.setState({ useAgain: !useAgain ? true : null });
                                            }}
                                        >
                                            예
                                        </Checkbox>
                                        <Checkbox
                                            value={false}
                                            checked={useAgain === false}
                                            onClick={() => {
                                                this.setState({ useAgain: useAgain === false ? null : false });
                                            }}
                                        >
                                            아니오
                                        </Checkbox>
                                    </CheckboxGroup>
                                </FormRowTitle>
                            </FormTitleRowWrapper>

                            <FormTitleRowWrapper>
                                <FormRowTitle>
                                    금일 방문하신 의료 기관의 서비스에 만족하시나요?
                                    <CheckboxGroup>
                                        <Checkbox
                                            value={true}
                                            checked={corpSatisfied === true}
                                            onClick={() => {
                                                this.setState({ corpSatisfied: !corpSatisfied ? true : null });
                                            }}
                                        >
                                            예
                                        </Checkbox>
                                        <Checkbox
                                            value={false}
                                            checked={corpSatisfied === false}
                                            onClick={() => {
                                                this.setState({ corpSatisfied: corpSatisfied === false ? null : false });
                                            }}
                                        >
                                            아니오
                                        </Checkbox>
                                    </CheckboxGroup>
                                </FormRowTitle>
                            </FormTitleRowWrapper>

                            <FormTitleRowWrapper>
                                <FormRowTitle>이유는 무엇인가요?</FormRowTitle>
                                <DivisionLine x_small />
                                <WithmateInput
                                    value={corpSatisfiedReason}
                                    maxLength={30}
                                    onChange={ev => {
                                        this.inputHandler({ type: 'corpSatisfiedReason', value: ev.target.value });
                                    }}
                                    placeholder='어떤 부분에서 의료기관이 만족스러우셨는지 입력해주세요.'
                                />
                            </FormTitleRowWrapper>

                            <FormTitleRowWrapper>
                                <FormRowTitle>
                                    금일 방문하신 의료 기관의 의료진은 누구이고, 서비스에 만족하시나요?
                                    <CheckboxGroup>
                                        <Checkbox
                                            value={true}
                                            checked={doctorSatisfied === true}
                                            onClick={() => {
                                                this.setState({ doctorSatisfied: !doctorSatisfied ? true : null });
                                            }}
                                        >
                                            예
                                        </Checkbox>
                                        <Checkbox
                                            value={false}
                                            checked={doctorSatisfied === false}
                                            onClick={() => {
                                                this.setState({ doctorSatisfied: doctorSatisfied === false ? null : false });
                                            }}
                                        >
                                            아니오
                                        </Checkbox>
                                    </CheckboxGroup>
                                </FormRowTitle>
                                <DivisionLine x_small />
                                <WithmateInput
                                    value={doctorSatisfiedReason}
                                    maxLength={30}
                                    onChange={ev => {
                                        this.inputHandler({ type: 'doctorSatisfiedReason', value: ev.target.value });
                                    }}
                                    placeholder='의료진의 이름과 만족하는 이유를 입력해주세요.'
                                />
                            </FormTitleRowWrapper>

                            <FormTitleRowWrapper>
                                <FormRowTitle>병원 동행 서비스의 개선점이 있다면 무엇이라고 생각하시나요?</FormRowTitle>
                                <DivisionLine x_small />
                                <WithmateTextarea
                                    value={hope || undefined}
                                    placeholder={`고객님의 소중한 후기는 위드메이트 서비스개선에 많은 도움이 됩니다.
감사합니다.`}
                                    onChange={ev => {
                                        this.inputHandler({ type: 'hope', value: ev.target.value });
                                    }}
                                />
                            </FormTitleRowWrapper>

                            <DivisionLine big />

                            <FormButtonWrapper>
                                <FormButton
                                    className='white'
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                >
                                    뒤로가기
                                </FormButton>
                                <FormButton className='blue' onClick={this.onAddReview}>
                                    등록하기
                                </FormButton>
                            </FormButtonWrapper>
                        </WritePaper>
                    </div>
                </FullContainer>
            </div>
        );
    }
}
export const PaddingDiv = styled.div``;

export const FullContainer = styled.div`
    width: 100%;
    background-color: #f6f6f6;
    min-height: 70vh;

    @media (max-width: 480px) {
        > .inner-container {
            padding: 16px 16px;
        }
    }
`;
