import React from 'react';
import { HashLink } from 'react-router-hash-link';
import swal from 'sweetalert2';

// components
import { GuideNavContainer, InnerContainer, GuideNavWrapper, NavTab, MobileBr } from '../guide.styled';

export const GuideNav = ({ activeTab, onTab, onReserve }) => {
    return (
        <GuideNavContainer>
            <InnerContainer>
                <GuideNavWrapper>
                    <NavTab active={activeTab === 'withmate'}>
                        <HashLink
                            to='/nemt/service/#withmate'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('withmate');
                            }}
                        >
                            위드메이트란?
                        </HashLink>
                    </NavTab>
                    {/* <NavTab active={activeTab === 'concept'}>
                        <HashLink
                            to='/nemt/service/#concept'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('concept');
                            }}
                        >
                            병원 동행이란?
                        </HashLink>
                    </NavTab> */}
                    <NavTab active={activeTab === 'types'}>
                        <HashLink
                            to='/nemt/service/#types'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('types');
                            }}
                        >
                            서비스 종류
                        </HashLink>
                    </NavTab>
                    <NavTab active={activeTab === 'merit'}>
                        <HashLink
                            to='/nemt/service/#merit'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('merit');
                            }}
                        >
                            믿을수 있는 위드메이트
                        </HashLink>
                    </NavTab>
                    <NavTab active={activeTab === 'usage'}>
                        <HashLink
                            to='/nemt/service/#usage'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('usage');
                            }}
                        >
                            이런분께 추천해요
                        </HashLink>
                    </NavTab>
                    <NavTab active={activeTab === 'partners'}>
                        <HashLink
                            to='/nemt/service/#partners'
                            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
                            onClick={() => {
                                onTab('partners');
                            }}
                        >
                            위드메이트 파트너
                        </HashLink>
                    </NavTab>
                </GuideNavWrapper>
            </InnerContainer>
        </GuideNavContainer>
    );
};
