import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Buy from './ProductPages/Buy';

export default class ProductRenderPages extends React.PureComponent {
    render() {
        const { url } = this.props.match;
        return (
            <Switch>
                <Route path={`${url}/buy/ticket`} component={Buy} />
            </Switch>
        );
    }
}
