import axios from '../axios';

export const addNewsEvent = async ({ id, title, content, photo, url, url2, urlSource, urlSource2 }) => {
    const config = {
        id,
        title,
        content,
        photo,
        url,
        url2,
        urlSource,
        urlSource2,
    };

    const result = await imageUpload(photo);
    config.photo = result.data;

    return axios.post(`/v1/newsEvent/add`, config);
};

export const fetchNewsEvent = () => {
    return axios.get('/v1/newsEvent/list');
};

export const getNewsEventInfo = async ({ newsEventId, info }) => {
    return axios.get('/v1/newsEvent/list/info', { newsEventId, info });
};

export const imageUpload = photo => {
    const formData = new FormData();
    formData.append('photo', photo);
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post('/v1/newsEvent/image/upload', formData, header);
};
