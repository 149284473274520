import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import styled from 'styled-components';
import { WithmateLabel } from '../../../../Components/Common/input';

export const EduVideo1 = () => {
  return (
    <VideoSectionContainer>
      <VideoSectionInnerContainer>
        <div style={{ paddingBottom: '30px' }}>
          <Title style={{ fontWeight: 'bold' }}>메이트 온라인 교육영상</Title><br />
          <p style={{ color: '#3bc1ff'}}>* 해당 영상 화면을 클릭하시면 재생/일시정지가 가능합니다.</p><br />
          <p style={{ paddingBottom: '10px' }}>온라인 교육영상을 모두 시청하신 후 <MobileBr />아래 주소의 구글폼에 접속하셔서<br/>
             교육영상 문제를 풀고 제출하시면 <MobileBr />확인 후 메이트 지원 합격안내를 해드립니다.
          </p>
            <Button 
              onClick={() => {
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSfimp7QrRY0OkrMQQiZLQ3xFodaD7KosDL7PRsIcypencUaXg/viewform?usp=share_link')
              }}
              style={{ width: '250px', height: '40px', fontSize: '20px', textAlign: 'center' }}
          >
            메이트 교육영상 문제풀기
          </Button>
        </div>
        <WithmateLabel style={{ fontSize: '15px' }}>병원동행 매니저 교육 1: 병원 동행 서비스의 정의</WithmateLabel>
        <VideoArea>
          <iframe
            width="100%"
            height="100%"
            src="https://youtube.com/embed/3f-CHGx75LY?modestbranding=1?cc_load_policy=1&controls=0&rel=0&disablekb=1"
            title="withmate YouTube video player2"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loop={true}
            controls={false}
          ></iframe>
        </VideoArea>
      </VideoSectionInnerContainer>
    </VideoSectionContainer>
  );
};

const VideoSectionContainer = styled.div`
  padding-top: 105px;
  padding-bottom: 30px;

  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
    padding-top: 100px;
    // padding-top: 10px;
    // padding-bottom: 0px;
    padding-bottom: 10px;
  }
`;

const VideoSectionInnerContainer = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
`;

const VideoSectionTitle = styled.h4`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  margin-top: 0px;
  margin-bottom: 105px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 68px;
  }
`;

const VideoArea = styled.div`
  width: 100%;
  height: calc(1120px / 16 * 9);
  background-color: #000000;

  > iframe,
  video {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1120px) {
    height: calc(100vw / 16 * 9);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100vw / 16 * 9);
    background-color: #000000;

    display: flex;
    align-items: center;
    justify-content: center;

    > iframe,
    video {
      width: 100%;
      height: 100%;
    }
  }
`;

const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;