import React from 'react';

class PrivacyComponent extends React.PureComponent {
    render() {
        return (
            <div className='privacy-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>㈜ 블루카멜 개인정보처리방침</h2>
                        <p>
                            병원동행서비스 위드메이트(www.withmate.kr, 이하 “서비스")를 운영하는 (주)블루카멜(이하 “회사”)은 「개인정보보호법」 제30조에 따라
                            정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보처리방침(이하 "방침")을
                            수립 및 공개합니다. 개인정보처리방침은 관련 법령 또는 지침, 회사의 약관 또는 정책에 따라 변경될 수 있습니다. 회사는 해당 변경 사항을
                            온라인 게시 등의 방법으로 이용자에게 알립니다.
                            <br />
                            <br />이 개인정보처리방침은 2023년 7월 25일부터 적용됩니다.
                        </p>
                        <br />
                        <br />
                        <br />

                        <p style={{ fontSize: '20px' }}>
                            <a
                                href='https://docs.google.com/document/d/e/2PACX-1vTQ3CepPqUO6BqEg8va9_5e0zZg7V1nMuK7xiibF1Nxa2RQAX1C5xv3p4mnIVmkeeh_ClORu1P3Fs0p/pub'
                                target='_blank'
                                style={{ color: 'gray' }}
                            >
                                ✅ 개인정보처리방침 2차(2023.07.22)
                            </a>
                        </p>
                        <p style={{ fontSize: '20px' }}>
                            <a
                                href='https://docs.google.com/document/d/e/2PACX-1vQTjHkJBb2VK4gofoPrHZVYbgCOvOgDDvClVJjwYpx7ikkLFW37GUlFS5Hwa8osGb7a7mNbg55qlEMu/pub'
                                target='_blank'
                                style={{ color: 'gray' }}
                            >
                                ✅ 개인정보처리방침 1차(2021.12.20)
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
export default PrivacyComponent;
