import React, { useState } from "react";
import styled from 'styled-components';
import { message, Button } from 'antd';
import { DivisionLine } from '../../Components/Common/input';
import { Checkbox } from 'antd';

const DepartmentComponent = () => {
    const department_data = [
        { id: 1, name: "가정의학과" },
        { id: 2, name: "내과" },
        { id: 3, name: "마취통증의학과" },
        { id: 4, name: "방사선종양학과" },
        { id: 5, name: "병리과" },
        { id: 6, name: "산부인과" },
        { id: 7, name: "성형외과" },
        { id: 8, name: "신경과" },
        { id: 9, name: "신경외과" },
        { id: 9, name: "안과" },
        { id: 9, name: "영상의학과" },
        { id: 9, name: "외과" },
        { id: 9, name: "응급의학과" },
        { id: 9, name: "이비인후과" },
        { id: 9, name: "임상약리학과" },
        { id: 9, name: "재할의학과" },
        { id: 9, name: "정신건강의학과" },
        { id: 9, name: "정형외과" },
        { id: 9, name: "중환자의학과" },
        { id: 9, name: "진단검사의학과" },
        { id: 9, name: "피부과" },
        { id: 9, name: "핵의학과" },
        { id: 9, name: "흉부외과" },
    ];

    const [isChecked, setIsChecked] = useState(false); //체크 여부
    const [checkedItems, setCheckedItems] = useState(new Set());//체크된 요소들

    const checkHandler = ({ target }) => {
        setIsChecked(!isChecked);
        checkedItemHandler(target.parentNode, target.value, target.checked);
    };

    const checkedItemHandler = (box, id, isChecked) => {
        if (isChecked) { //체크 되었을때
            checkedItems.add(id); //체크시 삽입
            setCheckedItems(checkedItems); //체크 요소 넣어주기

        } else if (!isChecked && checkedItems.has(id)) { //체크가 안되었고, id가 있을때(클릭 2번시)
            checkedItems.delete(id); //체크 두번시 삭제
            setCheckedItems(checkedItems);
        }

        console.log(checkedItems);
        return checkedItems;
    };

    return (
        <div className='department-container'>
            <div className='inner-container' style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='department-form' style={{ border: '1px solid lightgray', borderRadius: '7px', width: '900px', marginTop: '40px', paddingTop: '80px', backgroundColor: 'white' }}>
                    <div className='department-title'>
                        <h1 style={{ textAlign: 'center' }}>진료(치료) 받는 과 선택</h1>
                    </div>
                    <DivisionLine /><DivisionLine />
                    <div className="contStyle" style={{ padding: '20px' }}>
                        {department_data.map((item) => (
                            <label key={item.id} className="innerBox" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                    <Checkbox
                                        value={item.name}
                                        onChange={(e) => checkHandler(e)}
                                        style={{ fontSize: '20px' }}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </div>
                            </label>
                        ))}
                    </div>
                    <DivisionLine />
                    <hr style={{ textAlgn: 'center', width: '840px', color: 'gray' }} />

                    <div className='form' style={{ textAlign: 'center' }}>
                        <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px', backgroundColor: 'gray' }}
                            onClick={() => {
                                this.props.history.push('/user/mypage/profile');
                            }}
                        >
                            등록
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px' }}
                            onClick={() => {
                                this.props.history.push('/');
                            }}
                        >
                            취소
                        </Button>
                    </div>
                    <DivisionLine />
                </div>
            </div>
        </div>
    );
};

export default DepartmentComponent;

const Departments = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    line-height: 44px;

    > span {
        display: inline-block;
        cursor: pointer;

        flex: 1;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #e9e9e9;

        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        letter-spacing: -0.2px;

        margin-right: 8px;
    }

    > span.active {
        background-color: #3bc1ff;
        color: white;
    }

    > span:last-child {
        margin-right: 0px;
    }
`;