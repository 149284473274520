import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../theme';

const StyledFaqContainer = styled.div`
    padding-top: 55px;

    .faq-header {
        border-bottom: 1px solid #e6e6e6;

        .inner-container {
            padding: 0px;
            padding-top: 82px;

            @media (max-width: 768px) {
                padding-top: 42px;
            }

            > .flex-wrapper {
                position: relative;

                .faq-main-title {
                    flex: 1;
                    .title {
                        font-size: ${sizeHandler(44)};
                        font-weight: bold;
                        letter-spacing: -0.6px;
                        color: #000000;
                        margin-bottom: 8px;
                    }

                    .description {
                        font-size: ${sizeHandler(14)};
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.8);
                        margin-bottom: 82px;
                    }
                }

                .img-section {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;

                    > img {
                        width: 100%;
                        max-width: 549px;
                        height: auto;
                        object-fit: cover;
                    }

                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }

            .faq-nav {
                display: flex;
                align-items: center;

                .nav-tab {
                    margin-right: 32px;
                    padding-bottom: 12px;
                    position: relative;

                    a {
                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        text-align: center;
                        color: #3bc1ff;
                    }

                    span.underline {
                        background-color: #3bc1ff;
                        position: absolute;
                        bottom: 0px;
                        left: 0px;

                        &.active {
                            width: 100%;
                            height: 3px;
                            display: inline-block;
                        }
                    }

                    @media (max-width: 768px) {
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    .faq-contents {
        .inner-container {
            padding-top: 0px;

            @media (max-width: 768px) {
                h1 {
                    font-size: 30px;
                    margin-top: 60px;
                }
            }
        }
    }

    .panel-header {
        display: flex;
        align-items: center;

        .row-index {
            width: 80px;
            color: ${mainColor};
            font-size: ${sizeHandler(14)};
            font-weight: bold;
        }

        .title {
            flex: 1;
            font-size: ${sizeHandler(16)};
            font-weight: normal;
            color: rgba(0, 0, 0, 0.7);
        }

        @media (max-width: 768px) {
            .title {
                font-size: ${sizeHandler(14)};
            }
        }
    }

    .panel-content {
        padding-left: 80px;

        > p {
            width: 60%;
            font-size: ${sizeHandler(14)};
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.7);
        }

        > .panel-contents-wrapper {
            margin-top: 20px;
            margin-bottom: 20px;

            .panel-contents {
                padding: 12px 0px;
                border-bottom: 1px solid #e6e6e6;

                &:first-child {
                    border-top: 1px solid #e6e6e6;
                }

                .panel-content-subtitle {
                    float: left;
                    width: 40%;
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    text-align: left;
                    color: ${mainColor};
                }

                .panel-content-subcontents {
                    float: left;
                    width: 50%;
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.7);
                }

                @media (max-width: 768px) {
                    .panel-content-subtitle {
                        width: 100%;
                    }

                    .panel-content-subcontents {
                        width: 100%;
                    }
                }
            }
        }
    }

    h1 {
        font-size: ${sizeHandler(34)};
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: -1px;

        margin-bottom: 26px;
        margin-top: 50px;
    }

    @media (max-width: 1120px) {
        .faq-header {
            .inner-container {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
        .inner-container {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media (max-width: 768px) {
        .panel-header {
            .row-index {
                width: 70px;
            }
        }

        .panel-content {
            padding-left: 70px;

            > p {
                width: 90%;
            }
        }
    }
`;
export default StyledFaqContainer;
