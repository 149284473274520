import React from 'react';
import styled from 'styled-components';

import {
    WithmateContainer,
    InnerContainer,
    WithmateTitle,
    MeritCardDesc,
    FlexDivWrapper,
    BigSideBox,
    SmallSideBox,
    WithmateBg,
    GuideConceptTitle,
    MobileBr,
    DesktopBr,
} from '../guide.styled';
export const Withmate = () => {
    return (
        <WithmateContainer id='withmate'>
            <InnerContainer>
                <FlexDivWrapper top>
                    <SmallSideBox>
                        <WithmateTitle>
                            <img src='/assets/images/new_logo_img.png' />
                            <span>위드메이트란?</span>
                        </WithmateTitle>
                        <Block bigMargin>
                            위드메이트는 <span>온라인 병원 동행 서비스 매칭 플랫폼</span>을<MobileBr /> 시작으로,
                            <DesktopBr /> 대한민국에서{' '}
                            <span>
                                사회적 의미의 ‘시니어’라는 <MobileBr />
                                단어를 없애기 위해
                            </span>{' '}
                            설립된 <DesktopBr />
                            <span>
                                시니어 라이프 스타일 <MobileBr />
                                혁신 기업
                            </span>
                            입니다.
                        </Block>
                        <Title>위드메이트의 시작</Title>
                        <Block>
                            <span>2015년</span>에 설립된 위드메이트는 <span>초고령화 </span>및 <MobileBr />
                            <span>1인 가구 시대</span>에 <DesktopBr />
                            접어든 대한민국의 현 상황에 적합한
                            <MobileBr />
                            <span> 시니어 라이프 스타일 혁신</span>
                            <DesktopBr />
                            <span>서비스를 개발합니다.</span>
                        </Block>
                        <Block noMargin>
                            이를 위해 위드메이트는 <MobileBr />
                            <span>국내 최초로 병원 동행 서비스를 런칭해</span>
                            <MobileBr />
                            <DesktopBr />
                            <span>탄탄한 신뢰</span>와 <span>안정된 서비스</span> 시스템을 기반으로 <MobileBr />
                            축적되는 <DesktopBr />
                            데이터와 실전 경험을 바탕으로{' '}
                            <span>
                                다양한 <MobileBr />
                                신규 서비스를 연구
                            </span>
                            하여 <DesktopBr />
                            <span>
                                시니어 라이프 스타일 <MobileBr />
                                혁신 기업
                            </span>
                            의 길을 걷고 있습니다.
                        </Block>
                    </SmallSideBox>
                    <BigSideBox mHidden>
                        <WithmateBg bg='/assets/images/guide_withmate_main1.png' />
                    </BigSideBox>
                </FlexDivWrapper>
                {/* <FlexDivWrapper>
                    <SmallSideBox mHidden>
                        <WithmateBg bg='/assets/images/guide_withmate_main2.png' />
                    </SmallSideBox>
                    <BigSideBox>
                        <Title>등장 배경</Title>
                        <Block>
                            2017년, 국내 <span>65세 이상 인구 비율이 14%를 넘으며</span> <MobileBr />
                            대한민국은 공식적으로 <DesktopBr />
                            <span>고령 사회</span> 에 접어들었습니다. <MobileBr />
                            다가올 2025년, 65세 이상 인구 비율은 20%를
                            <MobileBr />
                            <DesktopBr />
                            넘길 것으로 전망되며 이후{' '}
                            <span>
                                대한민국은 초고령 사회로 <MobileBr />
                                접어들게 될 것
                            </span>
                            입니다.
                        </Block>

                        <Block>
                            이렇게 시니어가 늘어가는 순간에도, <MobileBr />
                            <span>현대 사회는 날로 분주해지기만 합니다.</span>
                            <DesktopBr />
                            젊은 세대는 <MobileBr />
                            매일 직장과 육아라는 전투를 치르며 살아남기 위한 <MobileBr />
                            발버둥을 치기에도 <DesktopBr />
                            버겁습니다. 이렇게 자기 자신을 <MobileBr />
                            돌보기 위한 시간을 내는 것도 힘겨운 상황 속에서,
                            <MobileBr />
                            <DesktopBr />
                            <span>
                                떨어져 있는 고령 가족에 대한 걱정은 어쩌면 <MobileBr />
                                사치처럼 보이기도 합니다.{' '}
                            </span>
                        </Block>

                        <Block noMargin>
                            물리적인 거리와 시간적인 제약, 그리고 직장과 육아가 <MobileBr />
                            주는 압박감을 떨쳐내고 <DesktopBr />
                            <span>
                                고령의 가족을 위해 선뜻 <MobileBr />
                                움직일 수 있을까요?{' '}
                            </span>
                            시니어는 날로 발전하는 기술의 <DesktopBr />
                            <MobileBr />
                            파도 속에서 자신의 생존을 위해 필요한 서비스를 <MobileBr />
                            누군가의 도움 없이 적재적소에 선택해 <DesktopBr />
                            <span>
                                자유롭게 <MobileBr />
                                사용할 수 있을까요?
                            </span>{' '}
                            그렇다면 우리는 언제까지 이런 <MobileBr />
                            막막한 상황을 견디기만 <DesktopBr />
                            해야 할까요? 위드메이트는 <MobileBr />
                            이렇게 시니어가 겪게 되는 다양한{' '}
                            <span>
                                일상 생활의 <MobileBr />
                                불편함을{' '}
                            </span>
                            <DesktopBr />
                            <span>병원 동행 서비스를 시작으로 해소합니다.</span>
                        </Block>
                    </BigSideBox>
                </FlexDivWrapper> */}
            </InnerContainer>
        </WithmateContainer>
    );
};

const Title = styled(GuideConceptTitle)`
    margin-bottom: 24px;
    text-align: left;
`;

const Block = styled(MeritCardDesc)`
    font-size: 16px;
    margin-bottom: 24px;
    ${props => props.noMargin && `margin-bottom: 0px;`}
    ${props => props.bigMargin && `margin-bottom: 74px;`}

    @media (max-width: 768px) {
        word-break: keep-all;
    }
`;
