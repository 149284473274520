import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as appAPI from '../../Lib/Api/app';
import _ from 'lodash';

// middleware
import { createReduxThunk } from '../../Lib/middleware';

const USER_INFO = 'app/USER_INFO';
const USER_INFO_SUCCESS = 'app/USER_INFO_SUCCESS';

const MODIFY_USER_INFO = 'app/MODIFY_USER_INFO';
const MODIFY_USER_INFO_SUCCESS = 'app/MODIFY_USER_INFO_SUCCESS';

const SERVICE_INFO = 'app/SERVICE_INFO';
const SERVICE_INFO_SUCCESS = 'app/SERVICE_INFO_SUCCESS';

const SERVICE_RESERVE = 'app/SERVICE_RESERVE';
const SERVICE_RESERVE_SUCCESS = 'app/SERVICE_RESERVE_SUCCESS';
const SERVICE_RESERVE_USAGE = 'app/SERVICE_RESERVE_USAGE';
const SERVICE_RESERVE_USAGE_SUCCESS = 'app/SERVICE_RESERVE_USAGE_SUCCESS';
const SERVICE_RESERVE_LIST = 'app/SERVICE_RESERVE_LIST';
const SERVICE_RESERVE_LIST_SUCCESS = 'app/SERVICE_RESERVE_LIST_SUCCESS';

const RESERVE_INFO = 'app/RESERVE_INFO';
const RESERVE_INFO_SUCCESS = 'app/RESERVE_INFO_SUCCESS';

const CANCEL_CALL = 'app/CANCEL_CALL';
const CANCEL_CALL_SUCCESS = 'app/CANCEL_CALL_SUCCESS';

const CANCEL_RESERVE = 'app/CANCEL_RESERVE';
const CANCEL_RESERVE_SUCCESS = 'app/CANCEL_RESERVE_SUCCESS';

const MODIFY_RESERVE = 'app/MODIFY_RESERVE';
const MODIFY_RESERVE_SUCCESS = 'app/MODIFY_RESERVE_SUCCESS';

const MATE_CALL_RESERVE_LIST = 'app/MATE_CALL_RESERVE_LIST';
const MATE_CALL_RESERVE_LIST_SUCCESS = 'app/MATE_CALL_RESERVE_LIST_SUCCESS';
const MATE_SERVICE_RESERVE_LIST = 'app/MATE_SERVICE_RESERVE_LIST';
const MATE_SERVICE_RESERVE_LIST_SUCCESS = 'app/MATE_SERVICE_RESERVE_LIST_SUCCESS';
const MATE_ACCEPT_CALL = 'app/MATE_ACCEPT_CALL';
const MATE_ACCEPT_CALL_SUCCESS = 'app/MATE_ACCEPT_CALL_SUCCESS';
const MATE_COMPLETE_CALL = 'app/MATE_COMPLETE_CALL';
const MATE_COMPLETE_CALL_SUCCESS = 'app/MATE_COMPLETE_CALL_SUCCESS';

const PAY_HISTORY = 'app/PAY_HISTORY';
const PAY_HISTORY_SUCCESS = 'app/PAY_HISTORY_SUCCESS';

const BUY_TICKET = 'app/BUY_TICKET';
const BUY_TICKET_SUCCESS = 'app/BUY_TICKET_SUCCESS';

const GET_CONTRACT_PARTNERS = 'app/GET_CONTRACT_PARTNERS';
const GET_CONTRACT_PARTNERS_SUCCESS = 'app/GET_CONTRACT_PARTNERS_SUCCESS';

const GET_CONTRACT_SERVICE_LIST = 'app/GET_CONTRACT_SERVICE_LIST';
const GET_CONTRACT_SERVICE_LIST_SUCCESS = 'app/GET_CONTRACT_SERVICE_LIST_SUCCESS';

const EDIT_USER_FORM = 'app/EDIT_USER_FORM';
const EDIT_BUY_FORM = 'app/EDIT_BUY_FORM';
const EDIT_RESERVE_FORM = 'app/EDIT_RESERVE_FORM';
const EDIT_DETAIL_VIEW = 'app/EDIT_DETAIL_VIEW';
const EDIT_LATEST_VIEW = 'app/EDIT_LATEST_VIEW';
const EDIT_VIEW_TYPE = 'app/EDIT_VIEW_TYPE';
const EDIT_CALL_PAGE = 'app/EDIT_CALL_PAGE';
const EDIT_CONTRACT_FORM = 'app/EDIT_CONTRACT_FORM';

const LOADING_ON = 'app/LOADING_ON';
const LOADING_OFF = 'app/LOADING_OFF';

// initalState
const initialState = {
    userInfo: {},
    serviceInfo: {},
    reserveForm: {
        page: 1,
        service: 'assist',
        addTime: 1,
        payMethod: 'transfer',
        sex: '남',
        transport: 'common', // common, own
        report: false,
    },
    reserve: {
        unemergency: 0,
        assist: 0,
    },
    contractForm: {
        page: 1,
        service: '',
        addTime: 1,
        payMethod: 'transfer',
        sex: '남',
        transport: 'common', // common, own
        report: false,
    },
    buyForm: {
        service: 'unemergency',
        count: 1,
    },
    mateCallPage: 0,
    mateCallList: [],
    reserveList: [],
    reserveInfo: {},
    mateReserveList: [],
    mateViewType: 'latest',
    mateReserveLatest: {},
    mateReserveDetail: {},
    payHistory: [],
    partners: [],
    loading: false,
    contractServices: [],
};

export const getUserInfo = createReduxThunk(USER_INFO, appAPI.getUserInfo);
export const modifyUserInfo = createReduxThunk(MODIFY_USER_INFO, appAPI.modifyUserInfo);

export const getServiceInfo = createReduxThunk(SERVICE_INFO, appAPI.serviceInfo);

export const buyTicket = createReduxThunk(BUY_TICKET, appAPI.buyTicket);
export const getPayHistory = createReduxThunk(PAY_HISTORY, appAPI.payHistory);

export const serviceReserve = createReduxThunk(SERVICE_RESERVE, appAPI.serviceReserve);
export const getReserveUsage = createReduxThunk(SERVICE_RESERVE_USAGE, appAPI.reserveList);
export const getReserveList = createReduxThunk(SERVICE_RESERVE_LIST, appAPI.getReserveList);
export const getMateReserveList = createReduxThunk(MATE_SERVICE_RESERVE_LIST, appAPI.mateReserveList);
export const getMateCallReserveList = createReduxThunk(MATE_CALL_RESERVE_LIST, appAPI.mateCallReserveList);
export const getReserveInfo = createReduxThunk(RESERVE_INFO, appAPI.getReserve);
export const getContractPartners = createReduxThunk(GET_CONTRACT_PARTNERS, appAPI.getPartnerServices);
export const getContractList = createReduxThunk(GET_CONTRACT_SERVICE_LIST, appAPI.getContractServices);

export const acceptCall = createReduxThunk(MATE_ACCEPT_CALL, appAPI.acceptCall);
export const cancelCall = createReduxThunk(CANCEL_CALL, appAPI.cancelCall);
export const completeCall = createReduxThunk(MATE_COMPLETE_CALL, appAPI.completeCall);
export const modifyReserve = createReduxThunk(MODIFY_RESERVE, appAPI.modifyReserve);
export const cancelReserve = createReduxThunk(CANCEL_RESERVE, appAPI.cancelReserve);

export const editViewType = createAction(EDIT_VIEW_TYPE, input => input);
export const editResrveForm = createAction(EDIT_RESERVE_FORM, input => input);
export const editContractForm = createAction(EDIT_CONTRACT_FORM, input => input);
export const editBuyForm = createAction(EDIT_BUY_FORM, input => input);
export const editUserForm = createAction(EDIT_USER_FORM, input => input);
export const editReserveDetailView = createAction(EDIT_DETAIL_VIEW, input => input);
export const editReserveLatestView = createAction(EDIT_LATEST_VIEW, input => input);
export const editCallPage = createAction(EDIT_CALL_PAGE, input => input);

// reducer
const appReducer = handleActions(
    {
        [LOADING_ON]: (state, action) =>
            produce(state, draft => {
                draft.loading = true;
            }),
        [LOADING_OFF]: (state, action) =>
            produce(state, draft => {
                draft.loading = false;
            }),
        [USER_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload._id) {
                    draft['userInfo'] = payload;
                }
            }),
        [MODIFY_USER_INFO_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const { photo, name, contact } = meta.info;
                    draft['userInfo']['name'] = name;
                    draft['userInfo']['contact'] = contact;
                    if (photo) {
                        draft['userInfo']['photo'] = photo;
                    }
                }
            }),
        [SERVICE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && Array.isArray(payload) && payload.length > 0) {
                    draft['serviceInfo'] = payload[0];
                }
            }),
        [SERVICE_RESERVE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    draft['reserveForm'] = {
                        page: 1,
                        service: 'unemergency',
                        addTime: 1,
                        payMethod: 'transfer',
                        sex: '남',
                    };
                }
            }),
        [RESERVE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserveInfo'] = payload;
            }),
        [SERVICE_RESERVE_USAGE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['reserve'] = payload;
                }
            }),
        [SERVICE_RESERVE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload) && Array.isArray(payload)) {
                    draft['reserveList'] = payload;
                }
            }),
        [GET_CONTRACT_PARTNERS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['partners'] = payload;
                if (payload && Array.isArray(payload)) {
                    localStorage.setItem('partners', JSON.stringify(payload));
                }
            }),
        [GET_CONTRACT_SERVICE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload) && Array.isArray(payload)) {
                    draft['contractServices'] = payload;
                }
            }),
        [MATE_SERVICE_RESERVE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload) && Array.isArray(payload)) {
                    draft['mateReserveList'] = payload;
                }
            }),
        [MATE_CALL_RESERVE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload) && Array.isArray(payload)) {
                    draft['mateCallList'] = payload;
                }
            }),
        [MATE_ACCEPT_CALL_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['mateReserveList'];
                    draft['mateReserveList'] = list.map(l => {
                        if (l._id === meta.callId) {
                            l.status = '예약완료';
                        }

                        return l;
                    });

                    draft['mateReserveDetail']['status'] = '예약완료';
                }
            }),
        [MATE_COMPLETE_CALL_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    if (payload && payload === 'success') {
                        const list = draft['mateReserveList'];
                        draft['mateReserveList'] = list.map(l => {
                            if (l._id === meta.callId) {
                                l.status = '서비스 종료';
                            }

                            return l;
                        });
                    }
                }
            }),
        [PAY_HISTORY_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['payHistory'] = payload;
            }),
        [BUY_TICKET_SUCCESS]: (state, { payload }) => produce(state, draft => {}),
        [CANCEL_CALL_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['mateReserveList'];
                    draft['mateReserveList'] = list.map(l => {
                        if (l._id === meta.callId) {
                            l.status = '접수대기';
                        }

                        return l;
                    });
                }
            }),
        [CANCEL_RESERVE_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                const list = draft['reserveList'];
                draft['reserveList'] = list.map(l => {
                    if (l.callId === meta.callId) l.call[0].status = '접수취소';
                    return l;
                });
            }),
        [MODIFY_RESERVE_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['reserveList'];
                    draft['reserveList'] = list.map(l => {
                        if (l._id === meta.reserveId) {
                            const { patient, relate, contact, comment, sex } = meta.info;
                            l['patient'] = patient;
                            l['relate'] = relate;
                            l['contact'] = contact;
                            l['comment'] = comment;
                            l['sex'] = sex;

                            if (meta.info && meta.info.starting) {
                                const { starting, destination, reserveDate, usageTime } = meta.info;
                                l['starting'] = starting;
                                l['destination'] = destination;
                                l['reserveDate'] = reserveDate;
                                l['usageTime'] = usageTime;
                            }

                            return l;
                        }

                        return l;
                    });
                }
            }),
        [EDIT_RESERVE_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['reserveForm'] = {};
                } else {
                    draft['reserveForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_CONTRACT_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['contractForm'] = {};
                } else {
                    draft['contractForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_BUY_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['buyForm'] = {
                        service: 'unemergency',
                        count: 1,
                    };
                } else {
                    draft['buyForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_USER_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['userInfo'] = {};
                } else {
                    draft['userInfo'][payload.type] = payload.value;
                }
            }),
        [EDIT_DETAIL_VIEW]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['mateReserveDetail'] = {};
                } else {
                    draft['mateReserveDetail'] = payload.value;
                }
            }),
        [EDIT_LATEST_VIEW]: (state, { payload }) =>
            produce(state, draft => {
                draft['mateReserveLatest'] = payload.value;
            }),
        [EDIT_VIEW_TYPE]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['mateViewType'] = 'latest';
                } else {
                    draft['mateViewType'] = payload.value;
                }
            }),
        [EDIT_CALL_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                if (typeof payload === 'number') {
                    draft['mateCallPage'] = payload;
                }
            }),
    },
    initialState,
);

export default appReducer;
