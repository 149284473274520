import React from 'react';

class PrivacyComponent extends React.PureComponent {
    render() {
        return (
            <div className='privacy-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>㈜ 블루카멜 개인정보처리방침</h2>
                        <p>
                            병원동행서비스 위드메이트(www.withmate.kr, 이하 “서비스")를 운영하는 (주)블루카멜(이하 “회사”)은 「개인정보보호법」 제30조에 따라
                            정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보처리방침(이하 "방침")을
                            수립 및 공개합니다. 개인정보처리방침은 관련 법령 또는 지침, 회사의 약관 또는 정책에 따라 변경될 수 있습니다. 회사는 해당 변경 사항을
                            온라인 게시 등의 방법으로 이용자에게 알립니다.
                            <br />
                            <br />이 개인정보처리방침은 2023년 7월 25일부터 적용됩니다.
                        </p>
                        <br />
                        <br />
                        <br />

                        <p style={{ fontSize: '20px' }}>
                            <a
                                href='https://docs.google.com/document/d/e/2PACX-1vTQ3CepPqUO6BqEg8va9_5e0zZg7V1nMuK7xiibF1Nxa2RQAX1C5xv3p4mnIVmkeeh_ClORu1P3Fs0p/pub'
                                target='_blank'
                                style={{ color: 'gray' }}
                            >
                                ✅ 개인정보처리방침 2차(2023.07.22)
                            </a>
                        </p>
                        <p style={{ fontSize: '20px' }}>
                            <a
                                href='https://docs.google.com/document/d/e/2PACX-1vQTjHkJBb2VK4gofoPrHZVYbgCOvOgDDvClVJjwYpx7ikkLFW37GUlFS5Hwa8osGb7a7mNbg55qlEMu/pub'
                                target='_blank'
                                style={{ color: 'gray' }}
                            >
                                ✅ 개인정보처리방침 1차(2021.12.20)
                            </a>
                        </p>
                    </div>
                    {/* <div className='context-block'>
                        <h3>제 1조 (수집하는 개인정보의 항목 및 수집 방법)</h3>
                        <h5>1. 수집하는 개인정보의 항목</h5>
                        <p>
                            회사는 회원가입(또는 서비스 가입), 원활한 고객 상담, 의료 시설 관계자와 메이트 지원자의 신분 확인, 그리고 각종 서비스의 제공을 위해
                            위드메이트 서비스 최초 이용 시 다음과 같은 개인정보를 수집하고 있습니다.
                            <br />
                            <br />
                            일반개인정보: 회원의 이름, 이메일 주소(아이디) 및 패스워드, 전화번호, 단말기 정보, 프로필 사진(메이트 지원자에 한함), 블랙박스로
                            인하여 수집되는 영상 (회사는 음성을 수집하지 않으나, 회사가 직접 관리하지 않는 일반 택시와 같은 여객운송 서비스 차량에 설치된
                            블랙박스 기종에 따라 음성이 녹음될 수 있습니다), 민원/사고 발생 관련 정보, 집이나 회사 등의 주소 정보, 기타 아래 서비스 이용
                            과정에서 수집되는 정보 및 회원이 서비스 이용을 위해 자발적으로 입력하는 정보 등.
                        </p>
                        <div className='info'>
                            <p className='title'>또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</p>
                            <p className='content'>
                                - IP Address, 방문 일시, 서비스 이용 기록, 쿠키, 접속 로그, 불량 이용 기록, 앱 설치정보, 네트워크 위치정보 등{' '}
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>나아가 서비스 이용과정에서 다음과 같은 정보들이 수집될 수 있습니다.</p>
                            <p className='content'>- 계좌 이체 시: 은행명, 계좌번호 등</p>
                            <p className='content'>- 신용카드 결제 시 : 카드사명, 카드번호 등</p>
                            <p className='content'>- 휴대전화 결제 시 : 전화 번호, 통신사, 결제 승인 번호 등</p>
                        </div>

                        <h5 style={{ marginTop: '80px' }}>2. 개인정보 수집방법</h5>
                        <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
                        <div className='info'>
                            <p className='content'>- 위드메이트 플랫폼(애플리케이션 & 웹사이트)의 실행 또는 사용 과정에서 수집</p>
                            <p className='content'>- 협력 회사로부터의 제공</p>
                            <p className='content'>- 생성 정보 수집 툴(쿠키를 포함)을 통한 수집</p>
                            <p className='content'>- 사용 중 회원의 자발적 제공을 통한 수집</p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 2조 (개인정보의 수집 및 이용 목적)</h3>
                        <p>
                            회사는 회원의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 회원의 개인정보를 활용하지 않습니다.
                            회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다.
                        </p>

                        <div className='info-block'>
                            <p className='title'>1. 위드메이트 서비스 제공 및 운영</p>
                            <p className='content'>
                                회원이 가입한 위드메이트 서비스 내 상호 식별, 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 이용 및 경비 결제,
                                콘텐츠 제공, 물품 배송 또는 청구서 등 발송, 금융거래 본인 인증 및 금융 서비스(추후 기능 추가 예정), 미수금 추심, 서비스 이용 중
                                사고 발생 시 사고처리, 회사의 약관 또는 이용 정책의 위반 행위 적발을 위한 기록 조회
                            </p>
                        </div>

                        <div className='info-block'>
                            <p className='title'>2. 회원관리</p>
                            <p className='content'>
                                서비스 제공, 회원 식별, 본인 확인, 개인 식별, 이용이 제한된 회원 - 위드메이트 이용약관에 따라 이용이 제한된 회원 - 의 부정 이용
                                방지와 비인가 사용 방지, 가입 의사 확인 및 가입 횟수 제한, 분쟁 조정을 위한 기록 보존, 불만 처리 등 민원 처리, 고지 사항 전달,
                                추후 법정 대리인 본인 확인, 최신 정보 전달, 서비스의 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계 확인 및 파악
                            </p>
                        </div>

                        <div className='info-block'>
                            <p className='title'>3. (선택적) 신규 서비스 개발 및 마케팅 · 광고에 활용</p>
                            <p className='content'>
                                신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 이벤트 참여 기회 제공 또는 광고성 정보 제공
                            </p>
                        </div>

                        <div className='info-block'>
                            <p className='title'>4. 사고 발생시 원인 규명 및 처리를 위한 수집 및 이용</p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 3조 (개인정보의 제한적 공개 및 제 3자 제공)</h3>
                        <h6>
                        회사는 회원의 개인정보를 “제2조 (개인정보의 수집 및 이용목적)”에서 고지한 범위 내에서 처리하며, 회원의 사전 동의가 있거나 법령에 규정된 
                        경우를 제외하고는 회원의 개인정보를 동의 범위를 초과하여 이용하거나 외부에 공개 또는 제3자에게 제공하지 않는 것을 원칙으로 합니다. 
                        다만, 다음의 경우 주의를 기울여 회원의 개인정보를 제한적으로 공개할 수 있습니다.
                        </h6>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 제휴관계 :</span>회사는 제 3자와 제휴 관계를 맺을 경우 반드시 사전에 제휴사명, 제휴사의 개인정보 이용목적, 
                                공유하는 개인정보의 항목, 제휴기간에 관하여 알릴 것이며 반드시 이용자의 동의에 의해서만 정보를 제공하거나 공유합니다. 제휴 관계에 있어서 
                                위 고지할 사항에 변화가 있을 때도 같은 절차에 의하여 이용자에게 알리고 동의를 구합니다.
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 취급위탁 :</span>원활한 업무 처리를 위해 이용자의 개인정보를 위탁 처리할 경우 반드시 사전에 위탁 처리 업체명과 
                                개인정보 취급 위탁을 하는 업무의 내용을 이용자에게 알리고 동의를 받아야 합니다.
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 매각, 인수합병 등 :</span>회사의 영업의 전부 또는 일부의 양도 합병 등으로 이용자의 개인정보를 제3자에게 이전하는 
                                경우에는 회사는 해당 이용자에게 개인정보를 이전하려는 사실, 개인정보를 이전받는 자의 명칭, 이용자가 개인정보의 이전을 원하지 아니하는 경우 
                                그 동의를 철회할 수 있는 방법과 절차에 관하여 사전에 전자우편ㆍ서면ㆍ전화 또는 이와 유사한 방법 중 어느 하나의 방법으로(회사가 과실 없이 
                                이용자의 연락처를 알 수 없어 이러한 방법에 따라 통지할 수 없는 경우에는 회사의 어플리케이션 또는 플랫폼 첫 화면에 30일 이상 게시하는 방법으로
                                 한다) 알릴 것이며 이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다.
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 고지 및 동의 방법 :</span>위 제휴 관계 또는 취급 위탁의 경우 회사가 이용자의 동의를 얻는 방법은 다음 열거된 방법들 중 
                                어느 하나와 같습니다. 다만, 개인정보 수집 매체의 특성상 동의 내용을 전부 표시하기 어려운 경우에는 이용자에게 동의 내용을 확인할 수 있는 
                                아래의 방법(인터넷주소ㆍ사업장 전화번호 등)을 안내하고 동의를 얻을 수 있습니다.
                                <br/><br/>
                                <span className='in'><b> 가. </b>인터넷 사이트(어플리케이션 또는 플랫폼)에 동의 내용을 게재하고 이용자가 동의 여부를 표시하도록 하는 방법</span><br/>
                                <span className='in'><b> 나. </b>동의 내용이 기재된 서면을 이용자에게 직접 교부하거나, 우편을 통하여 전달하고 이용자가 동의 내용에 대하여 
                                                                서명 날인 후 제출하도록 하는 방법</span><br/>
                                <span className='in'><b> 다. </b>동의 내용이 적힌 전자우편을 발송하여 이용자로부터 동의의 의사표시가 적힌 전자우편을 전송 받는 방법</span><br/>
                                <span className='in'><b> 라. </b>전화를 통하여 동의 내용을 이용자에게 알리고 동의를 얻거나 인터넷 주소 등 동의 내용을 확인할 수 있는 방법을 
                                                                 안내하고 <br/>재차 전화 통화를 통하여 동의를 얻는 방법</span>
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 예외 사항 :</span>다음 예외의 경우에는 회사는 이용자의 동의를 받지 아니하고 또한 이용자에게 고지하지 아니하고도 이용자의 
                                개인정보를 제공할 수 있습니다.
                                <br/><br/>
                                <span className='in'><b> 가. </b>법관이 발부한 영장에 의하여 이용자의 개인정보의 제공 요구가 있을 경우</span><br/>
                                <span className='in'><b> 나. </b>통계 작성, 학술 연구나 시장 조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력사 또는 연구 단체 등에 
                                                                제공하는 경우</span><br/>
                                <span className='in'><b> 다. </b>기타 관계 법령에 따라 회사에게 이용자의 개인정보를 제공할 의무가 인정되는 경우</span>
                                <br/>
                                그러나 위 예외에 해당하여 이용자의 동의를 받지 아니하고 정보를 제공하는 경우에도 정보를 제공하는 경우에도 본래의 수집 목적 및 이용 목적에 반하여 무분별하게 
                                정보가 제공되지 않도록 최대한 노력하겠습니다.
                            </p>
                        </div>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 병원 동행 서비스 매칭 서비스 및 온라인 플랫폼 특성에 의한 예외 사항 :</span>위드메이트 서비스 이용 도중 매칭된 회원과 메이트 상호간
                                식별을 위해 이름, 프로필 사진, 전화 번호, 주소, 병명, 나이 등이 위드메이트 서비스 내의 매칭된 회원에게 공개됩니다. 이는 공개 범위 특정이 불가능하여 별도로 고지하여 
                                동의 받지 못함을 양해바랍니다.
                            </p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 4조 (개인정보의 취급 위탁)</h3>
                        <h6>
                            회사는 원활한 개인정보 업무 처리를 위하여 추후 개인정보 처리 업무를 외부에 위탁할 예정이며, 해당 업체의 명단은 확정 후 공지될
                            예정입니다.
                            <br />
                            <br />
                            또, 회사는 위탁 계약 체결 시 개인정보보호법 제25조에 따라 위탁 업무 수행 목적 외 개인정보 처리 금지, 재위탁 제한, 수탁자에 대한 관리
                            및 감독, 책임에 관한 사항을 명시하고, 수탁자가 개인정보를 안전하게 처리하는지 감독할 의무가 있으며, 해당 의무를 책임지고 준수할
                            것입니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 5조 (동의의 거부권 및 거부 시의 불이익 고지)</h3>
                        <h6>
                            회원은 개인정보 수집ㆍ이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 및 이행 등을 위해 필요한 최소한의 개인정보
                            수집ㆍ이용에 관한 동의를 거부하는 경우에는 서비스 이용이 불가능하거나 처리 업무가 지연되는 등의 불이익이 있을 수 있습니다.
                            <br />
                            <br />
                            또한 마케팅 활동 및 홍보를 위한 개인정보 수집ㆍ이용 및/또는 선택적 수집ㆍ이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한
                            정보를 제공받지 못하거나 사은품ㆍ판촉물 제공, 제휴서비스 이용, 할인 혜택 적용 및 포인트 적립 불가 등의 불이익이 있을 수 있습니다.
                        </h6>
                        <p>
                            ※ 본 제공 동의 이외에도 회사는 회원이 별도로 동의한 경우, 회원이 동의한 바에 따라 개인정보를 수집ㆍ이용하거나 제3자에게 개인정보를
                            제공할 수 있습니다.
                        </p>
                    </div>

                    <div className='context-block'>
                        <h3>제 6조 (개인정보의 보유 및 이용 기간)</h3>
                        <h5>1.</h5>
                        <h6>회사는 회원이 회원의 자격을 유지하고 있는 동안 수집된 회원의 개인정보를 보유 및 이용할 수 있습니다.</h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            하지만 회원이 탈퇴하거나 자격을 상실한 경우, 회사는 회원의 별도 요청이 없더라도 수집된 회원 정보를 삭제 및 파기합니다. 회사는 다음의
                            방법으로 개인정보를 파기합니다.
                        </h6>
                        <div className='info'>
                            <p className='content'>- 전자적 파일 : 파일 삭제 및 디스크 등의 저장 매체 포맷</p>
                            <p className='content'>- 수기(手記) 문서 : 분쇄하거나 소각</p>
                        </div>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>단, 회원의 탈퇴 또는 자격 상실에도 불구하고 다음의 정보는 아래의 이유로 명시 기간 동안 보존 후 삭제 및 파기됩니다.</h6>
                        <div className='info'>
                            <p className='title'>3-1) 회사 내부 방침에 의한 정보 보유</p>
                            <p className='content'>- 회원 간의 분쟁 및 문제해결을 위한 회원정보 기록 : 분쟁의 소멸 시효 등 도과시까지</p>
                            <p className='content'>- 탈퇴 회원의 재가입 제한을 위한 회원정보 기록: 3개월</p>
                            <p className='content'>- 이용 제한 회원 및 부정 회원의 재가입 제한을 위한 회원 정보 기록: 제한 기간에 따름(별도저장)</p>
                        </div>
                        <div className='info' style={{ marginTop: '32px' }}>
                            <p className='title'>3-2) 관계 법령에 의한 정보 보유</p>
                            <p className='content'>- 표시/광고에 관한 기록 : 6개월 (「전자상거래 등에서의 소비자보호에 관한 법률」)</p>
                            <p className='content'>- 계약 또는 청약철회 등에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)</p>
                            <p className='content'>- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)</p>
                            <p className='content'>- 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (「전자상거래 등에서의 소비자보호에 관한 법률」)</p>
                            <p className='content'>- 로그인 기록 : 3개월 (「통신비밀보호법」)</p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 7조 (회원 및 법정대리인의 권리, 의무와 그 행사 방법)</h3>
                        <h5>1.</h5>
                        <h6>
                            회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를
                            거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는
                            회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회원의 개인정보 조회 및 수정을 위해서는 "개인정보변경"(또는 "회원정보수정" 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를, 각
                            클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 관리책임자에게 서면 또는 이메일로
                            연락하시면 지체 없이 조치하겠습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회원이 개인정보의 오류에 대한 정정을 요청하신 경우에 회사는 당해 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게
                            제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이
                            이루어지도록 하겠습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 "제6조 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로
                            열람 또는 이용할 수 없도록 처리하고 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            서비스에 가입할 수 있는 자는 만 19세 이상이며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는
                            원칙적으로 수집하지 않습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 8조 (개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항)</h3>
                        <h5>1.</h5>
                        <h6>
                            회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를
                            거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는
                            회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.
                        </h6>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>쿠키의 사용목적 :</span>이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어,
                                보안 접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                            </p>
                            <p className='title'>
                                <span className='bold'>쿠키의 설치∙운영 및 거부 :</span>웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키
                                저장을 거부 할 수 있습니다.
                            </p>
                            <p className='title'>
                                <span className='bold'>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span>
                            </p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 온라인 맞춤형 광고 서비스를 사용합니다.</h6>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>행태 정보 수집 방법 :</span>이용자가 당사 애플리케이션을 실행할 때 자동 수집 및 전송합니다.
                            </p>
                            <p className='title'>
                                <span className='bold'>모바일 애플리케이션 사용 시 광고 식별자 수집 :</span>회사는 이용자의 ADID/IDFA를 수집할 수 있습니다.
                                ADID/IDFA란 모바일 앱 이용자의 광고 식별 값으로써, 사용자의 맞춤 서비스 제공이나 더 나은 환경의 광고를 제공하기 위한 측정을 위해
                                수집할 수 있습니다.
                            </p>
                            <p className='title'>
                                <span className='bold'>거부 방법 :</span>- Android: 설정 → 구글(구글설정) → 광고 → 광고 맞춤설정 선택 해제
                            </p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 9조 (개인정보의 안전성 확보 조치)</h3>
                        <h6>
                            회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를
                            거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는
                            회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.
                        </h6>

                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>- 관리적 조치 :</span>내부 관리 계획 수립 및 시행, 직원과 종업원 등에 대한 정기적 교육
                            </p>
                            <p className='title'>
                                <span className='bold'>- 기술적 조치 : </span>개인정보 처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호 설정 등 접근권한
                                관리, 백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화
                            </p>
                            <p className='title'>
                                <span className='bold'>- 물리적 조치 :</span>개인정보가 저장․보관된 장소의 시건, 출입통제 등
                            </p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 10조 (개인정보 관리책임자 및 담당자의 연락처)</h3>
                        <h6>
                            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만 및 피해 구제를 처리하기 위하여 아래와
                            같이 개인정보 보호책임자를 지정하고 있습니다.
                        </h6>

                        <p className='medium-bold'>개인정보 보호 및 관리 책임자 (사업주 또는 대표자)</p>
                        <div className='info'>
                            <p className='title' style={{ borderTop: '1px solid #e6e6e6' }}>
                                <span className='bold'>성명 및 직책 :</span>지승배 / 대표이사
                            </p>
                            <p className='title'>
                                <span className='bold'>연락처 :</span>ceo@withmate.kr
                            </p>
                            <p className='title'>
                                <span className='bold'>고객센터 :</span>070-4300-9075
                            </p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 11조 (고지의 의무)</h3>
                        <h6>
                            현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 회사 애플리케이션, 서비스 내의 '공지사항' 또는 기타
                            알기 쉬운 방법을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 회원 권리의 중요한 변경이 있을 경우에는
                            최소 30일 전에 고지합니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <div className='privacy-justify'>
                            <p className='version'>개인정보 처리방법 버전 번호 : v.1.2</p>
                            <p className='date'>제정일자 : 2021년 12월 20일</p>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}
export default PrivacyComponent;
