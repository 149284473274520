import React from 'react';
import { Checkbox, Select, message, Spin } from 'antd';

// utils
import axios from '../../../Lib/axios';
import { imageUpload } from '../../../Lib/Api/app';

// styles
import { FormContainer, InnerContainer, InputWrapper, InputLabel, ApplyButton, LargeText, FormImgWrapper, SpinWrapper } from './mateApply.styled';
import { WithamteInputWithButton, WithmateInput, WithmateSelect, WithmateTextarea } from '../../../Components/Common/input';

class MateApplyPage extends React.PureComponent {
    state = {
        photo: '',
        onSpin: false,
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    handleTrimChange = e => {
        this.setState({ [e.target.title]: e.target.value.trim() });
    };

    onAddress = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    address: `${roadAddress} (${buildingName})`,
                }));
            },
        }).open();
    };

    editApply = ({ type, value }) => {
        this.setState({ [type]: value });
    };

    onSendApply = async () => {
        const {
            name,
            gender,
            birth,
            // photo,
            email,
            contact,
            address,
            connection,
            connection_etc,
            activity_area,
            drive,
            careCareer,
            care_experience,
            insurance,
            privacy,
            license_photo,
        } = this.state;

        this.setState({ onSpin: true });

        if (!name) {
            this.setState({ onSpin: false });
            return message.warning('성함을 입력하세요.');
        }

        if (!gender) {
            this.setState({ onSpin: false });
            return message.warning('성별을 입력하세요.');
        }

        if (!birth || birth.length < 8) {
            this.setState({ onSpin: false });
            return message.warning('생년월일 8자리를 입력하세요.');
        }

        if (!email) {
            this.setState({ onSpin: false });
            return message.warning('이메일을 입력하세요.');
        }

        if (!contact) {
            this.setState({ onSpin: false });
            return message.warning('개인 연락처를 입력하세요.');
        }

        if (!address) {
            this.setState({ onSpin: false });
            return message.warning('자택 주소를 검색하여 입력하세요.');
        }

        if (!careCareer) {
            this.setState({ onSpin: false });
            return message.warning('치매관련 간병 경력 기간을 선택하세요.');
        }

        // if (!activity_area) {
        //     this.setState({ onSpin: false });
        //     return message.warning('메이트 활동이 가능한 지역을 선택해주세요.');
        // }

        if (!care_experience) {
            this.setState({ onSpin: false });
            return message.warning('개인 간병 경력 사항을 입력하세요.');
        }

        if (!insurance) {
            this.setState({ onSpin: false });
            return message.warning('삼성화재 전문인 배상 책임 보험 가입 동의를 선택하세요.');
        }

        if (!privacy) {
            this.setState({ onSpin: false });
            return message.warning('개인정보 수집 및 이용 동의를 선택하세요.');
        }

        const formData = new FormData();
        formData.append('license_photo', license_photo);
        const header = { headers: { 'Content-Type': 'multipart/form-data' } };
        const { data: image } = await axios.post('/v1/user/apply/mate/image/upload', formData, header);

        const { data } = await axios.post('/v1/user/apply/mate/add', {
            name,
            gender,
            birth,
            // photo: image,
            email,
            contact,
            address,
            connection,
            connection_etc,
            activity_area: activity_area ? activity_area.join(', ') : '', // 배열을 쉼표로 구분된 문자열로 변환
            drive,
            careCareer,
            care_experience,
            insurance,
            privacy,
            license_photo: image,
        });

        if (!data) {
            this.setState({ onSpin: false });
            return message.error('서버 장애로 지원에 실패했습니다. 관리자에게 문의해주세요.');
        }

        if (data === 'success') {
            this.setState({ onSpin: false });
            message.success('감사합니다. 메이트 지원이 완료되었습니다. 검토후 결과를 안내해드리겠습니다.');
            setTimeout(() => {
                window.location.href = '/';
            }, 1500);
            return null;
        }

        if (data === 'check parameters') {
            this.setState({ onSpin: false });
            return message.info('누락된 정보가 있는지 지원서 양식을 확인해주세요.');
        }
    };

    render() {
        return (
            <FormContainer>
                <InnerContainer>
                    <LargeText style={{ color: '#161616', marginBottom: '20px' }}>메이트 지원하기</LargeText>

                    <InputWrapper>
                        <InputLabel>성함을 입력하세요</InputLabel>
                        <WithmateInput type='text' title='name' value={this.state.name || ''} onChange={this.handleTrimChange} />
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>성별을 선택하세요</InputLabel>
                        <div className='type-button-wrapper'>
                            <div
                                className={`type-button ${this.state.gender && this.state.gender === '남자' ? 'active' : ''}`}
                                onClick={() => {
                                    this.editApply({ type: 'gender', value: '남자' });
                                }}
                            >
                                남자
                            </div>
                            <div
                                className={`type-button ${this.state.gender && this.state.gender === '여자' ? 'active' : ''}`}
                                onClick={() => {
                                    this.editApply({ type: 'gender', value: '여자' });
                                }}
                            >
                                여자
                            </div>
                        </div>
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>생년월일을 입력하세요</InputLabel>
                        <WithmateInput
                            type='text'
                            title='birth'
                            value={this.state.birth || ''}
                            onChange={this.handleTrimChange}
                            placeholder='만 19세 이상의 성인만 메이트로 활동이 가능합니다. 생년월일을 8자리로 입력해주세요'
                        />
                    </InputWrapper>

                    {/* <InputWrapper>
                        <InputLabel>이미지 파일 첨부</InputLabel>
                        {this.state.photo ? (
                            <FormImgWrapper>
                                <img src={URL.createObjectURL(this.state.photo)} />
                            </FormImgWrapper>
                        ) : null}
                        <input
                            type='file'
                            accept='image/x-png,image/gif,image/jpeg'
                            onChange={ev => {
                                this.editApply({ type: 'photo', value: ev.target.files[0] });
                            }}
                        />
                    </InputWrapper> */}

                    <InputWrapper>
                        <InputLabel>개인 이메일을 입력하세요</InputLabel>
                        <WithmateInput
                            type='text'
                            title='email'
                            value={this.state.email || ''}
                            onChange={this.handleTrimChange}
                            placeholder='기입하신 이메일로 프리랜서 근로 계약 문서가 전송되고 온라인 계약 체결이 진행됩니다.'
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>개인 연락처를 입력하세요</InputLabel>
                        <WithmateInput
                            type='text'
                            title='contact'
                            value={this.state.contact || ''}
                            onChange={this.handleTrimChange}
                            placeholder='기입해주신 연락처로 메이트 합격/불합격 여부 알림과 메이트로 활동하실때 사용하시는 아이디와 임시 비밀번호 전송됩니다.'
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>자택 주소를 입력하세요</InputLabel>
                        <WithamteInputWithButton
                            inputConfig={{
                                type: 'text',
                                placeholder: 'ex) 서울특별시 도산대로 40, OO 아파트',
                                name: 'address',
                                value: this.state.address || '',
                            }}
                            buttonName='검색'
                            onButton={() => {
                                this.onAddress();
                            }}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>메이트 가입 경로(선택 사항)</InputLabel>
                        <WithmateSelect
                            style={{ height: '48px', width: '100%', paddingBottom: '5px' }}
                            placeholder='위드메이트의 메이트 가입 경로를 선택하세요'
                            value={this.state.connection || undefined}
                            onChange={value => {
                                this.editApply({ type: 'connection', value });
                            }}
                        >
                            <Select.Option value='한국직업평가진흥협회'>한국직업평가진흥협회</Select.Option>
                            <Select.Option value='검색'>검색</Select.Option>
                            <Select.Option value='광고'>광고</Select.Option>
                            <Select.Option value='구직사이트'>구직사이트</Select.Option>
                            <Select.Option value='뉴스기사'>뉴스기사</Select.Option>
                            <Select.Option value='대신위드유'>대신위드유</Select.Option>
                            <Select.Option value='에이플러스'>에이플러스</Select.Option>
                            <Select.Option value='복지넷'>복지넷</Select.Option>
                            <Select.Option value='강동 50+'>강동 50+</Select.Option>
                            <Select.Option value='서대문 50+'>서대문 50+</Select.Option>
                            <Select.Option value='국립 암센터'>국립 암센터</Select.Option>
                            <Select.Option value='지인'>지인</Select.Option>
                            <Select.Option value='카페'>카페•블로그</Select.Option>
                            <Select.Option value='기타'>기타</Select.Option>
                        </WithmateSelect>
                        {this.state.connection === '기타' ? (
                            <WithmateInput
                                type='text'
                                title='connection_etc'
                                value={this.state.connection_etc || ''}
                                onChange={this.handleChange}
                                placeholder='메이트 가입 경로를 입력하세요'
                            />
                        ) : null}
                        {this.state.connection === '한국직업평가진흥협회' ? (
                            <InputWrapper>
                                <InputLabel>자격증 이미지 파일첨부</InputLabel>
                                {this.state.license_photo ? (
                                    <FormImgWrapper>
                                        <img src={URL.createObjectURL(this.state.license_photo)} />
                                    </FormImgWrapper>
                                ) : null}
                                <input
                                    type='file'
                                    accept='image/x-png,image/gif,image/jpeg'
                                    onChange={ev => {
                                        this.editApply({ type: 'license_photo', value: ev.target.files[0] });
                                    }}
                                />
                            </InputWrapper>
                        ) : null}
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>메이트 활동 가능 지역을 알려주세요</InputLabel>
                        <WithmateSelect
                            style={{ height: '48px', width: '100%', paddingBottom: '5px' }}
                            placeholder='메이트 활동이 가능한 지역을 선택해주세요 (여러개 가능)'
                            value={this.state.si || undefined}
                            onChange={value => {
                                this.editApply({ type: 'si', value });
                            }}
                        >
                            <Select.Option value='서울'>서울</Select.Option>
                            <Select.Option value='경기도'>경기도</Select.Option>
                            <Select.Option value='경상남도'>경상남도</Select.Option>
                        </WithmateSelect>
                        {this.state.si === '서울' ? (
                            <WithmateSelect
                                mode='multiple'
                                style={{ height: '48px', width: '100%', paddingBottom: '5px' }}
                                placeholder='메이트 활동이 가능한 지역을 선택해주세요 (여러개 가능)'
                                value={this.state.activity_area || []}
                                onChange={value => {
                                    this.editApply({ type: 'activity_area', value });
                                }}
                            >
                                <Select.Option value='강남구'>강남구</Select.Option>
                                <Select.Option value='강동구'>강동구</Select.Option>
                                <Select.Option value='강북구'>강북구</Select.Option>
                                <Select.Option value='강서구'>강서구</Select.Option>
                                <Select.Option value='관악구'>관악구</Select.Option>
                                <Select.Option value='광진구'>광진구</Select.Option>
                                <Select.Option value='구로구'>구로구</Select.Option>
                                <Select.Option value='금천구'>금천구</Select.Option>
                                <Select.Option value='노원구'>노원구</Select.Option>
                                <Select.Option value='도봉구'>도봉구</Select.Option>
                                <Select.Option value='동대문구'>동대문구</Select.Option>
                                <Select.Option value='동작구'>동작구</Select.Option>
                                <Select.Option value='마포구'>마포구</Select.Option>
                                <Select.Option value='서대문구'>서대문구</Select.Option>
                                <Select.Option value='서초구'>서초구</Select.Option>
                                <Select.Option value='성동구'>성동구</Select.Option>
                                <Select.Option value='성북구'>성북구</Select.Option>
                                <Select.Option value='송파구'>송파구</Select.Option>
                                <Select.Option value='양천구'>양천구</Select.Option>
                                <Select.Option value='영등포구'>영등포구</Select.Option>
                                <Select.Option value='용산구'>용산구</Select.Option>
                                <Select.Option value='은평구'>은평구</Select.Option>
                                <Select.Option value='종로구'>종로구</Select.Option>
                                <Select.Option value='중구'>중구</Select.Option>
                                <Select.Option value='중랑구'>중랑구</Select.Option>
                            </WithmateSelect>
                        ) : null}
                        {this.state.si === '경기도' ? (
                            <WithmateSelect
                                mode='multiple'
                                style={{ height: '48px', width: '100%', paddingBottom: '5px' }}
                                placeholder='메이트 활동이 가능한 지역을 선택해주세요 (여러개 가능)'
                                value={this.state.activity_area || []}
                                onChange={value => {
                                    this.editApply({ type: 'activity_area', value });
                                }}
                            >
                                <Select.Option value='경기남부'>경기남부</Select.Option>
                                <Select.Option value='수원시'>수원시</Select.Option>
                                <Select.Option value='용인시'>용인시</Select.Option>
                                <Select.Option value='성남시'>성남시</Select.Option>
                                <Select.Option value='부천시'>부천시</Select.Option>
                                <Select.Option value='화성시'>화성시</Select.Option>
                                <Select.Option value='안산시'>안산시</Select.Option>
                                <Select.Option value='평택시'>평택시</Select.Option>
                                <Select.Option value='시흥시'>시흥시</Select.Option>
                                <Select.Option value='김포시'>김포시</Select.Option>
                                <Select.Option value='광주시'>광주시</Select.Option>
                                <Select.Option value='광명시'>광명시</Select.Option>
                                <Select.Option value='군포시'>군포시</Select.Option>
                                <Select.Option value='하남시'>하남시</Select.Option>
                                <Select.Option value='오산시'>오산시</Select.Option>
                                <Select.Option value='이천시'>이천시</Select.Option>
                                <Select.Option value='안성시'>안성시</Select.Option>
                                <Select.Option value='의왕시'>의왕시</Select.Option>
                                <Select.Option value='양평군'>양평군</Select.Option>
                                <Select.Option value='여주시'>여주시</Select.Option>
                                <Select.Option value='과천시'>과천시</Select.Option>
                                <Select.Option value='경기북부'>경기북부</Select.Option>
                                <Select.Option value='고양시'>고양시</Select.Option>
                                <Select.Option value='남양주시'>남양주시</Select.Option>
                                <Select.Option value='파주시'>파주시</Select.Option>
                                <Select.Option value='의정부시'>의정부시</Select.Option>
                                <Select.Option value='양주시'>양주시</Select.Option>
                                <Select.Option value='구리시'>구리시</Select.Option>
                                <Select.Option value='포천시'>포천시</Select.Option>
                                <Select.Option value='동두천시'>동두천시</Select.Option>
                                <Select.Option value='가평군'>가평군</Select.Option>
                                <Select.Option value='연천군'>연천군</Select.Option>
                            </WithmateSelect>
                        ) : null}

                        {this.state.si === '경상남도' ? (
                            <WithmateSelect
                                mode='multiple'
                                style={{ height: '48px', width: '100%', paddingBottom: '5px' }}
                                placeholder='메이트 활동이 가능한 지역을 선택해주세요 (여러개 가능)'
                                value={this.state.activity_area || []}
                                onChange={value => {
                                    this.editApply({ type: 'activity_area', value });
                                }}
                            >
                                <Select.Option value='창원시'>창원시</Select.Option>
                            </WithmateSelect>
                        ) : null}
                        {/* <WithmateInput
                            type='text'
                            title='activity_area'
                            value={this.state.activity_area || ''}
                            onChange={this.handleChange}
                            placeholder='메이트 활동이 가능한 서비스 지역과 병원을 알려주세요 (강남구 일대, 종로구 일대, 세브란스 병원, 서울대 병원, 아산병원 등'
                        /> */}
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>운전 가능 여부를 선택하세요</InputLabel>
                        <Checkbox
                            checked={this.state.drive === '가능'}
                            onClick={() => {
                                this.editApply({ type: 'drive', value: '가능' });
                            }}
                        >
                            가능
                        </Checkbox>
                        <Checkbox
                            checked={this.state.drive === '불가능'}
                            onClick={() => {
                                this.editApply({ type: 'drive', value: '불가능' });
                            }}
                        >
                            불가능
                        </Checkbox>
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>치매관련 간병 경력</InputLabel>
                        <Checkbox
                            checked={this.state.careCareer === '없어요'}
                            onClick={() => {
                                this.editApply({ type: 'careCareer', value: '없어요' });
                            }}
                        >
                            없어요
                        </Checkbox>
                        <Checkbox
                            checked={this.state.careCareer === '1년 미만'}
                            onClick={() => {
                                this.editApply({ type: 'careCareer', value: '1년 미만' });
                            }}
                        >
                            1년 미만
                        </Checkbox>
                        <Checkbox
                            checked={this.state.careCareer === '2 ~ 3년'}
                            onClick={() => {
                                this.editApply({ type: 'careCareer', value: '2 ~ 3년' });
                            }}
                        >
                            2 ~ 3년
                        </Checkbox>
                        <Checkbox
                            checked={this.state.careCareer === '3년이상'}
                            onClick={() => {
                                this.editApply({ type: 'careCareer', value: '3년이상' });
                            }}
                        >
                            3년이상
                        </Checkbox>
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>개인의 간병 경력 사항을 입력하세요</InputLabel>
                        <WithmateTextarea
                            style={{ height: '100px' }}
                            type='text'
                            title='care_experience'
                            value={this.state.care_experience || ''}
                            onChange={this.handleChange}
                            placeholder='메이트 활동을 위해 도움이 되는 자신의 간병 경력을 500자 이내로 자유롭게 남겨주세요 (요양보호사 자격증 보유 & OO요양병원 3년 근무 등 / 간병 이외의 경력도 기재 가능'
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>삼성화재 전문인 배상 책임 보험 가입 동의</InputLabel>
                        <p style={{ fontSize: '13px', color: 'gray', paddingBottom: '10px' }}>
                            보험 가입 심사를 위해 설계 동의를 진행해주세요. 위드메이트는 메이트의 서비스 이행 중 안전한 보호를 위해 회사가 부담하는 보험 가입
                            절차를 도와드리고 있습니다. 보험 가입에 대한 의사를 체크해주세요. 해당 절차는 보험 가입 심사 동의를 위한 것이며, 동의를 하는것
                            만으로 보험에 가입이 되는 것은 아닙니다. 보험 가입은 메이트 심사 합격 후 실제 서비스가 매칭된 인원에 한하여 본사와 전화 상담 후
                            진행됩니다.
                        </p>
                        <Checkbox
                            checked={this.state.insurance === '동의'}
                            onClick={() => {
                                this.editApply({ type: 'insurance', value: '동의' });
                            }}
                        >
                            동의
                        </Checkbox>
                        <Checkbox
                            checked={this.state.insurance === '비동의'}
                            onClick={() => {
                                this.editApply({ type: 'insurance', value: '비동의' });
                            }}
                        >
                            비동의
                        </Checkbox>
                    </InputWrapper>

                    <InputWrapper>
                        <InputLabel>개인정보 수집 및 이용 동의</InputLabel>
                        <p style={{ fontSize: '13px', color: 'gray', paddingBottom: '10px' }}>
                            위드메이트는 본 지원서를 통해 수집한 개인정보를 메이트 심사, 서비스 교육 안내, 동행 서비스 중개를 제외한 타 목적으로 이용하지
                            않습니다. 지원자는 본 개인정보 수집 및 이용에 동의하지 않을 권리가 있습니다. 단, 동의하지 않을 경우 메이트 심사 및 모집에 제한이
                            있을 수 있습니다. 위드메이트의 개인정보 수집 및 이용 동의 여부를 선택해주세요.
                        </p>
                        <Checkbox
                            checked={this.state.privacy === '동의'}
                            onClick={() => {
                                this.editApply({ type: 'privacy', value: '동의' });
                            }}
                        >
                            동의
                        </Checkbox>
                        <Checkbox
                            checked={this.state.privacy === '비동의'}
                            onClick={() => {
                                this.editApply({ type: 'privacy', value: '비동의' });
                            }}
                        >
                            비동의
                        </Checkbox>
                    </InputWrapper>

                    <div style={{ textAlign: 'center' }}>
                        <ApplyButton onClick={this.onSendApply}>지원하기</ApplyButton>
                    </div>
                </InnerContainer>
                {/* {this.state.onSpin ? (
                    <SpinWrapper>
                        <Spin size='large' />
                    </SpinWrapper>
                ) : null} */}
            </FormContainer>
        );
    }
}

export default MateApplyPage;
