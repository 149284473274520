import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../theme';

const StyledInfoContainer = styled.div`
    padding-top: 55px;

    .faq-header {
        // border-bottom: 1px solid #e6e6e6;

        .inner-container {
            padding: 0px;
            padding-top: 82px;

            @media (max-width: 768px) {
                padding-top: 42px;
            }

            > .flex-wrapper {
                position: relative;

                .faq-main-title {
                    flex: 1;
                    .title {
                        font-size: ${sizeHandler(30)};
                        font-weight: bold;
                        letter-spacing: -0.6px;
                        color: #000000;
                        margin-bottom: 8px;

                        @media {
                            padding-left: 10px;
                        }
                    }

                    .description {
                        font-size: ${sizeHandler(14)};
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.8);
                        margin-bottom: 82px;

                        @media {
                            padding-left: 10px;
                        }
                    }
                }

                .img-section {
                    position: absolute;
                    bottom: -50px;
                    right: 0px;

                    > img {
                        width: 100%;
                        max-width: 549px;
                        height: auto;
                        object-fit: cover;
                    }

                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }

    .info-contents {
        .inner-container {
            padding-top: 0px;

            @media (max-width: 768px) {
                h1 {
                    font-size: 30px;
                    margin-top: 60px;
                }
            }

            .img-section {

                > img {
                    max-width: 500px;
                    height: auto;
                    object-fit: cover;
                }
            }
        }
    }    

    h1 {
        font-size: ${sizeHandler(34)};
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: -1px;

        margin-bottom: 26px;
        margin-top: 50px;
    }

    @media (max-width: 832px) {
        .info-contents {
            .inner-container {
                .img-section {
                    > img {
                        max-width: 300px;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }
        }    
    }

    @media (max-width: 1120px) {
        .faq-header {
            .inner-container {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
        .inner-container {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media (max-width: 768px) {
        .panel-header {
            .row-index {
                width: 70px;
            }
        }

        .panel-content {
            padding-left: 70px;

            > p {
                width: 90%;
            }
        }
    }
`;

export default StyledInfoContainer;