import React from 'react';
import { message } from 'antd';
import axios from '../../Lib/axios';

import { DivisionLine, WithmateInput, WithmateLabel } from '../../Components/Common/input';
import { FlexFormWrapper, FormMainTitle, FormWrapper, LabelAndValueRow, PriceDetailLabel, PriceDetailValue, ResultFormWrapper, ServiceName, Sexes } from '../Reserve/reserve.styled';
import { FlexWrapper } from '../Apply/Ready/ready.styled';
import { HalfWidthButton } from '../../Components/Common/button';

class LongTermCareComponent extends React.PureComponent {
    state = {
        name: '',
        age: '',
        contact: '',
        sex: '',
        region: '',
        gradePresence: '',
        gradeLevel: ''
    };

    onLongTermCare = async () => {
        const userId = localStorage.getItem('id');
        const { name, age, contact, sex, region, gradePresence, gradeLevel } = this.state;

        if (!name) {
            return this.setState({
                modal: true,
                modalTitle: '이름을 입력해주세요.',
                modalInfo: '장기요양등급 상담하시는분의 이름을 입력해주세요.',
            });
        }

        if (!age) {
            return this.setState({
                modal: true,
                modalTitle: '나이를 입력해주세요.',
                modalInfo: '장기요양등급 상담하시는분의 나이를 입력해주세요.',
            });
        }

        if (!contact) {
            return this.setState({
                modal: true,
                modalTitle: '연락처를 입력하세요.',
                modalInfo: '장기요양등급 상담하시는분의 연락처를 입력해주세요.',
            });
        }

        if (!sex) {
            return this.setState({
                modal: true,
                modalTitle: '성별을 선택하세요.',
                modalInfo: '장기요양등급 상담하시는분의 성별을 선택해주세요.',
            });
        }

        if (!region) {
            return this.setState({
                modal: true,
                modalTitle: '거주지 지역을 입력하세요.',
                modalInfo: '장기요양등급 상담하시는 분의 거주지 지역을 입력해주세요.',
            });
        }

        if (!gradePresence) {
            return this.setState({
                modal: true,
                modalTitle: '요양등급 유무를 선택하세요.',
                modalInfo: '요양등급 유무를 선택해주세요.',
            });
        }


        const { data } = await axios.post('/v1/longTermCare/add', { userId, name, age, contact, sex, region, gradePresence, gradeLevel });

        if (!data) return message.error('죄송합니다. 일시적인 서버 장애가 발생하였습니다.');
        if (data === 'check parameters') return message.warning('필수 정보들을 입력해주세요.');
        if (data === 'success') {
            message.success('장기요양등급 신청서 작성이 완료되었습니다. 빠른 시일내에 상담 연락드리겠습니다.');
            setTimeout(() => {
                this.props.history.push('/');
            }, 600);
        }
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    render() {
        return (
            <div style={{ padding: '10px', marginTop: '100px' }}>
                <FlexFormWrapper>
                    <FormWrapper>
                        <FormMainTitle>장기요양 등급 상담 신청서</FormMainTitle>
                        <ServiceName style={{ fontSize: '15px' }}>장기요양 등급 신청서를 작성하시면 상담신청이 가능합니다.</ServiceName>

                        <FlexWrapper withHalf>
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>이름</WithmateLabel>
                                <WithmateInput
                                    type='text'
                                    title='name'
                                    value={this.state.name || ''}
                                    onChange={this.handleChange}
                                />
                            </div>
                            &ensp;&ensp;
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>나이</WithmateLabel>
                                <WithmateInput maxLength='2' placeholder='50' title='age' suffix='세' value={this.state.age} onChange={this.handleChange} />
                            </div>
                        </FlexWrapper>

                        <FlexWrapper withHalf>
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>연락처</WithmateLabel>
                                <WithmateInput maxLength='11' title='contact' value={this.state.contact} onChange={this.handleChange} />
                            </div>
                            &ensp;&ensp;
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>성별</WithmateLabel>
                                <Sexes style={{ height: '46px' }}>
                                    <span
                                        className={this.state.sex === '남성' ? 'active' : 'inactive'}
                                        onClick={() => {
                                            this.handleChange({ target: { title: 'sex', value: '남성' } });
                                        }}
                                    >
                                        남성
                                    </span>
                                    <span
                                        className={this.state.sex === '여성' ? 'active' : 'inactive'}
                                        onClick={() => {
                                            this.handleChange({ target: { title: 'sex', value: '여성' } });
                                        }}
                                    >
                                        여성
                                    </span>
                                </Sexes>
                            </div>
                        </FlexWrapper>

                        <FlexWrapper withHalf>
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>지역</WithmateLabel>
                                <WithmateInput placeholder='지역의 구까지 입력해주세요. ex) 서울특별시 강남구' title='region' value={this.state.region} onChange={this.handleChange} />
                            </div>
                        </FlexWrapper>

                        <FlexWrapper withHalf>
                            <div style={{ flex: 1 }}>
                                <WithmateLabel required>요양등급 유무</WithmateLabel>
                                <Sexes style={{ height: '46px' }}>
                                    <span
                                        className={this.state.gradePresence === '있다' ? 'active' : 'inactive'}
                                        onClick={() => {
                                            this.handleChange({ target: { title: 'gradePresence', value: '있다' } });
                                        }}
                                    >
                                        있다
                                    </span>
                                    <span
                                        className={this.state.gradePresence === '없다' ? 'active' : 'inactive'}
                                        onClick={() => {
                                            this.handleChange({ target: { title: 'gradePresence', value: '없다' } });
                                        }}
                                    >
                                        없다
                                    </span>
                                </Sexes>
                            </div>
                            &ensp;&ensp;
                            {this.state.gradePresence === '있다' ? <>
                                <div style={{ flex: 1 }}>
                                    <WithmateLabel required>등급을 입력하세요</WithmateLabel>
                                    <WithmateInput title='gradeLevel' suffix='등급' value={this.state.gradeLevel} onChange={this.handleChange} />
                                </div>
                            </> : null}
                        </FlexWrapper>
                    </FormWrapper>

                    <ResultFormWrapper>
                        <FormMainTitle style={{ fontSize: '25px' }}>신청 내역</FormMainTitle>

                        <LabelAndValueRow borderColor='#cecfd0'>
                        </LabelAndValueRow>

                        <DivisionLine x_small />
                        <DivisionLine />

                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>이름</PriceDetailLabel>
                            <PriceDetailValue>{this.state.name}</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>나이</PriceDetailLabel>
                            <PriceDetailValue>{this.state.age + ' 세'}</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>연락처</PriceDetailLabel>
                            <PriceDetailValue>{this.state.contact}</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>성별</PriceDetailLabel>
                            <PriceDetailValue>{this.state.sex}</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>지역</PriceDetailLabel>
                            <PriceDetailValue>{this.state.region}</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow>
                            <PriceDetailLabel><span>* </span>장애등급 유무</PriceDetailLabel>
                            <PriceDetailValue>{this.state.gradePresence}</PriceDetailValue>
                        </LabelAndValueRow>
                        {this.state.gradePresence == '있다' ? <>
                            <LabelAndValueRow>
                                <PriceDetailLabel><span>* </span>장애등급</PriceDetailLabel>
                                <PriceDetailValue>{this.state.gradeLevel}</PriceDetailValue>
                            </LabelAndValueRow>
                        </> : null}

                        <DivisionLine /><DivisionLine /><DivisionLine /><DivisionLine small />
                        <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff'
                            onClick={this.onLongTermCare}
                        >
                            신청하기
                        </HalfWidthButton>
                    </ResultFormWrapper>
                </FlexFormWrapper>
            </div>
        );
    }
}
export default LongTermCareComponent;