import React from 'react';
import { connect } from 'react-redux';

// component
import MarketingStyledContainer from './marketing.styled';
import Marketing from './marketing';

class MarketingContainer extends React.PureComponent {
    render() {
        return (
            <MarketingStyledContainer component={this.props.isComponent || undefined}>
                <Marketing {...this.props} />
            </MarketingStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MarketingContainer);
