import React from 'react';
import styled from 'styled-components';

const CommentPageHeader = ({ children, title, desc }) => {
    return (
        <HeaderContainer>
            <HeaderWrapper className='inner-container'>
                <HeaderBlock>
                    <HeaderTitle>{title}</HeaderTitle>
                    <HeaderDescription>
                        {desc.split('\n').map((word, index) => (
                            <p key={'add-word-brea-' + index.toString()}>{word}</p>
                        ))}
                    </HeaderDescription>
                    {children}
                </HeaderBlock>
                <HeaderBlock className='img-cover'>
                    <MainImageWrapper>
                        {/* <MainImage src='/assets/images/comment_main.png' /> */}
                        <MainImage src='/assets/images/w_main_crop.png' />
                    </MainImageWrapper>
                </HeaderBlock>
            </HeaderWrapper>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    // border-bottom: 1px solid #e6e6e6;
`;

const HeaderWrapper = styled.div`
    display: flex;

    padding: 0px;
    padding-top: 100px;
    padding-bottom: 0px;

    @media (max-width: 1120px) {
        padding: 100px 16px 0px 16px;
    }
`;

const HeaderBlock = styled.div`
    flex: 1;

    &.img-cover {
        flex: 1.2;

        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

const HeaderTitle = styled.div`
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #000000;

    margin-top: 30px;
    margin-bottom: 6px;

    @media (max-width: 768px) {
        font-size: 32px;
        margin-top: 16px;
    }
`;

const HeaderDescription = styled.div`
    > p {
        margin-bottom: 0px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.8);

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
`;

const MainImageWrapper = styled.div`
    display: flex;
    justify-content: flex-start;

    width: 80%;
    height: 100%;
    max-height: 391px;
`;

const MainImage = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
`;

export default CommentPageHeader;
