import React from 'react';
import styled from 'styled-components';

export const UserVideoSection = () => {
    return (
        <VideoSectionContainer>
            <VideoSectionInnerContainer>
                {/* <VideoSectionTitle>
                    위드메이트를 사용하는 것,
                    <MobileBr /> 일상을 돌려받는 것
                </VideoSectionTitle> */}
                <VideoArea>
                    {/* <iframe
                        width='100%'
                        height='100%'
                        src='/assets/videos/withmate_video.mp4'
                        title='withmate YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        autoplay='autoplay'
                        loop='loop'
                    ></iframe> */}
                    <video controls loop='loop' autoplay='autoplay' muted playsinline width='800px' height='auto'>
                        <source src='/assets/videos/withmate_video.mp4' />
                    </video>
                </VideoArea>
            </VideoSectionInnerContainer>
        </VideoSectionContainer>
    );
};

const VideoSectionContainer = styled.div`
    // padding-top: 105px;
    // padding-bottom: 208px;
    padding-top: 55px;
    padding-bottom: 55px;

    width: 100%;
    background-color: #f9f9f9;

    @media (max-width: 768px) {
        // padding-top: 62px;
        padding-top: 10px;
        // padding-bottom: 0px;
        padding-bottom: 10px;
    }
`;

const VideoSectionInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
`;

const VideoSectionTitle = styled.h4`
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);

    margin-top: 0px;
    margin-bottom: 105px;

    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 68px;
    }
`;

const VideoArea = styled.div`
    width: 100%;
    height: calc(1120px / 16 * 9);
    background-color: #000000;

    > iframe,
    video {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 1120px) {
        height: calc(100vw / 16 * 9);
    }

    @media (max-width: 768px) {
        width: 100%;
        height: calc(100vw / 16 * 9);
        background-color: #000000;

        display: flex;
        align-items: center;
        justify-content: center;

        > iframe,
        video {
            width: 100%;
            height: 100%;
        }
    }
`;

const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;
