import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Popup from '../../../Pages/Home/Section/renew/Popup';

// utils
import { sizeHandler, mainColor } from '../../../theme';
import { UNEMER } from '../../../Lib/variables';

export const NoCatchedList = ({ list, detailHandler, data, onRefresh }) => {
    return (
        <FullContainer>
            {/* <Popup /> */}
            <Inner>
                <NoCatchTitle>
                    실시간 예약 내역
                    <RefreshButton onClick={onRefresh}>새로고침</RefreshButton>
                </NoCatchTitle>
                <ListConatiner>
                    {list && Array.isArray(list) && list.length > 0 ? (
                        list.map(item => {
                            const serviceType =
                                !item.productInfo || !item.productInfo.usePro || !item.productInfo.useBasic ? '' : item.service === UNEMER ? 'Pro' : 'Basic';
                            const serviceName = (item.productInfo && item.productInfo.name && item.service && item.productInfo.name + serviceType) || '-';
                            return (
                                <ReserveItem
                                    key={item._id.toString()}
                                    serviceType={item.service}
                                    service={serviceName}
                                    name={item.patient}
                                    reserveDate={moment(item.reserveDate).format('MM월 DD일 HH시mm분')}
                                    destination={item.destination || '-'}
                                    shortStarting={item.shortStarting || '-'}
                                    status={item.call.status}
                                    onDetail={() => {
                                        detailHandler(item);
                                    }}
                                />
                            );
                        })
                    ) : (
                        <EmptyList>현재 예약내역이 없습니다.</EmptyList>
                    )}
                </ListConatiner>
            </Inner>
        </FullContainer>
    );
};

const ReserveItem = ({ service, name, reserveDate, shortStarting, destination, serviceType, status, onDetail, usageTime }) => (
    <ItemContainer>
        <TitleSection>
            <ItemColumn>
                <ItemTitle>서비스 명</ItemTitle>
                <ItemContents style={{ color: mainColor }}>{service}</ItemContents>
            </ItemColumn>
        </TitleSection>

        <ContentSection>
            <ItemColumn>
                <ItemTitle>환자 성함</ItemTitle>
                <ItemContents>{name}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>병원 진료 예약 시간</ItemTitle>
                <ItemContents>{reserveDate}</ItemContents>
            </ItemColumn>
            {serviceType === UNEMER && (
                <ItemColumn>
                    <ItemTitle>자택 주소</ItemTitle>
                    <ItemContents>{shortStarting}</ItemContents>
                </ItemColumn>
            )}
            <ItemColumn>
                <ItemTitle>병원 주소</ItemTitle>
                <ItemContents>{destination}</ItemContents>
            </ItemColumn>
        </ContentSection>

        <ItemColumn>
            <DetailButton onClick={onDetail}>
                <span>자세히보기</span>
            </DetailButton>
        </ItemColumn>
    </ItemContainer>
);

const TitleSection = styled.div``;

const ContentSection = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 300px;
    height: 316px;
    overflow-y: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const EmptyList = styled.p`
    font-size: 20px;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 16px;
    position: relative;
`;

const FullContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(to top, #3bc1ff, #5b62f5);
`;

const Inner = styled.div`
    max-width: 1024px;
    height: 100%;
    max-height: 628px;

    margin: 0 auto;
    background-image: url('/assets/images/w_main.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 1080px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (max-width: 768px) {
        padding-top: 40px;
        background: transparent;
    }
`;

const NoCatchTitle = styled.p`
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 24px;
    position: relative;

    @media (max-width: 480px) {
        margin-bottom: 16px;
    }
`;

const RefreshButton = styled.span`
    position: absolute;
    margin-left: 8px;
    top: 16px;

    font-size: 14px;
    color: #161616;
    text-decoration: underline;
    cursor: pointer;
`;

const ListConatiner = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div:nth-child(3n) {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        > div:nth-child(3n) {
            margin-right: 16px;
        }

        > div:nth-child(2n) {
            margin-right: 0px;
        }
    }

    @media (max-width: 480px) {
        > div:nth-child(3n) {
            margin-right: 0px;
        }

        flex-direction: column;
        justify-content: center;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 340px;
    height: 340px;
    overflow: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    padding: 20px;
    margin-bottom: 16px;
    margin-right: 16px;

    border-radius: 4px;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        width: 100%;
        max-width: initial;
        margin-right: 0px;
    }
`;

const ItemTitle = styled.p`
    color: #a7a7a7;
    font-size: ${sizeHandler(12)};
    font-weight: bold;
    margin: 0px;
    padding: 0px;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(11)};
    }
`;

const ItemContents = styled.p`
    font-size: ${sizeHandler(14)};
    font-weight: bold;
    color: #606060;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(12)};
        margin-bottom: 4px;
    }
`;

const ItemColumn = styled.div`
    width: 100%;
    margin-bottom: 6px;

    @media (max-width: 768px) {
        display: ${props => (props.hiddenMobile ? 'none' : 'block')};
        width: 100%;
    }
`;

const DetailButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 44px;

    border-radius: 4px;
    border: 1px solid ${mainColor};
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    span {
        font-size: ${sizeHandler(14)};
        font-weight: bold;
        color: ${mainColor};
        text-align: center;

        @media (max-width: 430px) {
            font-size: ${sizeHandler(12)};
        }
    }

    &:hover {
        background-color: ${mainColor};
        span {
            color: white;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 34px;
        font-size: ${sizeHandler(12)};
    }
`;
