import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../../theme';

const StyledMateContainer = styled.div`
    margin-top: 55px;

    .auth-container {
        // min-height: 100vh;
        // display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.02);
    }

    .navigation-container {
        border-bottom: 1px solid #e6e6e6;
        background-color: white;

        .inner-container {
            padding: 0px;
        }

        .user-info-container {
            display: flex;
            align-items: center;
            padding: 82px 30px;

            .default-info {
                flex: 1;
                display: flex;
                align-items: center;

                div {
                    width: 110px;
                    height: 110px;
                    border-radius: 55px;
                    margin-right: 20px;
                    background-color: #e6e6e6;
                    position: relative;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 110px;
                        object-fit: cover;
                        position: absolute;
                        margin: auto;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                    }

                    span {
                        display: inline-block;
                        position: absolute;
                        margin: auto;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        line-height: 110px;
                        text-align: center;
                        color: #ada5a5;
                    }
                }

                p {
                    flex: 1;
                    font-size: ${sizeHandler(38)};
                    font-weight: bold;
                    letter-spacing: -0.6px;
                    color: rgba(0, 0, 0, 0.8);

                    > span {
                        font-size: ${sizeHandler(38)};
                        font-weight: bold;
                        letter-spacing: -0.6px;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    br {
                        display: block;
                    }

                    @media (max-width: 768px) {
                        font-size: ${sizeHandler(26)};
                        
                        > span {
                            font-size: ${sizeHandler(26)};
                        }
                    }
                }
            }

            .reserve-info {
                flex: 1;

                .title {
                    margin-bottom: 12px;

                    p {
                        font-size: ${sizeHandler(12)};
                        font-weight: bold;
                        color: rgba(145, 145, 145, 0.8);
                    }
                }

                .value-wrapper {
                    display: flex;
                    align-items; center;

                    .value-block {
                        flex: 1;

                        .title {
                            img {
                                height: 14px;
                                margin-right: 4px;
                            }
    
                            span {
                                font-size: ${sizeHandler(14)};
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.8);
                            }
                        }
    
                        .value {
                            font-size: ${sizeHandler(16)};
                            font-weight: bold;
                            color: #3bc1ff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .navigator {
            display: flex;
            align-items: center;
            padding: 0px 30px;

            @media (max-width: 768px) {
                padding: 0px 16px;
            }

            .nav-tab {
                margin-right: 32px;
                padding-bottom: 12px;
                position: relative;

                @media (max-width: 768px) {
                    margin-right: 16px;
                }

                .name {
                    font-size: ${sizeHandler(14)};
                    text-align: center;
                    cursor: pointer;

                    &.active { 
                        font-weight: bold;
                        color: #3bc1ff;
                    }
                }

                span.underline {
                    background-color: #3bc1ff;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;

                    &.active {
                        width: 100%;
                        height: 3px;
                        display: inline-block;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .user-info-container {
                padding: 42px 16px;
                flex-direction: column;
                align-items: flex-start;

                .reserve-info {
                    margin-top: 40px;
                }

                .value-wrapper {
                    flex-direction: column;

                    .value-block:last-child {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .usage-info-container {
        flex: 1;
        // background-color: rgba(0, 0, 0, 0.02);

        .inner-container {
            height: 100%;
            padding: 56px 30px;

            @media (max-width: 768px) {
                padding: 40px 16px;
            }
        }

        .filter-wrapper {
            margin-bottom: 20px;

            .filter {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: rgba(0, 0, 0, 0.4);
                margin-right: 32px;
                cursor: pointer;
            }

            .filter.active {
                color: ${mainColor};
            }
        }
    }

    .user-info-wrapper {
        .wrapper {
            padding: 36px;
            background-color: white;
            border-radius: 4px;
            border: solid 1px #e6e6e6;
            float: left;
            width: calc(50% - 10px);
            
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        
        .title-wrapper {
            font-size: ${sizeHandler(20)};
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 28px;
        }

        .service {
            margin-bottom: 20px;

            p.info {
                font-size: ${sizeHandler(12)};
                font-weight: normal;
                letter-spacing: -0.15px;
                color: rgba(0, 0, 0, 0.7);
            }

            input {
                height: 36px;
                display: inline-block;
                width: 100%;
            }

            button {
                width: 100%;
                height: 48px;
                border-radius: 2px;
                color: white;
            }

            .ant-upload-drag-container {
                .title {
                    margin-top: 12px;
                    font-size: ${sizeHandler(14)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.8);
                }
        
                .reco {
                    font-size: ${sizeHandler(12)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: #a7a7a7;
                }
            }

            .flex-wrapper {
                display: flex;

                p {
                    flex: 1;
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: #606060;
                    cursor: pointer;
                }
            }
        }

        .default-info {
            margin-right: 20px;
        }

        .privacy-info {

            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }

        .etc-info {
            margin-top: 20px;
        }
    }
`;

export const UserPhoto = styled.div`
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    border: 2px dotted #e9e9e9;
    // background-color: #e6e6e6;

    > img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
    }
`;

export const GuideRowWrapper = styled.div`
    width: 100%;
    height: 293px;

    max-width: 372px;

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;

    margin-right: 12px;

    @media (max-width: 768px) {
        > div:last-child {
            margin-right: 0px;
        }
    }
`;

export const GuideRowWrapper2 = styled.div`
    width: 100%;
    height: 293px;

    max-width: 750px;

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;

    margin-right: 12px;

    @media (max-width: 768px) {
        > div:last-child {
            margin-right: 0px;
        }
    }
`;

export const GuideContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-bottom: 56px;

    > div:last-child {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        > div:last-child {
            margin-top: 12px;
        }
    }
`;

export const GuideDescriptionSectionTitle = styled.div`
    margin-top: 0px;
    margin-bottom: 16pxs;

    border-bottom: 1px solid #f2f2f2;
    padding: 32px;
    padding-bottom: 24px;

    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: underline;
    cursor: pointer;

    > img {
        text-decoration: none;
        width: 24px;
        height: 24px;
        object-fit: contain;

        margin-left: 4px;
    }
`;

export const GuideDescriptionSection = styled.div`
    padding: 32px;
    padding-top: 24px;
`;

export const GuideDescriptionSectionContent = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    word-break: keep-all;
`;

export const StudyButton = styled.div`
    margin: 32px auto;

    height: 76px;
    width: fit-content;

    padding: 22px 30px;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 2px #3bc1ff;
    background-color: #ffffff;

    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.5px;
    text-align: center;
    color: #3bc1ff;

    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
        object-fit: contain;

        margin-left: 8px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        height: 52px;
        padding: 12px 24px;
    }
`;

export const AlertMessage = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ff5500;

    max-width: 682px;
    margin: 0 auto;

    word-break: keep-all;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export default StyledMateContainer;
