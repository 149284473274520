import styled from 'styled-components';

import Pallete from '../../theme';

const H1 = styled.h1`
    margin: 0px;
    margin-bottom: 16px;

    font-size: 26px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.5;

    > span {
        font-size: 26px;
        font-weight: ${props => (props.bold ? 'bold' : 'normal')};
        line-height: 1.5;
    }
`;

const H2 = styled.h2`
    margin: 0px;
    margin-bottom: 16px;

    font-size: 23px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.5;
`;

const H3 = styled.h3`
    margin: 0px;
    margin-bottom: 12px;

    font-size: 18px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.5;
`;

const H4 = styled.h4`
    margin: 0px;
    margin-bottom: 8px;

    font-size: 16px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.4;
`;

const H5 = styled.h5`
    margin: 0px;
    margin-bottom: 8px;

    font-size: 13px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.4;
`;

const H6 = styled.h6`
    margin: 0px;
    margin-bottom: 4px;

    font-size: 12px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.4;
`;

const Text = styled.p`
    margin: 0px;
    margin-bottom: 0px;

    font-size: 11px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    line-height: 1.4;
`;

export { H1, H2, H3, H4, H5, H6, Text };
