import React from "react";
import Modal from "react-modal";
import styled from 'styled-components';
import { Button, message, Table } from 'antd';

import CardStyledContainer from "../Auth/AuthPages/Card/card.styled";
import { HalfWidthButton } from "../../Components/Common/button";
import { DivisionLine } from "../../Components/Common/input";
import { getCardInfo } from "../../Lib/Api/auth";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(15, 15, 15, 0.79)",
    },
    content: {
        position: "absolute",
        top: "30px",
        height: "40%",
        border: "1px solid #ccc",
        background: "#ffffff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
    }
}

const cardColumn = ({ onCheck }) => {
    return [
        {
            title: '카드명',
            dataIndex: 'cardName',
            align: 'center',
            width: '80px'
        },
        {
            title: '카드번호',
            dataIndex: 'cardNum',
            align: 'center',
            render: cardNum => <span>**** - **** - **** - {cardNum.slice(15, 19)}</span>
        },
        {
            title: '선택',
            align: 'center',
            width: '60px',
            dataIndex: '_id',
            render: (_id, data) =>
                <Button
                    style={{ width: '60px', height: '30px' }}
                    onClick={() => { onCheck(data) }}
                >
                    선택
                </Button>
        },
    ]
}

class CardListComponent extends React.PureComponent {
    state = {
        cardInfo: {},
        cardList: [],
        cardId: '',
        cardInfo: null
    };

    componentWillUnmount = () => {
        this.setState({ cardInfo: null });
    };

    onChoose = () => {
        const { cardInfo } = this.state;
        console.log(cardInfo)

        getCardInfo({ cardId: cardInfo._id }).then(resolve => {
            console.log(resolve)
            const { data } = resolve;
            if (resolve && resolve.data && resolve.data.message === 'success') {
                if (cardInfo && cardInfo.cardName) delete data.cardName;
                if (cardInfo && cardInfo.cardNum) delete data.cardNum;
                if (cardInfo && cardInfo.year) delete data.year;
                if (cardInfo && cardInfo.month) delete data.month;

                this.setState({ cardInfo: resolve.data.data })
            } else {
                message.info('선택하신 카드정보가 존재하지 않습니다.');
            }
        });
    };

    render() {
        // console.log(this.props)
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <CardStyledContainer>
                    <div className='inner-container'>
                        <div className='profile-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>결제 카드 목록</WideModalTitle>
                                    <AlertText>
                                        * 결제하실 카드를 선택할 수 있습니다. <br />
                                        [ 마이페이지 ]에서 결제 카드를 등록하실 수 있습니다.
                                    </AlertText>
                                </TitleSection>

                                <br />

                                <DivisionLine />

                                <div className='profileList'>
                                    <Table
                                        dataSource={this.props.list}
                                        columns={cardColumn({
                                            onCheck: (data) => {
                                                this.props.onUpdateCard(data);
                                                this.props.onClose()
                                            }
                                        })}
                                        rowKey='_id'
                                        pagination={{
                                            pageSize: 3,
                                            hideOnSinglePage: true,
                                            size: 'small',
                                        }}
                                    />
                                </div>

                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff'
                                        onClick={this.props.onClose}
                                    >
                                        닫기
                                    </HalfWidthButton>
                                </ButtonWrap>
                            </div>
                        </div>
                    </div>
                </CardStyledContainer >
            </Modal >
        );
    }
};

export default CardListComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;