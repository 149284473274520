import styled from 'styled-components';

export const ValueBox = styled.div`
  margin-bottom: 16px;

  .switch {
    margin-right: 10px;
  }

  .slide-img-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    margin-bottom: 10px;

    .slide-img-desc {
      bottom-margin: 20px;
    }

    .slide-img-preview {
        position: relative;
  
        .upload-img {
          max-width: 200px;
          max-height: auto;
          margin-top: 10px;
          pointer-evnets: none;
        }
  
        .icon-div {
          position: absolute;
          padding-bottom: 30px;
          right: 0;
  
          .delete-btn {
            border: none;
            font-size: 18px;
          }
        }
      }
    }
  }
`;

const ReservePageContainer = styled.div`
    width: 100%;
    min-height: 100vh;

    padding-top: 54px;
    // padding-bottom: 54px;
`;

const ResrevePageInnerContainer = styled.div`
    width: 1120px;
    margin: 0 auto;

    ${props => props.bg && `background-image: url(${props.bg}); background-size: contain; background-position: 50% 80%; background-repeat: no-repeat;`}

    padding-top: ${props => (props.smallPadding ? '0px' : '48px')};
    padding-bottom: ${props => (props.smallPadding ? '0px' : '188px')};

    @media (max-width: 768px) {
        width: 100%;
        padding-top: 24px;
        padding-bottom: 30px;
        ${props => props.bg && `background-image: transparent; background-color: ${props.mobileBg ? props.mobieBg : '#ffffff'};`}
    }
`;

const ResrevePageHalfInnerContainer = styled.div`
    margin: 0 auto;
    width: 1120px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;

    ${props => props.bg && `background-image: url(${props.bg}); background-size: cover; background-position: 50% 80%; background-repeat: no-repeat;`}

    padding-top: ${props => (props.smallPadding ? '0px' : '10px')};
    padding-bottom: ${props => (props.smallPadding ? '0px' : '30px')};

    @media (max-width: 768px) {
        width: 100%;
        padding-top: 24px;
        padding-bottom: 48px;
        ${props => props.bg && `background-image: transparent; background-color: ${props.mobileBg ? props.mobieBg : '#ffffff'};`}
    }
`;

const ReserveTopCover = styled.div`
    background: ${props =>
        props.isMain ? `url('/assets/images/w_reserve_web.png') no-repeat top center` : `url('/assets/images/w_reserve_m.png') no-repeat top center`};
    background-size: cover;

    @media (max-width: 768px) {
        ${props => !props.isMain && `display: none;`}
    }
`;
export { ReserveTopCover, ReservePageContainer, ResrevePageInnerContainer, ResrevePageHalfInnerContainer };

const TopBannerSubDescription = styled.div`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ffffff;
    margin-top: 8px;
`;

const TopBannerSection = styled.div`
    width: 100%;

    padding-top: ${props => (props.isMain ? '110px' : '86px')};
    padding-bottom: ${props => (props.isMain ? '0px' : '67px')};

    text-align: center;

    @media (max-width: 768px) {
        text-align: center;
        ${props => props.bg === '#f9f9f9' && `display: none;`}
        padding-top: 56px;
    }
`;

const TopBannerText = styled.h3`
    margin-top: 0px;
    margin-bottom: 2px;

    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.7px;
    text-align: center;
    color: #ffffff;

    > span {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.7px;
        text-align: left;
        color: #ffffff;
    }

    @media (max-width: 768px) {
        font-size: 24px;

        > span {
            font-size: 24px;
        }
    }
`;

const TopBannerDescription = styled.div`
    padding: 8px 24px;
    width: fit-content;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: center;
    background-color: #3bc1ff;
    color: #ffffff;

    border-radius: 50vh;
    margin: 0 auto;
    margin-bottom: 16px;
`;

export { TopBannerSection, TopBannerText, TopBannerDescription };

const StepSectionContainer = styled.div`
    padding-top: 32px;
    padding-bottom: 32px;

    background-color: #ffffff;

    @media (max-width: 768px) {
        display: none;
    }
`;

const StepButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const StepButton = styled.button`
    width: 200px;
    height: 64px;
    line-height: 64px;
    border: 0px;
    outline: none;

    border-radius: 50vh;
    cursor: not-allowed;

    text-align: center;
    font-size: 18px;
    letter-spacing: -0.1px;

    ${props =>
        props.active
            ? `
    background-color: #3bc1ff;
    color: #ffffff;
    `
            : `
    background-color: #e6e6e6;
    color:#afb0b2;`}

    @media (max-width: 768px) {
        width: 192px;
        height: 52px;
        line-height: 52px;
        font-size: 14px;
        letter-spacing: normal;
    }
`;

const UnderLineText = styled.span`
    cursor: pointer;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: #3bc1ff;

    text-decoration: underline;
`;

const StepArrow = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;

    margin-left: 8px;
    margin-right: 8px;

    @media (max-width: 768px) {
        transform: rotate(90deg);
        widteh: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
`;

export { UnderLineText, TopBannerSubDescription, StepSectionContainer, StepButtonWrapper, StepButton, StepArrow };

const NextStepGudieContainer = styled.div`
    width: 100%;

    padding-top: 56px;
    padding-bottom: 42px;

    ${props => props.useBg && `background-color: rgba(242,242,242,0.3);`}
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    // margin-bottom: 48px;

    @media (max-width: 768px) {
        padding-top: 24px;
        padding-bottom: 24px;

        // margin-bottom: 24px;
    }
`;

const NextStepEmoj = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
    }
`;

const NextStepTitle = styled.h4`
    margin-top: 24px;
    margin-bottom: 16px;

    font-size: 36px;
    line-height: 48px;
    color: ${props => props.color};
    letter-spacing: -0.7px;
    text-align: center;
    font-weight: bold;

    @media (max-width: 768px) {
        margin-top: 16px;

        font-size: 22px;
        line-height: 30px;
        letter-spacing: -0.5px;
    }
`;

const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const DesktopBr = styled.br`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

const NextStepArrowDown = styled.img`
    width: 48px;
    height: 48px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 24px;
        height: 24px;
    }
`;

export { NextStepGudieContainer, NextStepEmoj, NextStepTitle, NextStepArrowDown };

const ServiceSectionContainer = styled.div`
    position: relative;
`;

const ServiceSectionTitle = styled.h5`
    font-size: 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const ServiceSectionDescription = styled.p`
    margin-bottom: 52px;

    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: -0.2px;
    line-height: 28px;

    @media (max-width: 768px) {
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.3px;

        margin-bottom: 48px;
    }
`;

export { ServiceSectionContainer, ServiceSectionTitle, ServiceSectionDescription };

const ServiceCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 80px;

    > div:nth-child(5n) {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        padding-left: 16px;
        padding-right: 16px;

        > div:nth-child(5n) {
            margin-right: 12px;
        }

        > div:nth-child(2n) {
            margin-right: 0px;
        }
    }
`;

const ServiceCardWrapper = styled.div`
    width: 216px;
    height: 234px;

    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: ${props => (props.on ? '4px solid #3bc1ff' : '1px solid #e6e6e6')};
    padding: ${props => (props.on ? '13px' : '16px')};
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 10px;
    margin-bottom: 10px;

    > img {
        width: 144px;
        height: 144px;
        object-fit: contain;

        margin-bottom: 4px;
    }

    @media (max-width: 768px) {
        width: calc(50% - 6px);
        height: 195px;

        margin-right: 12px;
        margin-bottom: 10px;

        padding: 4px;

        padding-top: ${props => (props.on ? '12px' : '15px')};
        padding-bottom: ${props => (props.on ? '12px' : '15px')};

        > img {
            // width: 100%;
            // max-height: 120px
            width: 120px;
            height: 120px;

            margin-bottom: 2px;
        }
    }
`;

const ServiceCardButton = styled.div`
    display: inline-block;

    width: 100%;
    max-width: 184px;
    height: 48px;
    line-height: 48px;

    cursor: pointer;

    font-size: 18px;
    letter-spacing: -0.1px;
    color: #3bc1ff;
    background-color: #ffffff;
    border-radius: 50vh;
    text-align: center;
    border: 1px solid #afb0b2;

    &:hover {
        transition: all 0.25s;
        color: #ffffff;
        background-color: #3bc1ff;
    }

    &.selected {
        color: #ffffff;
        background-color: #3bc1ff;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        max-width: 130px;

        height: 40px;
        line-height: 40px;

        &:hover {
            transition: none;
            color: #3bc1ff;
            background-color: #ffffff;
        }

        &.selected {
            color: #ffffff;
            background-color: #3bc1ff;
        }
    }
`;

export { ServiceCardList, ServiceCardWrapper, ServiceCardButton };

const ZzzServiceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
`;

const ZzzArt = styled.img`
    width: 200px;
    height: 160px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 175px;
        height: 140px;

        margin-bottom: 16px;
    }
`;

const ZzzDescription = styled.div`
    margin-bottom: 8px;

    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.1px;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: center;
    }
`;

export { ZzzServiceWrapper, ZzzArt, ZzzDescription };

const AlzheServiceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
`;

const AlzheArt = styled.img`
    width: 200px;
    height: 160px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 175px;
        height: 140px;

        margin-bottom: 16px;
    }
`;

const AlzheDescription = styled.div`
    margin-bottom: 8px;

    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.1px;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: center;
    }
`;

export { AlzheServiceWrapper, AlzheArt, AlzheDescription };

const ServiceTypeSectionContainer = styled.div`
    width: 100%;
    position: relative;

    padding-top: 36px;
    ${props => props.gap && `padding-bottom: 54px;`}

    ${props => props.bg && `background-image: url(${props.bg}); background-size: contain; background-position: right; background-repeat: no-repeat;`}

    @media (max-width: 768px) {
        ${props => props.bg && `background-image: transparent; background-color: ${props.mobileBg ? props.mobieBg : '#ffffff'};`}
    }
`;

const ServiceTypeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > div:last-child {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        > div:last-child {
            margin-bottom: 0px;
            margin-right: auto;
        }
    }
`;

export { ServiceTypeSectionContainer, ServiceTypeWrapper };

const ServiceTypeCardWrapper = styled.div`
    width: 368px;
    height: 410px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: 4px solid ${props => (props.selected ? '#3bc1ff' : '#e6e6e6')};

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 8px;

    @media (max-width: 768px) {
        margin-right: auto;
        margin: 0 auto;
        margin-bottom: 10px;

        width: calc(100% - 32px);
        height: auto;
        border: 1px solid ${props => (props.selected ? '#3bc1ff' : '#e6e6e6')};
    }
`;

const ServiceTypeCardImg = styled.img`
    width: 140px;
    height: 140px;
    object-fit: cover;
`;

const ServiceTypeName = styled.span`
    margin-top: 11px;
    margin-bottom: 8px;

    font-size: 24px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.5px;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 1.42;
        letter-spacing: -0.5px;

        margin-top: 0px;
        margin-bottom: 0px;
    }
`;

const ServiceTypeDescription = styled.div`
    word-break: keep-all;
    font-size: 15px;
    text-align: center;
    letter-spacing: -0.3px;
    margin-bottom: 36px;

    max-width: 204px;
`;

const NextStepButton = styled.div`
    width: 280px;
    height: 72px;
    line-height: 72px;
    text-align: center;

    margin: 0 auto;
    margin-top: 100px;

    border-radius: 50vh;
    cursor: pointer;

    background-color: ${props => (props.on ? '#3bc1ff' : '#e6e6e6')};
    color: ${props => (props.on ? '#ffffff' : '#afb0b2')};
    font-size: 22px;
    letter-spacing: -0.5px;

    @media (max-width: 768px) {
        height: 55px;
        line-height: 55px;
        font-size: 16px;
        letter-spacing: -0.1px;

        margin-top: 56px;
    }
`;

const LastStepButton = styled(NextStepButton)`
    @media (max-width: 768px) {
        margin-top: 32px;
        margin-bottom: 32px;
    }
`;
export { MobileBr, DesktopBr, NextStepButton, LastStepButton, ServiceTypeCardWrapper, ServiceTypeCardImg, ServiceTypeName, ServiceTypeDescription };

const FormContainer = styled.div`
    width: 100%;
    background-color: #f9f9f9;

    padding-top: 68px;
    padding-bottom: 80px;

    background-image: url('/assets/images/artwork_both_bg.png');
    background-size: contain;
    background-position: 50% 70%;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        background: transparent;

        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

const FormWrapper = styled.div`
    width: 646px;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    padding: 48px 32px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0px;
        box-shadow: unset;
        border: 0px;

        padding: 32px 16px;
        margin-bottom: 0px;
    }

    .type-button-wrapper {
        width: 100%;
        height: 48px;

        background-color: white;
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        display: flex;
        align-items: center;

        > .type-button {
            width: 50%;
            height: 100%;
            line-height: 48px;

            text-align: center;
            cursor: pointer;

            font-size: 16px;
            letter-spacing: -0.2px;
            color: rgba(0, 0, 0, 0.2);
        }

        > .type-button.active {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 4px;
            color: #f9f9f9;
        }
    }
`;

const FormMainTitle = styled.h3`
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.7px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-top: 0px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 1.42;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
    }
`;

const FormMainAlert = styled.p`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;

    margin-top: 0px;
    margin-bottom: 32px;

    word-break: keep-all;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.58;
        letter-spacing: -0.3px;
    }
`;

export { FormContainer, FormWrapper, FormMainTitle, FormMainAlert };

const ServiceName = styled.h5`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: left;
    color: #3bc1ff;

    margin-bottom: 40px;
`;

const Sexes = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    line-height: 44px;

    > span {
        display: inline-block;
        cursor: pointer;

        flex: 1;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #e9e9e9;

        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        letter-spacing: -0.2px;

        margin-right: 8px;
    }

    > span.active {
        background-color: #3bc1ff;
        color: white;
    }

    > span:last-child {
        margin-right: 0px;
    }
`;

const LabelDescription = styled.span`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: #afb0b2;
    margin-left: 4px;
`;

const FlexFormWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    max-width: 1120px;
    margin: 0 auto;

    > div:first-child {
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        > div:first-child {
            margin-right: 0px;
            margin-bottom: 0px;
        }
    }
`;

const ResultFormWrapper = styled(FormWrapper)`
    width: auto;
    flex: 1;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const LabelAndValueRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props => props.borderColor && `border-bottom: 1px solid ${props.borderColor};`}

    padding-top: 8px;
    padding-bottom: 8px;
`;

const LabelValue = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
        font-size: 16px;
        ine-height: 1.42;
    }
`;

const PriceValue = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: right;
    color: #3bc1ff;

    @media (max-width: 768px) {
        font-size: 16px;
        ine-height: 1.42;
    }
`;

const PriceDetailLabel = styled.span`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    padding-left: 12px;
    position: relative;

    > span {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.3px;
        text-align: left;
        color: #cecfd0;
    }
`;

const PriceDetailValue = styled.span`
    font-size: 16px;
    font-weight: ${props => (props.value ? 'bold' : 'normal')};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.2px;
    text-align: right;
    color: ${props => (props.value ? '#3bc1ff' : '#afb0b2')};
`;

const DashAndText = styled.div`
    display: flex;
    align-items: flex-start;

    img {
        width: 16px;
        height: 16px;
        margin-top: 4px;
        margin-right: 8px;
    }

    > a {
        color: #3bc1ff;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: -0.3px;
        text-align: left;
    }

    > span {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.3px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
        word-break: keep-all;
    }

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    word-break: keep-all;

    ${props => props.alert && `color: #ff5500;`}

    @media (max-width: 768px) {
        font-size: 14px;

        > span {
            font-size: 14px;
        }
    }
`;
export {
    DashAndText,
    LabelValue,
    PriceDetailLabel,
    PriceDetailValue,
    ServiceName,
    LabelAndValueRow,
    PriceValue,
    Sexes,
    LabelDescription,
    FlexFormWrapper,
    ResultFormWrapper,
};

const CongraturateLogo = styled.div`
    width: 100%;
    height: auto;

    text-align: center;

    margin-top: 12px;
    margin-bottom: 12px;

    > img {
        width: 200px;
        height: 200px;
    }
`;

const DoneResultWrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
`;

const MobileStepImage = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin-top: 32px;
        text-align: center;
        width: 100%;

        > img {
            margin: 0 auto;
            width: 174px;
            height: 174px;
        }
    }
`;

export { CongraturateLogo, MobileStepImage, DoneResultWrapper };
