import React from 'react';
import { connect } from 'react-redux';

// component
import AdvertisementStyledContainer from './advertisement.styled';
import Advertisement from './advertisement';

class AdvertisementContainer extends React.PureComponent {
    render() {
        return (
            <AdvertisementStyledContainer component={this.props.isComponent || undefined}>
                <Advertisement {...this.props} />
            </AdvertisementStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementContainer);
