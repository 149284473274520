import React from 'react';
import { connect } from 'react-redux';

// component
import LoginStyledContainer from './login.styled';
import Login from './login';
import { login, editLoginForm, editRegisterForm, checkUserId } from '../../../../Reducer/Modules/auth';

class LoginPage extends React.PureComponent {
    componentDidMount = () => {
        const { auth } = this.props;
        const userId = localStorage.getItem('id');
        if ((auth && auth.id) || userId) return this.props.history.goBack('/');
    };
    render() {
        return (
            <LoginStyledContainer>
                <Login {...this.props} />
            </LoginStyledContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapDispatchToProps = dispatch => ({
    editRegisterForm: ({ type, value }) => dispatch(editRegisterForm({ type, value })),
    login: ({ id, password, userType, type }) => dispatch(login({ id, password, userType, type })),
    checkUserId: ({ id, userType, type }) => dispatch(checkUserId({ id, userType, type })),
    editLoginForm: ({ type, value }) => dispatch(editLoginForm({ type, value })),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
