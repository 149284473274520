import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Timeline } from 'antd';
import moment from 'moment';

// components
import { TabContainer, Tab } from '../Components/Common/tab';
import { FullWidthButton, HalfWidthButton, ButtonWrapper } from '../Components/Common/button';
import {
    DivisionLine,
    WithmateLabel,
    WithmateInput,
    HalfWithmateInput,
    WithmateTextarea,
    WithmateSelect,
    FlexWrapper,
    FlexDiv,
    HalfWithmateSelect,
} from '../Components/Common/input';

import { Step1, Step2, Step3, ContractdStep_1, ContractStep_2, ContractStep_3 } from './Reserve/step';
import { EditStep1, EditStep2, ContractEditStep_1, ContractEditStep_2 } from './Reserve/editStep';
import ColorPallete from '../theme';

const customStyles = {
    overlay: {
        display: 'flex',

        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        alignItems: 'center',
        justifyContent: 'center',

        zIndex: '99999999999999 !important',
    },
    content: {
        width: '100%',
        height: '100%',
        boxShadow: 'initial',
        border: '0px',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        backgroundColor: 'transparent',
        padding: '0px',

        display: 'flex',
        alignItems: 'center',
    },
};

class CustomModal extends React.PureComponent {
    state = {};

    componentDidMount = () => {
        window.addEventListener('resize', this.onCheckSize, false);
        if (this.props.visible) {
            document.body.style.overflow = 'hidden';
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onCheckSize, false);
        document.body.style.overflow = 'unset';
    };

    onCheckSize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const innerContent = document.getElementById(this.props.childrenId);

        if (windowWidth <= 480) {
            let top = innerContent.clientHeight / windowHeight >= 0.8 ? '100px' : 'initial';
            let bottom = innerContent.clientHeight / windowHeight >= 0.8 ? 'initial' : '0px';

            if (this.props.childrenId && this.props.childrenId.includes('full-header')) {
                top = '0px';
                bottom = '0px';
            }

            innerContent.style.maxWidth = '424px';
            innerContent.style.top = top;
            innerContent.style.bottom = bottom;

            this.setState({
                top,
                bottom,
            });
        } else {
            innerContent.style.maxWidth = this.props.childrenId && this.props.childrenId.includes('wide') ? '720px' : '424px';
            innerContent.style.top = windowHeight * 0.15 + 'px';
            innerContent.style.marginBottom = windowHeight * 0.15 + 'px';
        }
    };

    render() {
        return (
            <Modal style={{ ...customStyles }} isOpen={this.props.visible} onAfterOpen={this.onCheckSize}>
                {this.props.children}
            </Modal>
        );
    }
}

export const CommonWithmateModal = ({ visible, config = {}, onCancel, onOk }) => {
    return (
        <CustomModal visible={visible} childrenId='common-withmate-modal'>
            <ModalInnerContainer id='common-withmate-modal'>
                {!config.noClose && <CloseButton src={'/assets/images/close.png'} onClick={onCancel} />}

                <TitleSection style={{ textAlign: 'center' }}>
                    <CommonModalTitle style={{ textAlign: 'center' }}>{config && config.title ? config.title : ''}</CommonModalTitle>
                    <CommonModalSubtitle style={{ marginTop: 0, wordBreak: 'keep-all' }}>
                        {config && config.description ? config.description : ''}
                    </CommonModalSubtitle>
                </TitleSection>

                <ContentSection style={{ textAlign: 'center' }}>
                    {/* contents */}
                    {config && config.content ? config.content : null}
                    <ButtonWrapper half={config && config.cancelText && onCancel && typeof onCancel === 'function'}>
                        {/* cancel */}
                        {config && config.cancelText && onCancel && typeof onCancel === 'function' ? (
                            <FullWidthButton bg={ColorPallete.dark70} onClick={onCancel}>
                                {config && config.cancelText ? config.cancelText : '취소'}
                            </FullWidthButton>
                        ) : null}
                        {/* confirm */}
                        <FullWidthButton bg={ColorPallete.blue50} small onClick={onOk && typeof onOk === 'function' ? onOk : onCancel}>
                            {config && config.confirmText ? config.confirmText : '확인 및 저장'}
                        </FullWidthButton>
                    </ButtonWrapper>
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const SampleModal = ({ visible, visibleHandler, inputHandler, inputDeepHandler, data, onSave }) => {
    return (
        <CustomModal visible={visible} childrenId='sample-new-modal'>
            <ModalInnerContainer id='sample-new-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <TitleSection>
                    <CommonModalTitle></CommonModalTitle>
                </TitleSection>

                <ContentSection></ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const NoramlReserveModal = ({ visible, visibleHandler, inputHandler, inputDeepHandler, data, setDestination, setStarting, onSave }) => {
    const { step = 1 } = data;

    return (
        <CustomModal visible={visible} childrenId='normal-reserve-modal'>
            <ModalInnerContainer id='normal-reserve-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />

                <ContentSection>
                    {step === 1 && <Step1 data={data} inputDeepHandler={inputDeepHandler} setDestination={setDestination} setStarting={setStarting} />}

                    {step === 2 && <Step2 data={data} inputDeepHandler={inputDeepHandler} />}

                    {step === 3 ? <Step3 data={data} inputDeepHandler={inputDeepHandler} onReserve={onSave} /> : null}
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const HappyReserveModal = ({ visible, visibleHandler, inputHandler, inputDeepHandler, data, setDestination, setStarting, onSave, onDropSave }) => {
    const { step = 1 } = data;

    return (
        <CustomModal visible={visible} childrenId='normal-reserve-modal'>
            <ModalInnerContainer id='normal-reserve-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />

                <ContentSection>
                    {step === 1 && (
                        <ContractdStep_1
                            data={data}
                            inputDeepHandler={inputDeepHandler}
                            setDestination={setDestination}
                            setStarting={setStarting}
                            onDropoReserve={onDropSave}
                        />
                    )}

                    {step === 2 && <ContractStep_2 data={data} inputDeepHandler={inputDeepHandler} />}

                    {step === 3 ? <ContractStep_3 data={data} inputDeepHandler={inputDeepHandler} onReserve={onSave} /> : null}
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const NoramlReserveEditModal = ({ visible, visibleHandler, data, inputDeepHandler, onModify }) => {
    const { step = 1 } = data;
    return (
        <CustomModal visible={visible} childrenId='normal-edit-reserve-modal'>
            <ModalInnerContainer id='normal-edit-reserve-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <ContentSection>
                    {step === 1 && <EditStep1 data={data} inputDeepHandler={inputDeepHandler} />}

                    {step === 2 && <EditStep2 data={data} inputDeepHandler={inputDeepHandler} onModify={onModify} onClose={visibleHandler} />}
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const HappyReserveEditModal = ({ visible, visibleHandler, data, inputDeepHandler, onModify }) => {
    const { step = 1 } = data;
    return (
        <CustomModal visible={visible} childrenId='normal-edit-reserve-modal'>
            <ModalInnerContainer id='normal-edit-reserve-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <ContentSection>
                    {step === 1 && <ContractEditStep_1 data={data} inputDeepHandler={inputDeepHandler} />}

                    {step === 2 && <ContractEditStep_2 data={data} inputDeepHandler={inputDeepHandler} onClose={visibleHandler} />}
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const NewHandleReportModal = ({ visible, visibleHandler, data, inputHandler, onSave }) => {
    return (
        <CustomModal visible={visible} childrenId='wide-edit-report-modal'>
            <ModalInnerContainer id='wide-edit-report-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <TitleSection>
                    <WideModalTitle>서비스 리포트 작성</WideModalTitle>
                    <AlertText>
                        *서비스 리포트를 작성하지 않을 경우 서비스 종료로 인정되지 않습니다.
                        <br />
                        환자와 헤어진 후 1시간 내에 꼭 리포트를 작성해주세요. 리포트 작성 완료 후 정산이 진행됩니다*
                    </AlertText>
                </TitleSection>
                <ContentSection>
                    <DivisionLine />

                    <WithmateLabel required>서비스 진행 중 특이사항</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) 금일 이동 중 차가 많이 막혀 서비스 시간이 늘어났습니다.
                                    금일 진료와는 상관없이 환자분의 잔기침이  많았습니다.
                                    금일 내시경 검사가 밀려 예약된 시간보다 늦게 진료가 시작되었습니다.'
                        value={data.description || null}
                        onChange={ev => {
                            inputHandler({ type: 'description', value: ev.target.value });
                        }}
                    />

                    <DivisionLine />

                    <WithmateLabel required>진료 및 치료 내용</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) 환자분의 증상은 점차 호전되고 있으며, 다음 진료 간 최종 완치 유무를 결정한다고 합니다. 
                                        또, 따로 물어보셨던 안마의자 사용은 삼가라는 답변을 들었습니다.'
                        value={data.care || null}
                        onChange={ev => {
                            inputHandler({ type: 'care', value: ev.target.value });
                        }}
                    />

                    <DivisionLine />

                    <WithmateLabel>복약지도 사항</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) (약이름) : 1일 3회 식 후 복용, 해당 약 복용 중 매운 음식 자제'
                        value={data.medicine || null}
                        onChange={ev => {
                            inputHandler({ type: 'medicine', value: ev.target.value });
                        }}
                    />

                    <DivisionLine />

                    <WithmateLabel>다음 진료 일정 정리</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) 다음 진료일 > 서울대병원 2021년 6월 15일 (화) 14시 20분 / CT 촬영 및 혈액 검사 예정'
                        value={data.nextSchedule || null}
                        onChange={ev => {
                            inputHandler({ type: 'nextSchedule', value: ev.target.value });
                        }}
                    />

                    <DivisionLine />

                    <WithmateLabel>메이트 전달 사항</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) 오늘 병원 동행 서비스를 진행하며 어머님이 참 밝고 용감한 분이라는 생각이 많이 들었습니다. 
                                    시종일관 웃음으로 저를 대해주셨고, 치료와 진료 또한 긍정적인 마음으로 받으셨습니다. 
                                    어머님의 건강을 기원하며, 오늘 서비스를 마무리하겠습니다. 감사합니다.'
                        value={data.mateComment || null}
                        onChange={ev => {
                            inputHandler({ type: 'mateComment', value: ev.target.value });
                        }}
                    />

                    <DivisionLine />

                    <WithmateLabel required>의료 기관 및 의사 평가</WithmateLabel>
                    <WithmateTextarea
                        small
                        placeholder='ex) 서비스에 만족했습니다. 
                                        의사 성함 : 만족스러웠던 이유'
                        value={data.doctorComment || null}
                        onChange={ev => {
                            inputHandler({ type: 'doctorComment', value: ev.target.value });
                        }}
                    />

                    <DivisionLine big />

                    <ButtonWrapper>
                        <HalfWidthButton style={{ marginRight: '8px', color: '#afb0b2' }} bg='#e6e6e6' onClick={visibleHandler}>
                            닫기
                        </HalfWidthButton>
                        <HalfWidthButton bg='#3bc1ff' onClick={onSave}>
                            리포트 등록하기
                        </HalfWidthButton>
                    </ButtonWrapper>
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

export const NoReportModal = ({ visible, visibleHandler, onNext }) => {
    return (
        <CustomModal visible={visible} childrenId='viewer-report-modal'>
            <ModalInnerContainer id='viewer-report-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <TitleSection>
                    <WideModalTitle>서비스 리포트 미완료 예약 알림</WideModalTitle>
                </TitleSection>
                <ContentSection>
                    <DivisionLine big />

                    <p>서비스 리포트 작성을 완료하지않은 수행 이력이 있습니다. </p>
                    <p>서비스 리포트 등록을 완료하고 서비스 수행 대금을 수령해보세요.</p>

                    <DivisionLine big />

                    <ButtonWrapper>
                        <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff' onClick={visibleHandler}>
                            리포트 작성하러 가기
                        </HalfWidthButton>
                    </ButtonWrapper>
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};
export const ReportMoal = ({ visible, visibleHandler, data }) => {
    const { report, timeline, selectedServiceName } = data;

    return (
        <CustomModal visible={visible} childrenId='wide-viewer-report-modal'>
            <ModalInnerContainer id='wide-viewer-report-modal'>
                <CloseButton src={'/assets/images/close.png'} onClick={visibleHandler} />
                <TitleSection>
                    <WideModalTitle>서비스 리포트</WideModalTitle>
                </TitleSection>
                <ContentSection>
                    <DivisionLine big />

                    <ReportInfoBlock style={{ border: '0px' }}>
                        <p className='title'>{moment(report.createdAt).format('YYYY년 MM월 DD일')}</p>
                        <ReportDescription>
                            금일 {selectedServiceName} 서비스는
                            <span style={{ color: '#3bc1ff', fontWeight: 'bold' }}>
                                총 {moment(timeline.complete.time).diff(timeline.receipt.time, 'minutes')}분
                            </span>
                            동안 진행됐습니다.
                        </ReportDescription>
                    </ReportInfoBlock>

                    <Timeline mode={'left'}>
                        {timeline && timeline.receipt && timeline.receipt.time && (
                            <Timeline.Item label={moment(timeline.receipt.time).format('HH:mm').toString()}>고객님과 메이트가 만났습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.start && timeline.start.time && (
                            <Timeline.Item label={moment(timeline.start.time).format('HH:mm').toString()}>병원으로 출발했습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.arrived && timeline.arrived.time && (
                            <Timeline.Item label={moment(timeline.arrived.time).format('HH:mm').toString()}>병원에 도착했습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.careStart && timeline.careStart.time && (
                            <Timeline.Item label={moment(timeline.careStart.time).format('HH:mm').toString()}>진료를 시작했습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.careDone && timeline.careDone.time && (
                            <Timeline.Item label={moment(timeline.careDone.time).format('HH:mm').toString()}>
                                진료가 종료됐습니다. {moment(timeline.careDone.time).diff(moment(timeline.careStart.time).toDate(), 'minutes')}분 동안
                                진료했습니다.
                            </Timeline.Item>
                        )}

                        {timeline && timeline.treatmentStart && timeline.treatmentStart.time && (
                            <Timeline.Item label={moment(timeline.treatmentStart.time).format('HH:mm').toString()}>치료를 시작했습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.treatmentDone && timeline.treatmentDone.time && (
                            <Timeline.Item label={moment(timeline.treatmentDone.time).format('HH:mm').toString()}>
                                치료가 종료됐습니다. {moment(timeline.treatmentDone.time).diff(moment(timeline.treatmentStart.time).toDate(), 'minutes')}분 동안
                                치료했습니다.
                            </Timeline.Item>
                        )}

                        {/* 진료 시작/종료(두 번째) */}
                        {timeline && timeline.careStart_second && timeline.careStart_second.time && (
                            <Timeline.Item label={moment(timeline.careStart_second.time).format('HH:mm').toString()}>
                                진료(두 번째)를 시작했습니다.
                            </Timeline.Item>
                        )}
                        {timeline && timeline.careDone_second && timeline.careDone_second.time && (
                            <Timeline.Item label={moment(timeline.careDone_second.time).format('HH:mm').toString()}>
                                진료(두 번째)가 종료됐습니다.{' '}
                                {moment(timeline.careDone_second.time).diff(moment(timeline.careStart_second.time).toDate(), 'minutes')}분 동안 진료했습니다.
                            </Timeline.Item>
                        )}
                        {/* 치료 시작/종료(두 번째) */}
                        {timeline && timeline.treatmentStart_second && timeline.treatmentStart_second.time && (
                            <Timeline.Item label={moment(timeline.treatmentStart_second.time).format('HH:mm').toString()}>
                                치료(두 번째)를 시작했습니다.
                            </Timeline.Item>
                        )}
                        {timeline && timeline.treatmentDone_second && timeline.treatmentDone_second.time && (
                            <Timeline.Item label={moment(timeline.treatmentDone_second.time).format('HH:mm').toString()}>
                                치료(두 번째)가 종료됐습니다.{' '}
                                {moment(timeline.treatmentDone_second.time).diff(moment(timeline.treatmentStart_second.time).toDate(), 'minutes')}분 동안
                                치료했습니다.
                            </Timeline.Item>
                        )}

                        {/* 진료 시작/종료(세 번째) */}
                        {timeline && timeline.careStart_third && timeline.careStart_third.time && (
                            <Timeline.Item label={moment(timeline.careStart_third.time).format('HH:mm').toString()}>
                                진료(세 번째)를 시작했습니다.
                            </Timeline.Item>
                        )}
                        {timeline && timeline.careDone_third && timeline.careDone_third.time && (
                            <Timeline.Item label={moment(timeline.careDone_third.time).format('HH:mm').toString()}>
                                진료(세 번째)가 종료됐습니다.{' '}
                                {moment(timeline.careDone_third.time).diff(moment(timeline.careStart_third.time).toDate(), 'minutes')}분 동안 진료했습니다.
                            </Timeline.Item>
                        )}
                        {/* 치료 시작/종료(세 번째) */}
                        {timeline && timeline.treatmentStart_third && timeline.treatmentStart_third.time && (
                            <Timeline.Item label={moment(timeline.treatmentStart_third.time).format('HH:mm').toString()}>
                                치료(세 번째)를 시작했습니다.
                            </Timeline.Item>
                        )}
                        {timeline && timeline.treatmentDone_third && timeline.treatmentDone_third.time && (
                            <Timeline.Item label={moment(timeline.treatmentDone_third.time).format('HH:mm').toString()}>
                                치료(세 번째)가 종료됐습니다.{' '}
                                {moment(timeline.treatmentDone_third.time).diff(moment(timeline.treatmentStart_third.time).toDate(), 'minutes')}분 동안
                                치료했습니다.
                            </Timeline.Item>
                        )}

                        {timeline && timeline.backStart && timeline.backStart.time && (
                            <Timeline.Item label={moment(timeline.backStart.time).format('HH:mm').toString()}>귀가를 시작했습니다.</Timeline.Item>
                        )}
                        {timeline && timeline.complete && timeline.complete.time && (
                            <Timeline.Item label={moment(timeline.complete.time).format('HH:mm').toString()}>
                                귀가 완료하였으며, 서비스가 종료됐습니다.
                            </Timeline.Item>
                        )}
                    </Timeline>
                    {report.description && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>서비스 진행 중 특이사항</p>
                                <ReportDescription>
                                    {(report.description &&
                                        report.description.split('\n').map((word, index) => (
                                            <span key={'report-desc-word' + index.toString()}>
                                                {word}
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>

                            <DivisionLine small />
                        </>
                    )}

                    {report.care && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>진료 및 치료 내용</p>
                                <ReportDescription>
                                    {(report.care &&
                                        report.care.split('\n').map((word, index) => (
                                            <span key={'report-care-word' + index.toString()}>
                                                {word}
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>
                            <DivisionLine small />
                        </>
                    )}

                    {report.medicine && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>복약지도 내용</p>
                                <ReportDescription>
                                    {(report.medicine &&
                                        report.medicine.split('\n').map((word, index) => (
                                            <span key={'report-medicine-word' + index.toString()}>
                                                {word}
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>

                            <DivisionLine small />
                        </>
                    )}
                    {report.nextSchedule && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>다음 진료 일정 정리</p>
                                <ReportDescription>
                                    {(report.nextSchedule &&
                                        report.nextSchedule.split('\n').map((word, index) => (
                                            <span key={'report-nextSchedule-word' + index.toString()}>
                                                {word}
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>

                            <DivisionLine small />
                        </>
                    )}
                    {report.mateComment && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>메이트 전달 사항</p>
                                <ReportDescription>
                                    {(report.mateComment &&
                                        report.mateComment.split('\n').map((word, index) => (
                                            <span key={'report-mateComment-word' + index.toString()}>
                                                {word}
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>

                            <DivisionLine small />
                        </>
                    )}

                    {selectedServiceName.includes('은평 성모병원') && (
                        <>
                            <ReportInfoBlock>
                                <p className='title'>가톨릭대학교 은평성모병원 안내 말</p>
                                <ReportDescription>
                                    {(report.mateComment &&
                                        report.mateComment.split('\n').map((word, index) => (
                                            <span key={'report-mateComment-word' + index.toString()}>
                                                가톨릭대학교 은평성모병원을 이용해 주셔서 감사드립니다. <br />
                                                최고의 환자경험이 되도록 항상 최선을 다하겠습니다.
                                                <br />
                                            </span>
                                        ))) ||
                                        '-'}
                                </ReportDescription>
                            </ReportInfoBlock>

                            <DivisionLine small />
                        </>
                    )}

                    <ButtonWrapper>
                        <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff' onClick={visibleHandler}>
                            닫기
                        </HalfWidthButton>
                    </ButtonWrapper>
                </ContentSection>
            </ModalInnerContainer>
        </CustomModal>
    );
};

const ModalInnerContainer = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    border-radius: 8px;

    width: 100%;
    max-width: 424px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    margin: 0 auto;
    height: auto;
    padding: 24px;

    ${props =>
        props.onlyMobile
            ? `
        display: none;
    `
            : ''}
    @media (max-width: 480px) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        bottom: 0px;
        padding: 16px;

        ${props =>
            props.onlyMobile
                ? `
        display: flex;
        border-radius: 0px;
        padding: 0px;
    `
                : ''}
    }
`;

const TitleSection = styled.div``;

const HeaderTitleSection = styled.div`
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.45px;
    text-align: center;
    color: #161616;
    border-bottom: 1px solid #e9e9e9;
    > span.mobile-only {
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.45px;
        text-align: center;
        color: #161616;
    }
    position: relative;
    > img {
        position: absolute;
        top: 6px;
        left: 10px;
        width: 32px;
        height: 32px;
        object-fit: contain;
        cursor: pointer;
    }
    > img.close {
        left: unset;
        right: 10px;
    }
    @media (min-width: 480px) {
        height: unset;
        line-height: unset;
        border-bottom: 0px;
        > span.mobile-only {
            display: none;
        }
        > img.mobile-only {
            display: none;
        }
    }
`;

const ContentSection = styled.div`
    flex: 1;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`;

const MobileContentSection = styled(ContentSection)`
    padding: 16px;
    @media (min-width: 480px) {
        padding: 0px;
    }
`;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 24px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;
const CommonModalTitle = styled.p`
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: left;
    color: #161616;

    margin-bottom: ${props => (props.withSub ? '8px' : '16px')};
`;

const CommonModalSubtitle = styled.p`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.7px;
    text-align: center;
    color: #565656;

    margin-bottom: 40px;

    @media (max-width: 480px) {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: -0.65px;
        text-align: center;
        margin-bottom: 32px;
    }
`;

const CommonModalDescription = styled.p`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: center;
    color: #a6a6a6;
    margin-top: -32px;
    margin-bottom: 40px;

    @media (max-width: 480px) {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: -0.55px;
        text-align: center;
    }
`;

const ReportInfoBlock = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;

    > p.title {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #afb0b2;
        margin-bottom: 8px;
    }
`;

const ReportDescription = styled.div`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.3px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
    }
`;
