import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../theme';
const MarketingStyledContainer = styled.div`
    .inner-container {
        ${props => props.component && `padding: 0px !important;`}
    }
    .main-context {
        width: ${props => (props.component ? '100%' : '60%')};

        h2 {
            ${props => props.component && `display: none;`}
            font-size: ${sizeHandler(44)};
            font-weight: bold;
            letter-spacing: -0.6px;
            color: #000000;
            margin-bottom: 24px;
        }

        p {
            font-size: ${sizeHandler(14)};
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.8);
            padding: 0px;
            margin: 0px;
        }
    }

    .context-block {
        margin-top: ${props => (props.component ? '24px' : '100px')};
        padding-top: 24px;
        border-top: 1px solid #e6e6e6;
        width: ${props => (props.component ? '100%' : '60%')};

        > p {
            font-size: ${sizeHandler(14)};
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.7);
        }

        > table {
            border-spacing: 0px;
            width: 100%;
            height: 100px;
            border: 1px solid #e2e2e2;

            > tr, th, td {
                width: 200px;
                border: 1px solid #e2e2e2;
                text-align: left;
                padding-left: 10px;
            }

            > td {
                line-height: 2rem;
                border: 1px solid #e2e2e2;
            }

            > thead {
                height: 35px;    
            }

            > tbody {
                background-color: #f9f9f9;
            }

            @media (max-width: 832px) {
                width: 100%;
            }
        }

        > .info-block {
            margin-top: 68px;

            .title {
                font-size: ${sizeHandler(18)};
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 8px;
            }

            .content {
                font-size: ${sizeHandler(14)};
                line-height: ${sizeHandler(24)};
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    @media (max-width: 1120px) {
        .inner-container {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media (max-width: 768px) {
        .main-context {
            width: 100%;

            h2 {
                font-size: ${sizeHandler(38)};
            }
        }

        .context-block {
            width: 100%;
        }

        .privacy-justify {
            flex-direction: column;

            p {
                &:first-child {
                    text-align: center;
                }
                &:last-child {
                    text-align: center;
                }
            }
        }
    }
`;
export default MarketingStyledContainer;