import React from 'react';
import { connect } from 'react-redux';

import Products from './product';

class ProductPages extends React.PureComponent {
    render() {
        return <Products {...this.props} />;
    }
}

const mapStateToProps = ({}) => ({});

const mapStateToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapStateToDispatch)(ProductPages);
