import React from 'react';
import { connect } from 'react-redux';
import StyledBuyContaeinr from './buy.styled';
import Buy from './buy';

import { MATE } from '../../../../Lib/variables';
import { editBuyForm, getServiceInfo, buyTicket } from '../../../../Reducer/Modules/app';

class BuyTicketPage extends React.PureComponent {
    componentDidMount = () => {
        const { id, userType } = this.props.auth;
        if (!id || userType === MATE) this.props.history.goBack();
    };

    render() {
        return (
            <StyledBuyContaeinr>
                <Buy {...this.props} />
            </StyledBuyContaeinr>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    edit: ({ value, type }) => dispatch(editBuyForm({ type, value })),
    getServiceInfo: ({ code }) => dispatch(getServiceInfo({ code })),
    buyTicket: ({ userId, info }) => dispatch(buyTicket({ userId, info })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyTicketPage);
