import styled from 'styled-components';
import { mainColor, sizeHandler } from '../../theme';

const HomeStyledContainer = styled.div`
    padding-top: 55px;

    .center-title {
        text-align: center;
        margin-bottom: 28px;

        > p {
            height: 52px;
            line-height: 52px;
            margin-bottom: 0px;
            font-size: 28px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0px;
    }

    .ant-collapse-borderless > .ant-collapse-item {
        border: 0px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
        padding-top: 4px;
        padding-bottom: 12px;
    }

    .fake-input {
        width: 100%;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
    }

    .main-section {
        position: relative;
        width: 100%;
        height: 100vh;
        max-height: 628px;
        // min-height: calc(100vh - 55px);

        // background-color: #ebf9ff;
        // background-image: url('/assets/images/new_mainbg.png');
        background-color: linear-gradient(to top, #3bc1ff, #5b62f5);
        background-image: url('/assets/images/w_main.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 768px) {
            background-image: url('/assets/images/new_mainbg-mobile.png');
            min-height: 86vh;

            .inner-container {
                padding: 0px;
                padding-bottom: 30px;
                min-height: 86vh;

                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }

        .ant-input[disabled] {
            color: rgba(0, 0, 0, 0.7);
        }
        .ant-input {
            font-size: 12px;
        }

        .order-menu-wrapper {
            width: 50%;
            position: relative;
            max-width: 440px;
            min-width: 370px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 1px 2px 3.5px #e6e6e6;
            padding: 32px;

            .mobile-close-button {
                display: none;
            }

            @media (max-width: 768px) {
                display: none;

                &.active {
                    display: block;
                }

                margin: 0px auto;
                position: fixed;
                z-index: 99;
                top: 55px;
                left: 0px;
                width: 100%;
                max-width: 100%;
                height: calc(100vh - 55px);
                overflow: scroll;
                border-radius: 0px;

                .mobile-close-button {
                    display: block;
                    position: absolute;
                    top: 16px;
                    right: 24px;
                    padding: 6px;
                    cursor: pointer;

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.blocked {
                    z-index: 0;
                    position: relative;
                    height: auto;
                    overflow: auto;
                }
            }

            .order-title {
                margin-bottom: 20px;

                p {
                    margin: 0px;
                    padding: 0px;
                    font-size: ${sizeHandler(28)};
                    font-weight: bold;
                    letter-spacing: -0.7px;
                    color: rgba(0, 0, 0, 0.7);
                }
            }

            .service {
                margin-bottom: 20px;

                .time-checker {
                    width: calc(50% - 4px);
                }

                .time-checker.margin {
                    margin-right: 8px;
                }

                .search-button {
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    background-color: #ececec;
                    height: 34px;
                    width: 50px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;

                    font-size: 12px;
                    line-height: 34px;
                    text-align: center;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.4);
                }

                .service-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #ddd;
                    border-radius: 6px;

                    .tab {
                        flex: 1;
                        height: 48px;
                        border-right: 1px solid #ddd;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:last-child {
                            border: 0px;
                        }

                        span {
                            font-size: ${sizeHandler(14)};
                            letter-spacing: -0.2px;
                            font-weight: bold;
                            color: ${mainColor};
                        }
                    }

                    .tab.active {
                        background-color: ${mainColor};

                        span {
                            color: white;
                        }
                    }
                }

                .info-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        flex: 1;
                        margin: 0px;
                        padding: 0px;
                        text-align: left;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }

                .info-input-tab {
                    display: flex;
                    align-items: center;

                    > div {
                        flex: 1;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }

                    .name-wrapper {
                        position: relative;

                        .sex-type-wrapper {
                            position: absolute;
                            top: 1px;
                            right: 1px;
                            z-index: 3;
                            background-color: white;

                            .sex-type {
                                display: inline-block;
                                width: 34px;
                                height: 34px;
                                line-height: 36px;
                                text-align: center;
                                border-left: 1px solid #e6e6e6;

                                font-size: ${sizeHandler(14)};
                                font-weight: normal;
                                letter-spacing: -0.2px;
                                color: rgba(0, 0, 0, 0.2);
                                cursor: pointer;

                                &:hover {
                                    color: rgba(0, 0, 0, 0.3);
                                    transition: all 0.3s;
                                }

                                &.active {
                                    color: ${mainColor};
                                }
                            }
                        }
                    }

                    .input {
                        flex-grow: 1;
                        display: flex;
                        min-width: 0;
                        align-items: center;

                        border-radius: 4px;
                        border: solid 1px #e6e6e6;
                        min-height: 36px;
                        line-height: 36px;
                        text-indent: 12px;
                        font-size: ${sizeHandler(12)};
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.8);
                        background-color: #f5f5f5;
                        cursor: not-allowed;

                        p {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        // .ant-input {
                        //     color: rgba(0, 0, 0, 0.8);
                        // }

                        &.phone {
                            flex: 0.5;

                            @media (max-width: 768px) {
                                flex: 1;
                            }
                        }

                        &.input-with-icon {
                            p {
                                height: 100%;
                                flex: 1;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            div {
                                height: 100%;
                                width: 36px;
                                border-left: 1px solid #e6e6e6;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span {
                                    text-align: center;
                                    text-indent: 0px;
                                    // opacity: 0.3;
                                    font-size: ${sizeHandler(12)};
                                    letter-spacing: -0.3px;
                                    color: rgba(0, 0, 0, 0.8);

                                    img {
                                        width: 18px;
                                    }
                                }
                            }
                        }
                    }
                }

                .service-fee-wrapper {
                    margin-bottom: 32px;

                    .service {
                        display: flex;
                        align-items: center;
                        padding-top: 12px;
                        margin-bottom: 0px;

                        .name {
                            flex: 1;
                            font-size: ${sizeHandler(14)};
                            letter-spacing: -0.3px;
                            color: rgba(0, 0, 0, 0.7);
                        }

                        .fee {
                            font-size: ${sizeHandler(14)};
                            font-weight: bold;
                            text-align: right;
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }

                input {
                    height: 36px;
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .ant-input-number-input-wrap {
                    height: 100%;

                    .ant-input-number-input {
                        height: 100%;
                    }

                    input {
                        height: 100%;
                    }
                }

                .select {
                    display: inline-block;
                    height: 36px;
                    width: 100%;

                    .ant-select-selection--single {
                        height: 100%;
                    }

                    .ant-select-selection__rendered {
                        line-height: 36px;
                    }
                }

                .info {
                    font-size: ${sizeHandler(12)};
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 4px;
                    letter-spacing: -0.15px;
                }

                .ant-select-selection__rendered {
                    margin-right: 34px;
                    border-right: 1px solid #e6e6e6;
                }

                .ant-select-arrow {
                    color: ${mainColor};
                }

                .image-with-text {
                    display: flex;
                    align-items: center;
                    img {
                        height: 18px;
                        border: 0px;
                    }

                    p {
                        flex: 1;
                        margin: 0px;
                        padding: 0px;
                        margin-left: 12px;
                        font-size: ${sizeHandler(18)};
                        font-weight: bold;
                        color: #3bc1ff;
                    }
                }
            }

            .side-summary-tab {
                position: absolute;
                top: 0px;
                right: -380px;
                width: 360px;
                height: 100%;
                background-color: white;
                border-radius: 8px;
                box-shadow: 1px 2px 3.5px #e6e6e6;
                padding: 32px;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                @media (max-width: 768px) {
                    width: 100%;
                    height: auto;
                    border-radius: 0px;
                    box-shadow: none;
                    padding: 0px;
                    position: relative;
                    top: 55px;
                    left: 0px;
                    background-color: transparent;
                }

                .refund-info-wrapper {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;

                    .info-wrapper {
                        width: 100%;

                        .title {
                            font-size: ${sizeHandler(12)};
                            font-weight: bold;
                            color: #a7a7a7;
                            margin-bottom: 8px;
                        }

                        .info {
                            font-size: ${sizeHandler(10)};
                            line-height: ${sizeHandler(16)};
                            color: rgba(0, 0, 0, 0.7);
                        }

                        @media (max-width: 768px) {
                            margin-top: 36px;
                            padding-bottom: 20px;

                            .info {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
            }
        }
    }

    .additional-fee {
        p {
            margin: 0px;
            padding: 0px;
            margin-top: 8px;
            font-size: ${sizeHandler(10)};
            color: rgba(0, 0, 0, 0.7);
        }
    }

    .ant-input-number {
        width: 100%;
        height: 48px;

        .ant-input-number-input-wrap {
            height: 100%;

            .ant-input-number-input {
                height: 100%;
            }

            input {
                height: 100%;
            }
        }

        .ant-input-number-handler-wrap {
            opacity: 1;
            width: 48px;

            span {
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    position: relative;
                    margin-top: 0px;
                    left: 0px;
                    top: 0px;
                    bottom: 0px;
                    right: 0px;
                    font-size: 16px;
                }
            }
            .anticon.anticon-up.ant-input-number-handler-up-inner {
                color: ${mainColor};
            }
            .anticon.anticon-down.ant-input-number-handler-down-inner {
                color: ${mainColor};
            }
        }
    }

    .total-fee-wrapper {
        padding: 18px 0px;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;

        .total-fee {
            display: flex;
            align-items: center;

            .total-min {
                flex: 1;
                font-size: ${sizeHandler(14)};
                // font-weight: bold;
                letter-spacing: -0.5px;
                color: rgba(0, 0, 0, 0.7);
            }

            .fee-min {
                font-size: ${sizeHandler(14)};
                letter-spacing: -0.5px;
                text-align: right;
                // font-weight: bold;
                // color: #3bc1ff;

                color: rgba(0, 0, 0, 0.7);
            }

            span.vat {
                font-size: ${sizeHandler(11)};
                color: #565656;
            }
        }

        .total {
            flex: 1;
            font-size: ${sizeHandler(16)};
            font-weight: bold;
            letter-spacing: -0.5px;
            color: rgba(0, 0, 0, 0.7);
        }

        .fee {
            font-size: ${sizeHandler(16)};
            font-weight: bold;
            letter-spacing: -0.5px;
            text-align: right;
            color: #3bc1ff;
        }
    }

    // 여기
    .service-section {
        .concept-container {
            margin-bottom: 68px;

            .concept-title {
                font-size: 24px;
                font-weight: bold;
                line-height: 2.17;
                letter-spacing: -0.67px;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 12px;
            }

            .concept-body {
                font-size: 18px;
                font-weight: normal;
                line-height: 1.78;
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.8);

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .service-item:last-child {
            border-top: 1px solid #e6e6e6;
            margin-top: 96px;
            padding-top: 96px;
        }

        .service-item {
            .service-name {
                p {
                    font-size: ${sizeHandler(36)};
                    font-weight: bold;
                    letter-spacing: -1px;
                    color: rgba(0, 0, 0, 0.8);

                    position: relative;

                    .stamp {
                        margin-left: 20px;
                        padding: 8px 12px;
                        border-radius: 4px;
                        background-color: #ff0052;
                        font-size: 10px;
                        font-weight: bold;
                        text-align: center;
                        color: #ffffff;

                        position: absolute;
                        bottom: 6px;
                    }
                }

                margin-bottom: 12px;
            }

            .mobile {
                display: none;
            }

            .desktop {
                display: block;
            }

            .service-info {
                display: flex;
                align-items: center;

                .service-photo {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 60px;

                    img {
                        // height: 100%;
                        width: 100%;
                    }
                }

                .info-wrapper {
                    flex: 2;

                    .main-description {
                        font-size: ${sizeHandler(18)};
                        color: rgba(0, 0, 0, 0.8);
                        letter-spacing: -0.3px;
                        margin-bottom: 32px;

                        @media (max-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    .service-detail {
                        display: flex;
                        align-item: center;

                        .target-price {
                            // flex: ;
                            min-width: 240px;

                            .target {
                                margin-bottom: 36px;
                            }
                        }

                        .scheme {
                            flex: 1;
                            margin-left: 20px;

                            .scheme-items {
                                p {
                                    font-size: ${sizeHandler(14)};
                                    letter-spacing: -0.3px;
                                    color: rgba(0, 0, 0, 0.7);
                                    height: 38px;
                                    line-height: 38px;
                                    margin: 0px;
                                    padding: 0px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                                border-bottom: 1px solid #e6e6e6;
                            }

                            .ps {
                                font-size: ${sizeHandler(14)};
                                color: ${mainColor};
                                // font-weight: bold;
                                height: 28px;
                                line-height: 28px;

                                &:nth-child(2n-1) {
                                    color: #ff5500;
                                }
                            }
                        }
                    }

                    .detail-button-wrapper {
                        min-width: 240px;
                        margin-top: 40px;
                        background-color: #606060;
                        border-radius: 4px;
                        height: 48px;
                        cursor: pointer;

                        p {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            line-height: 48px;
                            font-size: ${sizeHandler(14)};
                            color: white;
                            font-weight: bold;
                        }
                    }

                    .reserve-button-wrapper {
                        flex: 1;

                        margin-top: 40px;
                        background-color: ${mainColor};
                        border-radius: 4px;
                        height: 48px;
                        cursor: pointer;

                        p {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            line-height: 48px;
                            font-size: ${sizeHandler(14)};
                            color: white;
                            font-weight: bold;
                        }
                    }

                    .title {
                        font-size: ${sizeHandler(18)};
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);
                        margin-top: 36px;
                        margin-bottom: 8px;
                    }

                    .content {
                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    .price-content {
                        font-size: ${sizeHandler(16)};
                        font-weight: bold;
                        color: #606060;
                        letter-spacing: -0.3px;
                    }

                    .info-container {
                        .spec-title {
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 1.56;
                            letter-spacing: normal;
                            color: rgba(0, 0, 0, 0.8);
                        }

                        .spec-wrapper {
                            padding: 8px 0px;
                            border-bottom: 1px solid #e6e6e6;

                            .spec-row {
                                font-size: 14px;
                                letter-spacing: -0.3px;
                                color: rgba(0, 0, 0, 0.7);
                                margin: 0px;
                            }

                            .spec-sub {
                                font-size: 12px;
                                letter-spacing: -0.26px;
                                color: #a7a7a7;
                                margin: 0px;
                                margin-top: 4px;
                            }
                        }
                    }

                    .button-container {
                        display: flex;
                        align-items: center;
                        margin-top: 32px;

                        .button {
                            border-radius: 4px;
                            height: 48px;
                            line-height: 48px;
                            text-align: center;
                            cursor: pointer;

                            &.video-button {
                                width: 285px;
                                background-color: #606060;
                                margin-right: 20px;

                                &:hover {
                                    transition: all 0.3s;
                                    background-color: #707070;
                                }
                            }

                            &.reserve-button {
                                background-color: #3bc1ff;
                                flex: 1;

                                &:hover {
                                    transition: all 0.3s;
                                    background-color: #54c9ff;
                                }
                            }

                            span {
                                font-size: 14px;
                                font-weight: bold;
                                text-align: center;
                                color: #ffffff;
                            }
                        }

                        @media (max-width: 768px) {
                            flex-direction: column;

                            .button {
                                width: 100% !important;
                                margin-right: 0px !important;
                                height: 40px;
                                line-height: 40px;

                                &:last-child {
                                    margin-top: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .service-item {
                .service-name {
                    p {
                        font-size: ${sizeHandler(30)};

                        .stamp {
                            padding: 4px 8px;
                            bottom: 8px;
                        }
                    }
                }

                .service-info {
                    display: block;

                    .service-photo {
                        margin-top: 40px;
                        padding-left: 0px;

                        img {
                            width: 100%;
                        }
                    }

                    .info-wrapper {
                        width: 100%;

                        > p {
                            font-size: ${sizeHandler(16)};
                        }

                        .service-detail {
                            flex-direction: column;

                            .service-photo {
                                display: none;
                            }

                            .scheme {
                                margin: 0px;
                            }
                        }

                        .detail-button-wrapper {
                            display: block;
                            width: 100%;
                            height: 40px;

                            p {
                                line-height: 40px;
                            }
                        }

                        .reserve-button-wrapper {
                            display: block;
                            width: 100%;
                            height: 40px;

                            p {
                                line-height: 40px;
                            }
                        }
                        .mobile {
                            display: block;
                        }

                        .desktop {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .reserve-section {
        .menu-top {
            display: flex;
            align-items: flex-end;
            margin-bottom: 16px;

            .main-title {
                flex: 1;
                font-size: 28px;
                font-weight: bold;
                letter-spacing: -1px;
                color: rgba(0, 0, 0, 0.8);
            }

            .sub-button {
                color: ${mainColor};
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .menu-bottom {
            .reserve-menu {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                margin-bottom: 12px;
                border-radius: 4px;
                box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
                background-color: #ffffff;

                .title {
                    color: #a7a7a7;
                    font-size: ${sizeHandler(12)};
                    font-weight: bold;
                    margin: 0px;
                    padding: 0px;
                }

                .contents {
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: #606060;
                    margin: 0px;
                    padding: 0px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .service-name {
                    flex: 0.7;
                    .contents {
                        color: ${mainColor};
                    }
                }

                .user {
                    flex: 0.5;
                }

                .date {
                    flex: 0.7;
                }

                .hospital {
                    flex: 1;
                }

                .status {
                    flex: 0.4;
                }

                .button {
                    flex: 0.5;
                    > div {
                        display: flex;
                        height: 48px;
                        border-radius: 4px;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid ${mainColor};
                        cursor: pointer;
                        transition: all 0.3s;

                        span {
                            font-size: ${sizeHandler(14)};
                            font-weight: bold;
                            color: ${mainColor};
                        }

                        &:hover {
                            background-color: ${mainColor};
                            span {
                                color: white;
                            }
                        }
                    }
                }

                .content.service {
                    color: ${mainColor};
                }
            }
            @media (max-width: 768px) {
                .reserve-menu {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .contents {
                        margin-bottom: 20px;
                    }

                    .button {
                        width: 100%;
                        > div {
                            height: 40px;
                        }
                    }
                }
            }
        }
    }

    .section-title {
        padding-bottom: 16px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 48px;

        p {
            font-size: ${sizeHandler(18)};
            color: #a7a7a7;
            font-weight: bold;
            margin: 0px;
            padding: 0px;
            opacity: 1;
        }
    }

    .order-menu-mobile-wrapper {
        // position: absolute;
        // bottom: 68px;
        // left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 10px;

        p {
            text-align: center;
            font-size: ${sizeHandler(24)};
            font-weight: bold;
            color: white;
        }

        p:nth-child(1) {
            font-size: ${sizeHandler(14)};
            font-weight: normal;
            letter-spacing: -0.3px;
            text-align: center;
            color: white;
            lineheight: ${sizeHandler(20)};
        }

        button {
            width: 100%;
            height: 56px;
            font-size: ${sizeHandler(16)};
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            margin-top: 28px;
        }

        @media (min-width: 768px) {
            display: none;
        }
    }
`;

export default HomeStyledContainer;

export const UserMainContainer = styled.div`
    width: 100%;
    // min-height: calc(100vh - 55px);
    // background-image: url('/assets/images/');

    height: 100vh;
    max-height: 628px;

    background-color: linear-gradient(to top, #3bc1ff, #5b62f5);
    background-image: url('/assets/images/w_main.png');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: calc((100vh - 55px) * 0.2);

    @media (max-width: 768px) {
        background-size: cover;
        background-image: url('/assets/images/new_mainbg-mobile.png');

        min-height: initial;
        height: 600px;

        padding-top: 354px;
    }
`;

export const InnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    @media (max-width: 1120px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const UserMainTitle = styled.div`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 66px;

    letter-spacing: -1.44444px;

    color: #ffffff;

    position: relative;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        > br {
            display: block;
        }

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;

        text-align: center;
        color: #ffffff;

        margin-bottom: 4px;
    }
`;

export const UserMainTitleDeco = styled.img`
    position: absolute;

    object-fit: contain;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const DecoRight = styled(UserMainTitleDeco)`
    top: 0px;
    left: 240px;

    width: 32px;
    height: 32px;
`;

export const DecoLeft = styled(UserMainTitleDeco)`
    top: 36px;
    left: -60px;

    width: 22px;
    height: 22px;
`;

export const DecoLeftTop = styled(UserMainTitleDeco)`
    top: -52px;
    left: -62px;

    width: 62px;
    height: 62px;
`;

export const UserMainSubtitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: -0.4px;

    margin-bottom: 44px;

    @media (max-width: 768px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-align: center;

        margin-bottom: 24px;
    }
`;

export const UserMainRoute = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const UserMainRouteButton = styled.a`
    display: inline-block;
    width: 100%;
    max-width: 360px;
    height: 64px;
    line-height: 64px;

    background: #3bc1ff;
    border: 1px solid #3bc1ff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 0.2;

    text-align: center;

    color: #ffffff;
    cursor: pointer;

    &:hover {
        transition: 0.5s;
        opacity: 0.85;
    }

    @media (max-width: 768px) {
        width: calc(100vw - 20px);
        max-width: 360px;

        height: 56px;
        line-height: 56px;
        font-size: 16px;

        margin: 0 auto;
    }
`;

export const PartnerContainer = styled.div`
    width: 100%;

    padding-top: 60px;
    padding-bottom: 60px;

    @media (max-width: 768px) {
        margin-top: 40px;
    }
`;

export const PartnerInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;

    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

export const PartnerTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;

    align-items: center;
    margin-top: 10px;
    margin-bottom: 46px;

    > img {
        width: 24px;
        height: 24px;
        object-fit: contain;

        margin-bottom: 16px;
    }

    > span {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.5px;
        text-align: center;
        // color: #3bc1ff;
    }

    @media (max-width: 1120px) {
        width: 100%;
        text-align: left;
    }
`;

export const PartnerListRow = styled.div`
    display: flex;
    width: 100%;

    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        justify-content: flex-start;
    }

    @media (max-width: 480px) {
        justify-content: space-between;

        > div:nth-child(2n) {
            margin-right: 0px;
        }
    }
`;

export const PartnerItem = styled.div`
    margin-bottom: 10px;

    width: 215px;
    height: 76px;

    border-radius: 4px;
    // box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    // border: 1px solid rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1.1);
    }

    @media (max-width: 768px) {
        margin-right: 8px;
        margin-bottom: 8px;
    }

    @media (max-width: 480px) {
        width: calc(50% - 4px);
        height: calc((50vw - 24px) * 0.35);
    }
`;

export const SectionHeader = styled.div`
    padding-bottom: 10px;
    margin-bottom: 66px;

    border-bottom: 1px solid #e9e9e9;

    display: flex;
    align-items: flex-end;

    > img {
        width: 58px;
        height: 76px;
        object-fit: contain;

        margin-right: 16px;
    }

    > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 50px;

        text-align: center;
        letter-spacing: -0.777778px;

        color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > img {
            width: 48px;
            height: 48px;

            margin-bottom: 10px;
        }

        > span {
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            letter-spacing: -0.555556px;
        }
    }
`;

export const ServiceListContainer = styled.div`
    margin-bottom: 102px;

    @media (max-width: 1120px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const ServiceListInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;

    margin: 0 auto;
`;

export const ServiceBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ServiceBoxContainer = styled.div`
    margin-right: 20px;

    &:last-child {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        margin-right: 0px;
    }

    margin-bottom: 46px;
`;

export const ServiceBoxHeader = styled.div`
    > p:first-child {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 26px;

        letter-spacing: -0.3px;
    }

    > p:last-child {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;

        letter-spacing: -0.6px;
    }
`;

export const ServiceBoxImageWrapper = styled.div`
    border-radius: 6px;
    overflow: hidden;
`;

export const ServiceBoxImg = styled.img`
    width: calc((1120px / 3) - (40px / 3));
    max-width: 360px;
    object-fit: contain;

    @media (max-width: 1120px) {
        max-width: 280px;
    }

    @media (max-width: 900px) {
        max-width: 240px;
    }

    @media (max-width: 768px) {
        max-width: 300px;
    }
`;

export const ServiceBoxButtons = styled.div`
    width: 100%;
    display: flex;

    margin-top: -12px;
`;

export const MoreButton = styled.a`
    width: 132px;
    height: 48px;

    text-align: center;
    line-height: 48px;

    background: #606060;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;

    color: #ffffff;

    cursor: pointer;
`;

export const ActionButton = styled.span`
    flex: 1;

    height: 48px;
    line-height: 48px;

    text-align: center;

    background: #3bc1ff;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;

    color: #ffffff;

    > img {
        margin-right: 6px;

        width: 18px;
        height: 18px;
        object-fit: contain;
    }

    cursor: pointer;
`;

export const IntroduceSection = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 40px;

    > div {
        width: 50%;
        flex: 1;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const IntroduceSectionFst = styled(IntroduceSection)`
    div.text-block {
        @media (max-width: 768px) {
            order: 2;
            width: 100%;

            p {
                text-align: center;
            }
        }
    }

    div.img-block {
        position: relative;
        text-align: center;

        display: flex;
        align-items: center;
        height: 100px;

        img {
            position: absolute;
            bottom: -50px;

            left: 5%;

            width: 50%;
            object-fit: contain;
        }

        @media (max-width: 768px) {
            order: 1;
            width: 100%;
            justify-content: center;

            margin-bottom: 14px;

            > img {
                position: initial;
            }
        }
    }
`;

export const IntroduceSectionSnd = styled(IntroduceSection)`
    div.text-block {
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    div.img-block {
        text-align: center;
        width: 100%;

        img {
            width: 50%;
            object-fit: cover;

            @media (max-width: 768px) {
                object-fit: contain;
                margin-bottom: 14px;
            }
        }
    }
`;

export const IntroduceSectionTrd = styled(IntroduceSection)`
    div.text-block {
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    div.img-block {
        text-align: center;
        width: 100%;

        img {
            width: 50%;
            object-fit: cover;

            @media (max-width: 768px) {
                width: 65%;
                object-fit: contain;
                margin-bottom: 14px;
            }
        }
    }
`;

export const IntroduceTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 48px;

    letter-spacing: -0.666667px;

    color: rgba(0, 0, 0, 0.8);

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.5px;
    }
`;

export const IntroduceContent = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: ${props => (props.small ? '13px' : '16px')};
    line-height: ${props => (props.small ? '22px' : '30px')};

    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-weight: bold;
    }

    @media (max-width: 768px) {
        word-break: keep-all;
        font-size: ${props => (props.small ? '12px' : '14px')};
        line-height: ${props => (props.small ? '20px' : '23px')};
    }
`;

export const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

export const DesktopBr = styled.br`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const SpecListRow = styled.div`
    display: flex;
    align-items: flex-start;

    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SpecWrapper = styled.div`
    margin-bottom: 28px;
    margin-right: 20px;

    &:last-child {
        margin-right: 0px;
    }

    width: calc((100% / 3) - (40px / 3));

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
    }
`;

export const SpecIndex = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: -0.333333px;
    color: rgba(0, 0, 0, 0.2);

    margin-bottom: 4px;
`;

export const SpecTitleWrapper = styled.div`
    display: flex;
    align-items: center;

    text-align: left;

    > img {
        width: 24px;
        height: 24px;

        margin-right: 8px;
        object-fit: contain;
    }

    > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 44px;

        letter-spacing: -0.666667px;

        color: rgba(0, 0, 0, 0.8);
    }
`;

export const SpecContent = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 0px;

    > span {
        font-weight: bold;
    }

    @media (max-width: 768px) {
        br {
            display: block;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 360px;

    > div,
    span,
    a {
        width: calc(50% - 4px);
    }

    @media (max-width: 768px) {
        margin: 0 auto;
    }
`;
