export const BASE_URL = 'https://server.withmate.kr';
export const DEV = false;

// info
export const COMPANY_NANE = 'WITHMATE';
export const COMPANY_NANE_EN = 'withmate';
export const CONTACT_TEL = '010-xxxx-xxxx';
export const CONTACT_EMAIL = 'withmate@mail.com';
export const CUSTOMER_SERVICE = 'customer@withmate.com';
export const USER = 'user';
export const MATE = 'mate';
export const LOCAL = 'local';
export const KAKAO = 'kakao';
export const ASSIST = 'assist';
export const UNEMER = 'unemergency';
export const HAPPYCARE = 'happycare';
