import React from 'react';
import { withRouter } from 'react-router-dom';

// component
import Footer from './footer';

// styles
import FooterStyledContainer from './footer.styled';

class FooterContainer extends React.PureComponent {
    render() {
        return (
            <FooterStyledContainer>
                <Footer {...this.props} />
            </FooterStyledContainer>
        );
    }
}

export default withRouter(FooterContainer);
