import React from 'react';
import {} from 'antd';

export default class Pending extends React.PureComponent {
    render() {
        return (
            <div className='pending-container'>
                <div className='inner-container'>
                    <div className='pending-form'>
                        <div className='login-title'>
                            <p>회원가입</p>
                        </div>
                        <div className='form'>
                            <div className='img-wrapper'>
                                <img src='/assets/images/checkpoint.png' alt='check-circle' />
                            </div>
                            <div className='info-title'>
                                <p>메이트 가입신청 완료</p>
                            </div>
                            <div className='info-body'>
                                <p>
                                    약 7일 이내에 메이트 가입신청 결과를 <br />
                                    가입하신 이메일로 보내드리겠습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
