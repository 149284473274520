import React from 'react';
import { connect } from 'react-redux';

// component
import SensitiveStyledContainer from './sensitive.styled';
import Sensitive from './sensitive';

class SensitiveContainer extends React.PureComponent {
    render() {
        return (
            <SensitiveStyledContainer component={this.props.isComponent || undefined}>
                <Sensitive {...this.props} />
            </SensitiveStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SensitiveContainer);
