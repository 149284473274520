import React from 'react';
import styled from 'styled-components';

class FstBlock extends React.PureComponent {
    render() {
        return (
            <div className='fifth-section'>
                <div className='inner-container'>
                    <div className='text-block' data-aos='fade-up' data-aos-anchor-placement='top-bottom' data-aos-duration='2000'>
                        <p className='title'>믿을 수 있는 기업</p>
                        <p className='content'>
                            위드메이트는 국가 기관과 지자체에게 검증 받은 서비스로, 현재 <b>보건복지부</b>와 <b>고양시</b>, 그리고 <br />
                            <b>국립 암센터</b>가 주관하는 <b>‘고양 해피 케어’</b> 컨소시엄의 일원으로 소속되어 국립 암센터를 이용하는 <br />
                            고양 시민에게 병원 동행 서비스를 제공하고 있습니다. 위드메이트와 함께할 <br />
                            협력 단체 및 기업은 추후 확장될 예정이니 많은 관심 부탁드립니다.
                            <br />
                        </p>
                        <p className='news'>
                            <a href='https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=103&oid=025&aid=0002919541#' target='_blank'>
                                기사링크 >
                            </a>
                        </p>
                    </div>
                    <CoCompany />
                </div>
            </div>
        );
    }
}

export const CoCompany = ({ style }) => {
    return (
        <StyledCoCompany data-aos='fade-up' data-aos-anchor-placement='top-bottom' data-aos-duration='2000' style={style || {}}>
            <div className='logo-block'>
                <div className='three'>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/gov-care.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/goyang.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/cancel.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/goyang-society.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/ericchi.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/beatus.png' alt='logo' />
                    </div>

                    <div className='logo-wrapper'>
                        <img src='/assets/images/juga.png' alt='logo' />
                    </div>

                    <div className='logo-wrapper'>
                        <img src='/assets/images/goyang-happy.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/gpcop.png' alt='logo' />
                    </div>

                    <div className='logo-wrapper'>
                        <img src='/assets/images/paju_woman.png' alt='logo' />
                    </div>

                    <div className='logo-wrapper'>
                        <img src='/assets/images/cheongdam.png' alt='logo' />
                    </div>
                    <div className='logo-wrapper'>
                        <img src='/assets/images/hyo-hospital.png' alt='logo' />
                    </div>
                    <div className='clearfix' />
                </div>
            </div>
        </StyledCoCompany>
    );
};

const StyledCoCompany = styled.div`
    .logo-block {
        .three {
            .logo-wrapper {
                float: left;
                width: calc(100% / 6);

                display: flex;
                align-items: center;
                justify-content: center;

                margin-bottom: 16px;

                img {
                    height: 76px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .logo-block {
            .three {
                // flex-direction: column;
                display: block;

                .logo-wrapper {
                    width: 50%;
                    float: left;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`;
export default FstBlock;
