import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as reviewAPI from '../../Lib/Api/review';
import _ from 'lodash';
import { createReduxThunk } from '../../Lib/middleware';

const ADD_REVIEW = 'review/ADD_REVIEW';
const ADD_REVIEW_SUCCESS = 'review/ADD_REVIEW_SUCCESS';

const REPLY_REVIEW = 'review/REPLY_REVIEW';
const REPLY_REVIEW_SUCCESS = 'review/REPLY_REVIEW_SUCCESS';

const DELETE_REVIEW = 'review/DELETE_REVIEW';
const DELETE_REVIEW_SUCCESS = 'review/DELETE_REVIEW_SUCCESS';

const GET_REVIEW_LIST = 'review/GET_REVIEW_LIST';
const GET_REVIEW_LIST_SUCCESS = 'review/GET_REVIEW_LIST_SUCCESS';

const EDIT_REVIEW = 'review/EDIT_REVIEW';

// initalState
const initialState = {
    review: {},
    reviewList: [],
    reviewPage: 0,
    tab: 'basic',
};

export const addReview = createReduxThunk(ADD_REVIEW, reviewAPI.addReview);
export const replyReview = createReduxThunk(REPLY_REVIEW, reviewAPI.replyReview);
export const deleteReview = createReduxThunk(DELETE_REVIEW, reviewAPI.deleteReview);
export const getReviewList = createReduxThunk(GET_REVIEW_LIST, reviewAPI.getReviewList);

export const editReview = createAction(EDIT_REVIEW, input => input);

const appReducer = handleActions(
    {
        [ADD_REVIEW_SUCCESS]: (state, { payload }) => produce(state, draft => {}),
        [GET_REVIEW_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reviewList'] = payload;
            }),
        [EDIT_REVIEW]: (state, { payload }) =>
            produce(state, draft => {
                draft[payload.type] = payload.value;
            }),
    },
    initialState,
);

export default appReducer;
