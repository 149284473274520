import React from 'react';
import styled from 'styled-components';

export const UserMainBanner = ({ goPage }) => (
    <BannerContainer>
        <Description>
            위드메이트에 대해 더 알고 싶으시죠? <br />
            위드메이트를 소개해드릴게요!
        </Description>
        <LinkButton onClick={goPage}>
            위드메이트 소개
            <img src='/assets/images/main_right_white.png' />
        </LinkButton>
    </BannerContainer>
);

const BannerContainer = styled.div`
    width: 100%;
    background-color: #3bc1ff;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 48px 0px;

    @media (max-width: 480px) {
        padding-top: 67px;
        padding-bottom: 51px;
    }
`;

const Description = styled.p`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: center;
    color: #ffffff;

    margin-bottom: 24px;

    > br {
        display: none;
        @media (max-width: 480px) {
            display: block;
        }
    }
`;

const LinkButton = styled.a`
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    border-radius: 50vh;

    padding: 20px 24px;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: #ffffff;

    cursor: pointer;

    > img {
        width: 24px;
        height: 24px;
        object-fit: contain;

        margin-left: 18px;
    }
    &:hover {
        background-color: transparent;
        color: #ffffff;
    }
`;
