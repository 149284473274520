import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import * as newsEventAPI from '../../Lib/Api/newsEvent';
import _ from 'lodash';
import { createReduxThunk } from '../../Lib/middleware';

const ADD_NEWSEVENT = 'newsEvent/ADD_NEWSEVENT';
const ADD_NEWSEVENT_SUCCESS = 'newsEvent/ADD_NEWSEVENT_SUCCESS';

const NEWSEVENT_INFO = 'newsEvent/NEWSEVENT_INFO';
const NEWSEVENT_INFO_SUCCESS = 'newsEvent/NEWSEVENT_INFO_SUCCESS';

const GET_NEWSEVENT = 'newsEvent/GET_NEWSEVENT';
const GET_NEWSEVENT_SUCCESS = 'newsEvent/GET_NEWSEVENT_SUCCESS';

export const addNewsEvent = createReduxThunk(ADD_NEWSEVENT, newsEventAPI.addNewsEvent);
export const fetchNewsEvent = createReduxThunk(GET_NEWSEVENT, newsEventAPI.fetchNewsEvent);
export const getNewsEventInfo = createReduxThunk(NEWSEVENT_INFO, newsEventAPI.getNewsEventInfo);


const initalState = {
    newsEventInfo: {},
    newsEventList: [],
};

const appReducer = handleActions(
    {
        [ADD_NEWSEVENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => { }),

        [GET_NEWSEVENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['newsEventList'] = payload.data;
                }
            }),

        [NEWSEVENT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['newsEventInfo'] = payload.data;
                }
            }),
    },
    initalState
);

export default appReducer;