import React from 'react';

// component
import PrivacyStyledContainer from './privacy.styled';
import Privacy from './privacy';

class PrivacyContainer extends React.PureComponent {
    render() {
        return (
            <PrivacyStyledContainer component={this.props.isComponent || undefined}>
                <Privacy {...this.props} />
            </PrivacyStyledContainer>
        );
    }
}

export default PrivacyContainer;
