import React from 'react';
import { notification, message, Button, Checkbox, Select } from 'antd';
import validator from 'validator';
import styled from 'styled-components';

import { LOCAL, MATE, USER, KAKAO } from '../../../../Lib/variables';
import axios from '../../../../Lib/axios';
import { CommonWithmateModal } from '../../../../Components/newModal';
import { DivisionLine, WithmateSelect, WithmateInput } from '../../../../Components/Common/input';

import Terms from '../../../Policy/Terms';
import Privacy from '../../../Policy/Privacy';
import Marketing from '../../../Policy/Marketing';
import EssentialNormal from '../../../Policy/Essential/Normal';
import EssentialSensitive from '../../../Policy/Essential/Sensitive';
import SelectiveNormal from '../../../Policy/Selective/Normal';
import SelectiveSensitive from '../../../Policy/Selective/Sensitive';
import Advertisement from '../../../Policy/Advertisement';

class RegisterComponent extends React.PureComponent {
    state = {
        modal: false,
        modalInfo: '',
        modalTitle: '',
        createdAuthCode: '',
        authCodeCheck: false,
        vaildated: false,
    };

    componentWillUnmount = () => {
        this.onEdit({ type: 'clear', value: '' });
    };

    onEdit = ({ type, value }) => {
        this.props.editRegisterForm({ type, value });
    };

    handleCheckAllChange = () => {
        const { registerForm } = this.props.auth;

        this.onEdit({ type: 'checkAll', value: !registerForm.checkAll });

        this.onEdit({ type: 'termsOfUse', value: !registerForm.checkAll });
        this.onEdit({ type: 'privacyPolicy', value: !registerForm.checkAll });
        this.onEdit({ type: 'essentialGeneral', value: !registerForm.checkAll });
        this.onEdit({ type: 'essentialSensitive', value: !registerForm.checkAll });
        this.onEdit({ type: 'selectiveGeneral', value: !registerForm.checkAll });
        this.onEdit({ type: 'selectiveSensitive', value: !registerForm.checkAll });
        this.onEdit({ type: 'selectiveAdvertise', value: !registerForm.checkAll });
    };

    onSendMailHandler = event => {
        event.preventDefault();

        const { email } = this.props.auth.registerForm;

        if (!email) {
            this.setState({
                modal: true,
                modalTitle: '이메일 미입력',
                modalInfo: '이메일이 입력되지 않았습니다. 사용하실 이메일을 입력해주세요.',
            });
            return;
        }

        axios.post('/v1/auth/create/code', { email: this.props.auth.registerForm.email }).then(response => {
            console.log('>> 인증코드가 발송되었습니다.');
            alert('인증코드가 발송되었습니다.', response);
        });
    };

    onCheckHandler = event => {
        event.preventDefault();
        const { email, code } = this.props.auth.registerForm;
        axios.get('/v1/auth/vaildate/code', { params: { email, code } }).then(resolve => {
            const { data } = resolve;
            if (data === 'success') {
                notification.open({
                    message: '이메일 인증완료',
                    description: '이메일 인증이 완료되었습니다.',
                    type: 'success',
                });
            } else {
                notification.open({
                    message: '이메일 인증실패',
                    description: '이메일 인증에 실패하였습니다. 다시 입력하세요.',
                    type: 'warning',
                });
            }

            this.setState({ vaildated: true });
        });
    };

    pageOneValidator = () => {
        const { termsOfUse, privacyPolicy, essentialGeneral, essentialSensitive } = this.props.auth.registerForm;

        if (!termsOfUse) {
            return this.setState({
                modal: true,
                modalTitle: '서비스 이용 약관에 동의해주세요.',
                modalInfo: '원활한 서비스 이용을 위해 위드메이트 서비스 이용약관에 동의해주세요.',
            });
        }

        if (!privacyPolicy) {
            return this.setState({
                modal: true,
                modalTitle: '개인 정보 처리 방침에 동의해주세요.',
                modalInfo: '원활한 서비스 이용을 위해 위드메이트 개인 정보 처리 방침에 동의해주세요.',
            });
        }

        if (!essentialGeneral) {
            return this.setState({
                modal: true,
                modalTitle: '[필수/일반] 개인정보 수집 및 이용 동의에 동의해주세요.',
                modalInfo: '원활한 서비스 이용을 위해 위드메이트 [필수/일반] 개인정보 수집 및 이용 동의에 동의해주세요.',
            });
        }

        if (!essentialSensitive) {
            return this.setState({
                modal: true,
                modalTitle: '[필수/민감] 개인정보 수집 및 이용 동의에 동의해주세요.',
                modalInfo: '원활한 서비스 이용을 위해 위드메이트 [필수/일반] 개인정보 수집 및 이용 동의에 동의해주세요.',
            });
        }

        this.onEdit({ type: 'page', value: 2 });
    };

    pageSndValidator = () => {
        const { userType, name, photo = '', contact, sex, birth } = this.props.auth.registerForm;

        if (!name)
            return this.setState({
                modal: true,
                modalTitle: '성함을 입력해주세요.',
                modalInfo: '보다 원활한 서비스 이용을 위해 고객님의 성함을 입력해주세요.',
            });

        if (!sex) {
            return this.setState({
                modal: true,
                modalTitle: '성별을 선택해주세요.',
                modalInfo: '보다 원활한 서비스 이용을 위해 고객님의 성별을 선택해주세요.',
            });
        }

        if (!birth || birth.length < 8) {
            return this.setState({
                modal: true,
                modalTitle: '생년월일을 입력해주세요.',
                modalInfo: '보다 원활한 서비스 이용을 위해 고객님의 생년월일을 입력해주세요.',
            });
        }

        if (userType === USER) {
            if (!contact || contact.length < 11) {
                return this.setState({
                    modal: true,
                    modalTitle: '연락처를 입력해주세요.',
                    modalInfo: '원활한 위드메이트 서비스를 이용을 위해 고객님의 연락처를 입력해주세요.',
                });
            }
        }

        if (userType === MATE) {
            if (!photo) {
                return this.setState({
                    modal: true,
                    modalTitle: '메이트님의 프로필 사진을 설정해주세요.',
                    modalInfo: '실물 사진(ex. 증명사진)으로 프로필 사진을 설정하시면 고객과의 원활한 소통에 도움이 됩니다.',
                });
            }

            if (!contact) {
                return this.setState({
                    modal: true,
                    modalTitle: '메이트님의 연락처를 입력해주세요.',
                    modalInfo: '메이트님의 신원 확인을 위해 위드메이트에서 연락이 갈 예정입니다. 올바른 연락처를 입력해주세요.',
                });
            }
        }

        this.onEdit({ type: 'page', value: 3 });
    };

    onRegister = () => {
        const {
            termsOfUse, // 서비스 이용약관
            privacyPolicy, // 개인정보처리방침 동의
            essentialGeneral, // 필수_일반
            essentialSensitive, // 필수_민감
            selectiveGeneral = false, // 선택_일반
            selectiveSensitive = false, // 선택_민감
            selectiveAdvertise = false, // 선택_광고
            email,
            password,
            repassword,
            connection,
            recommenderName,
            longtermCare = false,
            marketing = false,
            userType,
            type = LOCAL,
            name,
            photo,
            contact,
            birth,
            code,
            identify,
            reason,
        } = this.props.auth.registerForm;

        const urlSource = window.location.search === '?source=dlive' ? 'dlive' : 'withmate';

        if (type === LOCAL) {
            if (!email || !validator.isEmail(email)) {
                return this.setState({
                    modal: true,
                    modalTitle: '올바른 이메일을 입력해주세요',
                    modalInfo: '잘못된 이메일을 입력하시면 예약 알림을 받지 못하는 등의 불이익을 받으실 수 있습니다',
                });
            }

            if (!password || password.length < 10) {
                return this.setState({
                    modal: true,
                    modalTitle: '비밀번호를 확인해주세요.',
                    modalInfo: '고객님의 개인정보 보호를 위해 비밀번호는 최소 10자리 이상으로 설정해주세요.',
                });
            }

            if (!repassword || password !== repassword) {
                return this.setState({
                    modal: true,
                    modalTitle: '비밀번호가 일치하지 않습니다.',
                    modalInfo: '입력하신 비밀번호와 비밀번호 확인란의 정보가 일치하지 않습니다.',
                });
            }

            if (!this.state.vaildated || !code) {
                return this.setState({
                    modal: true,
                    modalTitle: '이메일 인증을 먼저 진행해주세요.',
                    modalInfo: '이메일을 입력 후 인증을 먼저 진행해주세요.',
                });
            }
        }

        if (!connection) {
            return this.setState({
                modal: true,
                modalTitle: '유입 경로를 선택해주세요.',
                modalInfo: '위드메이트를 알게된 경로를 선택해주세요.',
            });
        }

        if (!identify) {
            return this.setState({
                modal: true,
                modalTitle: '보호자인지 본인인지 선택해주세요.',
                modalInfo: '보다 원활한 서비스 이용을 위해 보호자인지 본인인지 선택해주세요.',
            });
        }

        if (identify === '보호자') {
            if (!reason) {
                return this.setState({
                    modal: true,
                    modalTitle: '이용하는 이유를 입력하세요.',
                    modalInfo: '위드메이트를 이용하는 이유를 입력해주세요.',
                });
            }
        }

        this.props
            .checkUserId({ id: email, userType, type })
            .then(resolve => {
                if (!resolve) {
                    this.props
                        .register({
                            termsOfUse,
                            privacyPolicy,
                            essentialGeneral,
                            essentialSensitive,
                            selectiveGeneral,
                            selectiveSensitive,
                            selectiveAdvertise,

                            id: email,
                            password,
                            userType,
                            name,
                            code,
                            identify,
                            reason,
                            type,
                            photo,
                            contact,
                            marketing,
                            connection,
                            recommenderName,
                            birth,
                            longtermCare,
                            urlSource:
                                window.location.search === '?source=dlive' ? 'dlive' : window.location.search === '?source=metlife' ? 'metlife' : 'withmate',
                        })
                        .then(resolve => {
                            // 메이트 회원가입 처리
                            if (userType === MATE && resolve && resolve.message === 'success') {
                                this.props.editRegisterForm({ type: 'clear', value: '' });

                                notification.open({
                                    message: '회원가입을 완료하였습니다.',
                                    description: '관리자의 회원가입 승인 후 활동가능합니다.',
                                    type: 'success',
                                });
                            }

                            if (userType === USER && resolve === 'check parameters') {
                                notification.open({
                                    message: '회원가입에 실패하였습니다.',
                                    description: '누락된 정보가 없는지 확인해주세요.',
                                    type: 'success',
                                });
                            }

                            if (userType === USER && resolve && resolve.message === 'success') {
                                this.props.history.push(`/success`);
                            }
                        })
                        .catch(error => {
                            message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
                        });
                } else {
                    return this.setState({
                        modal: true,
                        modalTitle: '이미 존재하는 사용자의 이메일입니다.',
                        modalInfo: '아이디를 확인해주세요. 비밀번호를 분실하신 경우 비밀번호 찾기를 이용해주세요.',
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    setPhoto = ({ file }) => {
        this.onEdit({ type: 'photo', value: file.originFileObj });
    };

    formCompleteStatus = () => {
        let valid = true;
        if (!this.props.auth || !this.props.auth.registerForm) return false;

        const { page } = this.props.auth.registerForm;
        if (page === 1) {
            const { termsOfUse, privacyPolicy, essentialGeneral, essentialSensitive, selectiveGeneral, selectiveSensitive, selectiveAdvertise } =
                this.props.auth.registerForm;
            if (!termsOfUse) valid = false;
            if (!privacyPolicy) valid = false;
            if (!essentialGeneral) valid = false;
            if (!essentialSensitive) valid = false;
        }

        if (page === 2) {
            const { name, contact, sex, birth } = this.props.auth.registerForm;
            if (!name) valid = false;
            if (!contact || contact.length < 11) valid = false;
            if (!sex) valid = false;
            if (!birth || birth.length < 8) valid = false;
        }

        if (page === 3) {
            const { email, password, repassword, connection, recommenderName, code, type, identify, reason, longtermCare } = this.props.auth.registerForm;
            if (type === LOCAL) {
                if (!email) valid = false;
                if (!password) valid = false;
                if (!repassword) valid = false;
                if (!code) valid = false;
                if (!this.state.vaildated) valid = false;
            }
            if (!connection) valid = false;
            if (!identify) valid = false;

            if (identify === '보호자') {
                if (!reason) valid = false;
            }
        }

        return valid;
    };

    render() {
        const { registerForm } = this.props.auth;
        // console.log(registerForm)
        const status = this.formCompleteStatus();

        return (
            <div className='register-container'>
                <div className='inner-container'>
                    <div className='register-form'>
                        <div className='title-wrapper'>
                            <p>회원가입</p>
                            <span>{registerForm.page}/3</span>
                        </div>
                        {registerForm.page === 1 ? (
                            <div className='form snd'>
                                <Agree>
                                    <Checkbox onChange={this.handleCheckAllChange} value={registerForm.checkAll || false}>
                                        전체 동의하기
                                    </Checkbox>
                                </Agree>
                                <p style={{ fontSize: '12px', color: 'gray' }}>
                                    [선택/일반] 개인정보 수집 및 이용 동의, [선택/민감] 개인정보 수집 및 이용 동의, [맞춤 광고] 개인정보 수집 및 이용 동의를
                                    포함합니다.
                                </p>
                                <br />

                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'termsOfUse', value: !registerForm.termsOfUse });
                                        }}
                                        checked={registerForm.termsOfUse || false}
                                    >
                                        [필수] 서비스 이용 약관
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({ modal: true, modalTitle: '이용약관 확인', modalInfo: <Terms isComponent /> });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>

                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'privacyPolicy', value: !registerForm.privacyPolicy });
                                        }}
                                        checked={registerForm.privacyPolicy || false}
                                    >
                                        [필수] 개인 정보 처리 방침 동의
                                    </Checkbox>

                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({ modal: true, modalTitle: '개인정보 처리방침 확인', modalInfo: <Privacy isComponent /> });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>

                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'essentialGeneral', value: !registerForm.essentialGeneral });
                                        }}
                                        checked={registerForm.essentialGeneral || false}
                                    >
                                        [필수/<span style={{ color: '#3399ff' }}>일반</span>] 개인정보 수집 및 이용 동의
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalTitle: '[필수/일반] 개인정보 수집 및 이용 동의',
                                                modalInfo: <EssentialNormal isComponent />,
                                            });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>
                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'essentialSensitive', value: !registerForm.essentialSensitive });
                                        }}
                                        checked={registerForm.essentialSensitive || false}
                                    >
                                        [필수/<span style={{ color: '#ff4040' }}>민감</span>] 개인정보 수집 및 이용 동의
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalTitle: '[필수/민감] 개인정보 수집 및 이용 동의',
                                                modalInfo: <EssentialSensitive isComponent />,
                                            });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>
                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'selectiveGeneral', value: !registerForm.selectiveGeneral });
                                        }}
                                        checked={registerForm.selectiveGeneral || false}
                                    >
                                        [<span style={{ color: '#03c75a' }}>선택</span>/<span style={{ color: '#3399ff' }}>일반</span>] 개인정보 수집 및 이용
                                        동의
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalTitle: '[선택/일반] 개인정보 수집 및 이용 동의',
                                                modalInfo: <SelectiveNormal isComponent />,
                                            });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>
                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'selectiveSensitive', value: !registerForm.selectiveSensitive });
                                        }}
                                        checked={registerForm.selectiveSensitive || false}
                                    >
                                        [<span style={{ color: '#03c75a' }}>선택</span>/<span style={{ color: '#ff4040' }}>민감</span>] 개인정보 수집 및 이용
                                        동의
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalTitle: '[선택/민감] 개인정보 수집 및 이용 동의',
                                                modalInfo: <SelectiveSensitive isComponent />,
                                            });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>
                                <Agree>
                                    <Checkbox
                                        onChange={() => {
                                            this.onEdit({ type: 'selectiveAdvertise', value: !registerForm.selectiveAdvertise });
                                        }}
                                        checked={registerForm.selectiveAdvertise || false}
                                    >
                                        [<span style={{ color: '#03c75a' }}>선택</span>/<span style={{ color: '#FF6666' }}>광고</span>] 개인정보 수집 및 이용
                                        동의
                                    </Checkbox>
                                    <AgreeLinkText
                                        onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalTitle: '[맞춤 광고] 개인정보 수집 및 이용 동의',
                                                modalInfo: <Advertisement isComponent />,
                                            });
                                        }}
                                    >
                                        <p style={{ color: '#A5A5A5' }}>내용보기</p>
                                    </AgreeLinkText>
                                </Agree>

                                <DivisionLine />

                                <Button
                                    onClick={() => {
                                        if (!status) return null;

                                        this.pageOneValidator();
                                    }}
                                    type='primary'
                                    style={!status ? { backgroundColor: '#E6E6E6', border: 0, cursor: 'not-allowed' } : {}}
                                >
                                    계속하기
                                </Button>
                            </div>
                        ) : registerForm.page === 2 ? (
                            <div className='form snd'>
                                <label>이름</label>
                                <WithmateInput
                                    type='text'
                                    placeholder='ex. 홍길동'
                                    value={registerForm.name || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'name', value: ev.target.value });
                                    }}
                                />

                                <DivisionLine small />

                                <label>성별</label>
                                <div className='type-button-wrapper'>
                                    <div
                                        className={`type-button ${registerForm.sex && registerForm.sex === '남' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.onEdit({ type: 'sex', value: '남' });
                                        }}
                                    >
                                        남자
                                    </div>
                                    <div
                                        className={`type-button ${registerForm.sex && registerForm.sex === '녀' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.onEdit({ type: 'sex', value: '녀' });
                                        }}
                                    >
                                        여자
                                    </div>
                                </div>

                                <DivisionLine small />

                                <label>생년월일</label>
                                <WithmateInput
                                    type='text'
                                    maxLength={8}
                                    placeholder='숫자만 입력해주세요 (ex. 19880320)'
                                    value={registerForm.birth || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'birth', value: ev.target.value });
                                    }}
                                />

                                <DivisionLine />

                                <label>연락처</label>
                                <WithmateInput
                                    type='text'
                                    maxLength={11}
                                    placeholder='숫자만 입력해주세요.(ex. 01014231324)'
                                    value={registerForm.contact || ''}
                                    onChange={ev => {
                                        this.onEdit({ type: 'contact', value: ev.target.value });
                                    }}
                                />

                                <DivisionLine />

                                <Button
                                    onClick={() => {
                                        if (!status) return null;

                                        this.pageSndValidator();
                                    }}
                                    type='primary'
                                    style={!status ? { backgroundColor: '#E6E6E6', border: 0, cursor: 'not-allowed' } : {}}
                                >
                                    계속하기
                                </Button>

                                <div className='link-wrapper'>
                                    <span className='text'>메이트 신청을 희망하시나요? </span>
                                    <span
                                        onClick={() => {
                                            this.props.history.push('/mate/apply');
                                        }}
                                        className='link'
                                    >
                                        메이트 신청하기
                                    </span>
                                </div>

                                <div className='link-wrapper'>
                                    <span className='text'>이미 계정이 있으신가요? </span>
                                    <span
                                        onClick={() => {
                                            this.props.history.push('/user/login');
                                        }}
                                        className='link'
                                    >
                                        로그인
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className='form fst'>
                                    {registerForm.type === KAKAO ? null : (
                                        <>
                                            <label>이메일</label>
                                            <div>
                                                <WithmateInput
                                                    type='email'
                                                    value={registerForm.email || ''}
                                                    onChange={ev => {
                                                        this.onEdit({ type: 'email', value: ev.target.value });
                                                    }}
                                                    required
                                                    style={{ width: '180px' }}
                                                    placeholder='이메일을 입력해주세요.'
                                                />
                                                <button
                                                    onClick={this.onSendMailHandler}
                                                    style={{
                                                        width: '90px',
                                                        height: '44px',
                                                        marginLeft: '10px',
                                                        backgroundColor: '#3bc1ff',
                                                        borderColor: '#3bc1ff',
                                                        border: '0',
                                                        outline: '0',
                                                        borderRadius: '3px',
                                                    }}
                                                >
                                                    인증하기
                                                </button>
                                            </div>

                                            <div>
                                                <WithmateInput
                                                    type='password'
                                                    value={registerForm.code || ''}
                                                    onChange={ev => {
                                                        this.onEdit({ type: 'code', value: ev.target.value });
                                                    }}
                                                    required
                                                    style={{ width: '180px' }}
                                                    placeholder='인증번호를 입력해주세요.'
                                                />
                                                <button
                                                    onClick={this.onCheckHandler}
                                                    style={{
                                                        width: '90px',
                                                        height: '44px',
                                                        marginLeft: '10px',
                                                        backgroundColor: '#3bc1ff',
                                                        borderColor: '#3bc1ff',
                                                        border: '0',
                                                        outline: '0',
                                                        borderRadius: '3px',
                                                    }}
                                                >
                                                    확인
                                                </button>
                                            </div>

                                            <DivisionLine small />

                                            <label style={{ display: 'block' }}>비밀번호</label>
                                            <WithmateInput
                                                password='true'
                                                type='password'
                                                onChange={ev => {
                                                    this.onEdit({ type: 'password', value: ev.target.value });
                                                }}
                                                placeholder='10자리 이상 비밀번호를 입력해주세요.'
                                                value={registerForm.password || ''}
                                            />

                                            <DivisionLine small />

                                            <label style={{ display: 'block' }}>비밀번호 확인</label>
                                            <WithmateInput
                                                type='password'
                                                password='true'
                                                onChange={ev => {
                                                    this.onEdit({ type: 'repassword', value: ev.target.value });
                                                }}
                                                placeholder='비밀번호를 확인해주세요.'
                                                value={registerForm.repassword || ''}
                                            />

                                            <DivisionLine small />
                                        </>
                                    )}

                                    <label style={{ display: 'block' }}>위드메이트를 어떻게 알고 오셨나요?</label>
                                    <WithmateSelect
                                        style={{ height: '48px', width: '100%' }}
                                        placeholder='유입 경로를 선택해주세요.'
                                        value={registerForm.connection || undefined}
                                        onChange={value => {
                                            this.onEdit({ type: 'connection', value });
                                        }}
                                    >
                                        <Select.Option value='메트라이프'>메트라이프(360Future)</Select.Option>
                                        <Select.Option value='딜라이브'>딜라이브</Select.Option>
                                        <Select.Option value='검색'>검색</Select.Option>
                                        <Select.Option value='언론기사'>언론기사</Select.Option>
                                        <Select.Option value='광고'>광고</Select.Option>
                                        <Select.Option value='지인 추천'>지인 추천</Select.Option>
                                    </WithmateSelect>

                                    <DivisionLine small />

                                    {registerForm.connection === '지인 추천' ? (
                                        <>
                                            <label style={{ display: 'block' }}>추천인 성함을 입력하세요.</label>
                                            <WithmateInput
                                                type='text'
                                                onChange={ev => {
                                                    this.onEdit({ type: 'recommenderName', value: ev.target.value });
                                                }}
                                                value={registerForm.recommenderName || ''}
                                            />
                                        </>
                                    ) : null}

                                    <DivisionLine small />

                                    <label>본인인가요 ? 보호자인가요 ?</label>
                                    <div className='type-button-wrapper'>
                                        <div
                                            className={`type-button ${registerForm.identify && registerForm.identify === '본인' ? 'active' : ''}`}
                                            onClick={() => {
                                                this.onEdit({ type: 'identify', value: '본인' });
                                            }}
                                        >
                                            본인
                                        </div>
                                        <div
                                            className={`type-button ${registerForm.identify && registerForm.identify === '보호자' ? 'active' : ''}`}
                                            onClick={() => {
                                                this.onEdit({ type: 'identify', value: '보호자' });
                                            }}
                                        >
                                            보호자
                                        </div>
                                    </div>

                                    <DivisionLine small />

                                    {registerForm.identify === '본인' ? null : (
                                        <>
                                            <label style={{ display: 'block' }}>이용하는 이유 (보호자 선택시)</label>
                                            <WithmateInput
                                                type='text'
                                                onChange={ev => {
                                                    this.onEdit({ type: 'reason', value: ev.target.value });
                                                }}
                                                placeholder='육아, 직장등'
                                                value={registerForm.reason || ''}
                                            />

                                            <DivisionLine small />
                                        </>
                                    )}

                                    {/* <Agree>
                                        <Checkbox
                                            onChange={() => {
                                                this.onEdit({ type: 'longtermCare', value: !registerForm.longtermCare });
                                            }}
                                            value={registerForm.agree || false}
                                        >
                                            장기요양등급 상담 신청하기 (선택)
                                        </Checkbox>
                                    </Agree>
                                    <Alert>*장기요양등급 상담 신청을 원하시면 체크해주세요.</Alert> */}

                                    <DivisionLine small />

                                    <Agree>
                                        <Checkbox
                                            onClick={() => {
                                                this.onEdit({ type: 'marketing', value: !registerForm.marketing });
                                            }}
                                        >
                                            개인정보 마케팅 활용 동의 (선택)
                                        </Checkbox>
                                        <a
                                            className='point'
                                            onClick={() => {
                                                this.setState({ modal: true, modalTitle: '개인정보 마케팅 활용 동의', modalInfo: <Marketing isComponent /> });
                                            }}
                                        >
                                            더보기 {'>'}
                                        </a>
                                    </Agree>

                                    <Alert>*개인 정보 활용에 동의할 경우, 2000 포인트를 지급합니다.</Alert>

                                    <DivisionLine small />

                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            this.onRegister();
                                        }}
                                        style={{ display: 'block' }}
                                        style={!status ? { backgroundColor: '#E6E6E6', border: 0, cursor: 'not-allowed' } : {}}
                                    >
                                        가입하기
                                    </Button>

                                    <div className='link-wrapper'>
                                        <span className='text'>이미 계정이 있으신가요? </span>
                                        <a
                                            className='link'
                                            onClick={() => {
                                                this.props.history.push('/user/login');
                                            }}
                                        >
                                            로그인
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {this.state.modal && (
                    <CommonWithmateModal
                        visible={this.state.modal}
                        config={{
                            title: this.state.modalTitle,
                            description: this.state.modalInfo,
                            confirmText: '확인 및 닫기',
                            noClose: false,
                        }}
                        onOk={() => {
                            this.setState({ modal: false, modalTitle: '', modalInfo: '' });
                        }}
                        onCancel={() => {
                            this.setState({ modal: false, modalTitle: '', modalInfo: '' });
                        }}
                    />
                )}
            </div>
        );
    }
}
export default RegisterComponent;

const Agree = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 8px;

    span {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.7);
    }

    span.point {
        color: #3bc1ff;
    }

    a.point {
        color: #3bc1ff;
        cursor: pointer;
        text-decoration: none;
    }

    .ant-checkbox-wrapper {
        margin-bottom: 0px !important;
    }
`;

const Alert = styled.p`
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.15px;
    color: #ff5500;
`;

const AgreeLinkText = styled.p`
    margin-bottom: 4px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);s

    cursor: pointer;
    &:hover {
        color: #3bc1ff;
        transition: all 0.3s;
    }
`;
