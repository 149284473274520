import React from 'react';
import { connect } from 'react-redux';
import StyledPendingContainer from './pending.styled';
import Pending from './pending';

class PendingPage extends React.PureComponent {
    render() {
        return (
            <StyledPendingContainer>
                <Pending {...this.props} />
            </StyledPendingContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapDispatchToProps = dsipatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PendingPage);
