import React from 'react';

class MarketingComponent extends React.PureComponent {
    render() {
        return (
            <div className='marketing-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>위드메이트 마케팅 정보 활용 동의</h2>
                    </div>

                    <div className='context-block'>
                        <p>
                            마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, <br />
                            거부 시에도 위드메이트 서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.
                        </p>
                        <br/>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>개인정보 수집 항목</th>
                                    <th>개인정보 수집 이용 목적</th>
                                    <th>보유 및 이용기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>이름, 연락처, 이메일</td>
                                    <td>• 이벤트 운영 및 광고성 정보 전송
                                        <br/>
                                        • 서비스 관련 정보 전송
                                    </td>
                                    <td>이용자가 동의를 철회하거나, 회원 탈퇴시 즉시 삭제</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default MarketingComponent;
