import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../../theme';

const StyledNormalContainer = styled.div`
    margin-top: 55px;

    .auth-container {
        // display: flex;
        // flex-direction: column;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.02);
    }

    .navigation-container {
        flex-shrink: 0;
        border-bottom: 1px solid #e6e6e6;
        background-color: white;

        .inner-container {
            padding: 0px;
        }

        .user-info-container {
            display: flex;
            align-items: center;
            padding: 82px 30px;

            .default-info {
                flex: 1;
                display: flex;
                align-items: center;

                div {
                    width: 110px;
                    height: 110px;
                    border-radius: 55px;
                    margin-right: 20px;
                    background-color: #e6e6e6;
                    position: relative;
                    overflow: hidden;

                    img {
                        height: 110px;
                        position: absolute;
                        margin: auto;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                    }

                    span {
                        display: inline-block;
                        position: absolute;
                        margin: auto;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        line-height: 110px;
                        text-align: center;
                        color: #ada5a5;
                    }
                }

                p {
                    flex: 1;
                    font-size: ${sizeHandler(38)};
                    font-weight: bold;
                    letter-spacing: -0.6px;
                    color: rgba(0, 0, 0, 0.8);
                }
            }

            .reserve-info {
                flex: 1;

                .title {
                    margin-bottom: 12px;

                    p {
                        font-size: ${sizeHandler(12)};
                        font-weight: bold;
                        color: rgba(145, 145, 145, 0.8);
                    }
                }

                .value-wrapper {
                    display: flex;
                    align-items; center;

                    .value-block {
                        flex: 1;

                        .title {
                            img {
                                height: 14px;
                                margin-right: 4px;
                            }
    
                            span {
                                font-size: ${sizeHandler(14)};
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.8);
                            }
                        }
    
                        .value {
                            font-size: ${sizeHandler(16)};
                            font-weight: bold;
                            color: #3bc1ff;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .navigator {
            display: flex;
            align-items: center;
            padding: 0px 30px;

            .nav-tab {
                margin-right: 32px;
                padding-bottom: 12px;
                position: relative;

                .name {
                    font-size: ${sizeHandler(14)};
                    text-align: center;
                    cursor: pointer;

                    &.active { 
                        font-weight: bold;
                        color: #3bc1ff;
                    }
                }

                span.underline {
                    background-color: #3bc1ff;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;

                    &.active {
                        width: 100%;
                        height: 3px;
                        display: inline-block;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .user-info-container {
                flex-direction: column;
                align-items: flex-start;

                .reserve-info {
                    margin-top: 40px;
                }

                .value-wrapper {
                    flex-direction: column;

                    .value-block:last-child {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .usage-info-container {
        flex: 1;
        // background-color: rgba(0, 0, 0, 0.02);

        .inner-container {
            height: 100%;
            padding: 56px 30px;

            @media (max-width: 768px) {
                padding: 40px 16px;
            }
        }

        .filter-wrapper {
            margin-bottom: 20px;

            .filter {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: rgba(0, 0, 0, 0.4);
                margin-right: 32px;
                cursor: pointer;
            }

            .filter.active {
                color: ${mainColor};
            }
        }
    }

    .user-info-wrapper {
        .wrapper {
            padding: 36px;
            background-color: white;
            border-radius: 4px;
            border: solid 1px #e6e6e6;
            float: left;
            width: calc(50% - 10px);
            
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .wrapper.etc-info {
            float: right;
        }
        
        .title-wrapper {
            font-size: ${sizeHandler(20)};
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 28px;
        }

        .service {
            margin-bottom: 20px;

            p.info {
                font-size: ${sizeHandler(12)};
                font-weight: normal;
                letter-spacing: -0.15px;
                color: rgba(0, 0, 0, 0.7);
            }

            input {
                height: 36px;
                display: inline-block;
                width: 100%;
            }

            button {
                width: 100%;
                height: 48px;
                border-radius: 2px;
                color: white;
            }

            .ant-upload-drag-container {
                .title {
                    margin-top: 12px;
                    font-size: ${sizeHandler(14)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.8);
                }
        
                .reco {
                    font-size: ${sizeHandler(12)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: #a7a7a7;
                }
            }

            .flex-wrapper {
                display: flex;

                p {
                    flex: 1;
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: #606060;
                    cursor: pointer;
                }
            }
        }

        .default-info {
            margin-right: 20px;
        }

        .card-info {
            margin-right: 20px;
            margin-top: 20px;
        }

        .privacy-info {
            margin-top: -352px;
            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }

        .etc-info {
            margin-top: -290px;
            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }
    }

    .user-profile-wrapper {
        .wrapper {
            padding: 36px;
            background-color: white;
            border-radius: 4px;
            border: solid 1px #e6e6e6;
            float: left;
            width: calc(100% - 10px);
            
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .wrapper.etc-info {
            float: right;
        }
        
        .title-wrapper {
            font-size: ${sizeHandler(20)};
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 28px;
        }

        .service {
            margin-bottom: 20px;

            p.info {
                font-size: ${sizeHandler(12)};
                font-weight: normal;
                letter-spacing: -0.15px;
                color: rgba(0, 0, 0, 0.7);
            }

            input {
                height: 36px;
                display: inline-block;
                width: 100%;
            }

            button {
                width: 110px;
                height: 48px;
                border-radius: 2px;
                color: white;
            }

            .ant-upload-drag-container {
                .title {
                    margin-top: 12px;
                    font-size: ${sizeHandler(14)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.8);
                }
        
                .reco {
                    font-size: ${sizeHandler(12)};
                    letter-spacing: -0.3px;
                    text-align: center;
                    color: #a7a7a7;
                }
            }

            .flex-wrapper {
                display: flex;

                p {
                    flex: 1;
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: #606060;
                    cursor: pointer;
                }
            }
        }

        .default-info {
            margin-right: 20px;
        }

        .privacy-info {

            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }

        .etc-info {
            margin-top: 20px;
        }
    }

    input {
        height: 36px;
        display: inline-block;
        width: 100%;
    }

    .ant-input-number-input-wrap {
        height: 100%;

        .ant-input-number-input {
            height: 100%;
        }

        input {
            height: 100%;
        }
    } 
`;

export const PointBoxRow = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    > div:last-child {
        margin-right: 0px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0px;
        flex-direction: column;
    }
`;

export const PointBox = styled.div`
    width: calc(50% - 10px);
    margin-right: 20px;

    padding: 32px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;

    @media (max-width: 480px) {
        margin-right: 0px;
        margin-bottom: 10px;

        max-height: 320px;
        padding: 16px;

        width: 100%;
    }
`;

export const PointBox2 = styled.div`
    width: 100%;
    margin-right: 20px;

    padding: 32px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;

    @media (max-width: 480px) {
        margin-right: 0px;
        margin-bottom: 10px;

        max-height: 320px;
        padding: 16px;

        width: 100%;
    }
`;

export const PointBox3 = styled.div`
    width: 100%;
    margin-right: 20px;

    padding: 32px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;

    @media (max-width: 480px) {
        margin-right: 0px;
        margin-bottom: 10px;

        max-height: 370px;
        padding: 16px;

        width: 100%;
    }
`;

export const PointContent = styled.div`
    max-height: 400px;
    padding-right: 10px;
    overflow-y: scroll;

    @media (max-width: 480px) {
        max-height: 320px;
    }
`;

export const PointBoxTitle = styled.h4`
    margin-top: 0px;
    margin-bottom: 10px;

    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

export const PointTotal = styled.h5`
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.7px;
    text-align: left;
    color: #3bc1ff;

    @media (max-width: 480px) {
        font-size: 26px;
    }
`;

export const PointHistoryRow = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
`;

export const PointHistoryNameRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 4px;

    > .title {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: -0.2px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);

        @media (max-width: 480px) {
            font-size: 12px;
        }
    }

    > .value {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: -0.1px;
        text-align: right;
        color: ${props => (props.add ? '#3bc1ff' : '#ff5500')};

        @media (max-width: 480px) {
            font-size: 12px;
        }
    }
`;

export const PointHistoryValueRow = styled(PointHistoryNameRow)`
    margin-bottom: 0px;

    > .title {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.1px;
        text-align: left;
        color: #afb0b2;

        @media (max-width: 480px) {
            font-size: 10px;
        }
    }

    > .value {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: -0.15px;
        text-align: left;
        color: #afb0b2;

        @media (max-width: 480px) {
            font-size: 10px;
        }
    }
`;

export const NoHistory = styled.div`
    padding: 12px;
    text-align: center;

    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
`;

export default StyledNormalContainer;
