import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as authAPI from '../../Lib/Api/auth';
import { USER, MATE } from '../../Lib/variables';

// middleware
import { createReduxThunk } from '../../Lib/middleware';

const EDIT_LOGIN_FORM = 'auth/EDIT_LOGIN_FORM';
const EDIT_REGISTER_FORM = 'auth/EDIT_REGISTER_FORM';

const ADD_LONGTERMCARE = 'auth/ADD_LONGTERMCARE';
const ADD_LONGTERMCARE_SUCCESS = 'auth/ADD_LONGTERMCARE_SUCCESS';

// 환자 프로필
const ADD_PATIENT = 'auth/ADD_PATIENT';
const ADD_PATIENT_SUCCESS = 'auth/ADD_PATIENT_SUCCESS';
const GET_PATIENT = 'auth/GET_PATIENT';
const GET_PATIENT_SUCCESS = 'auth/GET_PATIENT_SUCCESS';
const EDIT_ADD_PATIENT_FORM = 'auth/EDIT_ADD_PATIENT_FORM';
const DELETE_PATIENT = 'auth/DELETE_PATIENT';
const DELETE_PATIENT_SUCCESS = 'auth/DELETE_PATIENT_SUCCESS';
const MODIFY_PATIENT = 'auth/MODIFY_PATIENT';
const MODIFY_PATIENT_SUCCESS = 'auth/MODIFY_PATIENT_SUCCESS';
const PATIENT_INFO = 'auth/PATIENT_INFO';
const PATIENT_INFO_SUCCESS = 'auth/PATIENT_INFO_SUCCESS';

// 카드 정보
const ADD_CARD = 'auth/ADD_CARD';
const ADD_CARD_SUCCESS = 'auth/ADD_CARD_SUCCESS';
const GET_CARD = 'auth/GET_CARD';
const GET_CARD_SUCCESS = 'auth/GET_CARD_SUCCESS';
const EDIT_ADD_CARD_FORM = 'auth/EDIT_ADD_CARD_FORM';
const DELETE_CARD = 'auth/DELETE_CARD';
const DELETE_CARD_SUCCESS = 'auth/DELETE_CARD_SUCCESS';
const MODIFY_CARD = 'auth/MODIFY_CARD';
const MODIFY_CARD_SUCCESS = 'auth/MODIFY_CARD_SUCCESS';
const CARD_INFO = 'auth/CARD_INFO';
const CARD_INFO_SUCCESS = 'auth/CARD_INFO_SUCCESS';

const MATE_INFO = 'auth/MATE_INFO';
const MATE_INFO_SUCCESS = 'auth/MATE_INFO_SUCCESS';
const GET_MATE = 'auth/GET_MATE';
const GET_MATE_SUCCESS = 'auth/GET_MATE_SUCCESS';

const EDIT_FINDPASSWORD_FORM = 'auth/EDIT_FINDPASSWORD_FORM';
const EDIT_PASSWORD_FORM = 'auth/EDIT_PASSWORD_FORM';
const EDIT_INFO = 'auth/EDIT_INFO';
const EDIT_AUTH = 'auth/EDIT_AUTH';

const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGOUT = 'auth/LOGOUT';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
const VALIDATE_USER_ID = 'auth/VALIDATE_USER_ID';
const VALIDATE_USER_ID_SUCCESS = 'auth/VALIDATE_USER_ID_SUCCESS';
const REGISTER = 'auth/REGISTER';
const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
const CHECK_AUTH = 'auth/CHECK_AUTH';
const CHECK_AUTH_SUCCESS = 'auth/CHECK_AUTH_SUCCESS';
const FIND_PASSWORD = 'auth/FIND_PASSWORD';
const FIND_PASSWORD_SUCCESS = 'auth/FIND_PASSWORD_SUCCESS';
const MODIFY_PASSWORD = 'auth/MODIFY_PASSWORD';
const MODIFY_PASSWORD_SUCCESS = 'auth/MODIFY_PASSWORD_SUCCESS';

const USER_WITHDRAWL = 'auth/USER_WITHDRAWL';
const USER_WITHDRAWL_SUCCESS = 'auth/USER_WITHDRAWL_SUCCESS';
const KAKAO_WITHDRAWL = 'auth/KAKAO_WITHDRAWL';
const KAKAO_WITHDRAWL_SUCCESS = 'auth/KAKAO_WITHDRAWL_SUCCESS';

const CONFIRM_EMAILAUTH = 'auth/CONFIRM_EMAILAUTH';
const CONFIRM_EMAILAUTH_SUCCESS = 'auth/CONFIRM_EMAILAUTH_SUCCESS';

export const login = createReduxThunk(LOGIN, authAPI.login);
export const logout = createReduxThunk(LOGOUT, authAPI.logout);
export const register = createReduxThunk(REGISTER, authAPI.register);

export const checkUserId = createReduxThunk(VALIDATE_USER_ID, authAPI.checkUserId);
export const checkAuth = createReduxThunk(CHECK_AUTH, authAPI.checkAuth);
export const findPassword = createReduxThunk(FIND_PASSWORD, authAPI.findPassword);
export const modifyPassword = createReduxThunk(MODIFY_PASSWORD, authAPI.modifyPassword);

export const withdrawlUser = createReduxThunk(USER_WITHDRAWL, authAPI.withdrawl);
export const withdrawlKakao = createReduxThunk(KAKAO_WITHDRAWL, authAPI.withdrawlKakao);

export const confirmEmailAuth = createReduxThunk(CONFIRM_EMAILAUTH, authAPI.confirmEmailAuth);

export const editAuth = createAction(EDIT_AUTH, input => input);
export const editLoginForm = createAction(EDIT_LOGIN_FORM, input => input);
export const editRegisterForm = createAction(EDIT_REGISTER_FORM, input => input);

export const onEditLongTermCare = createAction(ADD_LONGTERMCARE, authAPI.onAddLongTermCare);

export const fetchPatient = createReduxThunk(GET_PATIENT, authAPI.fetchPatient);
export const addPatient = createReduxThunk(ADD_PATIENT, authAPI.addPatient);
export const editAddPatientForm = createAction(EDIT_ADD_PATIENT_FORM, input => input);
export const deletePatient = createReduxThunk(DELETE_PATIENT, authAPI.deletePatient);
export const modifyPatient = createReduxThunk(MODIFY_PATIENT, authAPI.modifyPatient);
export const getPatientInfo = createReduxThunk(PATIENT_INFO, authAPI.getPatientInfo);

export const fetchCard = createReduxThunk(GET_CARD, authAPI.fetchCard);
export const addCard = createReduxThunk(ADD_CARD, authAPI.addCard);
export const editAddCardForm = createAction(EDIT_ADD_CARD_FORM, input => input);
export const deleteCard = createReduxThunk(DELETE_CARD, authAPI.deleteCard);
export const modifyCard = createReduxThunk(MODIFY_CARD, authAPI.modifyCard);
export const getCardInfo = createReduxThunk(CARD_INFO, authAPI.getCardInfo);

export const getMateInfo = createReduxThunk(MATE_INFO, authAPI.getMateInfo);
export const fetchMate = createReduxThunk(GET_MATE, authAPI.fetchMate);

export const editFindPasswordForm = createAction(EDIT_FINDPASSWORD_FORM, input => input);
export const editPasswordForm = createAction(EDIT_PASSWORD_FORM, input => input);
export const editInfo = createAction(EDIT_INFO, input => input);

// initalState
const initialState = {
    id: '',
    name: '',
    photo: '',
    userType: '',
    allowed: null,
    ticket: {
        assist: 0,
        unemergency: 0,
    },

    loginForm: {
        userType: '',
    },
    registerForm: {
        userType: USER,
        page: 1,
        sex: '남',
        identify: '본인',
        agree: false,
    },

    longTermCare: [],
    longTermCareInfo: {},

    patientList: [],
    patientInfo: {},
    addPatientForm: {
        sex: '남'
    },

    cardList: [],
    cardInfo: {},
    addCardForm: {
        cardName: '',
        cardNum: '',
        year: '',
        month: ''
    },

    findForm: {
        userType: USER,
    },
    passwordForm: {
        password: '',
        newPassword: '',
        reNewPassword: '',
    },
    infoForm: {},

    mateInfo: {},
};

// reducer
const authReducer = handleActions(
    {
        [EDIT_LOGIN_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['loginForm'] = {
                        userType: USER,
                    };
                } else {
                    draft['loginForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_REGISTER_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['registerForm'] = {
                        userType: USER,
                        page: 1,
                        sex: '남',
                        identify: '본인',
                        agree: false,
                    };
                } else {
                    draft['registerForm'][payload.type] = payload.value;
                }
            }),

        [EDIT_ADD_PATIENT_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['addPatientForm'] = {
                        sex: '남',
                    };
                } else {
                    draft['addPatientForm'][payload.type] = payload.value;
                }
            }),

        [EDIT_FINDPASSWORD_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['findForm'] = {
                        userType: USER,
                    };
                } else {
                    draft['findForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_PASSWORD_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['passwordForm'] = {};
                } else {
                    draft['passwordForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_INFO]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['infoForm'] = {};
                } else {
                    draft['infoForm'][payload.type] = payload.value;
                }
            }),
        [EDIT_AUTH]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['id'] = '';
                    draft['userType'] = '';
                    draft['type'] = '';
                    draft['photo'] = '';
                    draft['name'] = '';
                } else {
                    draft[payload.type] = payload.value;
                }
            }),
        [LOGIN_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload._id) {
                    draft['id'] = payload._id;
                    draft['photo'] = payload.photo;
                    draft['name'] = payload.name;
                    draft['type'] = payload.type;
                    draft['userType'] = meta.userType;
                    // if (payload.ticket) {
                    //     draft['ticket'] = payload.ticket;
                    // }

                    localStorage.setItem('id', payload._id);
                    localStorage.setItem('photo', payload.photo);
                    localStorage.setItem('name', payload.name);
                    localStorage.setItem('contact', payload.contact);
                    localStorage.setItem('userType', meta.userType);

                    window.location.href = '/';
                }
            }),
        [USER_WITHDRAWL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    draft['id'] = '';
                    draft['userType'] = '';
                    draft['photo'] = '';
                    draft['name'] = '';
                    draft['password'] = '';
                    draft['allowed'] = false;
                    localStorage.clear();
                    window.location.href = '/';
                }
            }),
        [KAKAO_WITHDRAWL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    draft['id'] = '';
                    draft['userType'] = '';
                    draft['photo'] = '';
                    draft['name'] = '';
                    draft['allowed'] = false;
                    localStorage.clear();
                    window.location.href = '/';
                }
            }),
        [LOGOUT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    draft['id'] = '';
                    draft['userType'] = '';
                    draft['photo'] = '';
                    draft['name'] = '';
                    draft['allowed'] = false;
                    localStorage.clear();
                    window.location.href = '/';
                }
            }),
        [VALIDATE_USER_ID_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log('VALIDATE_USER_ID_SUCCESS: ', payload);
            }),
            
        [REGISTER_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    const { data } = payload;
                    draft['id'] = data._id;
                    draft['photo'] = data.photo;
                    draft['name'] = data.name;
                    draft['userType'] = meta.userType;
                    draft['allowed'] = data.allowed;
                    if (payload.ticket) {
                        draft['ticket'] = payload.ticket;
                    }

                    localStorage.setItem('id', data._id);
                    localStorage.setItem('photo', data.photo);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('allowed', data.allowed);
                    localStorage.setItem('userType', meta.userType);

                    // window.location.href = '/success';
                }
            }),

        [ADD_LONGTERMCARE_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['longTermCare'];
                    draft['longTermCare'] = list.map(info => {
                        const { name, age, contact, sex, region, gradePresence, gradeLevel } = meta.info;
                        info['name'] = name;
                        info['age'] = age;
                        info['contact'] = contact;
                        info['sex'] = sex;
                        info['region'] = region;
                        info['gradePresence'] = gradePresence;
                        info['gradeLevel'] = gradeLevel;

                        return info;
                    })
                }
            }),

        [CHECK_AUTH_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload._id) {
                    draft['id'] = payload._id;
                    draft['photo'] = payload.photo;
                    draft['name'] = payload.name;
                    draft['allowed'] = payload.allowed;
                    draft['type'] = payload.type;
                    draft['userType'] = meta.userType;
                    if (payload.ticket) {
                        draft['ticket'] = payload.ticket;
                    }
                } else {
                    localStorage.clear();
                    window.location.href = '/';
                    return null;
                }
            }),
        [FIND_PASSWORD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log();
            }),
        [MODIFY_PASSWORD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log();
            }),

        [CONFIRM_EMAILAUTH_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log();
            }),

        [ADD_PATIENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['patientList'].push(payload.data);
                }
            }),

        [GET_PATIENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['patientList'] = payload.data;
                }
            }),

        [PATIENT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['patientInfo'] = payload.data;
            }),

        [MODIFY_PATIENT_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['patientList'];
                    draft['patientList'] = list.map(info => {
                        if (info._id === meta.patientId) {
                            const { name, relate, sex, age, contact, starting, environment, caution } = meta.info;
                            info['name'] = name;
                            info['relate'] = relate;
                            info['sex'] = sex;
                            info['age'] = age;
                            info['contact'] = contact;
                            info['starting'] = starting;
                            info['environment'] = environment;
                            info['caution'] = caution;

                            return info;
                        }
                    })
                }
            }),

        [DELETE_PATIENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    // draft['patientList'] = '';
                    // window.location.href = '/user/mypage/profile';
                }
            }),

        // 결제 카드 등록
        [ADD_CARD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['cardList'].push(payload.data);
                }
            }),
        
        [EDIT_ADD_CARD_FORM]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.type === 'clear') {
                    draft['addCardForm'] = {
                        cardName: '',
                        cardNum: '',
                        year: '',
                        month: ''
                    };
                } else {
                    draft['addCardForm'][payload.type] = payload.value;
                }
            }),

        [GET_CARD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['cardList'] = payload.data;
                }
            }),

        [CARD_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['cardInfo'] = payload.data;
            }),

        [MODIFY_CARD_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['cardList'];
                    draft['cardList'] = list.map(info => {
                        if (info._id === meta.cardId) {
                            const { cardName, cardNum, year, month } = meta.info;
                            info['cardName'] = cardName;
                            info['cardNum'] = cardNum;
                            info['year'] = year;
                            info['month'] = month;

                            return info;
                        }
                    })
                }
            }),

        [DELETE_CARD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    // draft['patientList'] = '';
                    // window.location.href = '/user/mypage/profile';
                }
            }),

        [GET_MATE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['mateList'] = payload.data;
                }
            }),

        [MATE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['mateInfo'] = payload.data;
            }),
    },
    initialState,
);

export default authReducer;
