import React from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';

import { DivisionLine, WithmateLabel } from '../../../../Components/Common/input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHospitalUser } from '@fortawesome/free-solid-svg-icons';
import { faRectangleList } from '@fortawesome/free-regular-svg-icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { MobileBr } from '../../home.styled';
import { Button } from 'antd';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const UserNumberCount = () => {
    function handleClick(e) {
        window.location.href = '/review/list';
    }

    return (
        <div className='container' style={{ height: '700px' }}>
            <RollingContainer>
                <div className='numberCount' style={{ textAlign: 'center' }}>
                    <NumberLabel>
                        <FontAwesomeIcon icon={faHospitalUser} style={{ paddingRight: '6px' }} />총 가입자 수
                    </NumberLabel>
                    <br />
                    <CountUp className='numberCount' end={1557} suffix=' 명' style={{ fontWeight: 'bold' }} />
                </div>

                <div className='numberCount' style={{ textAlign: 'center', paddingLeft: '7%' }}>
                    <NumberLabel>
                        <FontAwesomeIcon icon={faUsers} style={{ paddingRight: '6px' }} />
                        메이트 수
                    </NumberLabel>
                    <br />
                    <CountUp end={612} suffix=' 명' style={{ fontWeight: 'bold' }} />
                </div>

                <div className='numberCount' style={{ textAlign: 'center', paddingLeft: '7%' }}>
                    <NumberLabel>
                        <FontAwesomeIcon icon={faRectangleList} style={{ paddingRight: '6px' }} />총 이용 건수
                    </NumberLabel>
                    <br />
                    <CountUp end={5000} suffix=' 건 <i class="fa-solid fa-arrow-up" style="color: #3bc1ff"></i>' style={{ fontWeight: 'bold' }} />
                </div>
            </RollingContainer>
            <SatisfactionContainer>
                <SatisfactionLabel>
                    만족도 200% 이용 후기와 서비스 만족도 98% <MobileBr />
                    "위드메이트" 에게 맡겨주세요
                </SatisfactionLabel>
            </SatisfactionContainer>

            <DivisionLine />

            <ReviewContainer>
                <Swiper
                    direction={'horizontal'} // 슬라이드의 방향을 수직으로 설정합니다.
                    spaceBetween={10}
                    slidesPerView={1}
                    breakpoints={{
                        768: {
                            slidesPerView: 1,
                            spaceBetween: -50,
                        },
                    }}
                    pagination={{ clickable: true }} // 우측의 점을 클릭했을 때, 클릭한 슬라이드로 이동하게 됩니다.
                    navigation
                    modules={[Pagination]} // 페이지네이션, 마우스휠등을 사용하려면 모듈을 import해줘야 합니다.
                    allowTouchMove // 터치 동작을 허용합니다.
                    className='main_slider'
                    threshold={20} // 터치 감도를 조정합니다. 숫자가 클수록 터치에 반응하지 않습니다.
                    autoplay={{ delay: 1500 }}
                    loop={true}
                    speed={1000} // 슬라이드가 넘어가는 속도를 조정합니다. 단위는 ms입니다.
                >
                    <SwiperSlide>
                        <ReviewMainContainer>
                            <ReviewMainInnerContainer>
                                <ImgStyle>
                                    <img src={'/assets/images/review/review1.png'} style={{ width: '100%', height: '100%' }} alt='' />
                                </ImgStyle>
                            </ReviewMainInnerContainer>
                        </ReviewMainContainer>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ReviewMainInnerContainer>
                            <ImgStyle>
                                <img src={'/assets/images/review/review2.png'} style={{ width: '100%', height: '100%' }} alt='' />
                            </ImgStyle>
                        </ReviewMainInnerContainer>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ReviewMainInnerContainer>
                            <ImgStyle>
                                <img src={'/assets/images/review/review3.png'} style={{ width: '100%', height: '100%' }} alt='' />
                            </ImgStyle>
                        </ReviewMainInnerContainer>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ReviewMainInnerContainer>
                            <ImgStyle>
                                <img src={'/assets/images/review/review4.png'} style={{ width: '100%', height: '100%' }} alt='' />
                            </ImgStyle>
                        </ReviewMainInnerContainer>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ReviewMainInnerContainer>
                            <ImgStyle>
                                <img src={'/assets/images/review/review5.png'} style={{ width: '100%', height: '100%' }} alt='' />
                            </ImgStyle>
                        </ReviewMainInnerContainer>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ReviewMainInnerContainer>
                            <ImgStyle>
                                <img src={'/assets/images/review/review6.png'} style={{ width: '100%', height: '100%' }} alt='' />
                            </ImgStyle>
                        </ReviewMainInnerContainer>
                    </SwiperSlide>
                </Swiper>
            </ReviewContainer>
            <DivisionLine />

            <Button
                type='primary'
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    margin: '0 auto',
                    width: '200px',
                    height: '46px',
                    borderRadius: '10px',
                    fontSize: '20px',
                }}
                onClick={handleClick}
            >
                이용후기 더보기
            </Button>
        </div>
    );
};

const ReviewContainer = styled.div`
    width: 42%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ReviewMainContainer = styled.div`
    width: 100%;
    height: 330px;

    @media (max-width: 768px) {
        height: 330px;
    }
`;

const ReviewMainInnerContainer = styled.div`
    max-width: 500px;
    height: 100%;
    margin: 0 auto;

    padding-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
    }
`;

const RollingContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
    display: flex;
    flex-direction: initial;
    background-color: #f9f9f9;

    .numberCount {
        font-size: 35px;
    }

    @media (max-width: 768px) {
        justify-content: center;

        .numberCount {
            font-size: 20px;
        }
    }
`;

const ImgStyle = styled.div`
    width: 330px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 280px;
        height: auto;
    }
`;

const SatisfactionContainer = styled.label`
    padding-top: 20px;
    justify-content: center;
    display: flex;
    flex-direction: initial;

    @media (max-width: 768px) {
    }
`;

const SatisfactionLabel = styled.label`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const NumberLabel = styled.label`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;
