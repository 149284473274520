import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input, Select, DatePicker, TimePicker } from 'antd';

import Pallete from '../../theme';

const DivisionLine = styled.div`
    width: 100%;
    height: ${props => (props.big ? '32px' : props.x_small ? '8px' : props.small ? '16px' : '24px')};

    @media (max-width: 480px) {
        height: ${props => (props.big ? '24px' : props.x_small ? '4px' : props.small ? '8px' : '16px')};
    }
`;

const BorderDivisionLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${Pallete.dark50};

    margin-top: ${props => (props.x_small ? '4px' : props.small ? '8px' : '12px')};
    margin-bottom: ${props => (props.x_small ? '4px' : props.small ? '8px' : '12px')};
`;

const WithmateLabelText = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;

    > span {
        color: ${Pallete.blue50};
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const WithmateLabel = ({ children, required, ...rest }) => {
    return (
        <WithmateLabelText {...rest}>
            {required && <span>* </span>}
            {children}
        </WithmateLabelText>
    );
};

WithmateLabel.propTypes = {
    required: PropTypes.bool,
};

const WithmateInput = styled(Input)`
    width: 100%;
    height: 46px;
    line-height: 44px;
    ${props => props.small && `max-width: 102px; height: 32px; line-height: 29px;`}

    margin-bottom: 0px;

    color: ${Pallete.dark100};
    font-size: 14px;
    font-weight: normal;

    border-radius: 4px;
    border: 1px solid ${Pallete.dark50} !important;
    background-color: #ffffff;

    @media (max-width: 480px) {
        font-size: 12px;
        border: 1px solid ${Pallete.dark50} !important;
    }

    &.ant-input {
        width: 100%;
        height: 46px;
        line-height: 44px;

        margin-bottom: 0px;

        color: ${Pallete.dark100};
        font-size: 14px;
        font-weight: normal;

        border-radius: 4px;
        border: 1px solid ${Pallete.dark50} !important;
        background-color: #ffffff;

        &::placeholder {
            color: ${Pallete.dark60};
            font-size: 14px;
            font-weight: normal;
        }

        @media (max-width: 480px) {
            font-size: 12px;
            border: 1px solid ${Pallete.dark50} !important;

            &::placeholder {
                font-size: 12px;
            }
        }
    }

    &:focus {
        border: 1px solid ${Pallete.blue100} !important;
        transition: all 0.3s;
        box-shadow: none;
        outline: 0;
    }

    &:disabled {
        background-color: ${Pallete.dark10};
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: #f5f5f5;
    }

    &.ant-input-affix-wrapper {
        height: 46px;
        line-height: 43px;
        padding: 0px 11px;

        ${props => props.small && `max-width: 102px; height: 32px; line-height: 29px;`}

        .ant-input {
            width: 100% !important;
            height: 100%;
            line-height: 43px;

            color: ${Pallete.dark100};
            font-size: 14px;
            font-weight: normal;

            border: 0px !important;
            &::placeholder {
                color: ${Pallete.dark60};
                font-size: 14px;
                font-weight: normal;
            }

            &:focus {
                border: 1px solid ${Pallete.blue100} !important;
                transition: all 0.3s;
                box-shadow: none;
                outline: 0;
            }

            @media (max-width: 480px) {
                font-size: 12px;
                line-height: 43px;

                &:focus {
                    border: 1px solid ${Pallete.dark50} !important;
                }

                &::placeholder {
                    font-size: 12px;
                }
            }
            &:disabled {
                background-color: ${Pallete.dark10};
            }
        }

        .ant-input-suffix {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            text-align: right;
            color: ${Pallete.dark100};

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
`;

const InputTextButton = styled.span`
    display: inline-block;
    min-width: 50px;

    height: 46px;
    line-height: 44px;

    padding-left: 6px;
    padding-right: 6px;

    font-size: 11px;
    font-weight: normal;
    color: ${Pallete.dark60};
    text-align: center;
    cursor: pointer;

    transition: all 0.3s;

    &:hover {
        color: ${Pallete.dark100};
    }
`;

const InputValue = styled.div`
    flex: 1;
    height: 46px;
    line-height: 44px;

    padding-left: 11px;
    padding-right: 11px;

    font-size: 14px;
    font-weight: normal;
    color: ${props => (props.withValue ? Pallete.dark100 : Pallete.dark60)};
`;

const WithamteInputWithButton = ({ inputConfig, buttonName, onButton }) => {
    return (
        <BorderFlexWrapper>
            <WithmateInput {...inputConfig} />
            {onButton && typeof onButton === 'function' && <InputTextButton onClick={onButton}>{buttonName}</InputTextButton>}
        </BorderFlexWrapper>
    );
};

WithamteInputWithButton.propTypes = {
    inputConfig: PropTypes.object,
    buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onButton: PropTypes.func,
};

const HalfWithmateInput = styled(WithmateInput)`
    width: calc(50% - 4px);
`;

const WithmateTextarea = styled(Input.TextArea)`
    &.ant-input {
        width: 100%;
        height: 240px;
        ${props => props.middle && `min-height: 110px; max-height: 110px;`}
        ${props => props.small && `min-height: 88px; max-height: 88px;`}

        color: ${Pallete.dark100};
        font-size: 14px;
        font-weight: normal;

        border-radius: 4px;
        border: 1px solid ${Pallete.dark50} !important;
        background-color: #ffffff;

        margin-bottom: 8px;
        padding: 8px;

        &::placeholder {
            color: ${Pallete.dark60};
            font-size: 14px;
            font-weight: normal;
        }

        @media (max-width: 480px) {
            font-size: 12px;
            &::placeholder {
                font-size: 12px;
            }
        }

        &:focus {
            border: 1px solid ${Pallete.blue100} !important;
            transition: all 0.3s;
            box-shadow: none;
            outline: 0;
        }

        &:disabled {
            background-color: ${Pallete.dark10};
        }

        @media (max-width: 480px) {
            min-height: 140px;
            ${props => props.middle && `min-height: 110px; max-height: 110px;`}
            ${props => props.small && `min-height: 88px; max-height: 88px;`}

            padding: 4px 11px;
            border: 1px solid ${Pallete.dark50} !important;

            &:focus {
                border: 1px solid ${Pallete.dark50} !important;
            }
        }
    }
`;

const WithmateSelect = styled(Select)`
    &.ant-select {
        // margin-bottom: 8px;
        width: 100%;
        height: 46px;
        border-radius: 4px;

        .ant-select-selector {
            height: 100%;
        }
    }

    &.ant-select .ant-select-selector {
        height: 100% !important;
        line-height: 43px;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 43px;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 43px;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #e9e9e9;
        border-radius: 4px;
    }

    .ant-select-selector .ant-select-selection-search-input {
        height: 100%;
        line-height: 43px;
    }

    &.ant-select.ant-select-enabled {
        width: 100%;
    }

    .ant-select-selection.ant-select-selection--single {
        border: 1px solid ${Pallete.dark50} !important;
        width: 100%;
        height: 46px;
        border-radius: 4px;
    }

    .ant-select-selection__rendered {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        .ant-select-selection__placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            text-align: left;
            color: ${Pallete.dark60};

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }

    .ant-select-selection-selected-value {
        min-width: 50%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        text-align: left;
        color: ${Pallete.dark100};

        @media (max-width: 480px) {
            font-size: 12px;
        }
    }

    &.ant-select-disabled {
        width: 100%;
    }
`;

const HalfWithmateSelect = styled(WithmateSelect)`
    width: calc(50% - 4px);
`;

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;

    ${props =>
        props.withHalf
            ? `
        > div, p {
            width: calc(50% - 4px) !important;
            margin-right: 8px;
        }
    `
            : ''}

    > div:last-child {
        margin-right: 0px;
    }
`;

const BorderFlexWrapper = styled(FlexWrapper)`
    border: 1px solid ${Pallete.dark50};
    border-radius: 4px;
    overflow: hidden;

    input.ant-input {
        margin-bottom: 0px !important;
        border: 0px !important;
    }
`;

const FlexDiv = styled.div`
    width: calc(50% - 4px);
`;

const StyledHotFix = styled.p`
    font-size: 11px;
    color: ${Pallete.blue100};
    font-weight: normal;

    margin-bottom: 4px;
`;

const WithmateDatePicker = styled(DatePicker)`
    &.ant-picker {
        width: 100%;
        height: 46px;
        line-height: 43px;
        border: 1px solid #e9e9e9;
        padding: 0px;
        padding-left: 11px;
        padding-right: 11px;
        border-radius: 4px;

        > div {
            height: 100%;
        }

        > div > input {
            height: 100%;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.46;
            text-align: left;
            color: rgba(0, 0, 0, 0.7);

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
`;

const WithmateTimepicker = styled(TimePicker)`
    &.ant-picker {
        width: 100%;
        height: 46px;
        line-height: 43px;
        border: 1px solid #e9e9e9;
        padding: 0px;
        padding-left: 11px;
        padding-right: 11px;
        border-radius: 4px;

        > div {
            height: 100%;
        }

        > div > input {
            height: 100%;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.46;
            text-align: left;
            color: rgba(0, 0, 0, 0.7);

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
`;

const WithmateValue = styled.p`
    margin-bottom: 4px;

    font-size: 13px;
    color: ${Pallete.blue50};
    font-weight: bold;
`;

const WithmateValueTip = styled.p`
    margin-bottom: 0px;

    font-size: 11px;
    color: ${Pallete.dark60};
    font-weight: normal;
`;

export {
    BorderDivisionLine,
    WithamteInputWithButton,
    DivisionLine,
    WithmateLabel,
    WithmateInput,
    HalfWithmateInput,
    WithmateTextarea,
    WithmateSelect,
    FlexWrapper,
    FlexDiv,
    HalfWithmateSelect,
    StyledHotFix,
    WithmateDatePicker,
    WithmateValue,
    WithmateValueTip,
    WithmateTimepicker,
};
