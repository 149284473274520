import React from 'react';
import { connect } from 'react-redux';

import { USER, MATE } from '../../../Lib/variables';
import { getReserveList } from '../../../Reducer/Modules/app';
import StyledMylistContainer from './mylist.styled';
import MyCommentList from './mylist';
import { message } from 'antd';

class MyCommentListPage extends React.PureComponent {
    componentDidMount = () => {
        const id = localStorage.getItem('id');
        const userType = localStorage.getItem('userTYp');

        if (id) {
            this.props.fetchReserveList({ userId: id });
        }

        if (!id) {
            message.info('로그인 후 이용해주세요.');
            return this.props.history.push('/user/login');
        }

        if (id && userType === MATE) {
            message.info('일반 사용자를 위한 페이지입니다.');
            return this.props.history.push('/');
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.auth;

        if (!prevProps.auth.id && id) {
            this.props.fetchReserveList({ userId: id });
        }
    };

    render() {
        return (
            <StyledMylistContainer>
                <MyCommentList {...this.props} />
            </StyledMylistContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});
const mapDispatchToProps = dispatch => ({
    fetchReserveList: ({ userId }) => dispatch(getReserveList({ userId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCommentListPage);
