import styled from 'styled-components';
import { Input } from 'antd';

const StyledAddContainer = styled.div``;

export default StyledAddContainer;

export const WritePaper = styled.div`
    background-color: white;
    padding: 21px 31px 31px 31px;
    border-radius: 8px;
    border: solid 1px #e6e6e6;

    @media (max-width: 480px) {
        padding: 16px;
        border-radius: 4px;
    }
`;

export const FormHeader = styled.div`
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 22px;
`;

export const FormTitle = styled.div`
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.7px;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;

    @media (max-width: 480px) {
        font-size: 24px;
    }
`;

export const FormSubtitle = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 32px;

    @media (max-width: 480px) {
        font-size: 13px;
    }
`;

export const FormTypeWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const FormTypeBlock = styled.div`
    margin-right 220px;

    @media (max-width: 768px) {
        margin-right: 0px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
`;

export const FormTypeTitle = styled.p`
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b1b1b1;

    margin-bottom: 11px;

    @media (max-width: 480px) {
        font-size: 10px;
    }
`;

export const FormTypeDesc = styled.p`
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.1px;
    text-align: left;
    color: #3bc1ff;

    margin-bottom: 0px;

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

export const FormDescription = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: left;
    color: #3bc1ff;

    margin-left: 4px;

    @media (max-width: 480px) {
        font-size: 11px;
    }
`;

export const StarImg = styled.img`
    width: 46px;
    height: 44px;
    margin-right: 8px;

    @media (max-width: 480px) {
        width: 26px;
        height: 24px;
        margin-right: 4px;
    }
`;

export const FormBody = styled.div`
    padding-top: 22px;
`;

export const FormRow = styled.div`
    margin-bottom: 32px;
`;

export const FormInput = styled(Input)`
    height: 44px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.3px;
    color: #161616;

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.3px;
        color: #a7a7a7;
    }

    @media (max-width: 480px) {
        font-size: 12px;

        &::placeholder {
            font-size: 11px;
        }
    }
`;

export const FormTextarea = styled(Input.TextArea)`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.3px;
    color: #161616;

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.3px;
        color: #a7a7a7;
    }

    @media (max-width: 480px) {
        font-size: 12px;

        &::placeholder {
            font-size: 11px;
        }
    }
`;

export const FormButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 50px;

    @media (max-width: 480px) {
        margin-top: 32px;
    }
`;

export const FormButton = styled.div`
    cursor: pointer;

    border-radius: 2px;
    width: 297px;
    height: 60px;
    line-height: 60px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.15px;
    text-align: center;
    color: #ffffff;

    &.blue {
        background-color: #3bc1ff;
    }

    &.white {
        background-color: #b7b7b7;
        margin-right: 16px;
    }

    &:hover {
        transition: all 0.5s;
        opacity: 0.8;
    }

    @media (max-width: 480px) {
        height: 44px;
        line-height: 44px;
        font-size: 13px;
    }
`;

export const FormTitleRowWrapper = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid #f2f2f2;
`;

export const FormRowTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0px;

    @media (max-width: 480px) {
        font-size: 14px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const CheckboxGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    .ant-checkbox-inner {
        width: 18px;
        height: 18px;
    }

    @media (max-width: 480px) {
        .ant-checkbox-wrapper {
            font-size: 12px !important;
        }
        .ant-checkbox-inner {
            width: 16px;
            height: 16px;
        }

        margin-top: 4px;
    }
`;
