import React from 'react';
import { connect } from 'react-redux';

// component
import NormalStyledContainer from './normal.styled';
import Normal from './normal';

class NormalContainer extends React.PureComponent {
    render() {
        return (
            <NormalStyledContainer component={this.props.isComponent || undefined}>
                <Normal {...this.props} />
            </NormalStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(NormalContainer);
