import React from 'react';
import styled from 'styled-components';
import { Button, Checkbox, message, notification, Radio, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import holidayKR from 'holiday-kr';
import swal from 'sweetalert2';

// libs
import axios from '../../Lib/axios';
import { combineDateAndTime } from '../../Lib/utils';
import { celluarValidate } from '../../Lib/regex';

// styled
import { ReserveTopCover, ReservePageContainer, ResrevePageInnerContainer, ResrevePageHalfInnerContainer, MobileBr, DesktopBr } from './reserve.styled';
import { TopBannerSection, TopBannerText, TopBannerDescription } from './reserve.styled';
import { StepSectionContainer, StepButton, LastStepButton, StepButtonWrapper, StepArrow } from './reserve.styled';
import { NextStepGudieContainer, NextStepEmoj, NextStepTitle, NextStepArrowDown } from './reserve.styled';
import { ServiceSectionContainer, ServiceSectionTitle, ServiceSectionDescription } from './reserve.styled';
import { ServiceCardWrapper, ServiceCardButton, ServiceCardList } from './reserve.styled';
import { ZzzServiceWrapper, ZzzArt, ZzzDescription } from './reserve.styled';
import { AlzheServiceWrapper, AlzheArt, AlzheDescription } from './reserve.styled';
import { ServiceTypeSectionContainer, ServiceTypeWrapper } from './reserve.styled';
import { ServiceTypeCardWrapper, ServiceTypeCardImg, NextStepButton, ServiceTypeName, ServiceTypeDescription } from './reserve.styled';
import { FormContainer, MobileStepImage, TopBannerSubDescription, UnderLineText, FormWrapper, FormMainTitle, FormMainAlert } from './reserve.styled';
import {
    ServiceName,
    LabelValue,
    DashAndText,
    Sexes,
    LabelAndValueRow,
    PriceValue,
    LabelDescription,
    FlexFormWrapper,
    ResultFormWrapper,
    PriceDetailLabel,
    PriceDetailValue,
    CongraturateLogo,
    DoneResultWrapper,
} from './reserve.styled';

// components
import {
    DivisionLine,
    WithmateLabel,
    WithmateInput,
    WithmateTextarea,
    WithmateSelect,
    FlexWrapper,
    WithmateTimepicker,
    WithamteInputWithButton,
    WithmateDatePicker,
} from '../../Components/Common/input';
import { ButtonWrapper, RadiusHalfWidthButton } from '../../Components/Common/button';

import PatientProfileListModal from './patientListModal';
import MateModal from './mateListModal';
import CardListModal from './cardListModal';
import { fetchPatient, fetchMate, fetchCard } from '../../Lib/Api/auth';
import { Label } from 'reactstrap';

export default class ReservationPage extends React.PureComponent {
    state = {
        step: 'items', // items, basic, form, confirm, done
        big5: [],
        local: [],
        corpBy: [],
        zzz: [],
        alzhe: [],
        productId: '',
        productInfo: null,
        service: '',
        patientList: [],
        patientId: '',
        mateList: [],
        chooseMate: '아니오',
        cardList: [],
    };

    componentDidMount = async () => {
        const userId = localStorage.getItem('id');
        if (!userId) {
            message.warning('로그인 후 이용해주세요.');
            setTimeout(() => {
                this.props.history.goBack();
            }, 250);
            return null;
        }

        const { params } = this.props.match;
        if (params.step && params.step !== 'items') {
            this.props.history.push('/reserve/items');
        }

        this.fetchUserInfo();
        this.fetchItemList();
    };

    componentDidUpdate = prevProps => {
        const { params: prevParams } = prevProps.match;
        const { params } = this.props.match;
        if (JSON.stringify(prevParams) !== JSON.stringify(params)) {
            this.setState({ step: params.step });
        }
    };

    fetchUserInfo = () => {
        const userId = localStorage.getItem('id');
        if (!userId) return null;
        axios.get(`/v1/user/${userId}/user`).then(resolve => {
            const { data } = resolve;
            if (data && data._id) {
                this.setState({ userInfo: data, point: data.point, usePoint: 0, connection: data.connection });
            }
        });
    };

    onPrevSetting = () => {
        const userId = localStorage.getItem('id');
        fetchPatient({ id: userId }).then(resolve => {
            // console.log(resolve)
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ patientList: resolve.data.data, patientProfileListModal: true });
            } else {
                notification.open({
                    message: '조회되는 환자 목록이 없습니다.',
                    description: '[마이페이지]에서 환자 프로필을 등록하실 수 있습니다.',
                    type: 'primary',
                });
            }
        });

        // const { productInfo } = this.state;
        // if (!userId) return null;

        // axios.get('/v2/user/recent/reserve/info', { params: { userId } }).then(resolve => {
        //     const { data } = resolve;
        //     if (data && data._id) {
        //         delete data._id;
        //         // 도착지랑 출발지 제외하고 나머지 다 추가
        //         if (productInfo && productInfo.reservedDestination) delete data.destination;
        //         if (productInfo && productInfo.reservedStarting) delete data.starting;

        //         this.setState({ ...data });
        //     } else {
        //         message.warning('조회되는 최근 예약정보가 없습니다.');
        //     }
        // });
    };

    onPrevSetting2 = () => {
        fetchMate({ valid: true }).then(resolve => {
            console.log(resolve);
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ mateList: resolve.data.data, mateListModal: true });
            } else {
                message.info('조회되는 메이트가 없습니다.');
            }
        });
    };

    // 카드 목록 불러오기
    onPrevSetting3 = () => {
        const userId = localStorage.getItem('id');
        fetchCard({ id: userId }).then(resolve => {
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ cardList: resolve.data.data, cardListModal: true });
            } else {
                notification.open({
                    message: '조회되는 카도 목록이 없습니다.',
                    description: '[마이페이지]에서 결제 카드를 등록하실 수 있습니다.',
                    type: 'primary',
                });
            }
        });
    };

    fetchItemList = () => {
        axios.get('/v1/product/service/tag/group', { params: { isOption: true } }).then(resolve => {
            const { data } = resolve;
            if (data) {
                this.setState({ ...data });
            }
        });
    };

    onSelectService = info => {
        this.setState(
            {
                productId: info.id,
                productInfo: info,
                isZzz: false,
                isAlzhe: false,
                starting: info.reservedStarting || '',
                destination: info.reservedDestination || '',
                shortStarting: info.reservedShortStarting || '',
                shortDestination: info.reservedShortDestination || '',
            },
            this.goSecondStep,
        );
    };

    goSecondStep = () => {
        const rect = document.querySelector('#second-step').getClientRects();
        const { top } = rect[0];
        window.scrollTo(0, window.scrollY + top - 55);
    };

    goNextButton = () => {
        const rect = document.querySelector('#fst-next-btn').getClientRects();
        const { top } = rect[0];
        window.scrollTo(0, window.scrollY + top - 55);
    };
    // Daum Address Search 출발지
    onStarting = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    shortStarting: `${sido} ${sigungu} ${bname}`,
                    starting: `${roadAddress} (${buildingName})`,
                }));
            },
        }).open();
    };
    // Daum Address Search 도착지
    onDestination = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    destination: `${roadAddress} (${buildingName})`,
                    shortDestination: `${sido} ${sigungu} ${bname}`,
                }));
            },
        }).open();
    };

    onAddress = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    address: `${roadAddress} (${buildingName})`,
                }));
            },
        }).open();
    };

    stepHandler = () => {
        const { step: currentStep, service, productId, shortStarting, shortDestination, recommender = '', recommenderContact = '' } = this.state;
        const { patient, sex, contact = '', age, guardContact, relate, starting, destination } = this.state;
        const {
            mateGender,
            reserveDate,
            meetTime,
            meetMinute,
            reserveTime,
            reserveMinute,
            addTime,
            comment,
            transport,
            // payment = 'transfer',
            payment,
            usePoint,
            point,
            productInfo,
            environment,
            caution,
            chooseMate,
            mateName,
            cardName,
            cardNum,
            year,
            month,
            installment,
        } = this.state;

        switch (currentStep) {
            case 'items':
                if (!service) return message.warning('서비스를 선택해주세요.');
                if (!productId) return message.warning('서비스 상세 분류를 선택해주세요. (ex. Pro, Basic, 내시경 등)');

                this.props.history.push('/reserve/basic');
                break;

            case 'basic':
                if (!patient) return message.warning('환자분의 성함을 입력해주세요.');
                if (!sex) return message.warning('환자분의 성별을 입력해주세요.');
                // if (!contact) return message.warning('환자분의 연락처를 입력해주세요.');
                if (!age) return message.warning('환자분의 나이를 입력해주세요.');
                if (!relate) return message.warning('환자분과의 관계를 선택해주세요.');
                if (!guardContact) return message.warning('보호자님의 연락처를 확인해주세요.');
                if (!destination) return message.warning('도착지를 검색 및 설정해주세요.');
                if (service === 'unemergency' && !starting) return message.warning('출발지를 검색 및 설정해주세요.');

                this.props.history.push('/reserve/form');
                break;

            case 'form':
                if (!reserveDate) return message.warning('진료 예약일을 설정해주세요.');
                if (!reserveTime || !reserveMinute) return message.warning('진료 예약 시간을 설정해주세요.');
                if (service === 'unemergency' && (!meetTime || !meetMinute)) return message.warning('동행인의 출발지 도착 희망 시간을 설정해주세요.');
                if (!addTime) return message.warning('예상 소요 시간을 입력해주세요.');
                if (recommenderContact && !celluarValidate(recommenderContact))
                    return message.warning('추천인 연락처를 올바르게 입력해주세요. (ex. 01012341243');
                if ((recommenderContact && !recommender) || (recommender && !recommenderContact)) return message.warning('추천인 정보를 마저 입력해주세요.');

                this.props.history.push('/reserve/confirm');
                break;

            case 'confirm':
                // 여기서 결제해야함
                const userId = localStorage.getItem('id');
                if (!userId) return null;

                const _transport = transport && transport === 'own' ? 20000 : 0;
                const _service =
                    productInfo &&
                    service &&
                    (service === 'unemergency' ? productInfo.proPrice : productInfo.basicPrice ? productInfo.basicPrice : 0) * addTime;
                const discount = service === 'unemergency' ? productInfo.proDiscount || 0 : productInfo.basicDiscount || 0;
                const totalFee = _transport + _service - discount;

                if (service === 'unemergency' && !transport) return message.warning('이동 수단을 선택해주세요.');
                if (usePoint > point) return message.warning('잔여 포인트보다 사용하고자 하는 포인트가 더 높습니다.');
                if (usePoint > totalFee) return message.warning('사용하고자 하는 포인트가 예상 결제금액보다 높습니다.');

                const info = {
                    shortStarting,
                    destination,
                    shortDestination,
                    comment,
                    service,
                    addTime,
                    starting,
                    contact,
                    guardContact,
                    reserveDate: combineDateAndTime({ date: reserveDate, time: reserveTime, minute: reserveMinute }),
                    meetDate: combineDateAndTime({ date: reserveDate, time: meetTime, minute: meetMinute }),
                    patient,
                    relate,
                    payment,
                    sex,
                    age,
                    transport,
                    recommender,
                    recommenderContact,
                    product: productId,
                    usingPoint: usePoint,
                    mateGender,
                    environment,
                    caution,
                    chooseMate,
                    mateName,
                    cardName,
                    cardNum,
                    year,
                    month,
                    installment,
                };

                axios
                    .post('/v2/service/reserve/new', { info, userId })
                    .then(async resolve => {
                        const { data } = resolve;
                        if (!data) message.error('죄송합니다 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                        if (data === 'check parameters') return message.warning('입력하지 않은 정보가 있는지 다시 한번 확인해주세요.');
                        if (data === 'invalid user') return message.warning('세션이 만료되었습니다. 재 로그인 후 다시 시도해주세요.');
                        if (data === 'invalid product')
                            return message.warning('죄송합니다. 선택하신 서비스가 더이상 지원하지 않는 서비스입니다. 다른 서비스를 이용해주세요.');

                        if (data === 'success') {
                            // 사용자 연락처 갱신
                            // await axios.post('/v1/user/modify/info', { id: userId, userType: 'user', info: { contact: guardContact } });

                            message.success('동행 서비스 요청 완료');
                            this.props.history.push('/reserve/done');
                        }
                    })
                    .catch(error => {
                        message.error('죄송합니다 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                    });
                break;
        }
    };

    stepHandler2 = () => {
        const { step: currentStep, service, productId, shortStarting, shortDestination, recommender = '', recommenderContact = '' } = this.state;
        const { patient, sex, contact, age, guardContact, relate, starting, destination, mateId } = this.state;
        const {
            mateGender,
            reserveDate,
            meetTime,
            meetMinute,
            reserveTime,
            reserveMinute,
            addTime,
            comment,
            transport,
            // payment = 'transfer',
            payment,
            usePoint,
            point,
            productInfo,
            environment,
            caution,
            chooseMate,
            mateName,
        } = this.state;

        switch (currentStep) {
            case 'items':
                if (!service) return message.warning('서비스를 선택해주세요.');
                if (!productId) return message.warning('서비스 상세 분류를 선택해주세요. (ex. Pro, Basic, 내시경 등)');

                this.props.history.push('/reserve/basic');
                break;

            case 'basic':
                if (!patient) return message.warning('환자분의 성함을 입력해주세요.');
                if (!sex) return message.warning('환자분의 성별을 입력해주세요.');
                // if (!contact) return message.warning('환자분의 연락처를 입력해주세요.');
                if (!age) return message.warning('환자분의 나이를 입력해주세요.');
                if (!relate) return message.warning('환자분과의 관계를 선택해주세요.');
                if (!guardContact) return message.warning('보호자님의 연락처를 확인해주세요.');
                if (!destination) return message.warning('도착지를 검색 및 설정해주세요.');
                if (service === 'unemergency' && !starting) return message.warning('출발지를 검색 및 설정해주세요.');

                this.props.history.push('/reserve/form');
                break;

            case 'form':
                if (!reserveDate) return message.warning('진료 예약일을 설정해주세요.');
                if (!reserveTime || !reserveMinute) return message.warning('진료 예약 시간을 설정해주세요.');
                if (service === 'unemergency' && (!meetTime || !meetMinute)) return message.warning('동행인의 출발지 도착 희망 시간을 설정해주세요.');
                if (!addTime) return message.warning('예상 소요 시간을 입력해주세요.');
                if (recommenderContact && !celluarValidate(recommenderContact))
                    return message.warning('추천인 연락처를 올바르게 입력해주세요. (ex. 01012341243');
                if ((recommenderContact && !recommender) || (recommender && !recommenderContact)) return message.warning('추천인 정보를 마저 입력해주세요.');

                this.props.history.push('/reserve/confirm');
                break;

            case 'confirm':
                // 여기서 결제해야함
                const userId = localStorage.getItem('id');
                if (!userId) return null;

                const _transport = transport && transport === 'own' ? 20000 : 0;
                const _service =
                    productInfo &&
                    service &&
                    (service === 'unemergency' ? productInfo.proPrice : productInfo.basicPrice ? productInfo.basicPrice : 0) * addTime;
                const discount = service === 'unemergency' ? productInfo.proDiscount || 0 : productInfo.basicDiscount || 0;
                const totalFee = _transport + _service - discount;

                if (service === 'unemergency' && !transport) return message.warning('이동 수단을 선택해주세요.');
                if (usePoint > point) return message.warning('잔여 포인트보다 사용하고자 하는 포인트가 더 높습니다.');
                if (usePoint > totalFee) return message.warning('사용하고자 하는 포인트가 예상 결제금액보다 높습니다.');

                const info = {
                    shortStarting,
                    destination,
                    shortDestination,
                    comment,
                    service,
                    addTime,
                    starting,
                    contact,
                    guardContact,
                    reserveDate: combineDateAndTime({ date: reserveDate, time: reserveTime, minute: reserveMinute }),
                    meetDate: combineDateAndTime({ date: reserveDate, time: meetTime, minute: meetMinute }),
                    patient,
                    relate,
                    payment,
                    sex,
                    age,
                    transport,
                    recommender,
                    recommenderContact,
                    product: productId,
                    usingPoint: usePoint,
                    mateGender,
                    environment,
                    caution,
                    chooseMate,
                    mateId,
                };

                axios
                    .post('/v2/service/reserve/mate/new', { info, userId, mateId })
                    .then(async resolve => {
                        const { data } = resolve;
                        if (!data) message.error('죄송합니다 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                        if (data === 'check parameters') return message.warning('입력하지 않은 정보가 있는지 다시 한번 확인해주세요.');
                        if (data === 'invalid user') return message.warning('세션이 만료되었습니다. 재 로그인 후 다시 시도해주세요.');
                        if (data === 'invalid product')
                            return message.warning('죄송합니다. 선택하신 서비스가 더이상 지원하지 않는 서비스입니다. 다른 서비스를 이용해주세요.');

                        if (data === 'success') {
                            message.success('동행 서비스 요청 완료');
                            this.props.history.push('/reserve/done');
                        }
                    })
                    .catch(error => {
                        message.error('죄송합니다 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
                    });
                break;
        }
    };

    prevStep = () => {
        this.props.history.goBack();
    };

    handleChange = e => {
        const type = e.target.name;
        const value = e.target.value;

        this.setState({ [type]: value });
    };

    patientProfileListModalOff = () => {
        this.setState({
            patientProfileListModal: false,
        });
    };

    mateListModalOff = () => {
        this.setState({
            mateListModal: false,
        });
    };

    cardListModalOff = () => {
        this.setState({
            cardListModal: false,
        });
    };

    onUpdatePatient = data => {
        this.setState({
            patient: data.name,
            age: data.age,
            contact: data.contact,
            relate: data.relate,
            sex: data.sex,
            starting: data.starting,
            environment: data.environment,
            caution: data.caution,
        });
    };

    onUpdateMate = data => {
        this.setState({
            mateName: data.name,
            mateContact: data.contact,
            mateId: data._id,
        });
    };

    onUpdateCard = data => {
        this.setState({
            cardName: data.cardName,
            cardNum: data.cardNum,
            year: data.year,
            month: data.month,
        });
    };

    render() {
        const { step, big5, local, corpBy, zzz, alzhe, productId = '', productInfo, service, isZzz = false, isAlzhe = false, mateName } = this.state;
        // console.log(this.state)
        return (
            <ReservePageContainer>
                <ReserveTopCover isMain={step === 'items'}>
                    <BannerSection isMain={step === 'items'} />
                    {step === 'items' && (
                        <NextGuider
                            color={'#ffffff'}
                            emoj={'/assets/images/guide_step_1.png'}
                            title={
                                <>
                                    먼저, 원하시는 <MobileBr />
                                    서비스 종류를 선택하세요!
                                </>
                            }
                        />
                    )}
                </ReserveTopCover>
                {step && step !== 'items' && <StepSection step={step} />}
                {step === 'items' && (
                    <>
                        <ServiceSection
                            bg='/assets/images/artwork_right_bg.png'
                            mobileBg='#ffffff'
                            title='대형병원 동행 서비스'
                            description='대형병원에 방문하는 분들을 위한 서비스입니다.'
                        >
                            <ServiceCardList>
                                {big5 &&
                                    big5.length > 0 &&
                                    big5.map((product, index) => (
                                        <Card
                                            key={'big5-item-' + index.toString()}
                                            source={product.logo}
                                            selected={product.id === productId ? 'selected' : ''}
                                            onSelect={() => {
                                                this.onSelectService(product);
                                            }}
                                        />
                                    ))}
                            </ServiceCardList>
                        </ServiceSection>
                        <ServiceSection
                            mobileBg='#f2f2f2'
                            title='지역별 병원 동행 서비스'
                            description={
                                <>
                                    대형병원을 제외한 각 지역별 병원에 <MobileBr /> 방문하는 분들을 위한 서비스입니다.
                                </>
                            }
                        >
                            <ServiceCardList>
                                {local &&
                                    local.length > 0 &&
                                    local.map((product, index) => (
                                        <Card
                                            key={'local-item-' + index.toString()}
                                            source={product.logo}
                                            selected={product.id === productId ? 'selected' : ''}
                                            onSelect={() => {
                                                this.onSelectService(product);
                                            }}
                                        />
                                    ))}
                            </ServiceCardList>
                        </ServiceSection>
                        {/* <ServiceSection
                            bg='/assets/images/artwork_left_bg.png'
                            mobileBg='#ffffff'
                            title='기업 기관별 동행 서비스'
                            description={
                                <>
                                    위드메이트와 협업 중인 <MobileBr /> 기업 및 기관을 위한 접수 창구입니다
                                </>
                            }
                        >
                            <ServiceCardList>
                                {corpBy &&
                                    corpBy.length > 0 &&
                                    corpBy.map((product, index) => (
                                        <Card
                                            key={'corpBy-item-' + index.toString()}
                                            source={product.logo}
                                            selected={product.id === productId ? 'selected' : ''}
                                            onSelect={() => {
                                                this.onSelectService(product);
                                            }}
                                        />
                                    ))}
                            </ServiceCardList>
                        </ServiceSection> */}
                        {/* <ZzzService
                            selected={zzz.length === 0 ? '' : zzz[0].id === productId ? 'selected' : ''}
                            onSelect={() => {
                                if (zzz.length === 0) return message.warning('운영중인 내시경 보호자 서비스가 없습니다.');

                                const z_one = zzz[0];
                                this.setState({ productId: z_one.id, productInfo: z_one, isZzz: true }, this.goSecondStep);
                            }}
                        />

                        <AlzheService
                            selected={alzhe.length === 0 ? '' : alzhe[0].id === productId ? 'selected' : ''}
                            onSelect={() => {
                                if (alzhe.length === 0) return message.warning('운영중인 치매 보호자 서비스가 없습니다.');

                                const a_one = alzhe[0];
                                this.setState({ productId: a_one.id, productInfo: a_one, isAlzhe: true }, this.goSecondStep);
                            }}
                        /> */}
                        {productId && (
                            <>
                                <NextGuider
                                    bg
                                    id='second-step'
                                    emoj={'/assets/images/guide_step_2.png'}
                                    title={
                                        <>
                                            이제, 원하시는 <MobileBr /> 서비스 타입을 선택하세요!
                                        </>
                                    }
                                />
                                <ServiceTypeSection bg='/assets/images/artwork_right_bg2.png' mobileBg={'#ffffff'}>
                                    <ServiceTypeWrapper>
                                        {productInfo.usePro && !isZzz && (
                                            <ServiceTypeCard
                                                emoj={'/assets/images/art_pro.png'}
                                                name='Pro'
                                                desc={`위드메이트 동행 전문가가 고객님 자택에서 병원까지 동행하고, 병원 업무를 마친 뒤  다시 자택까지 
                                                동행합니다.`}
                                                selected={service === 'unemergency' ? 'selected' : ''}
                                                onSelect={() => {
                                                    this.setState({ service: 'unemergency' }, this.goNextButton);
                                                }}
                                            />
                                        )}

                                        {productInfo.useBasic && !isZzz && (
                                            <ServiceTypeCard
                                                emoj={'/assets/images/art_basic.png'}
                                                name='Basic'
                                                desc={`위드메이트 베이직 서비스는 
                                                위드메이트 동행 전문가가 지정한 의료 시설 혹은 병원 내에서 
                                                보호자 대행 업무를 수행합니다.`}
                                                selected={service === 'assist' ? 'selected' : ''}
                                                onSelect={() => {
                                                    this.setState({ service: 'assist' }, this.goNextButton);
                                                }}
                                            />
                                        )}

                                        {isZzz && (
                                            <ServiceTypeCard
                                                emoj={'/assets/images/art_zzz.png'}
                                                name='내시경'
                                                desc={`위드메이트 동행 전문가가 고객님께서 수면 내시경 검사/진료를 
                                                받는 의료 시설에 도착한 후 
                                                보호자 대행 업무를 수행합니다.`}
                                                selected={service === 'assist' ? 'selected' : ''}
                                                onSelect={() => {
                                                    this.setState({ service: 'assist' }, this.goNextButton);
                                                }}
                                            />
                                        )}
                                    </ServiceTypeWrapper>
                                    <NextStepButton id='fst-next-btn' on={productId && service} onClick={this.stepHandler}>
                                        다음 단계로가기
                                    </NextStepButton>
                                </ServiceTypeSection>
                            </>
                        )}
                    </>
                )}
                {/* {step === 'basic' && ( */}
                {step === 'basic' && (
                    <>
                        <FormContainer>
                            <BasicForm
                                data={this.state}
                                handleChange={this.handleChange}
                                onStarting={this.onStarting}
                                onDestination={this.onDestination}
                                onPrevSetting={this.onPrevSetting}
                            />
                            <CustomButtonWrapper>
                                <RadiusHalfWidthButton bg='#cecfd0' onClick={this.prevStep}>
                                    이전으로
                                </RadiusHalfWidthButton>
                                <RadiusHalfWidthButton bg='#3bc1ff' onClick={this.stepHandler}>
                                    다음 단계로가기
                                </RadiusHalfWidthButton>
                            </CustomButtonWrapper>
                        </FormContainer>
                    </>
                )}
                {/* {step === 'form' && ( */}
                {step === 'form' && (
                    <>
                        <FormContainer>
                            <MainForm data={this.state} handleChange={this.handleChange} onPrevSetting2={this.onPrevSetting2} />
                            <CustomButtonWrapper>
                                <RadiusHalfWidthButton bg='#cecfd0' onClick={this.prevStep}>
                                    이전으로
                                </RadiusHalfWidthButton>
                                <RadiusHalfWidthButton bg='#3bc1ff' onClick={this.stepHandler}>
                                    다음 단계로가기
                                </RadiusHalfWidthButton>
                            </CustomButtonWrapper>
                        </FormContainer>
                    </>
                )}
                {step === 'confirm' && (
                    <>
                        <FormContainer>
                            <ConfirmForm data={this.state} handleChange={this.handleChange} onPrevSetting3={this.onPrevSetting3} onAddress={this.onAddress} />
                            <CustomButtonWrapper>
                                <RadiusHalfWidthButton bg='#cecfd0' onClick={this.prevStep}>
                                    이전으로
                                </RadiusHalfWidthButton>
                                {mateName ? (
                                    <>
                                        <RadiusHalfWidthButton bg='#3bc1ff' onClick={this.stepHandler2}>
                                            신청 완료
                                        </RadiusHalfWidthButton>
                                    </>
                                ) : (
                                    <RadiusHalfWidthButton bg='#3bc1ff' onClick={this.stepHandler}>
                                        신청 완료하기
                                    </RadiusHalfWidthButton>
                                )}
                            </CustomButtonWrapper>
                        </FormContainer>
                    </>
                )}
                {step === 'done' && (
                    <>
                        <FormContainer>
                            <DoneForm data={this.state} />
                            <LastStepButton
                                on
                                onClick={() => {
                                    this.props.history.push('/user/mypage/list');
                                }}
                            >
                                마이 페이지로
                            </LastStepButton>
                        </FormContainer>
                    </>
                )}

                <PatientProfileListModal
                    list={this.state.patientList}
                    visible={this.state.patientProfileListModal}
                    onClose={this.patientProfileListModalOff}
                    onUpdatePatient={this.onUpdatePatient}
                />

                <MateModal list={this.state.mateList} visible={this.state.mateListModal} onClose={this.mateListModalOff} onUpdateMate={this.onUpdateMate} />

                <CardListModal list={this.state.cardList} visible={this.state.cardListModal} onClose={this.cardListModalOff} onUpdateCard={this.onUpdateCard} />
            </ReservePageContainer>
        );
    }
}

const BannerSection = ({ isMain }) => (
    <TopBannerSection isMain={isMain}>
        {isMain && (
            <ResrevePageInnerContainer smallPadding>
                <TopBannerDescription>국내 최대 규모의 병원 동행 서비스 매칭 플랫폼</TopBannerDescription>
                <TopBannerText>
                    <span>위드메이트</span>가 함께 동행해드립니다
                </TopBannerText>
            </ResrevePageInnerContainer>
        )}
        {!isMain && (
            <ResrevePageInnerContainer smallPadding>
                <TopBannerText style={{ textAlign: 'left' }}>
                    <span>
                        위드메이트가
                        <br />
                        함께 동행 해드립니다
                    </span>
                </TopBannerText>
                <TopBannerSubDescription>국내 최대 규모의 병원 동행 서비스 매칭 플랫폼</TopBannerSubDescription>
            </ResrevePageInnerContainer>
        )}
    </TopBannerSection>
);

const StepSection = ({ step }) => (
    <StepSectionContainer>
        <ResrevePageInnerContainer smallPadding>
            <StepButtonWrapper>
                <StepButton active={step === 'basic'}>환자 기본 정보 입력</StepButton>
                <StepArrow src={'/assets/images/stepper.png'} alt='화살표' />
                <StepButton active={step === 'form'}>서비스 신청 정보 입력</StepButton>
                <StepArrow src={'/assets/images/stepper.png'} alt='화살표' />
                <StepButton active={step === 'confirm'}>매칭 신청 확인</StepButton>
                <StepArrow src={'/assets/images/stepper.png'} alt='화살표' />
                <StepButton active={step === 'done'}>신청 완료</StepButton>
            </StepButtonWrapper>
        </ResrevePageInnerContainer>
    </StepSectionContainer>
);

const NextGuider = ({ id, emoj, title, color = 'rgba(0,0,0,0.7)', bg = false }) => (
    <NextStepGudieContainer id={id} useBg={bg ? true : false}>
        <NextStepEmoj src={emoj} alt='스텝넘버' />
        <NextStepTitle color={color}>{title}</NextStepTitle>
        <NextStepArrowDown src={'/assets/images/arrowDown.png'} alt='아래로' />
    </NextStepGudieContainer>
);

const ServiceSection = ({ title, description, bg, mBg, children }) => {
    return (
        <ServiceSectionContainer>
            <ResrevePageInnerContainer bg={bg} mobileBg={mBg}>
                <ServiceSectionTitle>{title}</ServiceSectionTitle>
                <ServiceSectionDescription>{description}</ServiceSectionDescription>
                {children}
            </ResrevePageInnerContainer>
        </ServiceSectionContainer>
    );
};

const Card = ({ source, onSelect, selected }) => (
    <ServiceCardWrapper on={selected || ''}>
        <img src={source} alt='로고' />
        <ServiceCardButton className={selected ? 'selected' : 'none-selected'} onClick={onSelect}>
            선택
        </ServiceCardButton>
    </ServiceCardWrapper>
);

const ZzzService = ({ selected, onSelect }) => (
    <ResrevePageHalfInnerContainer>
        <ZzzServiceWrapper>
            <ZzzArt src='/assets/images/art_zzz.png' />
            <ServiceSectionTitle>수면 내시경 보호자 서비스</ServiceSectionTitle>
            <ZzzDescription>
                {' '}
                수면 내시경 검사 시 <MobileBr />
                보호자를 대행하는 서비스입니다.
            </ZzzDescription>
            <ServiceSectionDescription>
                수면 내시경 검사가 끝날 때까지 <b>병원 안에서</b>
                <MobileBr /> 고객님의 보호자 대행 업무를 수행합니다.
                <DesktopBr />
                <MobileBr />
                <MobileBr />
                <b>서울/경기(일부)</b>에서만 서비스가 제공됩니다.
                <DesktopBr />
                <MobileBr />
            </ServiceSectionDescription>
            <ServiceCardButton className={selected ? 'selected' : 'none-selected'} onClick={onSelect}>
                선택
            </ServiceCardButton>
        </ZzzServiceWrapper>
    </ResrevePageHalfInnerContainer>
);

const AlzheService = () => (
    <ResrevePageHalfInnerContainer>
        <AlzheServiceWrapper>
            <AlzheArt src='/assets/images/art_alzhe.jpg' />
            <ServiceSectionTitle>치매 동행 서비스 (beta)</ServiceSectionTitle>
            <AlzheDescription>
                메이트가 치매 환자분의 집-병원 간 <MobileBr /> 모든 여정을 함께하는 서비스입니다.
            </AlzheDescription>
            <ServiceSectionDescription>
                <b>치매 환자 전문</b> 메이트를 포함하여 <MobileBr /> 2인의 메이트가 배정됩니다.
                <DesktopBr />
                <MobileBr />
                <MobileBr />
                메이트(<b>2인</b>)-환자 간 매칭이 완료되지 않으면
                <MobileBr /> 서비스가 제공되지 않는 점 양해바랍니다.
                <MobileBr />
                <DesktopBr />
                <MobileBr />
                <b>전화</b>로만 서비스 예약을 하실 수 있습니다.
            </ServiceSectionDescription>
            <ServiceCardButton
                onClick={() => {
                    swal.fire({
                        title: '위드메이트 고객센터',
                        text: '☎ 070-4300-9075',
                        icon: 'info',
                        confirmButtonText: '전화걸기',
                        cancelButtonText: '확인',
                        showCancelButton: true,
                    }).then(result => {
                        if (result.isConfirmed) {
                            document.location.href = 'tel:070-4300-9075';
                        }
                    });
                }}
            >
                전화 상담하기
            </ServiceCardButton>
        </AlzheServiceWrapper>
    </ResrevePageHalfInnerContainer>
);

const ServiceTypeSection = ({ bg, mobileBg, children }) => (
    <ServiceTypeSectionContainer bg={bg} mBg={mobileBg} gap>
        {children}
    </ServiceTypeSectionContainer>
);

const ServiceTypeCard = ({ emoj, name, desc, selected, onSelect }) => (
    <ServiceTypeCardWrapper selected={selected || ''}>
        <ServiceTypeCardImg src={emoj} alt='type' />
        <ServiceTypeName>{name}</ServiceTypeName>
        <ServiceTypeDescription>{desc}</ServiceTypeDescription>
        <ServiceCardButton onClick={onSelect} className={selected ? 'selected' : 'none-selected'}>
            선택
        </ServiceCardButton>
    </ServiceTypeCardWrapper>
);

const BasicForm = ({ data, handleChange, onStarting, onDestination, onPrevSetting }) => {
    const { productInfo, service, patient, contact, sex, age, relate, guardContact = '', starting, destination } = data;
    return (
        <FormWrapper>
            <FormMainTitle>좋아요! 매칭 신청을 시작해볼까요?</FormMainTitle>

            <DivisionLine big />

            <WithmateLabel>선택하신 서비스</WithmateLabel>

            <ServiceName>
                {productInfo.name} {productInfo.name.includes('내시경') ? '' : service === 'unemergency' ? 'Pro' : 'Basic'}
            </ServiceName>

            <DivisionLine big />

            <FlexWrapper style={{ justifyContent: 'space-between' }}>
                <ServiceTypeName style={{ marginTop: '0px', marginBottom: '0px' }}>환자 정보 입력</ServiceTypeName>
            </FlexWrapper>

            <div style={{ textAlign: 'right' }}>
                <Label style={{ color: 'gray', fontSize: '11px' }}>* 등록하신 환자프로필 정보를 가져와 편리하게 예약하실 수 있습니다.</Label>
                <br />
                <UnderLineText onClick={onPrevSetting}>환자 프로필 불러오기</UnderLineText>
            </div>

            <DivisionLine />

            <FlexWrapper withHalf>
                <div style={{ flex: 1 }}>
                    <WithmateLabel required>환자 성함</WithmateLabel>
                    <WithmateInput type='text' placeholder='홍길동' maxLength='24' name='patient' value={patient || null} onChange={handleChange} />
                </div>
                <div style={{ flex: 1 }}>
                    <WithmateLabel required>환자 나이</WithmateLabel>
                    <WithmateInput type='text' maxLength='2' placeholder='50' name='age' suffix='세' value={age || null} onChange={handleChange} />
                </div>
            </FlexWrapper>

            <DivisionLine big />

            <FlexWrapper withHalf>
                {productInfo.name.includes('내시경') ? null : (
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>환자 연락처</WithmateLabel>
                        <WithmateInput
                            type='text'
                            maxLength='11'
                            placeholder='ex) 01029791234'
                            name='contact'
                            value={contact || null}
                            onChange={handleChange}
                        />
                    </div>
                )}
                <div style={{ flex: 1 }}>
                    <WithmateLabel required>환자 성별</WithmateLabel>
                    <Sexes>
                        <span
                            className={sex === '남자' ? 'active' : 'inactive'}
                            onClick={() => {
                                handleChange({ target: { name: 'sex', value: '남자' } });
                            }}
                        >
                            남성
                        </span>
                        <span
                            className={sex === '여자' ? 'active' : 'inactive'}
                            onClick={() => {
                                handleChange({ target: { name: 'sex', value: '여자' } });
                            }}
                        >
                            여성
                        </span>
                    </Sexes>
                </div>
            </FlexWrapper>

            <DivisionLine big />

            <FlexWrapper withHalf>
                <div style={{ flex: 1 }}>
                    {productInfo.name.includes('내시경') ? (
                        <>
                            <WithmateLabel required>서비스 신청인 연락처</WithmateLabel>
                            <WithmateInput
                                type='text'
                                maxLength='11'
                                placeholder='서비스를 제공받으실 분의 연락처를 입력하세요.'
                                name='guardContact'
                                value={guardContact || null}
                                onChange={handleChange}
                            />
                        </>
                    ) : (
                        <>
                            <WithmateLabel required>보호자 연락처</WithmateLabel>
                            <WithmateInput
                                type='text'
                                maxLength='11'
                                placeholder='ex) 01029791234'
                                name='guardContact'
                                value={guardContact || null}
                                onChange={handleChange}
                            />
                        </>
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <WithmateLabel required>환자와의 관계</WithmateLabel>
                    <WithmateSelect
                        placeholder='본인'
                        value={relate || undefined}
                        onChange={value => {
                            handleChange({ target: { name: 'relate', value } });
                        }}
                    >
                        <Select.Option value='본인'>본인</Select.Option>
                        <Select.Option value='부모'>부모</Select.Option>
                        <Select.Option value='자매'>자매</Select.Option>
                        <Select.Option value='친구'>친구</Select.Option>
                        <Select.Option value='지인'>지인</Select.Option>
                        <Select.Option value='기타'>기타</Select.Option>
                    </WithmateSelect>
                </div>
            </FlexWrapper>

            <DivisionLine big />

            <WithmateLabel required>목적지 주소 (방문 병원 위치)</WithmateLabel>
            <WithamteInputWithButton
                inputConfig={{
                    type: 'text',
                    placeholder: 'ex) 서울특별시 강남 을지병원',
                    name: 'destination',
                    disabled: true,
                    value: destination || null,
                }}
                buttonName='검색'
                onButton={() => {
                    if (productInfo.reservedDestination) return message.info('목적지가 고정된 서비스입니다.');
                    else onDestination();
                }}
            />

            <DivisionLine big />
            {service === 'unemergency' && (
                <>
                    <WithmateLabel required>출발지 주소 (자택, 터미널, 역, 입원 병원 등)</WithmateLabel>
                    <WithamteInputWithButton
                        inputConfig={{
                            type: 'text',
                            placeholder: 'ex) 서울특별시 서초대로  30, ABC아파트',
                            name: 'starting',
                            disabled: true,
                            value: starting || null,
                        }}
                        buttonName='검색'
                        onButton={() => {
                            if (productInfo.reservedStarting) return message.info('출발지가 고정된 서비스입니다.');
                            else onStarting();
                        }}
                    />

                    <DivisionLine big />
                </>
            )}

            <MobileStepImage>
                <img src={service === 'unemergency' ? '/assets/images/art_pro.png' : '/assets/images/art_basic.png'} alt='스텝이미지' />
            </MobileStepImage>
        </FormWrapper>
    );
};

const MainForm = ({ data, handleChange, onPrevSetting2 }) => {
    const {
        productInfo,
        service,
        addTime,
        reserveDate,
        reserveTime,
        reserveMinute,
        meetTime,
        meetMinute,
        mateGender,
        comment,
        recommender,
        recommenderContact,
        environment,
        caution,
        chooseMate,
        mateName,
        mateContact,
    } = data;
    const time = !reserveTime ? 9 : moment(reserveTime).get('hour');
    let allowedAddTimes = 18 - parseInt(time, 10) > 0 ? 18 - parseInt(time, 10) : 0;

    return (
        <FormWrapper>
            <FormMainTitle>이것만 하면 매칭 신청이 끝나요!</FormMainTitle>
            <DivisionLine x_small />

            <WithmateLabel>선택하신 서비스</WithmateLabel>
            <ServiceName>
                {productInfo.name} {productInfo.name.includes('내시경') ? '' : service === 'unemergency' ? 'Pro' : 'Basic'}
            </ServiceName>

            <ServiceTypeName style={{ marginTop: '0px', marginBottom: '0px' }}>병원 정보 입력</ServiceTypeName>
            <DivisionLine />

            <WithmateLabel required>동행 인력 희망 성별</WithmateLabel>
            <Sexes>
                <span
                    className={!mateGender ? 'active' : 'inactive'}
                    onClick={() => {
                        handleChange({ target: { name: 'mateGender', value: '' } });
                    }}
                >
                    상관없음
                </span>
                <span
                    className={mateGender === '남' ? 'active' : 'inactive'}
                    onClick={() => {
                        handleChange({ target: { name: 'mateGender', value: '남' } });
                    }}
                >
                    남성
                </span>
                <span
                    className={mateGender === '녀' ? 'active' : 'inactive'}
                    onClick={() => {
                        handleChange({ target: { name: 'mateGender', value: '녀' } });
                    }}
                >
                    여성
                </span>
            </Sexes>
            <DivisionLine big />

            <WithmateLabel required>
                메이트 선택
                {/* <LabelDescription>(선택하신 메이트님과 상의가 되신 경우에만 가능합니다.)</LabelDescription> */}
                <LabelDescription>(이전에 동행한 메이트 중에서 선택할 수 있습니다.)</LabelDescription>
            </WithmateLabel>
            <Sexes>
                <span
                    className={chooseMate === '예' ? 'active' : 'inactive'}
                    onClick={() => {
                        handleChange({ target: { name: 'chooseMate', value: '예' } });
                    }}
                >
                    예
                </span>
                <span
                    className={chooseMate === '아니오' ? 'active' : 'inactive'}
                    onClick={() => {
                        handleChange({ target: { name: 'chooseMate', value: '아니오' } });
                    }}
                >
                    아니오
                </span>
            </Sexes>
            <DivisionLine small />

            {chooseMate === '아니오' ? null : (
                <>
                    <FlexWrapper withHalf>
                        <div style={{ flex: 3 }}>
                            <WithmateInput
                                allowClear
                                readonly='readonly'
                                type='text'
                                name='mateName'
                                value={mateName || null}
                                onChange={ev => {
                                    handleChange({ target: { name: 'mateName', value: ev.target.value } });
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button onClick={onPrevSetting2} style={{ height: '46px' }}>
                                메이트 목록 불러오기
                            </Button>
                        </div>
                    </FlexWrapper>
                    <DivisionLine big />
                </>
            )}

            <WithmateLabel required>진료 예약일</WithmateLabel>
            <WithmateDatePicker
                locale={locale}
                placeholder='진료 예약일을 설정해주세요.'
                disabledDate={current =>
                    current < moment().add(0, 'day') ||
                    current.get('day') === 0 ||
                    current.get('day') === 6 ||
                    holidayKR.isSolarHoliday(moment(current).toDate())
                }
                inputReadOnly
                value={reserveDate || undefined}
                name='reserveDate'
                onChange={date => {
                    handleChange({ target: { name: 'reserveDate', value: date } });
                }}
            />
            <DivisionLine big />

            <>
                {service === 'unemergency' && (
                    <WithmateLabel required>
                        동행인의 출발지 도착 희망 시간 <LabelDescription>(자택, 터미널, 역, 입원 병원 등)</LabelDescription>
                    </WithmateLabel>
                )}
                {service === 'assist' && (
                    <WithmateLabel required>
                        동행인의 병원 도착 희망 시간 <LabelDescription>(진료 병원, 입원 병원 등)</LabelDescription>
                    </WithmateLabel>
                )}
                <FlexWrapper withHalf>
                    <div style={{ flex: 1 }}>
                        <WithmateInput
                            placeholder='13'
                            name='meetTime'
                            maxLength={2}
                            value={meetTime || undefined}
                            onChange={ev => {
                                if (ev.target.value.length > 2) return null;
                                if (ev.target.value && parseInt(ev.target.value) > 24) return null;

                                handleChange({ target: { name: 'meetTime', value: ev.target.value } });
                            }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <WithmateInput
                            placeholder='30'
                            name='meetMinute'
                            maxLength={2}
                            value={meetMinute || undefined}
                            onChange={ev => {
                                if (ev.target.value.length > 2) return null;
                                if (ev.target.value && parseInt(ev.target.value) > 59) return null;

                                handleChange({ target: { name: 'meetMinute', value: ev.target.value } });
                            }}
                        />
                    </div>
                </FlexWrapper>
                <DivisionLine big />
            </>
            {/* {service === 'unemergency' && (
            )} */}

            <WithmateLabel required>방문병원 진료 예약 시간</WithmateLabel>
            <FlexWrapper withHalf>
                <div style={{ flex: 1 }}>
                    <WithmateInput
                        placeholder='13'
                        name='reserveTime'
                        maxLength={2}
                        value={reserveTime || undefined}
                        onChange={ev => {
                            if (ev.target.value.length > 2) return null;
                            if (ev.target.value && parseInt(ev.target.value) > 24) return null;

                            handleChange({ target: { name: 'reserveTime', value: ev.target.value } });
                        }}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <WithmateInput
                        placeholder='30'
                        name='reserveMinute'
                        maxLength={2}
                        value={reserveMinute || undefined}
                        onChange={ev => {
                            if (ev.target.value.length > 2) return null;
                            if (ev.target.value && parseInt(ev.target.value) > 59) return null;

                            handleChange({ target: { name: 'reserveMinute', value: ev.target.value } });
                        }}
                    />
                </div>
            </FlexWrapper>

            <DivisionLine big />

            <WithmateLabel required>예상 소요 시간</WithmateLabel>
            <WithmateSelect
                placeholder='예상 소요 시간을 선택해주세요.'
                value={
                    addTime
                        ? meetTime && meetMinute
                            ? `${addTime}시간 (${parseInt(meetTime, 10) + parseInt(addTime, 10)}시 ${meetMinute}분 서비스 종료 예정)`
                            : addTime
                        : addTime
                }
                onChange={val => {
                    handleChange({ target: { name: 'addTime', value: val } });
                }}
            >
                {new Array(9).fill(0).map((hour, index) => {
                    return (
                        <Select.Option key={'hour-row-' + index.toString()} value={index + 1}>
                            {index + 1}시간
                        </Select.Option>
                    );
                })}
            </WithmateSelect>
            <DivisionLine big />

            <WithmateLabel required>외출하실 때 '꼭' 계단을 이용하셔야 하나요?</WithmateLabel>
            <Radio
                checked={environment === '네'}
                onClick={() => {
                    handleChange({ target: { name: 'environment', value: '네' } });
                }}
            >
                네
            </Radio>
            <Radio
                checked={environment === '아니요, 엘레베이터가 있어요'}
                onClick={() => {
                    handleChange({ target: { name: 'environment', value: '아니요, 엘레베이터가 있어요' } });
                }}
            >
                아니요, 엘레베이터가 있어요
            </Radio>
            <Radio
                checked={environment === '1층이라 괜찮아요'}
                onClick={() => {
                    handleChange({ target: { name: 'environment', value: '1층이라 괜찮아요' } });
                }}
            >
                1층이라 괜찮아요
            </Radio>
            <DivisionLine />

            <DivisionLine />

            <WithmateLabel required>메이트가 동행할 때 주의해야 할 점이 있을까요?</WithmateLabel>
            <WithmateInput
                type='text'
                placeholder='동행시 주의사항을 입력하세요.'
                value={caution || ''}
                onChange={ev => {
                    handleChange({ target: { name: 'caution', value: ev.target.value } });
                }}
            />
            <Checkbox
                checked={caution === '없어요'}
                onClick={() => {
                    handleChange({ target: { name: 'caution', value: '없어요' } });
                }}
            >
                없어요
            </Checkbox>

            <DivisionLine />

            <WithmateLabel>기타 요청 및 전달 사항</WithmateLabel>
            <WithmateTextarea
                type='text'
                middle='true'
                placeholder={`그 외에, "정형외과에 갑니다", "안마 의자 사용이 가능한지 물어봐주세요", "다음 예약 일정을 꼭 잡아주세요" 등 메이트님께 요청 및 전달하는 사항을 입력해주세요.`}
                name='comment'
                value={comment || null}
                onChange={handleChange}
            />
            <DivisionLine />

            <FlexWrapper withHalf>
                <div style={{ flex: 1 }}>
                    <WithmateLabel>추천인 성함</WithmateLabel>
                    <WithmateInput type='text' placeholder='홍길동' maxLength={24} name='recommender' onChange={handleChange} value={recommender || null} />
                </div>
                <div style={{ flex: 1 }}>
                    <WithmateLabel>추천인 전화번호</WithmateLabel>
                    <WithmateInput
                        type='text'
                        maxLength={11}
                        placeholder='ex) 01029701234'
                        name='recommenderContact'
                        onChange={handleChange}
                        value={recommenderContact || null}
                    />
                </div>
            </FlexWrapper>

            <MobileStepImage>
                <img src={service === 'unemergency' ? '/assets/images/art_pro.png' : '/assets/images/art_basic.png'} alt='스텝이미지' />
            </MobileStepImage>
        </FormWrapper>
    );
};

const ConfirmForm = ({ data, handleChange, onAddress, onPrevSetting3 }) => {
    const { productInfo, service, patient, contact, sex, age, relate, guardContact = '', starting, destination, environment, caution } = data;
    const { addTime, usePoint, reserveDate, transport, reserveTime, meetTime, mateGender, comment, reserveMinute, meetMinute, mateName, connection } = data;
    const { cardName, cardNum, year, month, installment } = data;

    const _transport = transport && transport === 'own' ? 20000 : 0;
    const _service =
        productInfo && service && (service === 'unemergency' ? productInfo.proPrice : productInfo.basicPrice ? productInfo.basicPrice : 0) * addTime;

    const _point = !usePoint ? 0 : parseInt(usePoint, 10) * -1;
    const discount = service === 'unemergency' ? productInfo.proDiscount || 0 : productInfo.basicDiscount || 0;
    const totalFee = _transport + _service + _point - discount;

    return (
        <FlexFormWrapper>
            <FormWrapper>
                <FormMainTitle>매칭 신청 내역을 알려드릴게요!</FormMainTitle>
                <DivisionLine x_small />
                <WithmateLabel>선택하신 서비스</WithmateLabel>
                <ServiceName>
                    {productInfo.name} {productInfo.name.includes('내시경') ? '' : service === 'unemergency' ? 'Pro' : 'Basic'}
                </ServiceName>

                <LabelValue style={{ marginTop: '0px', marginBottom: '0px' }}>신청 내역</LabelValue>
                <DivisionLine />

                <FlexWrapper withHalf>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>환자 성함</WithmateLabel>
                        <WithmateInput type='text' placeholder='' maxLength='24' name='patient' value={patient || null} disabled />
                    </div>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>환자 나이</WithmateLabel>
                        <WithmateInput type='text' maxLength='2' placeholder='50' name='age' suffix='세' value={age || null} disabled />
                    </div>
                </FlexWrapper>

                <DivisionLine big />

                <FlexWrapper withHalf>
                    {productInfo.name.includes('내시경') ? null : (
                        <div style={{ flex: 1 }}>
                            <WithmateLabel required>환자 연락처</WithmateLabel>
                            <WithmateInput type='text' placeholder='' maxLength='11' name='contact' value={contact || null} disabled />
                        </div>
                    )}
                </FlexWrapper>

                <DivisionLine big />
                <FlexWrapper withHalf>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>보호자 연락처</WithmateLabel>
                        <WithmateInput type='text' placeholder='' maxLength='11' name='guardContact' value={guardContact || null} disabled />
                    </div>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>환자와의 관계</WithmateLabel>
                        <WithmateInput type='text' placeholder='50' name='relate' value={relate || null} disabled />
                    </div>
                </FlexWrapper>

                <DivisionLine big />

                <FlexWrapper withHalf>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>환자 성별</WithmateLabel>
                        <WithmateInput type='text' placeholder='' name='sex' value={sex || null} disabled />
                    </div>
                    <div style={{ flex: 1 }}>
                        <WithmateLabel required>동행 인력 희망 성별</WithmateLabel>
                        <WithmateInput type='text' placeholder='50' name='mateGender' value={mateGender || '상관 없음'} disabled />
                    </div>
                </FlexWrapper>

                <DivisionLine big />

                <FlexWrapper withHalf>
                    <WithmateLabel required>선택된 메이트</WithmateLabel>
                    <WithmateInput type='text' name='mateName' value={mateName || '선택 안함'} disabled />
                </FlexWrapper>

                <DivisionLine big />

                <WithmateLabel required>목적지 주소 (방문 병원 위치)</WithmateLabel>
                <WithmateInput type='text' placeholder='' name='destination' value={destination || null} disabled />

                <DivisionLine big />
                {service === 'unemergency' && (
                    <>
                        <WithmateLabel required>출발지 주소 (자택, 터미널, 역, 입원 병원 등)</WithmateLabel>
                        <WithmateInput type='text' placeholder='' name='starting' value={starting || null} disabled />

                        <DivisionLine big />
                    </>
                )}

                <WithmateLabel required>진료 일정</WithmateLabel>
                <WithmateInput
                    type='text'
                    placeholder=''
                    maxLength={24}
                    name='reserveDate'
                    value={moment(combineDateAndTime({ date: reserveDate, time: reserveTime, minute: reserveMinute })).format('YYYY-MM-DD HH시 mm분') || null}
                    disabled
                />

                <DivisionLine big />

                {service === 'unemergency' && (
                    <WithmateLabel required>
                        동행인의 출발지 도착 희망 시간 <LabelDescription>(자택, 터미널, 역, 입원 병원 등)</LabelDescription>
                    </WithmateLabel>
                )}
                {service === 'assist' && (
                    <WithmateLabel required>
                        동행인의 병원 도착 희망 시간 <LabelDescription>(진료 병원, 입원 병원 등)</LabelDescription>
                    </WithmateLabel>
                )}
                <WithmateInput
                    type='text'
                    placeholder=''
                    maxLength={24}
                    name='meetDate'
                    value={moment(combineDateAndTime({ date: reserveDate, time: meetTime, minute: meetMinute })).format('YYYY-MM-DD HH시 mm분') || null}
                    disabled
                />

                <DivisionLine big />

                <WithmateLabel required>외출하실 때 '꼭' 계단을 이용하셔야 하나요?</WithmateLabel>
                <WithmateInput type='text' placeholder='' maxLength='24' name='environment' value={environment || null} disabled />

                <DivisionLine />

                <WithmateLabel required>메이트가 동행할 때 주의해야 할 점이 있을까요?</WithmateLabel>
                <WithmateInput type='text' placeholder='' maxLength='24' name='caution' value={caution || null} disabled />

                <DivisionLine />

                <WithmateLabel required>기타 요청 및 전달 사항</WithmateLabel>
                <WithmateTextarea type='text' placeholder='' name='comment' value={comment || null} disabled />
            </FormWrapper>

            <ResultFormWrapper>
                <FormMainTitle>결제 내역</FormMainTitle>
                <DivisionLine x_small />
                {service === 'unemergency' && (
                    <>
                        <WithmateSelect
                            placeholder='이동 수단을 선택해주세요'
                            value={transport || undefined}
                            onChange={value => {
                                handleChange({ target: { name: 'transport', value } });
                            }}
                        >
                            <Select.Option value='common'>대중 교통</Select.Option>
                            <Select.Option value='own'>본인 소유 자차 이동</Select.Option>
                        </WithmateSelect>

                        {transport === 'own' && (
                            <>
                                <DivisionLine x_small />
                                <FormMainAlert>서비스 당일 ‘누구나 보험’ 변경 필수입니다.</FormMainAlert>
                            </>
                        )}
                        <DivisionLine />
                    </>
                )}

                <LabelAndValueRow borderColor='#cecfd0'>
                    <LabelValue>예상 비용</LabelValue>
                    {service === 'unemergency' ? (
                        <PriceValue>
                            {connection === '메트라이프'
                                ? '(10% 할인가격) ' + (parseInt(totalFee, 10) + -1 * _point > 0 ? parseInt(totalFee, 10) + -1 * _point : 0) * 0.9
                                : parseInt(totalFee, 10) + -1 * _point > 0
                                ? parseInt(totalFee, 10) + -1 * _point
                                : 0}
                            원
                        </PriceValue>
                    ) : (
                        <PriceValue>{parseInt(totalFee, 10) + -1 * _point > 0 ? parseInt(totalFee, 10) + -1 * _point : 0}원</PriceValue>
                    )}
                </LabelAndValueRow>
                {service === 'unemergency' ? (
                    <>
                        <LabelAndValueRow>
                            <PriceDetailLabel>
                                <span>ㄴ</span>보유 포인트
                            </PriceDetailLabel>
                            <PriceDetailValue>{data.point}원</PriceDetailValue>
                        </LabelAndValueRow>
                        <LabelAndValueRow borderColor='#e6e6e6'>
                            <PriceDetailLabel>
                                <span>ㄴ</span>사용할 포인트
                            </PriceDetailLabel>
                            <PriceDetailValue>
                                <WithmateInput
                                    type='text'
                                    small
                                    suffix='원'
                                    name='usePoint'
                                    value={data.usePoint || null}
                                    placeholder='0'
                                    onChange={handleChange}
                                />
                            </PriceDetailValue>
                        </LabelAndValueRow>
                    </>
                ) : null}
                <LabelAndValueRow borderColor='#e6e6e6'>
                    <PriceDetailLabel>
                        <span>ㄴ</span>결제 수단
                    </PriceDetailLabel>
                    <WithmateSelect
                        placeholder='결제수단'
                        value={data.payment || undefined}
                        onChange={value => {
                            handleChange({ target: { name: 'payment', value } });
                        }}
                        style={{ width: '102px' }}
                    >
                        <Select.Option value='transfer'>계좌이체</Select.Option>
                        <Select.Option value='card'>카드결제</Select.Option>
                    </WithmateSelect>
                </LabelAndValueRow>
                {data.payment === 'card' && (
                    <>
                        <FormMainAlert>카드결제 이용시 [ 서비스 안내 사항 ]을 확인하세요</FormMainAlert>
                        <div style={{ textAlign: 'right' }}>
                            <UnderLineText onClick={onPrevSetting3}>등록된 카드목록</UnderLineText>
                        </div>
                        <div style={{ flex: 1, paddingBottom: '10px' }}>
                            <WithmateLabel required>결제 카드사</WithmateLabel>
                            <WithmateSelect
                                name='cardName'
                                placeholder='카드사를 선택하세요'
                                value={cardName || undefined}
                                onChange={value => {
                                    handleChange({ target: { name: 'cardName', value } });
                                }}
                            >
                                <Select.Option value='KB국민카드'>KB국민카드</Select.Option>
                                <Select.Option value='삼성카드'>삼성카드</Select.Option>
                                <Select.Option value='현대카드'>현대카드</Select.Option>
                                <Select.Option value='우리카드'>우리카드</Select.Option>
                                <Select.Option value='BC카드'>BC카드</Select.Option>
                                <Select.Option value='신한카드'>신한카드</Select.Option>
                                <Select.Option value='롯데카드'>롯데카드</Select.Option>
                                <Select.Option value='NH농협카드'>NH농협카드</Select.Option>
                            </WithmateSelect>
                        </div>
                        <div style={{ flex: 1, paddingBottom: '10px' }}>
                            <WithmateLabel required>카드 번호</WithmateLabel>
                            <WithmateInput
                                type='text'
                                placeholder='카드 번호를 입력하세요'
                                maxLength='24'
                                name='cardNum'
                                value={cardNum || null}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ flex: 1, paddingBottom: '10px' }}>
                            <WithmateLabel required>유효기간(MM/YY)</WithmateLabel>
                            <WithmateInput
                                type='text'
                                placeholder='월(MM)'
                                maxLength='24'
                                name='month'
                                value={month || null}
                                onChange={handleChange}
                                style={{ width: '100px', marginRight: '15px' }}
                            />
                            <WithmateInput
                                type='text'
                                placeholder='년(YY)'
                                maxLength='24'
                                name='year'
                                value={year || null}
                                onChange={handleChange}
                                style={{ width: '100px', marginRight: '15px' }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <WithmateLabel required>할부 기간</WithmateLabel>
                            <WithmateSelect
                                name='installment'
                                placeholder='할부 기간을 선택하세요'
                                value={installment || undefined}
                                onChange={value => {
                                    handleChange({ target: { name: 'installment', value } });
                                }}
                            >
                                <Select.Option value='일시불'>일시불</Select.Option>
                                <Select.Option value='2개월'>2개월</Select.Option>
                                <Select.Option value='3개월'>3개월</Select.Option>
                                <Select.Option value='4개월'>4개월</Select.Option>
                                <Select.Option value='5개월'>5개월</Select.Option>
                                <Select.Option value='6개월'>6개월</Select.Option>
                                <Select.Option value='7개월'>7개월</Select.Option>
                                <Select.Option value='8개월'>8개월</Select.Option>
                                <Select.Option value='9개월'>9개월</Select.Option>
                                <Select.Option value='10개월'>10개월</Select.Option>
                                <Select.Option value='11개월'>11개월</Select.Option>
                                <Select.Option value='12개월'>12개월</Select.Option>
                            </WithmateSelect>
                        </div>
                    </>
                )}

                <DivisionLine />

                {/* <LabelAndValueRow>
                    <LabelValue>포인트 적용 후 예상 비용</LabelValue>
                    <PriceValue>{totalFee > 0 ? totalFee : 0}원</PriceValue>
                </LabelAndValueRow> */}

                <DivisionLine big />
                <DivisionLine big />

                <ServiceTypeName style={{ marginTop: '0px', marginBottom: '0px' }}>서비스 안내 사항</ServiceTypeName>
                <DivisionLine />

                <DashAndText>
                    <img src='/assets/images/w_check.png' alt='아이콘' />
                    • 위드메이트 서비스는 후불제이며, 모든 서비스가 종료된 이후 최종 요금 안내에 따라 [계좌이체] 또는 [카드결제]를 통한 결제를 진행해주시면
                    됩니다.
                    <br />• [카드결제]를 이용하실 경우 [마이페이지]에서 사용하실 카드를 등록하시면 편리하게 카드 정보로 결제하실 수 있습니다.
                </DashAndText>

                <DivisionLine />

                <DashAndText>
                    <img src='/assets/images/w_check.png' alt='아이콘' />
                    <span>
                        • [카드결제]는 [페이투페이]의 <a>수기결제</a>로 카드결제가 진행됩니다. <br />• 카드 정보는 서비스 비용 결제 목적으로만 이용되며, 그 외
                        다른 목적으로 이용되거나 제3자에게 제공되는 일은 절대 없습니다.
                    </span>
                </DashAndText>

                <DivisionLine />

                <DashAndText>
                    <img src='/assets/images/w_check.png' alt='아이콘' />
                    이동 수단 중 [자차 이동]을 선택한 경우 예약일에 맞춰 차량 보험을 예약자 또는 본인이 직접 [누구나 보험]으로 변경해주셔야 합니다.
                </DashAndText>

                <DivisionLine />

                <DashAndText>
                    <img src='/assets/images/w_check.png' alt='아이콘' />
                    택시비와 진료비 등의 부가 비용은 고객님께서 부담해주셔야 합니다.
                </DashAndText>

                <DivisionLine />

                <DashAndText>
                    <img src='/assets/images/w_pin.png' alt='아이콘' />
                    <span>
                        서비스에 대한 추가 문의 사항은 <a>FAQ</a>에서 확인 가능합니다. 위드메이트 상담 센터 운영 시간은 월 ~ 금 09:00 ~ 18:00 입니다.
                    </span>
                </DashAndText>

                <DivisionLine />

                <DashAndText alert>
                    <img src='/assets/images/w_alert.png' alt='아이콘' />
                    위드메이트는 플랫폼 내 전문 인력 스스로 매칭 신청을 검토하므로, 매칭 신청을 하시더라도 최종 매칭에 실패할 가능성이 있음을 알려드립니다.
                </DashAndText>
                <MobileStepImage>
                    <img src={service === 'unemergency' ? '/assets/images/art_pro.png' : '/assets/images/art_basic.png'} alt='스텝이미지' />
                </MobileStepImage>
            </ResultFormWrapper>
        </FlexFormWrapper>
    );
};

const DoneForm = ({ data }) => {
    const { usePoint, point, transport, reserveDate, reserveTime, reserveMinute, productInfo, service, addTime } = data;

    const _transport = transport && transport === 'own' ? 20000 : 0;
    const _service =
        productInfo && service && (service === 'unemergency' ? productInfo.proPrice : productInfo.basicPrice ? productInfo.basicPrice : 0) * addTime;

    const _point = (usePoint || 0) * -1;
    const discount = service === 'unemergency' ? productInfo.proDiscount || 0 : productInfo.basicDiscount || 0;

    const totalFee = _transport + _service + _point - discount;

    return (
        <FormWrapper>
            <ServiceTypeName style={{ marginTop: '0px', marginBottom: '0px' }}>매칭 신청이 완료되었어요!</ServiceTypeName>
            <CongraturateLogo>
                <img src='/assets/images/artwork_festival.png' alt='예약완료' />
            </CongraturateLogo>

            <DoneResultWrapper>
                <LabelAndValueRow borderColor='#cecfd0'>
                    <LabelValue>신청일자</LabelValue>
                    <PriceDetailValue style={{ color: 'rgba(0,0,0,0.7)' }}>
                        {moment(combineDateAndTime({ date: reserveDate, time: reserveTime, minute: reserveMinute })).format('YYYY-MM-DD HH시 mm분')}
                    </PriceDetailValue>
                </LabelAndValueRow>
                <LabelAndValueRow borderColor='#cecfd0'>
                    <LabelValue>선택하신 서비스</LabelValue>
                    <PriceDetailValue style={{ color: 'rgba(0,0,0,0.7)' }}>
                        {productInfo.name} {productInfo.name.includes('내시경') ? '' : service === 'unemergency' ? 'Pro' : 'Basic'}
                    </PriceDetailValue>
                </LabelAndValueRow>
                <LabelAndValueRow borderColor='#cecfd0'>
                    <LabelValue>총 예상 비용</LabelValue>
                    <ServiceName style={{ marginBottom: '0px' }}>{totalFee}</ServiceName>
                </LabelAndValueRow>
                <LabelAndValueRow>
                    <PriceDetailLabel>
                        <span>ㄴ</span>사용된 포인트
                    </PriceDetailLabel>
                    <PriceDetailValue>{data.usePoint}원</PriceDetailValue>
                </LabelAndValueRow>
                <LabelAndValueRow>
                    <PriceDetailLabel>
                        <span>ㄴ</span>잔여 포인트
                    </PriceDetailLabel>
                    <PriceDetailValue>{point - usePoint}원</PriceDetailValue>
                </LabelAndValueRow>
                <LabelAndValueRow>
                    <PriceDetailLabel>
                        <span>ㄴ</span>결제 수단
                    </PriceDetailLabel>
                    <PriceDetailValue value>{data.payment === 'card' ? '카드결제' : '계좌이체'}</PriceDetailValue>
                </LabelAndValueRow>
            </DoneResultWrapper>

            <DivisionLine />
            <ServiceTypeName style={{ marginTop: '0px', marginBottom: '0px' }}>서비스 안내 사항</ServiceTypeName>
            <DivisionLine />

            <DashAndText>
                <img src='/assets/images/w_check.png' alt='아이콘' />
                위드메이트 서비스는 후불제이며, 모든 서비스가 종료된 이후 최종 요금 안내에 따라 계좌 이체를 통한 결제를 진행해주시면 됩니다.
            </DashAndText>

            <DivisionLine />

            <DashAndText>
                <img src='/assets/images/w_check.png' alt='아이콘' />
                이동 수단 중 ‘자차 이동’을 선택한 경우 예약일에 맞춰 차량 보험을 예약자 또는 본인이 직접 ‘누구나 보험’으로 변경해주셔야 합니다.
            </DashAndText>

            <DivisionLine />

            <DashAndText>
                <img src='/assets/images/w_check.png' alt='아이콘' />
                택시비와 진료비 등의 부가 비용은 고객님께서 부담해주셔야 합니다.
            </DashAndText>

            <DivisionLine />

            <DashAndText>
                <img src='/assets/images/w_pin.png' alt='아이콘' />
                <span>
                    서비스에 대한 추가 문의 사항은 <a>FAQ</a>에서 확인 가능합니다. 위드메이트 상담 센터 운영 시간은 월 ~ 금 09:00 ~ 18:00 입니다.
                </span>
            </DashAndText>

            <DivisionLine />

            <DashAndText alert>
                <img src='/assets/images/w_alert.png' alt='아이콘' />
                위드메이트는 플랫폼 내 전문 인력 스스로 매칭 신청을 검토하므로, 매칭 신청을 하시더라도 최종 매칭에 실패할 가능성이 있음을 알려드립니다.
            </DashAndText>
        </FormWrapper>
    );
};

const CustomButtonWrapper = styled(ButtonWrapper)`
    max-width: 640px;
    margin: 0 auto;

    @media (max-width: 480px) {
        flex-direction: column;

        > a:first-child {
            order: 2;
            margin-bottom: 48px;
        }

        > a:last-child {
            order: 1;
        }
    }
`;
