import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../../theme';

const StyledPendingContainer = styled.div`
    margin-top: 55px;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.02);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;

    .inner-container {
        padding: 0px;
        padding-top: 144px;
        padding-bottom: 100px;

        .pending-form {
            background-color: white;
            border-radius: 8px;
            box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
            width: 100%;
            max-width: 340px;
            margin: 0 auto;
            padding: 24px;

            .login-title {
                margin-bottom: 24px;

                p {
                    font-size: ${sizeHandler(32)};
                    font-weight: bold;
                    letter-spacing: -0.7px;
                    color: rgba(0, 0, 0, 0.7);
                }
            }

            .form {
                .img-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 124px;
                    padding: 20px 0px;

                    img {
                        height: 100%;
                    }
                }

                .info-title {
                    p {
                        margin: 0px;
                        padding: 0px;
                        font-size: ${sizeHandler(24)};
                        font-weight: bold;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    margin-bottom: 4px;
                }

                .info-body {
                    margin-bottom: 32px;

                    p {
                        margin: 0px;
                        padding: 0px;
                        font-size: ${sizeHandler(14)};
                        letter-spacing: -0.3px;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }
        }
    }
`;
export default StyledPendingContainer;
