import React from 'react';
import { connect } from 'react-redux';

import { getReserveInfo } from '../../../Reducer/Modules/app';
import { addReview } from '../../../Reducer/Modules/review';
import StyledAddContainer from './add.styled';
import AddCommentPage from './add';
import { message } from 'antd';

class AddCommentPageContainer extends React.PureComponent {
    componentDidMount = () => {
        const id = localStorage.getItem('id');
        const { params } = this.props.match;

        if (!id) {
            message.info('로그인 후 이용해주세요.');
            return this.props.history.push('/user/login');
        }

        if (params && params.reserveId) {
            this.props
                .getReserve({
                    reserveId: params.reserveId,
                    userId: id,
                })
                .then(resolve => {
                    if (resolve === 'no matched user') {
                        message.info('해당 리뷰의 접근권한이 없습니다.');
                        return this.props.history.push('/review/own/usage/list');
                    }

                    if (resolve && resolve.review && resolve.review.length > 0) {
                        message.info('이미 리뷰를 작성하였습니다.');
                        return this.props.history.push('/review/own/usage/list');
                    }
                });
        }
    };

    render() {
        return (
            <StyledAddContainer>
                <AddCommentPage {...this.props} />
            </StyledAddContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, review }) => ({
    app,
    auth,
    review,
});

const mapDispatchToProps = dispatch => ({
    addReview: ({ reserveId, userId, title, review, score }) => dispatch(addReview({ reserveId, userId, title, review, score })),
    getReserve: ({ reserveId, userId }) => dispatch(getReserveInfo({ reserveId, userId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentPageContainer);
