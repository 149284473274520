import axios from '../axios';
import { MATE } from '../variables';

//type: 로그인방식
export const register = async ({
    termsOfUse,
    privacyPolicy,
    essentialGeneral,
    essentialSensitive,
    selectiveGeneral,
    selectiveSensitive,
    selectiveAdvertise,
    id,
    password,
    userType,
    name,
    code,
    identify,
    reason,
    type,
    photo,
    contact,
    marketing,
    connection,
    recommenderName,
    longtermCare,
    birth,
    email,
}) => {
    const config = {
        termsOfUse,
        privacyPolicy,
        essentialGeneral,
        essentialSensitive,
        selectiveGeneral,
        selectiveSensitive,
        selectiveAdvertise,
        id,
        password,
        userType,
        name,
        type,
        photo,
        contact,
        marketing,
        connection,
        recommenderName,
        longtermCare,
        birth,
        email,
        code,
        identify,
        reason,
        urlSource: window.location.search === '?url_source=dlive' ? 'dlive' : window.location.search === '?url_source=metlife' ? 'metlife' : 'withmate',
    };

    if (userType === MATE && photo) {
        const result = await imageUpload(photo);
        config.photo = result.data;
    }

    return axios.post('/v1/auth/register', config);
};

export const addPatient = async ({ id, relate, name, sex, age, contact, starting, environment, caution }) => {
    const config = {
        id,
        relate,
        name,
        sex,
        age,
        contact,
        starting,
        environment,
        caution,
    };

    return axios.post('/v1/auth/patient/profile', config);
};

export const addCard = async ({ id, userName, cardName, cardNum, year, month }) => {
    const config = {
        id,
        userName,
        cardName,
        cardNum,
        year,
        month,
    };

    return axios.post('/v1/auth/card/add', config);
};

export const getCardInfo = async ({ cardId, info }) => {
    return axios.get('/v1/auth/card/list/info', { cardId, info });
};

export const fetchCard = async ({ id }) => {
    return axios.get('/v1/auth/card/list', { params: { id } });
};

export const deleteCard = async ({ cardId }) => {
    return axios.post('/v1/auth/card/info/delete', { cardId });
};

export const modifyCard = async ({ cardId, info }) => {
    return axios.post('/v1/auth/card/info/modify', { cardId, info });
};

export const getMateInfo = async ({ mateId, info }) => {
    return axios.get('/v1/auth/mate/list/info', { mateId, info });
};

export const fetchMate = async ({ id }) => {
    return axios.get('/v1/auth/mate/list', { params: { id } });
};

export const getPatientInfo = async ({ patientId, info }) => {
    return axios.get('/v1/auth/patient/list/info', { patientId, info });
};

export const fetchPatient = async ({ id }) => {
    return axios.get('/v1/auth/patient/list', { params: { id } });
};

export const deletePatient = async ({ patientId }) => {
    return axios.post('/v1/auth/patient/info/delete', { patientId });
};

export const modifyPatient = async ({ patientId, info }) => {
    return axios.post('/v1/auth/patient/info/modify', { patientId, info });
};

export const login = ({ id, password, userType, type }) => {
    return axios.post('/v1/auth/login', { id, password, userType, type });
};

export const logout = ({ id, userType }) => {
    return axios.get('/v1/auth/logout', { params: { id, userType } });
};

export const checkUserId = ({ id, userType, type }) => {
    return axios.get('/v1/auth/validate/user', { params: { id, userType, type } });
};

export const checkAuth = ({ id, userType }) => {
    return axios.get('/v1/auth/authorized', { params: { id, userType } });
};

export const findPassword = ({ id, userType }) => {
    return axios.post('/v1/auth/find/password', { id, userType });
};

export const confirmEmailAuth = ({ verificationCode }) => {
    return axios.post('/v1/auth/register', { verificationCode });
};

export const modifyPassword = ({ id, userType, password, newPassword }) => {
    return axios.post('/v1/auth/modify/password', { id, userType, password, newPassword });
};

export const withdrawl = ({ id, userType, password }) => {
    return axios.post('/v1/auth/withdrawl', { id, userType, password });
};

export const withdrawlKakao = ({ id, userType }) => {
    return axios.post('/v1/auth/withdrawl/kakao', { id, userType });
};

export const imageUpload = photo => {
    const formData = new FormData();
    formData.append('photo', photo);
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post('/v1/common/image/upload', formData, header);
};

export const onAddLongTermCare = async ({ userId, info }) => {
    return axios.post('/v1/longTermCare/add', { userId, info });
};
