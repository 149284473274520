import React from 'react';
import {
    MeritContainer,
    InnerContainer,
    MeritHeaderWrapper,
    MeritCardList,
    MeritCardWrapper,
    MeritCardNumber,
    MeritCardDesc,
    MeritCardTitleRow,
} from '../guide.styled';

export const Merit = () => {
    return (
        <MeritContainer id='merit'>
            <MeritHeaderWrapper>
                <InnerContainer>
                    <div className='wrap'>
                        <img src='/assets/images/faith_up.png' />
                        <h3>믿을 수 있는 위드메이트</h3>
                    </div>
                </InnerContainer>
            </MeritHeaderWrapper>
            <InnerContainer>
                <MeritCardList>
                    <MeritCard
                        title='인정 받은 서비스'
                        source='/assets/images/faith_icon_1.png'
                        desc={
                            <>
                                위드메이트 병원 동행 서비스는
                                <br />
                                <span>신한 오픈 이노베이션 2기 우수 기업</span>으로
                                <br />
                                선정된 신뢰할 수 있는 서비스입니다.{' '}
                            </>
                        }
                        number='01'
                    />
                    <MeritCard
                        title='검증 받은 서비스'
                        source='/assets/images/faith_icon_2.png'
                        desc={
                            <>
                                위드메이트는 엄격한 검증을 거친 후<br />
                                보건복지부와 고양시, 그리고 국립 암센터가
                                <br />
                                주관하는<span>‘고양 해피 케어’</span>컨소시엄의 <br />
                                일원으로 활동 중에 있습니다.
                            </>
                        }
                        number='02'
                    />
                    <MeritCard
                        title='서비스 인력 평점 제도'
                        source='/assets/images/faith_icon_3.png'
                        desc={
                            <>
                                위드메이트는 더욱 높은 서비스 경험을
                                <br />
                                제공하기 위해 매뉴얼을 만들고, 서비스 <br />
                                종료 후 <span>고객님의 후기를 반영</span>하여 <br />
                                서비스 인력을 관리합니다.
                            </>
                        }
                        number='03'
                    />
                    <MeritCard
                        title='전문적인 서비스 인력 양성'
                        source='/assets/images/faith_icon_4.png'
                        desc={
                            <>
                                위드메이트는 여성 인력 개발 센터,
                                <br /> 재취업 지원 센터, 고용 복지 플러스
                                <br /> 센터 등의 전문 기관과 <span>일자리 협업 </span>
                                <br />
                                <span>프로그램</span>을 운영하며 검증된 서비스
                                <br />
                                인력을 주기적으로 양성하고 있습니다.
                            </>
                        }
                        number='04'
                    />
                    <MeritCard
                        title='책임지는 서비스'
                        source='/assets/images/faith_icon_5.png'
                        desc={
                            <>
                                위드메이트는 서비스 제공 인력 전원이
                                <br />
                                삼성화재가 제공하는 1억 2천만원 한도의 <br />
                                <span>‘전문인 영업 배상 책임 보험’</span>에 가입되어 <br />
                                책임지는 서비스를 제공합니다.
                            </>
                        }
                        number='05'
                    />
                    <MeritCard
                        title='안전한 서비스'
                        source='/assets/images/faith_icon_6.png'
                        desc={
                            <>
                                위드메이트는 <span> 시니어 헬스케어 서비스의</span>
                                <br />
                                <span>본질은 ‘안전’</span>이라고 생각하고, 서비스 인력의
                                <br />
                                정기적인 교육, 자격증 취득 및 보유 여부 <br />
                                점검, 서비스 매뉴얼의 수정 및 보완등을 <br />
                                진행하고 있습니다.
                            </>
                        }
                        number='06'
                    />
                </MeritCardList>
            </InnerContainer>
        </MeritContainer>
    );
};

const MeritCard = ({ number, title, source, desc }) => (
    <MeritCardWrapper>
        <MeritCardNumber>{number}</MeritCardNumber>
        <MeritCardTitleRow>
            <img src={source} />
            <span>{title}</span>
        </MeritCardTitleRow>
        <MeritCardDesc>{desc}</MeritCardDesc>
    </MeritCardWrapper>
);
