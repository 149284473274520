import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import List from './List';
import Info from './Info';

class NewsEvent extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/360F/newsEvent/list' component={List} />
                <Route exact path='/360F/newsEvent/info/:id' component={Info} />
            </Switch>
        );
    }
}
export default NewsEvent;
