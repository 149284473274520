import React from 'react';
import { Checkbox, Input, message, Spin } from 'antd';

// utils
import axios from '../../../Lib/axios';
import { imageUpload } from '../../../Lib/Api/app';

// styles
import { FormContainer, InnerContainer, InputWrapper, InputLabel, StyledInput, ApplyButton, LargeText, FormImgWrapper, SpinWrapper } from './form.styled';

const initialState = {
    info: [],
    photoFst: false,
    photoSnd: false,
    fileFst: false,
    fileSnd: false,
    photoFstValue: null,
    photoSndValue: null,
    fileFstValue: null,
    fileSndValue: null,
    onSpin: false,
};

class MateFormPage extends React.PureComponent {
    state = {
        ...initialState,
    };

    componentDidMount = () => {
        this.fetchForm();
    };

    componentWillUnmount = () => {
        this.setState({ ...initialState });
    };

    fetchForm = async () => {
        const { data: form } = await axios.get('/v1/user/recent/apply/form');
        this.setState({
            ...form,
        });
    };

    editForm = ({ type, value }) => {
        this.setState({ [type]: value });
    };

    sendForm = async () => {
        const { careCareer, info, photoFst, photoSnd, fileSnd, fileFst } = this.state;
        const { photoFstValue, photoSndValue, fileFstValue, fileSndValue } = this.state;

        this.setState({ onSpin: true });

        if (!careCareer) {
            this.setState({ onSpin: false });
            return message.warning('치매 간병 경력 여부를 선택해주세요.');
        }

        if (info.some(item => !item.value)) {
            this.setState({ onSpin: false });
            return message.warning('답변하지 않은 질문이 있습니다.');
        }

        if (photoFst && !photoFstValue) {
            this.setState({ onSpin: false });
            return message.warning('프로필 사진을 첨부해주세요.');
        }

        if (photoSnd && !photoSndValue) {
            this.setState({ onSpin: false });
            return message.warning('이미지 파일을 첨부해주세요.');
        }

        if (fileFst && !fileFstValue) {
            this.setState({ onSpin: false });
            return message.warning('이미지 파일을 첨부해주세요.');
        }

        if (fileSnd && !fileSndValue) {
            this.setState({ onSpin: false });
            return message.warning('추가 파일을 첨부해주세요.');
        }

        const form = { careCareer, info };
        if (photoFst) {
            const { data } = await imageUpload(photoFstValue);
            form.photoFst = data;
        }
        if (photoSnd) {
            const { data } = await imageUpload(photoSndValue);
            form.photoSnd = data;
        }
        if (fileFst) {
            const { data } = await imageUpload(fileFstValue);
            form.fileFst = data;
        }
        if (fileSnd) {
            const { data } = await imageUpload(fileSndValue);
            form.fileSnd = data;
        }

        const { data } = await axios.post('/v1/user/apply/mate', {
            form,
        });

        if (!data) {
            this.setState({ onSpin: false });
            return message.error('서버 장애로 지원에 실패했습니다. 관리자에게 문의해주세요.');
        }

        if (data === 'success') {
            this.setState({ onSpin: false });
            message.success('감사합니다. 메이트 지원이 완료되었습니다. 검토후 결과를 안내해드리겠습니다.');
            console.log(data, form);
            setTimeout(() => {
                window.location.href = '/';
            }, 1500);
            return null;
        }

        if (data === 'check parameters') {
            this.setState({ onSpin: false });
            return message.info('누락된 정보가 있는지 지원서 양식을 확인해주세요.');
        }
    };

    render() {
        const { careCareer, info, photoFst, photoSnd, fileFst, fileSnd } = this.state;

        return (
            <FormContainer>
                <InnerContainer>
                    <LargeText style={{ color: '#161616', marginBottom: '20px' }}>메이트 지원하기</LargeText>
                    <InputWrapper>
                        <InputLabel>치매관련 간병 경력</InputLabel>
                        <Checkbox checked={careCareer === '없어요'}
                            onClick={() => {
                                this.editForm({ type: 'careCareer', value: '없어요' })
                            }}
                        >
                            없어요
                        </Checkbox>
                        <Checkbox checked={careCareer === '1년 미만'}
                            onClick={() => {
                                this.editForm({ type: 'careCareer', value: '1년 미만' })
                            }}
                        >
                            1년 미만
                        </Checkbox>
                        <Checkbox checked={careCareer === '2 ~ 3년'}
                            onClick={() => {
                                this.editForm({ type: 'careCareer', value: '2 ~ 3년' })
                            }}
                        >
                            2 ~ 3년
                        </Checkbox>
                        <Checkbox checked={careCareer === '3년이상'}
                            onClick={() => {
                                this.editForm({ type: 'careCareer', value: '3년이상' })
                            }}
                        >
                            3년이상
                        </Checkbox>
                    </InputWrapper>

                    {info.map((item, index) => (
                        <InputWrapper key={'question-' + index.toString() + '-keys'}>
                            <InputLabel>
                                {item.question}{' '}
                                {item.maxLength ? (
                                    <span>
                                        ({item.value ? item.value.length : 0}/{item.maxLength})
                                    </span>
                                ) : (
                                    ''
                                )}
                            </InputLabel>
                            <StyledInput
                                value={item.value}
                                placeholder={item.placeholder}
                                onChange={ev => {
                                    const _info = JSON.parse(JSON.stringify(info));
                                    if (item.maxLength && ev.target.value.length > item.maxLength) {
                                        return null;
                                    }

                                    _info[index].value = ev.target.value;
                                    this.editForm({ type: 'info', value: _info });
                                }}
                            />
                        </InputWrapper>
                    ))}
                    {photoFst ? (
                        <InputWrapper>
                            <InputLabel>프로필 사진</InputLabel>
                            {this.state.photoFstValue ? (
                                <FormImgWrapper>
                                    <img src={URL.createObjectURL(this.state.photoFstValue)} />
                                </FormImgWrapper>
                            ) : null}
                            <input
                                type='file'
                                accept='image/x-png,image/gif,image/jpeg'
                                onChange={ev => {
                                    this.editForm({ type: 'photoFstValue', value: ev.target.files[0] });
                                }}
                            />
                        </InputWrapper>
                    ) : null}
                    {photoSnd ? (
                        <InputWrapper>
                            <InputLabel>이미지 파일 첨부</InputLabel>
                            {this.state.photoSndValue ? (
                                <FormImgWrapper>
                                    <img src={URL.createObjectURL(this.state.photoSndValue)} />
                                </FormImgWrapper>
                            ) : null}
                            <input
                                type='file'
                                accept='image/x-png,image/gif,image/jpeg'
                                onChange={ev => {
                                    this.editForm({ type: 'photoSndValue', value: ev.target.files[0] });
                                }}
                            />
                        </InputWrapper>
                    ) : null}
                    {fileFst ? (
                        <InputWrapper>
                            <InputLabel>이력서 첨부</InputLabel>
                            {this.state.fileFstValue ? <p>{this.state.fileFstValue.name}</p> : null}
                            <input
                                type='file'
                                onChange={ev => {
                                    this.editForm({ type: 'fileFstValue', value: ev.target.files[0] });
                                }}
                            />
                        </InputWrapper>
                    ) : null}
                    {fileSnd ? (
                        <InputWrapper>
                            <InputLabel>추가 파일첨부</InputLabel>
                            {this.state.fileSndValue ? <p>{this.state.fileSndValue.name}</p> : null}
                            <input
                                type='file'
                                onChange={ev => {
                                    this.editForm({ type: 'fileSndValue', value: ev.target.files[0] });
                                }}
                            />
                        </InputWrapper>
                    ) : null}

                    <div style={{ textAlign: 'center' }}>
                        <ApplyButton onClick={this.sendForm}>지원하기</ApplyButton>
                    </div>
                </InnerContainer>
                {this.state.onSpin ? (
                    <SpinWrapper>
                        <Spin size='large' />
                    </SpinWrapper>
                ) : null}
            </FormContainer>
        );
    }
}

export default MateFormPage;
