import React from "react";
import Modal from "react-modal";
import styled from 'styled-components';
import { Button, message, Table } from 'antd';

import ProfileStyledContainer from "../Auth/AuthPages/Profile/profile.styled";
import { HalfWidthButton } from "../../Components/Common/button";
import { DivisionLine } from "../../Components/Common/input";
import { getPatientInfo } from "../../Lib/Api/auth";

const customStyles = {

    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(15, 15, 15, 0.79)",
    },
    content: {
        position: "absolute",
        top: "30px",
        height: "60%",
        border: "1px solid #ccc",
        background: "#ffffff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
    },
}

const patientColumn = ({ onCheck }) => {
    return [
        {
            title: '이름',
            dataIndex: 'name',
            align: 'center',
            width: '100px'
        },
        {
            title: '나이',
            dataIndex: 'age',
            align: 'center',
            width: '100px'
        },
        {
            title: '선택',
            align: 'center',
            width: '100px',
            dataIndex: '_id',
            render: (_id, data) =>
                <Button
                    style={{ width: '70px', height: '30px' }}
                    onClick={() => { onCheck(data) }}
                >
                    선택
                </Button>
        },
    ]
}

class PatientProfileListComponent extends React.PureComponent {
    state = {
        patientInfo: {},
        patientList: [],
        patientId: '',
        patientInfo: null
    };

    componentWillUnmount = () => {
        this.setState({ patientInfo: null });
    };

    onChoose = () => {
        const { patientInfo } = this.state;
        console.log(patientInfo)

        getPatientInfo({ patientId: patientInfo._id }).then(resolve => {
            console.log(resolve)
            const { data } = resolve;
            if (resolve && resolve.data && resolve.data.message === 'success') {
                if (patientInfo && patientInfo.reservedStarting) delete data.starting;
                if (patientInfo && patientInfo.environment) delete data.environment;
                if (patientInfo && patientInfo.walk) delete data.walk;

                this.setState({ patientInfo: resolve.data.data })
            } else {
                message.info('선택하신 환자 프로필이 존재하지 않습니다.');
            }
        });
    };

    onChangeCheckbox = ({ }) => {

    };

    // 체크박스 선택
    // rowSelection = {
    //     onChange: (selectedRowKeys , selectedRows ) => {
    //       console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //     },
    //     getCheckboxProps: (record ) => {
    //         console.log(record)
    //     }
    //   };

    render() {
        // console.log(this.props)
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <ProfileStyledContainer>
                    <div className='inner-container'>
                        <div className='profile-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>환자 프로필 목록</WideModalTitle>
                                    <AlertText>
                                        * 환자 프로필 정보를 선택할 수 있습니다. <br />
                                        [ 마이페이지 ]에서 환자 프로필을 등록하실 수 있습니다.
                                    </AlertText>
                                </TitleSection>

                                <br />

                                <DivisionLine />

                                <div className='profileList'>
                                    <Table
                                        dataSource={this.props.list}
                                        columns={patientColumn({
                                            onCheck: (data) => {
                                                this.props.onUpdatePatient(data);
                                                this.props.onClose()
                                            }
                                        })}
                                        rowKey='_id'
                                        pagination={{
                                            pageSize: 3,
                                            hideOnSinglePage: true,
                                            size: 'small',
                                        }}
                                    // rowSelection={{
                                    //     type: 'checkbox',
                                    //     ...this.rowSelection
                                    // }}
                                    // rowKey='_id'
                                    />
                                </div>

                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff'
                                        onClick={this.props.onClose}
                                    >
                                        닫기
                                    </HalfWidthButton>
                                </ButtonWrap>
                            </div>
                        </div>
                    </div>
                </ProfileStyledContainer >
            </Modal >
        );
    }
};

export default PatientProfileListComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;