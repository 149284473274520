import React from 'react';
import styled from 'styled-components';

import { sizeHandler, mainColor } from '../../../theme';

export const UserNavigation = ({ active, tabHandler, onPatientModal }) => (
    <NaviWrapper>
        <div className='nav-tab'>
            <span
                onClick={() => {
                    tabHandler('list');
                }}
                className={`name ${active === 'list' ? 'active' : ''}`}
            >
                예약내역
            </span>
            <span className={`underline ${active === 'list' ? 'active' : ''}`}></span>
        </div>

        <div className='nav-tab'>
            <span
                onClick={() => {
                    tabHandler('info');
                }}
                className={`name ${active === 'info' ? 'active' : ''}`}
            >
                개인정보 관리
            </span>
            <span className={`underline ${active === 'info' ? 'active' : ''}`}></span>
        </div>

        <div className='nav-tab'>
            <span
                onClick={() => {
                    tabHandler('point');
                }}
                className={`name ${active === 'point' ? 'active' : ''}`}
            >
                내 포인트
            </span>
            <span className={`underline ${active === 'point' ? 'active' : ''}`}></span>
        </div>

        <div className='nav-tab'>
            <span
                onClick={() => {
                    // onPatientModal();
                    tabHandler('profile');
                }}
                className={`name ${active === 'profile' ? 'active' : ''}`}
            >
                환자 프로필 관리
            </span>
            <span className={`underline ${active === 'profile' ? 'active' : ''}`}></span>
        </div>
    </NaviWrapper>
);

const NaviWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 30px;

    .nav-tab {
        margin-right: 32px;
        padding-bottom: 12px;
        position: relative;

        .name {
            font-size: ${sizeHandler(14)};
            text-align: center;
            cursor: pointer;

            &.active {
                font-weight: bold;
                color: #3bc1ff;
            }
        }

        span.underline {
            background-color: #3bc1ff;
            position: absolute;
            bottom: 0px;
            left: 0px;

            &.active {
                width: 100%;
                height: 3px;
                display: inline-block;
            }
        }
    }
`;
