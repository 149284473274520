import React from 'react';

import { MATE } from '../../../Lib/variables';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// section
import Reserve from './Section/reserve';
import { UserMain as RenewMain } from './Section/renew/main';
import { GuideDetail } from '../../Guide/Section/detail';
import { UserGuideSection } from './Section/renew/guide';
import { UserNumberCount } from './Section/renew/numberCount';

import { WithmatePartners } from './Section/partners';

export default class HomePage extends React.PureComponent {
    state = {
        searchModal: false,
    };

    componentDidMount = async () => {};

    render() {
        const { userType } = this.props.auth;

        return (
            <div id='#main'>
                {userType === MATE ? (
                    <>
                        <Reserve {...this.props} />
                    </>
                ) : (
                    <>
                        <RenewMain {...this.props} />
                        <UserNumberCount />
                        <GuideDetail />
                        <UserGuideSection />
                        <WithmatePartners />
                        {/* <UserMainBanner
                            goPage={() => {
                                this.props.history.push('/nemt/service');
                            }}
                        /> */}
                    </>
                )}
            </div>
        );
    }
}
