import styled from 'styled-components';

const StyledContainer = styled.div``;

export default StyledContainer;

export const Container = styled.div``;

export const NemtTitleWrapper = styled.div`
    margin-top: 104px;
    margin-bottom: 58px;
`;

export const InnerContainer = styled.div`
    width: 100%;

    max-width: 1120px;
    margin: 0 auto;

    @media (max-width: 1120px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const NemtTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;

    text-align: center;
    letter-spacing: -0.6px;

    color: rgba(0, 0, 0, 0.8);
`;

export const NemtServiceWrapper = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
`;

export const NemtServiceFlexWrapper = styled.div`
    display: flex;

    align-items: flex-start;
    justify-content: center;

    margin-bottom: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const NemtBox = styled.div`
    margin-bottom: 30px;
    position: relative;

    @media (max-width: 768px) {
        margin-bottom: 80px;
    }
`;

export const NemtColumnBox = styled(NemtBox)`
    width: calc((100% - 20px) / 2);

    &:first-child {
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        &:first-child {
            margin-right: 0px;
        }

        width: 100%;
    }
`;

export const NemtBoxTitle = styled.p`
    position: absolute;
    top: 34px;
    left: 34px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: -0.4px;

    > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 44px;

        letter-spacing: -0.777778px;
    }

    @media (max-width: 768px) {
        position: relative;
        top: 0px;
        left: 0px;

        margin-bottom: 4px;

        font-size: 15px;
        line-height: 21px;

        > span {
            font-size: 24px;
            line-height: 40px;
        }
    }
`;

export const NemtServiceImageWrapper = styled.div`
    border-radius: 12px;
    overflow: hidden;

    &.desktop-visible {
        display: block;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &.mobile-visible {
        display: none;

        @media (max-width: 768px) {
            display: block;
        }
    }

    > img {
        width: 100%;
        object-fit: contain;
    }
`;

export const MobileBr = styled.br`
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

export const DesktopBr = styled.br`
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const NemtInfobox = styled.div`
    padding: 24px;

    @media (max-width: 768px) {
        padding: 0px;
        padding-top: 24px;
    }
`;

export const ReserveButton = styled.span`
    width: 246px;
    height: 48px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    background: #3bc1ff;
    border-radius: 4px;

    cursor: pointer;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    color: #ffffff;

    > img {
        width: 18px;
        height: 18px;

        margin-right: 6px;
    }

    margin-bottom: 20px;

    &:hover {
        opacity: 0.85;
        transition: all 0.5s;
    }

    @media (max-width: 430px) {
        font-size: 12px;
    }

    @media (max-width: 430px) {
        width: 100%;
    }
`;

export const NemtDescription = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    word-break: keep-all;

    color: rgba(0, 0, 0, 0.7);

    > span {
        color: #3bc1ff;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
    }
`;
