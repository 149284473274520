import styled from 'styled-components';

const StyledContainer = styled.div``;

export default StyledContainer;

export const Container = styled.div`
    padding-top: 55px;
`;

export const GuideNavContainer = styled.div`
    width: 100%;
    background-color: #ffffff;

    border-bottom: 4px solid #f2f2f2;
`;

export const InnerContainer = styled.div`
    width: 100%;

    max-width: 1120px;
    margin: 0 auto;

    // @media (max-width: 1120px) {
    //     padding-left: 32px;
    //     padding-right: 32px;
    // }

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const GuideNavWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: 768px) {
        overflow-x: scroll;
    }
`;

export const NavTab = styled.div`
    > a {
        cursor: pointer;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: -0.5px;
        text-align: left;

        white-space: nowrap;

        color: ${props => (props.active ? '#3BC1FF' : 'rgba(0,0,0,0.6)')};

        display: inline-block;
    }

    @media (max-width: 768px) {
        margin-right: 40px;

        > a {
            font-size: 16px;
        }
    }
`;

export const GuideConceptContainer = styled.div`
    margin-top: 102px;
`;

export const GuideConceptTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 50px;
`;

export const GuideConceptImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-bottom: 40px;
`;

export const GuideConceptImage = styled.img`
    height: 200px;
    object-fit: contain;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const GuideConceptImageMobile = styled.img`
    display: none;

    height: 300px;
    object-fit: contain;

    @media (max-width: 768px) {
        display: block;
    }
`;

export const GuideConceptTextWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-bottom: 122px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const GuideConceptTextBlock = styled.div`
    &:first-child {
        margin-right: 20px;

        @media (max-width: 768px) {
            margin-right: 0px;

            margin-bottom: 16px;
        }
    }

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.2px;
    text-align: left;
    word-break: keep-all;

    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.2px;
        text-align: left;
    }

    > span {
        font-weight: bold;
    }
`;

export const MobileBr = styled.br`
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 320px) {
        display: none;
    }
`;

export const DesktopBr = styled.br`
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const MeritContainer = styled.div`
    background-color: #ffffff;

    padding-top: 100px;
    padding-bottom: 80px;
`;

export const MeritHeaderWrapper = styled.div`
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px solid #f2f2f2;

    div.wrap {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
    }

    img {
        width: 72px;
        height: 72px;
        object-fit: contain;

        margin-right: 10px;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.5px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 0px;
    }
`;

export const MeritCardList = styled.div`
    display: flex;
    flex-wrap: wrap;

    padding-top: 64px;
`;

export const MeritCardWrapper = styled.div`
    height: 260px;
    width: 33.333%;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }
`;

export const MeritCardNumber = styled.p`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #afb0b2;
    margin-bottom: 16px;
`;

export const MeritCardTitleRow = styled.div`
    display: flex;
    align-items: center;

    > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 10px;
    }

    > span {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: -0.1px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
    }
`;

export const MeritCardDesc = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-weight: bold;
    }

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

export const GuideDetailContainer = styled.div`
    background-color: #f9f9f9;

    padding-top: 56px;
    padding-bottom: 100px;
`;

export const GuideDetailServiceCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const GuideDetailServiceCard = styled.div`
    width: 360px;
    padding: 34px 32px;

    background: #ffffff;
    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        margin-bottom: 24px;
        width: 100%;

        padding: 34px 16px;
    }
`;

export const GuideCardImage = styled.img`
    width: 124px;
    height: 124px;
    border-radius: 50%;

    margin-bottom: 40px;
`;

export const GuideCardSpecTitle = styled.p`
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 24px;
`;

export const GuideCardSpecRow = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;

    margin-bottom: 24px;

    > span {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #3bc1ff;
        margin-right: 10px;
    }

    > p {
        width: 100%;
        flex: 1;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.65;
        letter-spacing: -0.3px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
    }
`;

export const GuideCardName = styled.p`
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
`;

export const GuideCardType = styled.h3`
    width: 100%;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.5px;
    text-align: left;
    color: #3bc1ff;
    margin-bottom: 24px;
`;

export const GuideCardDescription = styled.p`
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-weight: bold;
    }

    margin-bottom: 60px;
`;

export const GuideDetailSubFlexWrapper = styled.div`
    display: flex;
    justify-content: content;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const GuideDetailButtonWrapper = styled(GuideDetailSubFlexWrapper)`
    border-radius: 4px;
    overflow: hidden;

    > span.button {
        cursor: pointer;
        display: inline-block;

        flex: 1;
        height: 44px;
        line-height: 44px;

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: #ffffff;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    > span.gray {
        background-color: #606060;

        @media (max-width: 768px) {
            margin-bottom: 8px;
        }
    }

    > span.blue {
        background-color: #3bc1ff;
    }
`;

export const GuideDetailSubFlexbox = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > p {
        margin-bottom: 20px;

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

        text-align: left;

        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.7);
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const GuideUsingContainer = styled.div`
    padding-top: 62px;
    padding-bottom: 78px;
    background-color: #f9f9f9;
`;

export const GuideUsingSubFlexWrapper = styled(GuideDetailSubFlexWrapper)`
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        align-items: flex-start;
        padding-left: 40px;
    }
`;

export const GuideUsingFlexbox = styled(GuideDetailSubFlexbox)`
    align-items: center;
    flex: initial;
    min-width: 320px;

    > p.index {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;

        letter-spacing: -0.333333px;
        color: rgba(0, 0, 0, 0.2);

        margin-bottom: 16px;
    }

    > p {
        width: auto;
        text-align: center;
    }

    > p.title {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;

        text-align: center;
        letter-spacing: -0.6px;
    }

    > div {
        display: flex;
        justify-content: center;

        margin-bottom: 16px;

        img {
            height: 90px;
            object-fit: contain;
        }
    }

    margin-bottom: 44px;
`;

export const GuideUsageFlexbox = styled.div`
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 68px;

    @media (max-width: 768px) {
        margin-bottom: 32px;
        // width: 230px;
        align-items: ;
    }

    div {
        // width: 120px;

        img {
            width: 64px;
            height: 64px;
            margin-right: 34px;
            object-fit: contain;
        }

        // @media (max-width: 768px) {
        //     width: 70px;

        //     img {
        //         height: 32px;
        //     }
        // }
    }

    span {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: -0.2px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);

        // @media (max-width: 768px) {
        //     font-size: 13px;
        //     line-height: 19px;
        // }
    }
`;

export const WithmateContainer = styled.div`
    padding-top: 56px;
    // padding-bottom: 120px;
`;

export const FlexDivWrapper = styled.div`
    display: flex;
    width: 100%;

    ${props => props.top && `margin-bottom: 72px;`}
`;

export const HiddenType = styled.div`
    @media (max-width: 768px) {
        ${props => props.mHidden && `display: none;`}
    }
`;

export const BigSideBox = styled(HiddenType)`
    width: 58%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const SmallSideBox = styled(HiddenType)`
    width: 42%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const WithmateBg = styled.div`
    width: 100%;
    height: 100%;

    background-image: url(${props => props.bg});
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
`;

export const WithmateTitle = styled.div`
    > img {
        width: 72.5px;
        height: 48px;
        object-fit: contain;
        margin-right: 8px;
    }

    > span {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: -0.5px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
    }

    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
`;
