import React from 'react';

class EssentialComponent extends React.PureComponent {
    render() {
        return (
            <div className='terms-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>[필수/일반] (주)블루카멜 개인정보 수집 및 이용 동의 확인서</h2>
                    </div>

                    <div className='context-block'>
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ width: '55%' }}>처리 항목</td>
                                    <td>처리 목적</td>
                                    <td>처리 목적</td>
                                </tr>
                            </thead>
                            <tbody style={{ border: '1px solid gray' }}>
                                <tr>
                                    <td>
                                        공통(가입경로, 가입자 생년월일, 가입자 성별, 가입자 이름, 가입자 휴대전화번호, 보호자/환자 여부), 이메일주소 가입
                                        시(이메일 주소, 비밀번호, 인증번호), 카카오계정 가입 시(카카오계정)
                                    </td>
                                    <td>회원 가입 및 관리</td>
                                </tr>
                                <tr>
                                    <td>민원인 이름, 민원인 휴대전화번호</td>
                                    <td>회원 가입 및 관리</td>
                                </tr>
                                <tr>
                                    <td>
                                        거주환경(계단이용여부), 동행인 선택 여부, 동행인 성별 선택, 동행인 이름, 예상 소요시간, 예약자 휴대전화번호, 이동 수단,
                                        환자 나이, 환자 성별, 환자 이름, 환자 휴대전화번호, 환자와의 관계, 희망 접선 일시
                                    </td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>온라인 예약하기</td>
                                </tr>
                                <tr>
                                    <td>
                                        서비스 종류, 작성 일시, 작성자 이름, 평점, 방문병원 주소, 서비스 진행 현황, 예약 신청 일자, 진료 예약 일시, 환자 이름
                                    </td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>후기 작성하기</td>
                                </tr>
                                <tr>
                                    <td>
                                        동행인 사진, 동행인 서비스 이력, 동행인 이름, 동행인 평점, 동행인 휴대전화번호, 보호자 휴대전화번호, 서비스 종류, 예상
                                        소요시간, 예약 신청 일시, 이동 수단, 환자 성별, 환자 이름, 환자 휴대전화번호, 환자와의 관계, 희망 접선 시간, 희망 접선
                                        일자
                                    </td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>마이페이지(예약내역)</td>
                                </tr>
                                <tr>
                                    <td>가입자 이름, 가입자 휴대전화번호, 비밀번호, 카드 유효기간, 카드번호, 카드사</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>마이페이지(개인정보 관리)</td>
                                </tr>
                                <tr>
                                    <td>쿠폰 번호, 쿠폰 사용 내역, 포인트 적립 내역, 포인트 사용 내역, 현재 잔여 포인트</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>마이페이지(내 포인트)</td>
                                </tr>
                                <tr>
                                    <td>거주환경(계단이용여부), 환자 나이, 환자 성별, 환자 이름, 환자 휴대전화번호, 환자와의 관계</td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>마이페이지(환자 프로필 관리)</td>
                                </tr>
                                <tr>
                                    <td>
                                        거주환경(계단이용여부), 동행인 성별 선택, 동행인 의료 기관 평가, 동행인 의사 평가, 동행인 이름, 동행인 휴대전화번호,
                                        보호자 휴대전화번호, 서비스 종류, 예상 소요시간, 예약번호, 예약자 이름, 이동 수단, 환자 나이, 환자 성별, 환자 이름, 환자
                                        휴대전화번호, 희망 접선 일시
                                    </td>
                                    <td>서비스 제공 및 관리</td>
                                    <td>관리자페이지(예약/수행이력)</td>
                                </tr>
                                <tr>
                                    <td>
                                        가입자 생년월일, 가입자 성별, 거주환경(계단이용여부), 병원동행서비스 개선점, 의료 기관 만족 여부, 동행인 서비스 이력,
                                        동행인 선택 여부, 동행인 성별 선택, 동행인 의료 기관 평가, 동행인 의사 평가, 동행인 평점, 의료 기관 만족 이유, 의료진
                                        만족 여부, 의료진 만족 이유, 서비스 종류, 예상 소요시간, 이벤트 참여 여부, 이용 후기, 향후 재신청 의향, 이동 수단,
                                        포인트 사용 내역, 환불 정보, 결제수단, 이용금액(결산), 이용금액(예상), 쿠폰 사용 내역, 평점, 총 이용시간, 포인트 적립
                                        내역, 현재 잔여 포인트, 환자 나이, 환자 성별, 환자와의 관계
                                    </td>
                                    <td>서비스 품질 개선 및 신규 서비스 개발</td>
                                    <td>서비스 품질 개선 및 신규 서비스 개발</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='info'>
                            <p className='title' />
                            <p className='title'>
                                <b>[ 개인정보의 수집 및 이용 동의 거부 시 불이익 ]</b> <br />
                                정보주체는 개인정보 수집 및 이용에 동의를 거부할 권리가 있습니다. 단, 필수항목에 대해 거부할 경우,
                                ‘(주)블루카멜(개인정보처리자)’이 제공하는 서비스의 일부 또는 전부를 이용할 수 없습니다.
                            </p>
                            <p className='title'>
                                <b>[ 개인정보의 보유 및 이용 기간 ]</b> <br />
                                ① 위에서 제시한 처리 목적과 관련한 개인정보는 수집 및 이용에 대한 동의일부터 회원 탈퇴 후 2년까지
                                ‘(주)블루카멜(개인정보처리자)’이 보유 및 처리합니다. <br />
                                ② 다음과 같은 경우에는 ‘(주)블루카멜(개인정보처리자)’이 개인정보를 제1항에서 정한 기간과 달리 보유 및 처리할 수 있습니다. <br />
                                &ensp; 1. 다음과 같이 관련 법령에 의하여 보존할 필요성이 있는 경우 <br />
                                &emsp; 1) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 <br />
                                &emsp; 2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 <br />
                                &emsp; 3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년 <br />
                                &emsp; 4) 계약 또는 청약철회 등에 관한 기록 : 5년 <br />
                                &emsp; 5) 표시/광고에 관한 기록 : 6개월 <br />
                                &ensp; 2. 정보주체로부터 사전에 동의를 받은 경우 : 정보주체가 사전 동의한 기간
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default EssentialComponent;
