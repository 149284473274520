import styled from 'styled-components';

const StyledListContainer = styled.div`
    // border-bottom: 1px solid #e6e6e6;
`;

export const HeaderButton = styled.div`
    cursor: pointer;

    width: 265px;
    height: 60px;
    line-height: 60px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ffffff;

    margin-top: 40px;
    margin-bottom: 90px;
    border-radius: 4px;
    background-color: #3bc1ff;

    &:hover {
        transition: all 0.5s;
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        width: 230px;
        height: 48px;
        line-height: 48px;
    }
`;

export const NavigationTabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const NavigationTab = styled.span`
    position: relative;
    display: inline-block;
    margin-right: 44px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    padding-bottom: 20px;

    cursor: pointer;

    &.active {
        color: #55d4ff;
    }
`;

export const NavigationTabUnderline = styled.span`
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: -3px;

    width: 100%;
    height: 3px;
    background-color: #55d4ff;
`;

export const CountDisplay = styled.div`
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    > span {
        color: #4bcfff;
    }

    margin-bottom: 20px;
`;

export const CollapseContainer = styled.div`
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding-left: 0px;
        padding-right: 0px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const CollapseHeader = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
`;

export const CollapseTab = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.7);

    padding-bottom: 11px;
`;

export const CollapseTabOnlyDesktop = styled(CollapseTab)`
    @media (max-width: 768px) {
        display: none;
    }
`;

export const PanelHeader = styled.div`
    display: flex;
`;

export const PanelField = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${props => (props.style ? props.style : `flex: 1;`)}
    ${props => (props.onlyDesktop ? `display: block;` : null)} 

    &.title {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        ${props => (props.onlyDesktop ? `display: none;` : null)}
    }
`;

export const Star = styled.img`
    width: 16.7px;
    height: 16px;
    object-fit: contain;
    margin-bottom: 4px;
`;

export const ReviewTextWrapper = styled.div`
    padding-left: 120px;
    padding-right: 50px;

    @media (max-width: 768px) {
        padding-left: 0px;
    }
`;

export const ReviewText = styled.p`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);
`;

export const MyReview = styled.span`
    display: inline-block;
    width: 53px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    background-color: #3bc1ff;

    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.21px;
    color: #ffffff;
    text-align: center;
`;

export const ReviewMobileInfo = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 27px;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

export const ReviewerName = styled.span`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 0px;
    margin-right: 16px;
`;

export default StyledListContainer;
