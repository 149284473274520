import React from 'react';

const NotFoundPage = () => (
    <div style={{ height: '100vh', padding: '100px' }}>
        <img src='/assets/images/new_logo_img.png' alt='위드메이트 로고' style={{ width: '55px', height: 'auto' }} />
        <p style={{ display: 'inline-block', verticalAlign: '-10px', paddingLeft: '10px', paddingBottom: '35px', color: 'skyblue', fontSize: '40px' }}>해당 페이지를 찾을 수 없습니다.</p>

        <h3 style={{ paddingBottom: '5px' }}>원하시는 결과를 찾을 수 없습니다.</h3>
        <h3>올바른 URL을 입력하였는지 확인하세요. 자세한 내용은 사이트 관리자에게 문의하시기 바랍니다.</h3>
    </div>
);
export default NotFoundPage;
