import styled from 'styled-components';
import { mainColor, sizeHandler } from '../../../../theme';

const StyledFindContainer = styled.div`
    margin-top: 55px;
    min-height: 100vh;
    background-image: url('/assets/images/main-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .find-container {
        .inner-container {
            padding: 0px;
            padding-top: 144px;
            padding-bottom: 100px;

            .login-form {
                background-color: white;
                border-radius: 8px;
                box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
                width: 100%;
                max-width: 340px;
                margin: 0 auto;
                padding: 24px;

                .login-title {
                    margin-bottom: 24px;

                    p {
                        font-size: ${sizeHandler(32)};
                        font-weight: bold;
                        letter-spacing: -0.7px;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                .form {
                    label {
                        font-size: ${sizeHandler(12)};
                        letter-spacing: -0.15px;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    input {
                        height: 40px;
                        border: solid 1px #e6e6e6;
                        margin-bottom: 20px;
                    }

                    button {
                        width: 100%;
                        height: 46px;
                        margin-top: 4px;
                        margin-bottom: 16px;

                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        letter-spacing: -0.2px;
                        text-align: center;
                        color: #ffffff;
                    }

                    .link-wrapper {
                        display: flex;
                        justify-content: center;

                        .link {
                            cursor: pointer;
                            font-size: ${sizeHandler(12)};
                            letter-spacing: -0.3px;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.5);

                            &:first-child {
                                border-right: 1px solid #e6e6e6;
                                padding-right: 16px;
                            }

                            &:last-child {
                                margin-left: 16px;
                            }
                        }
                    }
                }

                .form-user-type {
                    height: 36px;
                    margin-bottom: 20px;
                    border-radius: 4px;
                    border: 1px solid #e6e6e6;
                    display: flex;
                    align-items: center;
                    overflow: hidden;

                    .type {
                        flex: 1;
                        height: 100%;
                        line-height: 36px;
                        background-color: #ffffff;
                        cursor: pointer;

                        &:first-child {
                            border-right: 1px solid #e6e6e6;
                        }

                        p {
                            font-size: ${sizeHandler(12)};
                            font-weight: bold;
                            text-align: center;
                            color: #606060;
                        }

                        &.active {
                            background-color: #606060;

                            p {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
`;
export default StyledFindContainer;
