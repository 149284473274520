import React from 'react';
import styled from 'styled-components';
import { Input, message } from 'antd';

import { sizeHandler, mainColor } from '../../../theme';
import { colorParserByStatus } from '../../../Lib/utils';
import { ASSIST, UNEMER, HAPPYCARE } from '../../../Lib/variables';

import moment from 'moment';

export const InputFilter = ({ keyword, keywordHandler }) => (
    <FilterWrapper>
        <Input
            value={keyword}
            onChange={ev => {
                keywordHandler(ev.target.value);
            }}
            placeholder='예약번호, 이용자 성함 등을 검색해보세요.'
        />
    </FilterWrapper>
);

const FilterWrapper = styled.div`
    margin-bottom: 12px;
`;

export const CalleList = ({ list, onRow }) => {
    if (!list || list.length === 0) {
        return (
            <FullContainer>
                <Inner>
                    <EmptyList>서비스 수행 내역이 없습니다.</EmptyList>
                </Inner>
            </FullContainer>
        );
    }

    return (
        <ListConatiner>
            {list.map((item, index) => {
                const serviceType =
                    !item.productInfo || !item.productInfo.usePro || !item.productInfo.useBasic ? '' : item.service === 'unemergency' ? 'Pro' : 'Basic';
                const serviceName = (item.productInfo && item.productInfo.name && item.service && item.productInfo.name + serviceType) || '-';

                return (
                    <ReserveItem
                        key={item._id + index + 'call-list'}
                        reserveCode={item.reserveCode}
                        name={item.patient}
                        serviceType={item.service}
                        service={serviceName}
                        reserveDate={moment(item.reserveDate).format('MM월 DD일 HH시mm분')}
                        starting={item.call && item.call.status === '예약접수' ? item.shortStarting || '' : item.starting || ''}
                        destination={item.destination}
                        status={(item.call && item.call.status) || ''}
                        onRow={() => {
                            onRow(item);
                        }}
                    />
                );
            })}
        </ListConatiner>
    );
};

const ReserveItem = ({ service, name, reserveDate, starting, destination, serviceType, status, onDetail, reserveCode, onRow }) => (
    <ItemContainer>
        <TitleSection>
            <ItemColumn>
                <ItemTitle>서비스 명</ItemTitle>
                <ItemContents style={{ color: mainColor }}>{service}</ItemContents>
            </ItemColumn>
        </TitleSection>

        <ContentSection>
            <ItemColumn>
                <ItemTitle>예약 상태</ItemTitle>
                <ItemContents>{status}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>예약 코드</ItemTitle>
                <ItemContents>{reserveCode}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>이용자 성함</ItemTitle>
                <ItemContents>{name}</ItemContents>
            </ItemColumn>
            <ItemColumn>
                <ItemTitle>병원 진료 예약 시간</ItemTitle>
                <ItemContents>{reserveDate}</ItemContents>
            </ItemColumn>
            {serviceType === 'unemergency' && (
                <ItemColumn>
                    <ItemTitle>자택 주소</ItemTitle>
                    <ItemContents>{starting}</ItemContents>
                </ItemColumn>
            )}
            <ItemColumn>
                <ItemTitle>병원 주소</ItemTitle>
                <ItemContents>{destination}</ItemContents>
            </ItemColumn>
        </ContentSection>

        <ItemColumn>
            <DetailButton onClick={onRow}>
                <span>상세정보 및 관리</span>
            </DetailButton>
        </ItemColumn>
    </ItemContainer>
);

const EmptyList = styled.p`
    font-size: 20px;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 16px;
    position: relative;
`;

const FullContainer = styled.div`
    width: 100%;
    min-height: 300px;
`;

const Inner = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding-top: 40px;

    @media (max-width: 1080px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (max-width: 768px) {
        padding-top: 40px;
    }
`;
const TitleSection = styled.div``;

const ContentSection = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    overflow-y: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const ListConatiner = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div:nth-child(3n) {
        margin-right: 0px;
    }

    @media (max-width: 768px) {
        > div:nth-child(3n) {
            margin-right: 16px;
        }

        > div:nth-child(2n) {
            margin-right: 0px;
        }
    }

    @media (max-width: 480px) {
        > div:nth-child(3n) {
            margin-right: 0px;
        }

        flex-direction: column;
        justify-content: center;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 340px;
    height: 410px;
    overflow: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    padding: 20px;
    margin-bottom: 16px;
    margin-right: 16px;

    border-radius: 4px;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        width: 100%;
        max-width: initial;
        margin-right: 0px;
    }
`;

const ItemTitle = styled.p`
    color: #a7a7a7;
    font-size: ${sizeHandler(12)};
    font-weight: bold;
    margin: 0px;
    padding: 0px;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(11)};
    }
`;

const ItemContents = styled.p`
    font-size: ${sizeHandler(14)};
    font-weight: bold;
    color: #606060;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 430px) {
        font-size: ${sizeHandler(12)};
        margin-bottom: 4px;
    }
`;

const ItemColumn = styled.div`
    width: 100%;
    margin-bottom: 6px;

    @media (max-width: 768px) {
        display: ${props => (props.hiddenMobile ? 'none' : 'block')};
        width: 100%;
    }
`;

const DetailButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 44px;

    border-radius: 4px;
    border: 1px solid ${mainColor};
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    span {
        font-size: ${sizeHandler(14)};
        font-weight: bold;
        color: ${mainColor};
        text-align: center;

        @media (max-width: 430px) {
            font-size: ${sizeHandler(12)};
        }
    }

    &:hover {
        background-color: ${mainColor};
        span {
            color: white;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 34px;
        font-size: ${sizeHandler(12)};
    }
`;
