import React from "react";
import AnimatedNumbers from "react-animated-numbers";
import styled from 'styled-components';
import { BsPeopleFill, BsNewspaper, BsClockFill } from "react-icons/bs";

function MainNumber() {
  const [num, setNum] = React.useState(3021);
  const [num2, setNum2] = React.useState(289);
  const [num3, setNum3] = React.useState(16700);

  return (
    <div className="container" style={{ height: '70px', display: 'flex', flexDirection: 'initial' }}>
      <div style={{ textAlign: 'center' }}>
        <label>총 이용자 수</label>
        <HighLight>
          <b>
            <AnimatedNumbers
              includeComma // 숫자에 쉼표 여부
              easing
              animateToNumber={num}
              animationType={"calm"}
              fontStyle={{ fontSize: 30 }}
              configs={(number, index) => {
              return { mass: 1, tension: 430 * (index + 1), friction: 140 };
            }} />
          </b>
        </HighLight>
      </div>&emsp;&emsp;

      <div style={{ textAlign: 'center' }}>
        <label>메이트 수</label>
        <HighLight>
          <b><AnimatedNumbers
            includeComma
            easing
            animateToNumber={num2}
            animationType={"calm"}
            fontStyle={{ fontSize: 30 }}
            configs={(number, index) => {
              return { mass: 1, tension: 430 * (index + 1), friction: 140 };
            }}
          /></b>
        </HighLight>
      </div>&emsp;&emsp;

      <div style={{ textAlign: 'center' }}>
        <label>총 이용 건수</label>
        <HighLight>
          <b><AnimatedNumbers
            includeComma
            easing
            animateToNumber={num3}
            animationType={"calm"}
            fontStyle={{ fontSize: 30 }}
            configs={(number, index) => {
              return { mass: 1, tension: 430 * (index + 1), friction: 140 };
            }}
            suffix="건"
          /></b>
        </HighLight>
      </div>
    </div>
  );
}

export default MainNumber;

const HighLight = styled.p`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ffffff;

    margin-bottom: 64px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;