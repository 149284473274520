import React from 'react';
import styled from 'styled-components';
import { Table, Input, notification, Upload, Button, message, Modal, Select, DatePicker, Collapse, InputNumber, Radio } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import StarRatingComponent from 'react-star-rating-component';
import swal from 'sweetalert2';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Pallete from '../../../../theme';

// components
import { InputFilter, ReserveList } from '../../../../Components/User/Normal/auth';
import { UserStatistics } from '../../../../Components/User/Normal/statistics';
import { UserNavigation } from '../../../../Components/User/Normal/navi';
import { NoramlReserveEditModal, ReportMoal, NoReportModal } from '../../../../Components/newModal';
import {
    PointBoxRow,
    PointBox,
    PointBox2,
    PointBox3,
    NoHistory,
    PointBoxTitle,
    PointTotal,
    PointHistoryRow,
    PointHistoryNameRow,
    PointContent,
    PointHistoryValueRow,
} from './normal.styled';

// utils
import axios from '../../../../Lib/axios';
import { UNEMER, ASSIST, LOCAL } from '../../../../Lib/variables';
import { datetimeFormatter, timeFormatter, currencyFormatter, getWithcarPrice } from '../../../../Lib/utils';
import Profile from '../Profile/profile';
import Card from '../Card/card';
import ModifyCard from '../Card/cardInfoCheck';
import ModifyProfile from '../Profile/profileInfoCheck';
import { DivisionLine, WithmateInput, WithmateLabel } from '../../../../Components/Common/input';
import { patientColumn, cardColumn } from '../../../../Components/Columns/normal';
import { HalfWidthButton } from '../../../../Components/Common/button';

const { Kakao } = window;

moment.lang('ko', {
    weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
});

class NormalUser extends React.PureComponent {
    state = {
        active: 'list', // list, info, point
        reservedFilter: 'none', // none, reserved, matched, complete
        paidFilter: 'none', // none, paid, refund
        modal: false,
        kakaoModal: false,
        selected: null,
        cancelModal: false,
        modifyModal: false,
        detailModalPage: 1,
        reserveDetailModal: false,
        mateModal: false,
        mateInfo: {},
        serviceInfo: null,
        serviceStatus: null,
        serviceModal: false,
        keyword: '',
        addHalfTime: false,
        totalPro: 0,
        totalBasic: 0,
        pointUsingHistory: [],
        pointAddHistory: [],
        couponAddHistory: [],

        patientModal: false,
        patientModifyModal: false,
        patientInfo: {},
        patientId: '',

        cardModal: false,
        cardModifyModal: false,
        cardInfo: {},
        cardId: '',

        appointedCode: '',
    };

    patientModalOff = () => {
        const userId = localStorage.getItem('id');
        this.props.fetchPatient({ id: userId });
        this.setState({
            patientModal: false,
            patientInfo: {},
        });
    };

    patientModifyModalOff = () => {
        const userId = localStorage.getItem('id');
        this.props.fetchPatient({ id: userId });
        this.setState({
            patientModifyModal: false,
            patientInfo: {},
        });
    };

    cardModalOff = () => {
        const userId = localStorage.getItem('id');
        this.props.fetchCard({ id: userId });
        this.setState({
            cardModal: false,
            cardInfo: {},
        });
    };

    cardModifyModalOff = () => {
        const userId = localStorage.getItem('id');
        this.props.fetchCard({ id: userId });
        this.setState({
            cardModifyModal: false,
            cardInfo: {},
        });
    };

    componentDidMount = () => {
        this.syncStep();
        this.fetchUserInfo();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.active !== this.state.active) {
            this.props.history.push(`/user/mypage/${this.state.active}`);
        }
    };

    syncStep = () => {
        const { params } = this.props.match;
        const { active } = this.state;

        if (params.tab && active !== params.tab) {
            this.setState({ active: params.tab });
        } else if (!params.tab) {
            this.props.history.push(`/user/mypage/list`);
        }
    };

    fetchUserInfo = () => {
        const { userType, id } = this.props.auth;
        // 예약이력
        axios.get('/v2/service/user/reserve/list', { params: { userId: id } }).then(resolve => {
            const { data } = resolve;
            if (data && Array.isArray(data)) {
                this.setState({
                    reserves: data,
                    totalPro: data.filter(d => d && d.service === UNEMER).length,
                    totalBasic: data.filter(d => d && d.service === ASSIST).length,
                });
            }
        });
        // 사용자 포인트
        axios.get('/v2/user/point/history', { params: { userId: id } }).then(resolve => {
            const { data } = resolve;
            if (data && Array.isArray(data)) {
                this.setState({
                    pointUsingHistory: data.filter(d => d && d.actionType === 'use'),
                    pointAddHistory: data.filter(d => d && d.actionType === 'add'),
                });
            }
        });
        // 사용자 쿠폰
        axios.get('/v2/user/coupon/history', { params: { userId: id } }).then(resolve => {
            const { data } = resolve;
            if (data && Array.isArray(data)) {
                this.setState({
                    couponAddHistory: data.filter(d => d && d.actionType === 'add'),
                });
            }
        });
    };

    updateBasicInfo = () => {
        const { id, userType } = this.props.auth;
        const { userInfo } = this.props.app;
        const { name, contact } = userInfo;

        if (!name || !contact) return message.info('필수정보인 이름과 연락처를 입력해주세요.');
        if (name.length > 15) return message.info('이름이 너무 깁니다. 이름은 15자 이내로 설정해주세요.');

        const info = { name, contact };

        this.props.modify({ id, userType, info }).then(resolve => {
            if (resolve && resolve.name) {
                message.success('정보를 업데이트 하였습니다.');
                localStorage.setItem('name', resolve.name);
                this.props.editAuth({ type: 'name', value: resolve.name });
                this.props.editAuth({ type: 'contact', value: resolve.contact });
            }
        });
    };

    modifyPassword = () => {
        const { passwordForm, id, userType } = this.props.auth;
        const { password, newPassword, reNewPassword } = passwordForm;

        if (password === newPassword) return message.info('새 비밀번호는 기존 비밀번호와 달라야합니다.');
        if (reNewPassword !== newPassword) return message.info('새 비밀번호를 확인해주세요.');

        this.props
            .modifyPassword({ id, userType, password, newPassword })
            .then(resolve => {
                if (resolve && resolve === 'wrong password') {
                    return message.error('비밀번호가 일치하지 않습니다.');
                }

                if (resolve && resolve === 'not exist') {
                    message.error('유효하지 않은 계정입니다. 다시 로그인해주세요.');
                    return this.props.history.push('/user/logout');
                }

                if (resolve && resolve === 'success') {
                    message.success('비밀번호를 변경하였습니다.');
                    this.props.editPassword({ type: 'clear', value: '' });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    withdrawl = () => {
        const { id, userType, password } = this.props.auth;

        if (!password) return message.info('비밀번호를 입력해주세요.');

        this.props
            .withdrawlUser({ id, userType, password })
            .then(resolve => {
                if (resolve && resolve === 'wrong password') {
                    return message.info('잘못된 비밀번호입니다.');
                }

                if (resolve && resolve === 'success') {
                    message.info('회원탈퇴를 완료하였습니다. 이용해주셔서 감사합니다.');
                    this.setState({ modal: false });
                    setTimeout(() => {
                        this.props.history.push('/user/logout');
                    }, 500);
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    tabHandler = type => {
        this.setState({ active: type });
    };

    onMateModal = async info => {
        const { data } = await axios.get('/v2/user/mate/info/detail', {
            params: {
                mateId: info.mateId,
            },
        });

        this.setState({
            mateInfo: data,
            callStatus: info.status,
            callId: info.callId,
            reserveId: info.reserveId,
            mateModal: true,
            modifyModal: false,
            reserveDetailModal: false,
            cancelModal: false,
        });
    };

    // 요거 진행해야함
    onRematch = async () => {
        const { reserveId, callId } = this.state;

        const { data } = await axios.put('/v2/service/deny/mate/call', {
            callId,
            reserveId,
        });

        if (data === 'check parameters') {
            return message.warning('요청하신 정보가 올바르지 않습니다. 새로고침 후 다시 시도해주세요.');
        }

        if (data === 'no mate') {
            return console.warn('현재 예약 신청항 메이트가 없습니다.');
        }

        if (data === 'invalid call') {
            return message.warning('접수내역에 문제가 발생하였습니다. 새로고침 후 다시 시도해주세요.');
        }

        if (data === 'already matched') {
            return message.warning('이미 메이트 매칭이 완료되었습니다.');
        }

        if (data === 'do not') {
            return message.warning('해당 예약은 메이트 거절 불가한 상태입니다.');
        }

        if (data === 'success') {
            message.success('요청완료하였습니다.');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };

    confirmReserve = () => {
        const { callId, reserveId } = this.state;
        const { id } = this.props.auth;

        axios
            .put('/v2/service/allow/mate/call', {
                callId,
                reserveId,
            })
            .then(resolve => {
                const { data } = resolve;
                if (data === 'check parameters') {
                    return message.warning('요청하신 정보가 올바르지 않습니다. 새로고침 후 다시 시도해주세요.');
                }
                if (data === 'invalid call') {
                    return message.warning('유효하지않은 접수내역입니다. 새로고침 후 다시 시도해주세요.');
                }
                if (data === 'no mate') {
                    return console.warn('아직 접수 요청중인 메이트가 없습니다. 문제가 계속되면 고객센터로 문의해주세요.');
                }
                if (data === 'wrong status') {
                    return message.warning('해당 예약이 메이트 확정가능한 상태가 아닙니다.');
                }
                if (data === 'issue call') {
                    return message.warning('예약에 문제가 발생했습니다. 고객센터로 문의해주세요.');
                }
                if (data === 'success') {
                    message.success('메이트 확정 완료');
                    setTimeout(() => {
                        window.location.reload();
                    }, 250);
                }
            });
    };

    onDetailModal = reserveId => {
        const { userType, id } = this.props.auth;

        axios.get('/v2/service/user/reserve/info', { params: { userId: id, reserveId } }).then(resolve => {
            const { data } = resolve;
            if (!data) return message.error('죄송합니다. 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
            if (data === 'invalid reserve') return message.warning('유효하지않은 예약입니다.');

            this.setState({
                reserveDetailModal: true,
                temp: {
                    ...data,
                    disabled: true,
                    addTime: data.usageTime * 60 + '분',
                    date: moment(data.reserveDate),
                    time: moment(data.reserveDate).format('HH').toString(),
                    minute: moment(data.reserveDate).format('mm').toString(),
                    meetDate: data.meetDate ? moment(data.meetDate) : '',
                    meetTime: data.meetDate ? moment(data.meetDate).format('HH').toString() : '',
                    meetMinute: data.meetDate ? moment(data.meetDate).format('mm').toString() : '',
                },
            });
        });
    };

    inputHandler = ({ type, value }) => {
        this.setState({ [type]: value });
    };

    inputDeepHandler = ({ type, deepType, value }) => {
        this.setState(prevState => ({
            [type]: {
                ...prevState[type],
                [deepType]: value,
            },
        }));
    };

    onViewReport = item => {
        const { call } = item;
        if (!call.reportId) {
            message.warning('죄송합니다. 아직 담당 메이트가 리포트를 등록하지 않았습니다.');
            return null;
        }
        axios.get('/v2/review/report/viewer', { params: { reportId: call.reportId, callId: call._id } }).then(resolve => {
            const { data } = resolve;

            this.setState({
                report: data.report,
                timeline: data.timeline,
                selectedServiceName:
                    item.productInfo.name + (item.productInfo.name.includes('내시경') ? '' : item.service === 'unemergency' ? ' Pro' : ' Basic'),
                onReportMoal: true,
            });
        });
    };

    onPatientModal = () => {
        this.setState({ patientModal: true });
    };

    onPatientModifyModal = () => {
        this.setState({ patientModifyModal: true });
    };

    onCardModal = () => {
        this.setState({ cardModal: true });
    };

    onCardModifyModal = () => {
        this.setState({ cardModifyModal: true });
    };

    onRow = row => {
        return {
            onClick: async () => {
                // const info = await this.onFetchPatientInfo(row);
                // console.log(row)
                swal.fire({
                    title: '환자 프로필 정보',
                    icon: 'info',
                    width: '550px',
                    html: `
                    <br/>
                    <div align="left">
                        <p><b>ㆍ이름 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.name}</p>
                        <p><b>ㆍ관계 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.relate}</p>
                        <p><b>ㆍ성별 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.sex}</p>
                        <p><b>ㆍ나이 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.age} 세</p>
                        <p><b>ㆍ연락처 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.contact}</p>
                        <p><b>ㆍ주소 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.starting}</p>
                        <p><b>ㆍ계단 이용 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.environment}</p>
                        <p><b>ㆍ메이트 주의사항 : </b>&nbsp;&nbsp;&nbsp;&nbsp;${row.caution}</p>
                    </div>
                    `,
                });
            },
        };
    };

    onFetchPatientInfo = async row => {
        console.log(row);
        return axios.get('/v1/auth/patient/list/info', { params: { userId: row.userId, patientId: row._id } });
    };

    onPatientModify = info => {
        this.setState({ patientModifyModal: true, patientInfo: info });
        // console.log(info)
    };

    onPatientDelete = ({ _id }) => {
        const { id } = this.props.auth;
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 환자 프로필을 삭제하시겠습니까?');
        if (!confirmed) return null;

        this.props
            .deletePatient({ patientId: _id })
            .then(resolve => {
                if (resolve && resolve === 'success') {
                    const userId = localStorage.getItem('id');
                    this.props.fetchPatient({ id: userId });
                    notification.open({
                        message: '환자 프로필을 삭제하였습니다.',
                        description: '환자 프로필을 삭제하였습니다.',
                        type: 'success',
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    onCardModify = info => {
        this.setState({ cardModifyModal: true, cardInfo: info });
        // console.log(info)
    };

    onCardDelete = ({ _id }) => {
        const { id } = this.props.auth;
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 카드정보를 삭제하시겠습니까?');
        if (!confirmed) return null;

        this.props
            .deleteCard({ cardId: _id })
            .then(resolve => {
                if (resolve && resolve === 'success') {
                    const userId = localStorage.getItem('id');
                    this.props.fetchCard({ id: userId });
                    notification.open({
                        message: '카드정보를 삭제하였습니다.',
                        description: '카드정보를 삭제하였습니다.',
                        type: 'success',
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    // kakao api unlink
    kakaoUnlink = () => {
        const { id, userType } = this.props.auth;

        window.Kakao.API.request({
            url: '/v1/user/unlink',
            success: function (response) {
                console.log(response, 'kakao api unlink!');
                // window.location.href = '/user/logout'
            },
            fail: function (error) {
                console.log(error, 'kakao api unlink error');
            },
        });

        this.props
            .withdrawlKakao({ id, userType })
            .then(resolve => {
                if (resolve && resolve === 'success') {
                    notification.open({
                        message: '회원탈퇴를 완료하였습니다.',
                        description: '그동안 위드메이트의 서비스를 이용해주셔서 감사합니다.',
                        type: 'success',
                    });
                    this.setState({ kakaoModal: false });
                    setTimeout(() => {
                        this.props.history.push('/user/logout');
                    }, 500);
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    onAddCoupon = async () => {
        const { appointedCode } = this.state;
        if (!appointedCode) {
            // notification.open({
            //     message: '쿠폰 코드가 입력되지 않았습니다',
            //     description: '쿠폰 코드를 입력해주세요',
            //     type: 'warning',
            // });
        }

        if (appointedCode) {
            const { data } = await axios.post('/v2/user/add/coupon', {
                userId: this.props.auth.id,
                userName: this.props.auth.name,
                appointedCode,
                info: {
                    couponName: '',
                    couponValue: '',
                },
            });

            if (!data || data === 'check parameters') {
                notification.open({
                    message: '쿠폰 코드 등록에 실패하였습니다',
                    description: '다시 한번 쿠폰 코드를 입력해주세요',
                    type: 'warning',
                });
            }

            if (data === 'wrong access') {
                notification.open({
                    message: '쿠폰 코드가 일치하지 않습니다',
                    description: '입력하신 쿠폰 코드를 다시한번 확인하세요',
                    type: 'warning',
                });
            }

            if (data === 'duplicate') {
                notification.open({
                    message: '이미 등록된 쿠폰 코드입니다',
                    description: '다른 쿠폰 코드를 입력해주세요',
                    type: 'warning',
                });
            }

            if (data === 'success') {
                notification.open({
                    message: '쿠폰 코드가 등록되었습니다',
                    description: '포인트가 정상적으로 지급되었습니다',
                    type: 'success',
                });
                setTimeout(() => {
                    window.location.reload();
                }, 250);
            }
        }
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    render() {
        // console.log(this.props.auth)
        const { auth } = this.props;
        const { password, newPassword, reNewPassword } = auth.passwordForm;
        const { userInfo } = this.props.app;
        // console.log(userInfo)
        const { pointUsingHistory, pointAddHistory, couponAddHistory, active, reservedFilter, reserves, mateInfo, keyword, totalPro, totalBasic } = this.state;

        // console.log(auth);
        const list = keyword
            ? reserves.filter(reserve => {
                  const { call } = reserve;

                  return (
                      (reserve && reserve.reserveCode && reserve.reserveCode.toLowerCase().includes(keyword.toLowerCase())) ||
                      (call && call.mate && call.mate.toLowerCase().includes(keyword.toLowerCase()))
                  );
              })
            : reserves;

        return (
            <div className='auth-container'>
                <div className='navigation-container'>
                    <div className='inner-container'>
                        <UserStatistics auth={auth} pro={totalPro || 0} basic={totalBasic || 0} />
                        <UserNavigation
                            active={active}
                            tabHandler={this.tabHandler}
                            onPatientModal={this.onPatientModal}
                            onPatientModifyModal={this.onPatientModifyModal}
                            onCardModal={this.onCardModal}
                            onCardModifyModal={this.onCardModifyModal}
                        />
                    </div>
                </div>

                <div className='usage-info-container'>
                    <div className='inner-container'>
                        {active === 'list' ? (
                            <>
                                <div className='filter-wrapper'>
                                    <Filter
                                        filter={reservedFilter}
                                        value='none'
                                        name='전체'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'none' });
                                        }}
                                    />
                                    <Filter
                                        filter={reservedFilter}
                                        value='reserved'
                                        name='접수대기'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'reserved' });
                                        }}
                                    />

                                    <Filter
                                        filter={reservedFilter}
                                        value='matched'
                                        name='예약완료 (진행중)'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'matched' });
                                        }}
                                    />
                                    <Filter
                                        filter={reservedFilter}
                                        value='complete'
                                        name='서비스 종료'
                                        onClick={() => {
                                            this.setState({ reservedFilter: 'complete' });
                                        }}
                                    />
                                </div>

                                <InputFilter
                                    keyword={this.state.keyword}
                                    keywordHandler={value => {
                                        this.setState({ keyword: value });
                                    }}
                                />
                                <ReserveList
                                    list={
                                        reservedFilter === 'none'
                                            ? list
                                            : reservedFilter === 'reserved'
                                            ? list.filter(p => p.call.status === '접수대기' || p.call.status === '예약접수')
                                            : reservedFilter === 'matched'
                                            ? list.filter(
                                                  p =>
                                                      p.call.status === '예약완료' ||
                                                      p.call.status === '접선' ||
                                                      p.call.status === '병원 출발' ||
                                                      p.call.status === '병원 도착' ||
                                                      p.call.status === '진료 시작' ||
                                                      p.call.status === '진료 종료' ||
                                                      p.call.status === '자택 출발',
                                              )
                                            : reservedFilter === 'complete'
                                            ? list.filter(p => p.call.status === '서비스 종료')
                                            : []
                                    }
                                    onReport={this.onViewReport}
                                    onDetailModal={_id => {
                                        this.onDetailModal(_id);
                                    }}
                                    onMateModal={info => {
                                        this.onMateModal(info);
                                    }}
                                />
                            </>
                        ) : null}

                        {active === 'info' ? (
                            <div className='user-info-wrapper'>
                                <div>
                                    <div className='default-info wrapper'>
                                        <div className='title-wrapper'>
                                            <p>프로필 수정</p>
                                            <p style={{ fontSize: '7px', fontWeight: 'normal', color: 'gray', marginTop: '5px' }}>
                                                위드메이트의 이름과 연락처를 수정 하실 수 있습니다.
                                            </p>
                                        </div>

                                        <div className='service'>
                                            <p className='info'>이름</p>
                                            <div>
                                                <Input
                                                    placeholder='성함을 입력해주세요.'
                                                    value={userInfo.name}
                                                    onChange={ev => {
                                                        this.props.onEdit({ type: 'name', value: ev.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='service'>
                                            <p className='info'>연락처</p>
                                            <div>
                                                <Input
                                                    placeholder='연락처를 입력해주세요.'
                                                    value={userInfo.contact}
                                                    onChange={ev => {
                                                        this.props.onEdit({ type: 'contact', value: ev.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='service' style={{ marginBottom: '0px' }}>
                                            <Button onClick={this.updateBasicInfo} style={{ backgroundColor: '#484848' }}>
                                                수정된 프로필 저장
                                            </Button>
                                        </div>
                                    </div>

                                    <div className='card-info wrapper'>
                                        <div className='title-wrapper'>
                                            <p>결제 카드 정보</p>
                                            <p style={{ fontSize: '7px', fontWeight: 'normal', color: 'gray', marginTop: '5px' }}>
                                                등록된 카드정보를 통해 서비스 결제를 하실 수 있습니다.
                                            </p>
                                        </div>

                                        <Card {...this.props} visible={this.state.cardModal} onClose={this.cardModalOff} />
                                        <ModifyCard {...this.state.cardInfo} visible={this.state.cardModifyModal} onClose={this.cardModifyModalOff} />

                                        <Table
                                            dataSource={this.props.auth.cardList}
                                            columns={cardColumn({ onDelete: this.onCardDelete, onModify: this.onCardModify })}
                                            pagination={{ pageSize: 3 }}
                                        />

                                        <div className='service' style={{ marginBottom: '0px' }}>
                                            <Button onClick={this.onCardModal} style={{ backgroundColor: '#484848' }}>
                                                카드 정보 추가
                                            </Button>
                                        </div>
                                    </div>

                                    {/* 수정해야할 부분 - KAKAO 로그인인 경우 안보이게*/}
                                    {this.props.auth.type === LOCAL ? (
                                        <div className='privacy-info wrapper'>
                                            <div className='title-wrapper'>
                                                <p>비밀번호 변경</p>
                                            </div>

                                            <div className='service'>
                                                <p className='info'>현재 비밀번호</p>
                                                <div>
                                                    <Input.Password
                                                        password='true'
                                                        placeholder='현재 비밀번호를 입력해주세요.'
                                                        value={password}
                                                        onChange={ev => {
                                                            this.props.editPassword({ type: 'password', value: ev.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='service'>
                                                <p className='info'>새 비밀번호</p>
                                                <div>
                                                    <Input.Password
                                                        password='true'
                                                        placeholder='새 비밀번호를 입력해주세요.'
                                                        value={newPassword}
                                                        onChange={ev => {
                                                            this.props.editPassword({ type: 'newPassword', value: ev.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='service'>
                                                <p className='info'>새 비밀번호 확인</p>
                                                <div>
                                                    <Input.Password
                                                        password='true'
                                                        placeholder='새 비밀번호를 확인해주세요.'
                                                        value={reNewPassword}
                                                        onChange={ev => {
                                                            this.props.editPassword({ type: 'reNewPassword', value: ev.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='service'>
                                                <Button
                                                    onClick={this.modifyPassword}
                                                    style={{ backgroundColor: '#ffffff', color: '#606060', marginBottom: '0px' }}
                                                >
                                                    비밀번호 변경
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='etc-info wrapper' style={{ marginTop: '0px' }}>
                                            <div className='title-wrapper'>
                                                <p>기타</p>
                                            </div>

                                            <div className='service'>
                                                <div className='flex-wrapper'>
                                                    <p
                                                        onClick={() => {
                                                            this.props.history.push('/policy/privacy');
                                                        }}
                                                    >
                                                        개인정보 처리방침 <b>{'>'}</b>
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            this.props.history.push('/policy/terms');
                                                        }}
                                                    >
                                                        서비스 이용약관 <b>{'>'}</b>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='service'>
                                                <Button
                                                    style={{ backgroundColor: '#b7b7b7' }}
                                                    onClick={() => {
                                                        this.props.history.push('/user/logout');
                                                    }}
                                                >
                                                    로그아웃
                                                </Button>
                                            </div>

                                            <div className='service' style={{ marginBottom: '0px' }}>
                                                {this.props.auth.type === LOCAL ? (
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ modal: true });
                                                        }}
                                                        style={{ color: '#a7a7a7', height: '30px' }}
                                                    >
                                                        회원탈퇴
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ kakaoModal: true });
                                                        }}
                                                        style={{ color: '#a7a7a7', height: '30px' }}
                                                    >
                                                        카카오 회원탈퇴
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className='clearfix' />
                                </div>
                                {this.props.auth.type === LOCAL ? (
                                    <div>
                                        <div className='etc-info wrapper'>
                                            <div className='title-wrapper'>
                                                <p>기타</p>
                                            </div>

                                            <div className='service'>
                                                <div className='flex-wrapper'>
                                                    <p
                                                        onClick={() => {
                                                            this.props.history.push('/policy/privacy');
                                                        }}
                                                    >
                                                        개인정보 처리방침 <b>{'>'}</b>
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            this.props.history.push('/policy/terms');
                                                        }}
                                                    >
                                                        서비스 이용약관 <b>{'>'}</b>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='service'>
                                                <Button
                                                    style={{ backgroundColor: '#b7b7b7' }}
                                                    onClick={() => {
                                                        this.props.history.push('/user/logout');
                                                    }}
                                                >
                                                    로그아웃
                                                </Button>
                                            </div>

                                            <div className='service' style={{ marginBottom: '0px' }}>
                                                {this.props.auth.type === LOCAL ? (
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ modal: true });
                                                        }}
                                                        style={{ color: '#a7a7a7', height: '30px' }}
                                                    >
                                                        회원탈퇴
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ kakaoModal: true });
                                                        }}
                                                        style={{ color: '#a7a7a7', height: '30px' }}
                                                    >
                                                        카카오 회원탈퇴
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='clearfix' />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {active === 'point' && (
                            <>
                                <PointBoxRow>
                                    <PointBox>
                                        <PointBoxTitle>현재 잔여 포인트</PointBoxTitle>
                                        <PointTotal>{userInfo.point ? currencyFormatter(userInfo.point) : 0} 원</PointTotal>
                                    </PointBox>
                                </PointBoxRow>
                                <PointBoxRow>
                                    <PointBox3>
                                        <PointBoxTitle>포인트 적립 내역</PointBoxTitle>
                                        {pointAddHistory && pointAddHistory.length > 0 ? (
                                            <PointContent>
                                                {pointAddHistory.map((item, index) => (
                                                    <PointHistoryRow key={'add-point-' + index.toString()}>
                                                        <PointHistoryNameRow add>
                                                            <span className='title'>{item.name}</span>
                                                            <span className='value'>+ {currencyFormatter(item.value)} 원</span>
                                                        </PointHistoryNameRow>
                                                        <PointHistoryValueRow>
                                                            <span className='title'>{moment(item.addedAt).format('YY년 M월 D일 HH:mm')}</span>
                                                            <span className='value'>잔여 포인트 {currencyFormatter(item.leftValue)} 원</span>
                                                        </PointHistoryValueRow>
                                                    </PointHistoryRow>
                                                ))}
                                            </PointContent>
                                        ) : (
                                            <NoHistory>내역 없음</NoHistory>
                                        )}
                                    </PointBox3>
                                    <PointBox3>
                                        <PointBoxTitle>포인트 사용 내역</PointBoxTitle>
                                        {pointUsingHistory && pointUsingHistory.length > 0 ? (
                                            <PointContent style={{ paddingRight: '10px' }}>
                                                {pointUsingHistory.map((item, index) => (
                                                    <PointHistoryRow key={'using-point-' + index.toString()}>
                                                        <PointHistoryNameRow>
                                                            <span className='title'>{item.name}</span>
                                                            <span className='value'>- {currencyFormatter(item.value)} 원</span>
                                                        </PointHistoryNameRow>
                                                        <PointHistoryValueRow>
                                                            <span className='title'>{moment(item.usedAt).format('YY년 M월 D일 HH:mm')}</span>
                                                            <span className='value'>잔여 포인트 {currencyFormatter(item.leftValue)} 원</span>
                                                        </PointHistoryValueRow>
                                                    </PointHistoryRow>
                                                ))}
                                            </PointContent>
                                        ) : (
                                            <NoHistory>내역 없음</NoHistory>
                                        )}
                                    </PointBox3>
                                </PointBoxRow>
                                <PointBoxRow>
                                    <PointBox2>
                                        <PointBoxTitle>쿠폰 등록</PointBoxTitle>
                                        <CouponInput
                                            placeholder='쿠폰 번호를 입력하세요'
                                            title='appointedCode'
                                            value={this.state.appointedCode}
                                            onChange={this.handleChange}
                                        />
                                        <Button
                                            style={{
                                                margin: '0 auto',
                                                width: '100px',
                                                height: '45px',
                                                backgroundColor: '#3bc1ff',
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={() => {
                                                this.onAddCoupon();
                                            }}
                                        >
                                            등록
                                        </Button>

                                        <DivisionLine />
                                        <DivisionLine />
                                        <DivisionLine />

                                        <PointBoxTitle>쿠폰 사용 내역</PointBoxTitle>
                                        {couponAddHistory && couponAddHistory.length > 0 ? (
                                            <PointContent style={{ paddingRight: '10px' }}>
                                                {couponAddHistory.map((item, index) => (
                                                    <PointHistoryRow key={'add-coupon-' + index.toString()}>
                                                        <PointHistoryNameRow add>
                                                            <span className='title'>{item.couponName}</span>
                                                            <span className='value'>+ {currencyFormatter(item.couponValue)} P</span>
                                                        </PointHistoryNameRow>
                                                        <PointHistoryValueRow>
                                                            <span className='title'>{moment(item.addedAt).format('YY년 M월 D일 HH:mm')}</span>
                                                        </PointHistoryValueRow>
                                                    </PointHistoryRow>
                                                ))}
                                            </PointContent>
                                        ) : (
                                            <NoHistory>사용 내역 없음</NoHistory>
                                        )}
                                    </PointBox2>
                                </PointBoxRow>
                            </>
                        )}

                        {active === 'profile' ? (
                            <div className='user-profile-wrapper'>
                                <div>
                                    <div className='default-profile wrapper'>
                                        <div className='title-wrapper'>
                                            <p>환자 프로필 목록</p>
                                            <p style={{ fontSize: '12px', fontWeight: 'normal', color: 'gray', marginTop: '5px' }}>
                                                위드메이트 서비스를 사용하시는 프로필을 관리할 수 있습니다.
                                            </p>
                                            <div className='service' style={{ display: 'flex', justifyContent: 'right' }}>
                                                <Button onClick={this.onPatientModal} style={{ backgroundColor: '#3bc1ff', borderRadius: '5px' }}>
                                                    프로필 등록
                                                </Button>

                                                <Profile {...this.props} visible={this.state.patientModal} onClose={this.patientModalOff} />
                                                <ModifyProfile
                                                    {...this.state.patientInfo}
                                                    visible={this.state.patientModifyModal}
                                                    onClose={this.patientModifyModalOff}
                                                />
                                            </div>
                                        </div>

                                        <div className='profileList'>
                                            <Table
                                                dataSource={this.props.auth.patientList}
                                                columns={patientColumn({ onDelete: this.onPatientDelete, onModify: this.onPatientModify })}
                                                onRow={this.onRow}
                                                pagination={{
                                                    pageSize: 5,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='clearfix' />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                {this.state.reserveDetailModal && (
                    <NoramlReserveEditModal
                        visible={this.state.reserveDetailModal}
                        visibleHandler={() => {
                            this.setState({ temp: null, reserveDetailModal: false });
                        }}
                        data={this.state.temp}
                        inputDeepHandler={this.inputDeepHandler}
                    />
                )}

                {this.state.onReportMoal && (
                    <ReportMoal
                        visible={this.state.onReportMoal}
                        visibleHandler={() => {
                            this.setState({
                                report: null,
                                timeline: null,
                                onReportMoal: false,
                                selectedServiceName: '',
                            });
                        }}
                        data={{
                            report: this.state.report,
                            timeline: this.state.timeline,
                            selectedServiceName: this.state.selectedServiceName,
                        }}
                    />
                )}

                <Modal
                    visible={this.state.modal}
                    title='회원탈퇴'
                    footer={null}
                    onCancel={() => {
                        this.setState({ modal: false });
                    }}
                >
                    <DivisionLine />

                    <p>
                        <b>* 회원탈퇴 전, 유의사항을 확인해 주시기 바랍니다.</b> <DivisionLine />
                        - 회원탈퇴 시 위드메이트 서비스 이용이 불가합니다. <br />
                        - 회원탈퇴 후 회원 정보 및 이용 기록은 모두 삭제되며, <br /> &nbsp;&nbsp;복구 가능하지 않습니다.
                    </p>

                    <DivisionLine />

                    <label>
                        <b>* 비밀번호</b>
                    </label>
                    <Input.Password
                        style={{ height: '36px', marginBottom: '20px' }}
                        password='true'
                        placeholder='회원탈퇴를 위해 비밀번호를 입력해주세요.'
                        value={auth.password}
                        onChange={ev => {
                            this.props.editAuth({ type: 'password', value: ev.target.value });
                        }}
                    />
                    <br />

                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{ flex: 1, marginRight: '8px', height: '40px' }}
                            type='danger'
                            onClick={() => {
                                this.setState({ modal: false });
                            }}
                        >
                            취소
                        </Button>
                        <Button style={{ flex: 1, height: '40px' }} type='primary' onClick={this.withdrawl}>
                            확인
                        </Button>
                    </div>
                </Modal>

                <Modal
                    visible={this.state.kakaoModal}
                    title='카카오 회원탈퇴'
                    footer={null}
                    onCancel={() => {
                        this.setState({ modal: false });
                    }}
                >
                    <DivisionLine />

                    <p>
                        <b>* 회원탈퇴 전, 유의사항을 확인해 주시기 바랍니다.</b> <DivisionLine />
                        - 회원탈퇴 시 위드메이트 서비스 이용이 불가합니다. <br />
                        - 회원탈퇴 후 회원 정보 및 이용 기록은 모두 삭제되며, <br /> &nbsp;&nbsp;복구 가능하지 않습니다.
                    </p>
                    <DivisionLine />
                    <label>
                        <b>카카오 계정 회원 탈퇴를 하시겠습니까 ?</b>
                    </label>
                    <DivisionLine />
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{ flex: 1, marginRight: '8px', height: '40px' }}
                            type='danger'
                            onClick={() => {
                                this.setState({ kakaoModal: false });
                            }}
                        >
                            취소
                        </Button>
                        <Button style={{ flex: 1, height: '40px' }} type='primary' onClick={this.kakaoUnlink}>
                            확인
                        </Button>
                    </div>
                </Modal>

                <Modal
                    visible={this.state.mateModal}
                    title='담당 메이트 정보'
                    footer={null}
                    onCancel={() => {
                        this.setState({ mateModal: false });
                    }}
                >
                    <div className='mate-img-wrapper'>
                        <img src={mateInfo && mateInfo.mate ? mateInfo.mate.photo : '사진이 제공되지 않았습니다.'} alt='mate' />
                    </div>
                    <div className='mate-info-wrapper'>
                        <div>
                            <p className='label'>이름</p>
                            <div className='input'>
                                <p className='value'>{mateInfo && mateInfo.mate ? mateInfo.mate.name : ''}</p>
                            </div>
                        </div>
                        <div>
                            <p className='label'>연락처</p>
                            <div className='input'>
                                <p className='value'>{mateInfo && mateInfo.mate ? mateInfo.mate.contact : ''}</p>
                            </div>
                        </div>
                        <div>
                            <p className='label'>Pro 서비스 이력</p>
                            <div className='input'>
                                <p className='value'>{(mateInfo && mateInfo.review && mateInfo.review.unemergency) || 0}건</p>
                            </div>
                        </div>
                        <div>
                            <p className='label'>Basic 서비스 이력</p>
                            <div className='input'>
                                <p className='value'>{(mateInfo && mateInfo.review && mateInfo.review.assist) || 0}건</p>
                            </div>
                        </div>
                        {mateInfo && mateInfo.review ? (
                            <div>
                                <p className='label'>동행 서비스 이행 평점</p>
                                <StarRatingComponent
                                    starCount={5}
                                    value={(mateInfo.review && mateInfo.review.score) || 4}
                                    renderStarIcon={(index, value) => {
                                        return (
                                            <img
                                                style={{ width: '24px', height: '23px', marginRight: '8px' }}
                                                src={`/assets/images/icon_${index <= value ? 'active_star' : 'star'}.png`}
                                            />
                                        );
                                    }}
                                    onStarClick={() => {}}
                                />
                            </div>
                        ) : null}
                        <Button
                            type='primary'
                            onClick={() => {
                                if (mateInfo && this.state.callStatus === '예약접수') {
                                    this.confirmReserve();
                                } else {
                                    this.setState({ mateModal: false });
                                }
                            }}
                        >
                            {mateInfo && this.state.callStatus === '예약접수' ? '예약 및 메이트 확정하기' : '확인'}
                        </Button>
                        {mateInfo && this.state.callStatus === '예약접수' ? (
                            <Button style={{ marginTop: '12px' }} onClick={this.onRematch}>
                                메이트 거절 및 재배정
                            </Button>
                        ) : null}
                    </div>
                </Modal>
            </div>
        );
    }
}
export default NormalUser;

const Filter = ({ filter, value, onClick, name }) => {
    return (
        <span className={`filter ${filter === value ? 'active' : ''}`} onClick={onClick}>
            {name}
        </span>
    );
};

const PanelHead = styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0px;
    padding: 0px;
`;

const AddTimeSelector = styled.div`
    display: flex;
    flex-wrap: wrap;

    min-height: 60px;

    > span {
        margin-right: 8px;
        margin-bottom: 6px;

        display: inline-block;

        padding-left: 12px;
        padding-right: 12px;
        height: 24px;
        line-height: 24px;

        font-size: 12px;
        text-align: center;

        background-color: #dedede;
        color: #ffffff;

        border-radius: 10px;
        white-space: nowrap;
        cursor: pointer;

        @media (min-width: 768px) {
            &:hover {
                transition: all 0.5s;
                background-color: #3bc1ff;
            }
        }

        &.active {
            background-color: #3bc1ff;
        }
    }
`;

const CouponInput = styled(Input)`
    width: 100%;
    height: 46px;
    line-height: 44px;
    ${props => props.small && `max-width: 102px; height: 32px; line-height: 29px;`}

    margin-bottom: 0px;

    color: ${Pallete.dark100};
    font-size: 14px;
    font-weight: normal;

    border-radius: 4px;
    border: 1px solid ${Pallete.dark50} !important;
    background-color: #ffffff;

    @media (max-width: 480px) {
        font-size: 12px;
        border: 1px solid ${Pallete.dark50} !important;
    }

    &.ant-input {
        width: 65%;
        height: 45px;
        line-height: 44px;

        margin-bottom: 0px;

        color: ${Pallete.dark100};
        font-size: 14px;
        font-weight: normal;

        border-radius: 4px;
        border: 1px solid ${Pallete.dark50} !important;
        background-color: #ffffff;

        &::placeholder {
            color: ${Pallete.dark60};
            font-size: 14px;
            font-weight: normal;
        }

        @media (max-width: 480px) {
            font-size: 12px;
            border: 1px solid ${Pallete.dark50} !important;

            &::placeholder {
                font-size: 12px;
            }
        }
    }

    &:focus {
        border: 1px solid ${Pallete.blue100} !important;
        transition: all 0.3s;
        box-shadow: none;
        outline: 0;
    }

    &:disabled {
        background-color: ${Pallete.dark10};
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: #f5f5f5;
    }

    &.ant-input-affix-wrapper {
        height: 46px;
        line-height: 43px;
        padding: 0px 11px;

        ${props => props.small && `max-width: 102px; height: 32px; line-height: 29px;`}

        .ant-input {
            width: 100% !important;
            height: 100%;
            line-height: 43px;

            color: ${Pallete.dark100};
            font-size: 14px;
            font-weight: normal;

            border: 0px !important;
            &::placeholder {
                color: ${Pallete.dark60};
                font-size: 14px;
                font-weight: normal;
            }

            &:focus {
                border: 1px solid ${Pallete.blue100} !important;
                transition: all 0.3s;
                box-shadow: none;
                outline: 0;
            }

            @media (max-width: 480px) {
                font-size: 12px;
                line-height: 43px;

                &:focus {
                    border: 1px solid ${Pallete.dark50} !important;
                }

                &::placeholder {
                    font-size: 12px;
                }
            }
            &:disabled {
                background-color: ${Pallete.dark10};
            }
        }

        .ant-input-suffix {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            text-align: right;
            color: ${Pallete.dark100};

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
`;
