import React from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { message, Spin } from 'antd';
import _ from 'lodash';

// components
import { NoCatchedList } from '../../../../Components/User/Mate/reserve';
import { MateReserveInfoModal } from '../../../../Components/modal';

// utils
import axios from '../../../../Lib/axios';

class Reserve extends React.PureComponent {
    state = {
        reserveModal: false,
        reserveInfo: null,
        refreshing: false,
        list: [],
    };

    componentDidMount = () => {
        this.fetchList();
    };

    fetchList = debounce(() => {
        const mateId = localStorage.getItem('id');

        this.setState(
            {
                refreshing: true,
            },
            () => {
                axios
                    .get('/v2/service/mate/reserve/list', {
                        params: {
                            mateId,
                        },
                    })
                    .then(resolve => {
                        const { data: list } = resolve;
                        if (list && Array.isArray(list)) {
                            this.setState({ list, refreshing: false });
                        }
                    })
                    .catch(error => {
                        this.setState({ refreshing: false });
                    });
            },
        );
    }, 300);

    onCatchCall = () => {
        const { reserveInfo } = this.state;
        const { id } = this.props.auth;

        if (!reserveInfo || !reserveInfo.call || !reserveInfo.call._id) {
            this.setState({ reserveInfo: null, reserveModal: false });
            message.warning('유효하지않은 예약내역입니다. 다른 예약내역을 확인해주세요.');
            return null;
        }

        axios
            .put('/v2/service/catch/call', {
                callId: reserveInfo.call._id,
                mateId: id,
            })
            .then(resolve => {
                const { data: result } = resolve;
                if (!result) return message.warning('요청에 실패했습니다.');
                if (result === 'invalid call') return message.warning('잘못된 요청입니다.');
                if (result === 'already catched') return message.warning('이미 접수된 요청입니다.');
                if (result === 'denied') return message.warning('예약자에 의해 거절 당하셨거나 혹은 메이트님이 직접 해당 내역을 수신거부 하셨습니다.');
                if (result === 'success') {
                    message.success('예약 접수 요청완료');
                    this.setState({ reserveModal: false, reserveInfo: null }, () => {
                        this.fetchList();
                    });
                }
            })
            .catch(error => {
                message.warning('요청에 실패했습니다.');
            });
    };

    render() {
        const { list } = this.state;

        return (
            <>
                <NoCatchedList
                    data={this.state}
                    list={list}
                    onRefresh={this.fetchList}
                    detailHandler={info => {
                        this.setState({ reserveModal: true, reserveInfo: info });
                    }}
                />

                {this.state.reserveModal ? (
                    <MateReserveInfoModal
                        info={this.state.reserveInfo}
                        visibleHandler={() => {
                            this.setState({
                                reserveModal: false,
                                reserveInfo: null,
                            });
                        }}
                        onCatch={this.onCatchCall}
                    />
                ) : null}

                {this.state.refreshing ? (
                    <SpinWrapper>
                        <Spin size='large' />
                    </SpinWrapper>
                ) : null}
            </>
        );
    }
}

const SpinWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;


    z-index: 9999
    background-color: rgba(0, 0, 0, 0.6);
`;

export default Reserve;
