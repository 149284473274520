import styled from 'styled-components';

const StyledListContainer = styled.div``;

export const FullContainer = styled.div`
    width: 100%;
    background-color: #f6f6f6;
    min-height: 70vh;
`;

export const PaddingDiv = styled.div``;

export const TableContainer = styled.div``;

export const TableHeader = styled.div`
    padding: 23px 32px 32px 32px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

export const TableTitle = styled.p`
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 4px;
`;

export const TableSubTitle = styled.p`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.8);

    margin-bottom: 0px;
`;

export const TableAlert = styled.p`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 0px;
    margin-top: 16px;
`;

export const TableBody = styled.div`
    overflow-x: scroll;
    background-color: white;
`;

export const TableRow = styled.div`
    width: 100%;

    display: flex;
    align-items: center;

    padding-top: 11px;
    padding-bottom: 12px;

    border-bottom: 1px solid #e6e6e6;

    @media (max-width: 768px) {
        width: fit-content;
    }
`;

export const TableField = styled.div`
    ${props => (props.style ? props.style : `flex: 1;`)}

    display: flex;
    flex-direction: column;
    text-align: left;

    margin: 0px 26px;

    ${props =>
        props.desktopOnly
            ? `
        @media (max-width: 768px) {
            display: none;
        }
    `
            : undefined}

    ${props => (props.title ? `font-weight: bold;` : undefined)}
`;

export const TableButton = styled.div`
    align-self: flex-end;
    width: 134px;
    height: 28px;
    line-height: 28px;

    border-radius: 2px;
    border: 1px solid #3bc1ff;
    background-color: #3bc1ff;

    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    cursor: pointer;
    &:hover {
        transition: all 0.5s;
        opacity: 0.8;
    }

    &.disabled {
        border: 1px solid #a7a7a7;
        background-color: #a7a7a7;
        cursor: not-allowed;

        &:hover {
            transition: all 0.5s;
            opacity: 1;
        }
    }
`;

export const EmptyWrapper = styled.div`
    padding: 32px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const EmptyImage = styled.img`
    width: 200px;
    height: 120px;
    object-fit: contain;

    margin-bottom: 20px;
`;

export const EmptyText = styled.span`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: #b1b1b1;
`;

export default StyledListContainer;
