import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// component
import GuidePage from './guide';

// styles
import StyledContainer from './guide.styled';

// actions
import { serviceReserve, editResrveForm } from '../../../Reducer/Modules/app';

class GuideContainer extends React.PureComponent {
    componentDidMount = () => {};

    render() {
        return (
            <StyledContainer>
                <GuidePage {...this.props} />
            </StyledContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapStateToDispatch = dispatch => ({
    serviceReserve: ({ userId, info }) => dispatch(serviceReserve({ userId, info })),
    editForm: ({ type, value }) => dispatch(editResrveForm({ type, value })),
});

export default connect(mapStateToProps, mapStateToDispatch)(GuideContainer);
