import React from 'react';
import { NavLink } from 'react-router-dom';
import { notification, message, Input, Button } from 'antd';
import { LOCAL, MATE, USER } from '../../../../Lib/variables';
import { CommonWithmateModal } from '../../../../Components/newModal';
import { DivisionLine, WithmateInput } from '../../../../Components/Common/input';

import KakaoLogin from './KakaoLogin';

class LoginComponent extends React.PureComponent {
    state = {
        modal: false,
        modalTitle: '',
        modalInfo: '',
    };

    onEdit = ({ type, value }) => {
        this.props.editLoginForm({ type, value });
    };

    login = () => {
        const { email, password, userType } = this.props.auth.loginForm;
        const type = LOCAL;

        this.props
            .login({ id: email, password, userType, type })
            .then(resolve => {
                if (resolve === 'not exist') {
                    return this.setState({
                        modal: true,
                        modalTitle: '이메일 로그인',
                        modalInfo: '존재하지 않는 사용자의 아이디입니다. 아이디를 확인해주세요.',
                    });
                }

                if (resolve === 'not allowed') {
                    return this.setState({
                        modal: true,
                        modalTitle: '이메일 로그인',
                        modalInfo: '관리자로부터의 가입 승인을 대기중입니다. ',
                    });
                }

                if (resolve === 'wrong password') {
                    return this.setState({
                        modal: true,
                        modalTitle: '이메일 로그인',
                        modalInfo: '잘못된 비밀번호입니다. 확인 후 다시 시도해주세요. ',
                    });
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
                // return notification.open({
                //     message: '로그인 에러',
                //     description: '로그인 처리중 에러가 발생하였습니다. 새로고침 후 다시 시도해주세요.',
                //     type: 'error',
                // });
            });
    };

    render() {
        const { loginForm } = this.props.auth;

        return (
            <div className='login-container'>
                <div className='inner-container'>
                    <div className='login-form'>
                        <div className='login-title'>
                            <img src='/assets/images/new_logo_img.png' alt='위드메이트 로고' style={{ width: '45px', height: 'auto' }} /><p style={{ display: 'inline-block', verticalAlign: '-10px', paddingLeft: '10px' }}>로그인</p>
                            <br/>
                            <h5 style={{color: 'gray'}}>위드메이트의 서비스를 이용하기 위해 로그인을 해주세요.</h5>
                        </div>
                        <div>
                            <div className='form-user-type'>
                                <div
                                    onClick={() => {
                                        this.onEdit({ type: 'userType', value: USER });
                                    }}
                                    className={`type ${loginForm && loginForm.userType === USER ? 'active' : undefined}`}
                                >
                                    <p>일반 고객 로그인</p>
                                    <h5>병원 동행서비스를 이용하고 싶어요!</h5>
                                </div>
                            </div>
                            <div className='form-user-type'>
                                <div
                                    onClick={() => {
                                        this.onEdit({ type: 'userType', value: MATE });
                                    }}
                                    className={`type ${loginForm && loginForm.userType === MATE ? 'active' : undefined}`}
                                >
                                    <p>메이트 로그인</p>
                                    <h5>병원 동행서비스를 수행하는 메이트로 활동하고 싶어요!</h5>
                                </div>
                            </div>
                        </div>
                        {loginForm.userType === USER ? <>
                            <div className='form'>
                                <label>이메일</label>
                                <WithmateInput
                                    type='email'
                                    value={loginForm.email}
                                    onChange={ev => {
                                        this.onEdit({ type: 'email', value: ev.target.value });
                                    }}
                                    placeholder='ex) korea@mail.com'
                                />
                                <DivisionLine />
                                <WithmateInput
                                    type='password'
                                    value={loginForm.password}
                                    onChange={ev => {
                                        this.onEdit({ type: 'password', value: ev.target.value });
                                    }}
                                    onPressEnter={this.login}
                                    placeholder='비밀번호를 입력해주세요'
                                />

                                <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px', borderRadius: '12px' }} onClick={this.login}>
                                    로그인 하기
                                </Button>
                                {loginForm.userType === USER ?
                                    <KakaoLogin {...this.props} /> : null
                                }

                                <DivisionLine />

                                <div className='link-wrapper'>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/user/register');
                                        }}
                                    >
                                        회원가입
                                    </span>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/user/find/password');
                                        }}
                                    >
                                        비밀번호 찾기
                                    </span>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/mate/apply');
                                        }}
                                    >
                                        메이트 지원하기
                                    </span>
                                </div>
                            </div>
                        </> : null}

                        {loginForm.userType === MATE ? <>
                            <div className='form'>
                                <label>이메일</label>
                                <WithmateInput
                                    type='email'
                                    value={loginForm.email}
                                    onChange={ev => {
                                        this.onEdit({ type: 'email', value: ev.target.value });
                                    }}
                                    placeholder='ex) korea@mail.com'
                                />
                                <DivisionLine />
                                <WithmateInput
                                    type='password'
                                    value={loginForm.password}
                                    onChange={ev => {
                                        this.onEdit({ type: 'password', value: ev.target.value });
                                    }}
                                    onPressEnter={this.login}
                                    placeholder='비밀번호를 입력해주세요'
                                />

                                <Button type='primary' style={{ borderRadius: '4px', marginTop: '20px', borderRadius: '12px' }} onClick={this.login}>
                                    로그인 하기
                                </Button>

                                <DivisionLine />

                                <div className='link-wrapper'>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/user/register');
                                        }}
                                    >
                                        회원가입
                                    </span>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/user/find/password');
                                        }}
                                    >
                                        비밀번호 찾기
                                    </span>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            this.props.history.push('/mate/apply');
                                        }}
                                    >
                                        메이트 지원하기
                                    </span>
                                </div>
                            </div>
                        </> : null}
                    </div>
                </div>

                {this.state.modal && (
                    <CommonWithmateModal
                        visible={this.state.modal}
                        config={{
                            title: this.state.modalTitle,
                            description: this.state.modalInfo,
                            confirmText: '확인 및 닫기',
                            noClose: true,
                        }}
                        onOk={() => {
                            this.setState({ modal: false, modalTitle: '', modalInfo: '' });
                        }}
                    />
                )}
            </div>
        );
    }
}
export default LoginComponent;
