import React from 'react';

class AdvertisementComponent extends React.PureComponent {
    render() {
        return (
            <div className='terms-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>[맞춤 광고] (주)블루카멜 개인정보 수집 및 이용 동의 확인서</h2>
                    </div>

                    <div className='context-block'>
                        <div className='info'>
                            <p className='title'>
                                <b>[ 개인정보 처리 항목 ]</b> <br />
                                가입경로, 가입자 생년월일, 가입자 성별, 가입자 휴대전화번호, 서비스 종류, 이동 수단, 이메일 주소, 결제 카드사, 카드사, 환자
                                나이, 환자 성별, 환자 휴대전화번호, <b>다음 진료 일정 정리</b> <br />* ‘<b>다음 진료 일정 정리</b>’는 민감 정보에 해당
                            </p>
                            <p className='title'>
                                <b>[ 개인정보의 수집 및 이용 동의 거부 시 불이익 ]</b> <br />
                                정보주체는 개인정보 수집 및 이용에 동의를 거부할 권리가 있습니다. 맞춤 광고 목적의 항목에 대해 거부하더라도,
                                ‘(주)블루카멜(개인정보처리자)’이 제공하는 서비스를 전부 또는 일부를 이용하실 수 있습니다.
                            </p>
                            <p className='title'>
                                <b>[ 개인정보의 보유 및 이용 기간 ]</b> <br />
                                ① 위에서 제시한 처리 목적과 관련한 개인정보는 수집 및 이용에 대한 동의일부터 회원 탈퇴 후 2년까지
                                ‘(주)블루카멜(개인정보처리자)’이 보유 및 처리합니다. <br />
                                ② 다음과 같은 경우에는 ‘(주)블루카멜(개인정보처리자)’이 개인정보를 제1항에서 정한 기간과 달리 보유 및 처리할 수 있습니다. <br />
                                &ensp; 1. 다음과 같이 관련 법령에 의하여 보존할 필요성이 있는 경우 <br />
                                &emsp; 1) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 <br />
                                &emsp; 2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 <br />
                                &emsp; 3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년 <br />
                                &emsp; 4) 계약 또는 청약철회 등에 관한 기록 : 5년 <br />
                                &emsp; 5) 표시/광고에 관한 기록 : 6개월 <br />
                                &ensp; 2. 정보주체로부터 사전에 동의를 받은 경우 : 정보주체가 사전 동의한 기간
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AdvertisementComponent;
