import React from 'react';

import { mainColor } from '../../../theme';

class FstBlock extends React.PureComponent {
    render() {
        return (
            <div className='fst-section'>
                <div className='inner-container'>
                    <div className='text-block'>
                        <p className='title'>Built for Healthcare, Designed for NEMT</p>
                        <p className='content'>병원으로 향하는 새로운 길,</p>
                        <p className='content'>위드메이트가 열어갑니다.</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default FstBlock;
