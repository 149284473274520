import React from 'react';
import { connect } from 'react-redux';

// component
import MainRegister from './main';
import MainRegisterStyledContainer from './main.styled';

class MainRegisterPage extends React.PureComponent {
    render() {
        return (
            <MainRegisterStyledContainer>
                <MainRegister {...this.props} />
            </MainRegisterStyledContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRegisterPage);