import React from 'react';
import { connect } from 'react-redux';
import StyledCompanyContainer from './company.styled';
import Company from './company';

class CompanyPage extends React.PureComponent {
    render() {
        return (
            <StyledCompanyContainer>
                <Company {...this.props} />
            </StyledCompanyContainer>
        );
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
