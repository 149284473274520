import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// reducers
import auth from './Modules/auth';
import app from './Modules/app';
import review from './Modules/review';
import newsEvent from './Modules/newsEvent';

const reducers = combineReducers({ app, auth, review, newsEvent });
const store = createStore(reducers, applyMiddleware(thunk));
export default store;
