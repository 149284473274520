export const isNotNumber = value => {
    const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
    return regExp.test(value);
};

export const celluarValidate = phone => {
    const regExp = /^01(?:0|1|[6-9])[0-9]{4}[0-9]{4}$/;
    const regExp2 = /^1(?:0|1|[6-9])[0-9]{4}[0-9]{4}$/;

    const result = regExp.test(phone);
    const result2 = regExp2.test(phone);

    return result === true || result2 === true ? true : false;
};
