import React from 'react';
import { withRouter } from 'react-router-dom';

// component
import Nav from './nav';

// styles
import NavStyledContainer from './nav.styled';

// actions
// import {} from '../../Redux/Modules/';

class NavContainer extends React.PureComponent {
    render() {
        return (
            <NavStyledContainer>
                <Nav {...this.props} />
            </NavStyledContainer>
        );
    }
}

export default withRouter(NavContainer);
