import React from 'react';
import { Collapse, Icon, Table } from 'antd';

import { CollapseContainer } from '../../../Review/List/list.styled';
import { newsEvent } from '../../../../Components/Columns/user';

export default class Event extends React.PureComponent {
    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/360F/newsEvent/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    render() {
        return (
            <div className='faq-container'>
                <div className='faq-header'>
                    <div className='inner-container'>
                        <div className='flex-wrapper'>
                            <div className='faq-main-title'>
                                <p className='title'>위드메이트 소식</p>
                                <p className='description'>위드메이트의 다양한 소식을 확인할 수 있습니다.</p>
                            </div>
                            <div className='img-section'>
                                <img src={'/assets/images/comment_main.png'} style={{ width: '200px', height: 'auto', marginRight: '100px' }} alt='faq-img' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='faq-contents'>
                    <CollapseContainer className='inner-container'>
                        <Table
                            rowKey='id'
                            dataSource={this.props.newsEvent.newsEventList}
                            columns={newsEvent}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 10,
                            }}
                        />
                    </CollapseContainer>
                </div>
            </div>
        );
    }
}
