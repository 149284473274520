import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { UNEMER } from '../Lib/variables';

class ScrollTop extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return <>{this.props.children}</>;
    }
}
export default withRouter(ScrollTop);

export const currencyFormatter = currency => {
    if (currency === 0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = currency + '';

    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

    return n;
};

export const timeFormatter = ({ hour, minute = '00' }) => {
    const year = moment().format('YYYY').toString();
    const month = moment().format('MM').toString();
    const date = moment().format('DD').toString();

    if (hour && hour.length <= 1) hour = '0' + hour;
    if (minute && minute.length <= 1) minute = '0' + minute;
    return year + month + date + ' ' + hour + minute + '00';
};

export const datetimeFormatter = ({ date, hour, minute = '00' }) => {
    const year = moment(date).format('YYYY').toString();
    const month = moment(date).format('MM').toString();
    const _date = moment(date).format('DD').toString();

    if (hour && hour.length <= 1) hour = '0' + hour;
    if (minute && minute.length <= 1) minute = '0' + minute;
    return year + month + _date + ' ' + hour + minute + '00';
};

export const combineDateAndTime = ({ date, time, minute }) => {
    if (time && time.length === 1) time = '0' + time;
    if (minute && minute.length === 1) minute = '0' + minute;

    return moment(moment(date).format('YYYYMMDD') + ' ' + time + minute + '00').toDate();
};

export const serviceParser = service => {
    switch (service) {
        case 'assist':
            return '병원 동행 Basic';
        case 'unemergency':
            return '병원 동행 Pro';
        case 'happycare':
            return '해피케어';
    }
};

export const getNextStatus = (type, status) => {
    if (type === 'unemergency') {
        switch (status) {
            case '예약접수':
                return '';
            case '예약완료':
                return '접선';
            case '접선':
                return '병원 출발';
            case '병원 출발':
                return '병원 도착';
            case '병원 도착':
                return '진료 시작';
            case '진료 시작':
                return '진료 종료';
            case '진료 종료':
                return '귀가 시작';
            case '자택 출발':
                return '서비스 종료';
            case '서비스 종료':
                return '종료된 서비스';
        }
    }

    if (type === 'assist') {
        switch (status) {
            case '예약접수':
                return '';
            case '예약완료':
                return '접선';
            case '접선':
                return '진료 시작';
            case '진료 시작':
                return '진료 종료';
            case '진료 종료':
                return '서비스 종료';
            case '서비스 종료':
                return '종료된 서비스';
        }
    }

    if (type === 'happycare') {
        switch (status) {
            case '예약완료':
                return '접선';
            case '접선':
                return '진료 시작';
            case '진료 시작':
                return '진료 종료';
            case '진료 종료':
                return '서비스 종료';
            case '서비스 종료':
                return '종료된 서비스';
        }
    }
};

export const getTotal = (service, addTime, transport) => {
    if (transport === 'withcar' && service === UNEMER) {
        return getWithcarPrice(addTime);
    } else if (service === UNEMER) {
        return 20000 * addTime + (transport === 'own' ? 20000 : 0);
    } else {
        return 15000 * addTime;
    }
};

export const colorParserByStatus = status => {
    switch (status) {
        case '접수대기':
            return '#161616';
        case '예약접수':
            return '#43b30c';
        case '예약완료':
            return '#3bc1ff';
        case '서비스 종료':
            return '#ff5500';
        default:
            return '#606060';
    }
};

export const getWithcarPrice = time => {
    switch (time) {
        case 1:
            return 93500;
        case 1.5:
            return 93500 + 93500 / 2;
        case 2:
            return 143000;
        case 2.5:
            return 143000 + 93500 / 2;
        case 3:
            return 181500;
        case 3.5:
            return 181500 + 93500 / 2;
        case 4:
            return 220000;
        case 4.5:
            return 220000 + 93500 / 2;
        case 5:
            return 264000;
        case 5.5:
            return 264000 + 93500 / 2;
        case 6:
            return 308000;
        case 6.5:
            return 308000 + 93500 / 2;
        case 7:
            return 352000;
        case 7.5:
            return 352000 + 93500 / 2;
        case 8:
            return 396000;
    }
};
