import React from 'react';
import { connect } from 'react-redux';
import StyledInfoContainer from './info.styled';
import Info from './info';
import { addNewsEvent, fetchNewsEvent, getNewsEventInfo } from '../../../Reducer/Modules/newsEvent';

class InfoContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        const { state } = this.props.location;
        const { id: newsEventId } = this.props.match.params;

        // console.log(newsEventId, state);

        if (state === 'newsEvent') this.props.getNewsEventInfo({ newsEventId });
    };

    render() {
        return (
            <StyledInfoContainer>
                <Info {...this.props} />
            </StyledInfoContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, newsEvent }) => ({
    app, 
    auth,
    newsEvent
});

const mapDispatchToProps = dispatch => ({
    fetchNewsEvent: () => dispatch(fetchNewsEvent()),
    addNewsEvent: (info) => dispatch(addNewsEvent(info)),
    getNewsEventInfo: ({ id, newsEventId }) => dispatch(getNewsEventInfo({ id, newsEventId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer);
