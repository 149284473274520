import { message } from 'antd';
import React from 'react';
import { EduVideo1 } from './VideoList/Video1';
import { EduVideo2 } from './VideoList/Video2';
import { EduVideo3 } from './VideoList/Video3';
import { EduVideo4 } from './VideoList/Video4';

class ManualComponent extends React.PureComponent {
    state = {
        // showEduVideo2: false,
        // showEduVideo3: false,
        // showEduVideo4: false
    }

    componentDidMount = () => {
        if (!localStorage.getItem('id')) {
            message.warning('메이트 교육 영상을 시청하시려면 로그인 후 이용해주세요.');
            return this.props.history.push('/user/login');
        }
        this.props.history.push('/mateEducation/manual');

        // this.timer2 = setTimeout(() => {
        //     this.setState({ showEduVideo2: true });
        //   }, 2400000); // 40분 뒤 (40 * 60 * 1000)
          
        //   this.timer3 = setTimeout(() => {
        //     this.setState({ showEduVideo3: true });
        //   }, 3600000); // 1시간 뒤 (60 * 60 * 1000)
          
        //   this.timer4 = setTimeout(() => {
        //     this.setState({ showEduVideo4: true });
        //   }, 4800000); // 1시간 20분 뒤 (80 * 60 * 1000)
    }

    // componentWillUnmount() {
    //     clearTimeout(this.timer2);
    //     clearTimeout(this.timer3);
    //     clearTimeout(this.timer4);
    // }

    render() {
        return (
            <div className='video-form'>
                <EduVideo1 />
                <EduVideo2 />
                <EduVideo3 />
                <EduVideo4 />
                {/* {this.state.showEduVideo2 && <EduVideo2 />}
                {this.state.showEduVideo3 && <EduVideo3 />}
                {this.state.showEduVideo4 && <EduVideo4 />} */}
            </div>

        );
    }
}
export default ManualComponent;