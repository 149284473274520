import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// component
import Home from './home';
import { MATE } from '../../../Lib/variables';

// styles
import HomeStyledContainer from './home.styled';

// actions
import {
    editResrveForm,
    getServiceInfo,
    serviceReserve,
    getUserInfo,
    getContractPartners,
    acceptCall,
    editReserveDetailView,
    editReserveLatestView,
    editViewType,
    editCallPage,
    editContractForm,
} from '../../../Reducer/Modules/app';
import { editAuth } from '../../../Reducer/Modules/auth';

class HomeContainer extends React.PureComponent {
    componentDidMount = () => {
        const id = localStorage.getItem('id');
        const userType = localStorage.getItem('userType');

        if (id && userType === MATE) {
        } else {
            const prevList = localStorage.getItem('partners');
            if (prevList) {
                const list = JSON.parse(prevList);
                this.props.editContractForm({ type: 'partners', value: list });
            }

            this.props.fetchPartners();
        }
    };

    render() {
        return (
            <HomeStyledContainer>
                <Home {...this.props} />
            </HomeStyledContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapStateToDispatch = dispatch => ({
    editAuth: ({ type, value }) => dispatch(editAuth({ type, value })),
    editForm: ({ type, value }) => dispatch(editResrveForm({ type, value })),
    editLatest: ({ value }) => dispatch(editReserveLatestView({ value })),
    editDetail: ({ value }) => dispatch(editReserveDetailView({ value })),
    editViewType: ({ value }) => dispatch(editViewType({ value })),
    editCallPage: value => dispatch(editCallPage(value)),
    editContractForm: ({ type, value }) => dispatch(editContractForm({ type, value })),

    getServiceInfo: ({ code }) => dispatch(getServiceInfo({ code })),
    getUserInfo: ({ id, userType }) => dispatch(getUserInfo({ id, userType })),
    fetchPartners: () => dispatch(getContractPartners()),
    serviceReserve: ({ userId, info }) => dispatch(serviceReserve({ userId, info })),
    acceptCall: ({ mateId, callId }) => dispatch(acceptCall({ mateId, callId })),
});

export default connect(mapStateToProps, mapStateToDispatch)(HomeContainer);
