import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import styled from 'styled-components';
import { Input } from 'antd';

import { DivisionLine } from '../Components/Common/input';
import { FullWidthButton } from '../Components/Common/button';

// theme
import Pallete, { mainColor } from '../theme';

// utils
import { serviceParser, getNextStatus, getTotal, currencyFormatter } from '../Lib/utils';

export default class Modal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        const now = moment().valueOf();
        const begin = moment('20220923 180000').valueOf(); // 02. 24 ~
        const end = moment('20220925 180000').valueOf(); // ~ 03. 31

        if (now > begin && now < end) {
            this.modalValidation();
        }
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('modal') && typeof JSON.parse(localStorage.getItem('modal')) === 'object' && JSON.parse(localStorage.getItem('modal')) !== null
                ? JSON.parse(localStorage.getItem('modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container
                    id='event-popup'
                    onClick={e => {
                        if (e.target.id === 'event-popup') {
                            this.onClose();
                        }
                    }}
                >
                    <ModalBlock
                        onClick={e => {
                            e.preventDefault();
                        }}
                    >
                        <Top style={{ marginBottom: '16px' }}>
                            <Title>서버 업데이트 대비 서버점검 안내</Title>
                        </Top>
                        <Bottom>
                            <ContentRow>2022. 09. 23 더 편리한 서비스</ContentRow>
                            <ContentRow>제공을 위헤 업데이트를 진행할 예정입니다.</ContentRow>
                            <ContentRow>아래 명시한 기간동안 정상적인 서비스 이용에 어려움이</ContentRow>
                            <ContentRow>있을수 있습니다. 성원에 보답하는 위드메이트가 되겠습니다.</ContentRow>

                            <WarningRow>※ 서버점검 기간</WarningRow>
                            <WarningContentRow>22. 09. 23 18:00 ~ 22. 09. 25 18:00</WarningContentRow>

                            <div style={{ height: '8px' }} />
                            <MessageRow>국민 모두 힘을 합치면 코로나 사태 극복 가능합니다.</MessageRow>
                            <MessageRow>코로나19 극복, 위드메이트가 앞장서겠습니다.</MessageRow>
                            {/* <BottomImage /> */}
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

export class RadiationModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        // localStorage.clear();
        // const now = moment().valueOf();
        // const begin = moment('20200223 235959').valueOf(); // 02. 24 ~
        // const end = moment('20200331 190000').valueOf(); // ~ 03. 31

        // if (now > begin && now < end) {
        //     this.modalValidation();
        // }
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        <Top>
                            <Title>투석 & 방사선 치료 동행 서비스 안내</Title>
                        </Top>
                        <Bottom>
                            <BottomImage />
                            <ContentRow style={{ textAlign: 'center' }}>투석 & 방사선 치료 병원 동행 서비스가 필요하신 분들은</ContentRow>
                            <ContentRow style={{ textAlign: 'center' }}>고객센터 전화를 통해 서비스 상담을 받으실 수 있습니다.</ContentRow>
                            <MessageRow style={{ textAlign: 'center', cursor: 'pointer' }}>
                                <a href='tel:070-4300-9075'>고객센터 번호 ☞ 070-4300-9075</a>
                            </MessageRow>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

// class EventModal extends React.PureComponent {
//     state = {
//         modal: false,
//     };

//     componentDidMount = () => {
//         this.modalValidation();
//     };

//     modalValidation = () => {
//         const { lastTime } =
//             localStorage.getItem('radiation-modal') &&
//             typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
//             JSON.parse(localStorage.getItem('radiation-modal')) !== null
//                 ? JSON.parse(localStorage.getItem('radiation-modal'))
//                 : {
//                       lastTime: moment().subtract('day', 1).toDate(),
//                   };

//         if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
//             this.setState({ modal: true });
//         }
//     };

//     onClose = () => {
//         localStorage.setItem('radiation-modal', null);
//         this.setState({ modal: false });
//     };

//     onCloseToday = () => {
//         localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
//         this.setState({ modal: false });
//     };

//     render() {
//         if (this.state.modal) {
//             return (
//                 <Container id='event-popup'>
//                     <ModalBlock>
//                         <Top>
//                             <Title>위드메이트, 이제 포항에서 만나보세요!</Title>
//                         </Top>
//                         <Bottom>
//                             <BottomImage />
//                             <ContentRow>
//                                 위드메이트가 <span>한동대학교 ICT 창업팀</span>과 협력하여 포항시를 시작으로 경상권 정식 서비스를 시작합니다. 경상권 서비스 시작
//                                 전, <span>포항시 대상 시범 서비스</span>와 <span>사전 수요 조사</span>가 진행됩니다. (회원 가입 완료 고객님 대상)
//                             </ContentRow>
//                             <br />
//                             <ContentRow>
//                                 서비스 신청 시 '자택 주소' 또는 '병원 주소'가 <span>'포항시'에 해당하는 모든 고객님께 30% 할인 혜택</span>이 적용되며, 설문에
//                                 참여해주시는 모든 경상권 거주 고객님께 <span>20% 할인 쿠폰</span>과 추첨을 통한 <span>스타벅스 기프티콘</span>을 드립니다.{' '}
//                             </ContentRow>

//                             <div style={{ marginTop: '16px', marginBottom: '16px' }}>
//                                 <MessageRow>
//                                     <a
//                                         onClick={() => {
//                                             this.onClose();
//                                             this.props.history.push('/user/register');
//                                         }}
//                                     >
//                                         설문 전 회원가입 먼저 하기 {'>'}
//                                     </a>
//                                 </MessageRow>
//                                 <MessageRow>
//                                     <a
//                                         href='https://docs.google.com/forms/d/e/1FAIpQLSfX6N8E0-fPVHXDFQN0iKUCjpZNP_cHl4e1kDRwEORk9NmtCw/viewform'
//                                         target='_blank'
//                                     >
//                                         쿠폰(설문 조사) 링크 {'>'}
//                                     </a>
//                                 </MessageRow>
//                             </div>

//                             <ContentRow style={{ textIndent: '0px' }}>여러분의 소중한 지인에게 병원 동행 서비스를 알려주세요!</ContentRow>
//                         </Bottom>
//                         <EndBottom>
//                             <CloseToday>
//                                 <span onClick={this.onCloseToday}>
//                                     <i className='fas fa-check' />
//                                 </span>
//                                 <span onClick={this.onCloseToday}>오늘은 그만보기</span>
//                             </CloseToday>
//                             <CloseNow onClick={this.onClose}>닫기</CloseNow>
//                         </EndBottom>
//                     </ModalBlock>
//                 </Container>
//             );
//         } else {
//             return <></>;
//         }
//     }
// }

export const MateReserveInfoModal = ({ visibleHandler, info, onCatch }) => {
    const serviceType = !info.productInfo || !info.productInfo.usePro || !info.productInfo.useBasic ? '' : info.service === 'unemergency' ? 'Pro' : 'Basic';
    const serviceName = (info.productInfo && info.productInfo.name && info.service && info.productInfo.name + serviceType) || '-';

    return (
        <Container>
            <ModalBlock>
                <ModalTitle>
                    예약 상세내용
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>
                <ModalLabel>서비스명</ModalLabel>
                <ModalInfo>{serviceName}</ModalInfo>
                <ModalLabel>병원 진료 예약 시간</ModalLabel>
                <ModalInfo>{moment(info.reserveDate).format('YYYY년 MM월 DD일, HH시 mm분')}</ModalInfo>
                <ModalLabel>총 예상 소요 시간</ModalLabel>
                <ModalInfo>{info.usageTime || 0}시간</ModalInfo>
                {info && info.shortStarting ? (
                    <>
                        <ModalLabel>자택 주소</ModalLabel>
                        <ModalInfo>{info.shortStarting}</ModalInfo>
                    </>
                ) : null}
                <ModalLabel>병원 주소</ModalLabel>
                <ModalInfo>{info.destination}</ModalInfo>
                <ModalLabel>이동수단</ModalLabel>
                <ModalInfo>
                    {info.transport && info.transport === 'withcar'
                        ? '차량 호출'
                        : info.transport && info.transport === 'own'
                        ? '본인소유 자차 이용'
                        : '대중교통(택시 등) 이용'}
                </ModalInfo>
                <ModalLabel>예상 비용</ModalLabel>
                <ModalInfo>
                    {(info.transport && info.transport === 'own' ? 20000 : 0) +
                        (info.productInfo &&
                            info.service &&
                            (info.service === 'unemergency' ? info.productInfo.proPrice : info.productInfo.basicPrice ? info.productInfo.basicPrice : 0) *
                                info.usageTime)}
                    원
                </ModalInfo>

                <WhiteButton onClick={onCatch}>예약 접수하기</WhiteButton>
                <ModalFlexbox>
                    <div>
                        <ModalLabel>이용자 성함</ModalLabel>
                        <ModalInfo>{info.patient}</ModalInfo>
                    </div>
                    <div>
                        <ModalLabel style={{ paddingLeft: '0px' }}>이용자 성별</ModalLabel>
                        <ModalInfo style={{ paddingLeft: '0px' }}>{info.sex}</ModalInfo>
                    </div>
                </ModalFlexbox>
                <ModalFlexbox>
                    <div>
                        <ModalLabel>추가 요청 사항</ModalLabel>
                        <ModalInfo style={{ marginBottom: '24px' }}>{info.comment || '-'}</ModalInfo>
                    </div>
                </ModalFlexbox>
            </ModalBlock>
        </Container>
    );
};

export const MateCallStatusModal = ({ visibleHandler, info, onNext }) => {
    const serviceType = !info.productInfo || !info.productInfo.usePro || !info.productInfo.useBasic ? '' : info.service === 'unemergency' ? 'Pro' : 'Basic';
    const serviceName = (info.productInfo && info.productInfo.name && info.service && info.productInfo.name + serviceType) || '-';

    return (
        <Container>
            <ModalBlock>
                <ModalTitle>
                    예약 상세내용 및 관리
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>
                <ModalLabel>서비스 명</ModalLabel>
                <ModalInfo>{serviceName}</ModalInfo>
                <ModalLabel>병원 진료 예약 시간</ModalLabel>
                <ModalInfo>{moment(info.reserveDate).format('YYYY년 MM월 DD일, HH시 mm분')}</ModalInfo>
                <ModalLabel>총 예상 소요 시간</ModalLabel>
                <ModalInfo>{info.usageTime || 0}시간</ModalInfo>
                <ModalLabel>병원 주소</ModalLabel>
                <ModalInfo>{info.destination}</ModalInfo>
                {info.service === 'unemergency' && (
                    <>
                        <ModalLabel>이동 수단</ModalLabel>
                        <ModalInfo>{info.transport === 'own' ? '자차 이용' : info.transport === 'common' ? '대중교통' : '-'}</ModalInfo>
                    </>
                )}
                {info && info.starting && (
                    <>
                        <ModalLabel>자택 주소</ModalLabel>
                        <ModalInfo>{info.starting}</ModalInfo>
                    </>
                )}
                {info.call && info.call.status !== '예약접수' && (
                    <>
                        <ModalFlexbox>
                            <div>
                                <ModalLabel>이용자 성함</ModalLabel>
                                <ModalInfo>{info.patient}</ModalInfo>
                            </div>
                            <div>
                                <ModalLabel style={{ paddingLeft: '0px' }}>이용자 성별</ModalLabel>
                                <ModalInfo style={{ paddingLeft: '0px' }}>{info.sex}</ModalInfo>
                            </div>
                        </ModalFlexbox>
                        <ModalLabel>이용자 연락처</ModalLabel>
                        <ModalInfo>{info.contact}</ModalInfo>

                        {/* <ModalLabel>보호자 연락처</ModalLabel>
                        <ModalInfo>{(info.buyer && info.buyer.contact) || '-'}</ModalInfo> */}
                    </>
                )}

                <ModalLabel>추가 요청 사항</ModalLabel>
                <ModalInfo style={{ marginBottom: '24px' }}>{info.comment || '-'}</ModalInfo>

                {info.call && info.call.status !== '서비스 종료' && info.call.status !== '예약접수' && (
                    <WhiteButton onClick={onNext}>{getNextStatus(info.service, info.call.status)}</WhiteButton>
                )}
                {info.call && info.call.status === '서비스 종료' && <WhiteButton onClick={onNext}>서비스 리포트 작성</WhiteButton>}

                <BlueButton style={{ marginBottom: '24px' }} onClick={visibleHandler}>
                    닫기
                </BlueButton>
            </ModalBlock>
        </Container>
    );
};

export const UserServiceReportModal = ({ visibleHandler, info, service, onMateInfo }) => {
    const { timeline } = info;

    return (
        <Container>
            <ModalBlock>
                <ModalTitle>
                    서비스 리포트
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>
                <ModalLabel>타임라인</ModalLabel>
                {service === 'happycare' ? (
                    <ModalInfo>
                        {timeline.receipt ? <p>{`고객님과 메이트가 ${moment(timeline.receipt.time).format('HH시 mm분')}에 만났습니다.`}</p> : ''}
                        {timeline.careStart ? <p>{`${moment(timeline.careStart.time).format('HH시 mm분')}에 진료를 시작하였고,`}</p> : ''}
                        {timeline.careDone ? (
                            <p>{`${moment(timeline.careDone.time).diff(moment(timeline.careStart.time), 'minute')}분 동안 진료를 봤습니다.`}</p>
                        ) : (
                            ''
                        )}
                        {timeline.complete ? <p>{`${moment(timeline.complete.time).format('HH시 mm분')}에 고객님과의 동행 서비스가 종료되었습니다.`}</p> : ''}
                    </ModalInfo>
                ) : null}
                {service === 'assist' ? (
                    <ModalInfo>
                        {timeline.arrived ? <p>{`메이트가 ${moment(timeline.arrived.time).format('MM월 DD일, HH시 mm분')}에 병원에 도착하였습니다.`}</p> : ''}
                        {timeline.receipt ? <p>{`고객님과는 ${moment(timeline.receipt.time).format('HH시 mm분')}에 만났습니다.`}</p> : ''}
                        {timeline.careStart ? <p>{`${moment(timeline.careStart.time).format('HH시 mm분')}에 진료를 시작하였고,`}</p> : ''}
                        {timeline.careDone ? (
                            <p>{`${moment(timeline.careDone.time).diff(moment(timeline.careStart.time), 'minute')}분 동안 진료를 봤습니다.`}</p>
                        ) : (
                            ''
                        )}
                        {timeline.complete ? <p>{`${moment(timeline.complete.time).format('HH시 mm분')}에 고객님과의 동행 서비스가 종료되었습니다.`}</p> : ''}
                    </ModalInfo>
                ) : null}
                {service === 'unemergency' ? (
                    <ModalInfo>
                        {timeline.receipt ? <p>{`메이트가 고객님과 ${moment(timeline.receipt.time).format('MM월 DD일, HH시 mm분')}에 만났습니다.`}</p> : ''}
                        {timeline.start ? <p>{`병원으로 ${moment(timeline.start.time).format('HH시 mm분')}에 출발하였습니다.`}</p> : ''}
                        {timeline.arrived ? <p>{`그리고 ${moment(timeline.arrived.time).format('HH시 mm분')}에 병원에 도착하였습니다.`}</p> : ''}
                        {timeline.careStart ? <p>{`${moment(timeline.careStart.time).format('HH시 mm분')}에 진료를 시작하였고,`}</p> : ''}
                        {timeline.careDone ? (
                            <p>{`${moment(timeline.careDone.time).diff(moment(timeline.careStart.time), 'minute')}분 동안 진료를 봤습니다.`}</p>
                        ) : (
                            ''
                        )}
                        {timeline.backStart ? <p>{`진료 후 ${moment(timeline.backStart.time).format('HH시 mm분')}에 자택으로 출발했습니다.`}</p> : ''}
                        {timeline.complete ? <p>{`${moment(timeline.complete.time).format('HH시 mm분')}에 고객님과의 동행 서비스가 종료되었습니다.`}</p> : ''}
                    </ModalInfo>
                ) : null}
                <ModalLabel>{info.mate[0].name} 메이트 리포트</ModalLabel>

                <ModalInfo style={{ overflow: 'hidden' }}>{info.report || '담당 메이트가 아직 리포트를 작성중입니다.'}</ModalInfo>

                <FullWidthButton bg={Pallete.dark70} onClick={onMateInfo} style={{ marginBottom: '24px', width: '90%', margin: '0 auto' }}>
                    담당 메이트 정보
                </FullWidthButton>
                <DivisionLine small />
                <FullWidthButton bg={Pallete.blue50} onClick={visibleHandler} style={{ marginBottom: '24px', width: '90%', margin: '0 auto' }}>
                    닫기
                </FullWidthButton>
                <DivisionLine small />
            </ModalBlock>
        </Container>
    );
};

export const HandleReportModal = ({ visibleHandler, onHandleReport, inputHandler, desc }) => {
    return (
        <Container>
            <ModalBlock>
                <ModalTitle>
                    서비스 리포트 작성
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>

                <ModalLabel>서비스 수행 및 진료내용 {desc && desc.length > 0 ? `(글자수 - ${desc.length}자)` : ''}</ModalLabel>
                <ModalPaddingBlock>
                    <Input.TextArea
                        rows={6}
                        placleholder='서비스 수행중 있었던 특이사항이나 진료내용 등을 입력해주세요.'
                        maxLength={600}
                        onChange={ev => {
                            inputHandler({ type: 'report', value: ev.target.value });
                        }}
                    />
                </ModalPaddingBlock>
                <WhiteButton onClick={onHandleReport}>리포트 등록</WhiteButton>
                <BlueButton onClick={visibleHandler} style={{ marginBottom: '24px' }}>
                    닫기
                </BlueButton>
            </ModalBlock>
        </Container>
    );
};

export const ShareCarRequestModal = ({ visibleHandler, inputHandler, onSave, data }) => {
    return (
        <Container>
            <ModalLargeBlock>
                <ModalTitle>
                    셔틀 셰어링 신청
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>

                <FlexWrapper>
                    <FlexBox>
                        <p className='label'>기관명</p>
                        <Input
                            type='text'
                            placeholder='ex. 한빛 요양원'
                            value={data.corp}
                            onChange={ev => {
                                if (ev.target.value.length >= 20) return null;

                                inputHandler({ type: 'corp', value: ev.target.value });
                            }}
                        />
                    </FlexBox>
                    <FlexBox>
                        <p className='label'>지역</p>
                        <Input
                            type='text'
                            placeholder='ex. 경기도 수원시'
                            value={data.address}
                            onChange={ev => {
                                if (ev.target.value.length >= 20) return null;

                                inputHandler({ type: 'address', value: ev.target.value });
                            }}
                        />
                    </FlexBox>
                </FlexWrapper>

                <FlexWrapper>
                    <FlexBox>
                        <p className='label'>담당자</p>
                        <Input
                            type='text'
                            placeholder='ex. 홍길동'
                            value={data.manager}
                            onChange={ev => {
                                if (ev.target.value.length >= 20) return null;

                                inputHandler({ type: 'manager', value: ev.target.value });
                            }}
                        />
                    </FlexBox>
                    <FlexBox>
                        <p className='label'>전화번호</p>
                        <Input
                            type='number'
                            placeholder='ex. 01012349876'
                            value={data.contact}
                            onChange={ev => {
                                if (ev.target.value.length >= 13) return null;

                                inputHandler({ type: 'contact', value: ev.target.value });
                            }}
                        />
                    </FlexBox>
                </FlexWrapper>

                <FlexWrapper>
                    <FlexFullBox>
                        <p className='label'>기타 문의 및 전달사항</p>
                        <Input.TextArea
                            type='text'
                            placeholder='위드메이트 관리자에게 전달할 메시지를 입력해주세요.'
                            value={data.message}
                            onChange={ev => {
                                if (ev.target.value.length > 150) return null;

                                inputHandler({ type: 'message', value: ev.target.value });
                            }}
                        />
                    </FlexFullBox>
                </FlexWrapper>

                <BlueButton onClick={onSave} style={{ marginBottom: '24px' }}>
                    접수하기
                </BlueButton>
            </ModalLargeBlock>
        </Container>
    );
};

export const EmergencyCorpSearchModal = ({ visibleHandler, inputHandler, keyword = '', data = [] }) => {
    return (
        <Container>
            <ModalLargeBlock>
                <ModalTitle>
                    사설 구급업체 검색
                    <ModalCloseButtons onClick={visibleHandler} src='/assets/images/close.png' alt='close-button' />
                </ModalTitle>

                <FlexWrapper>
                    <FlexFullBox>
                        <p className='label'>지역 검색</p>
                        <Input
                            placeholder='ex. 경기, 대구, 부산'
                            value={keyword}
                            onChange={ev => {
                                if (ev.target.value.length > 10) return null;

                                inputHandler(ev.target.value);
                            }}
                        />
                    </FlexFullBox>
                </FlexWrapper>

                <FlexWrapper>
                    <FlexFullBox>
                        <p className='label'>검색결과</p>
                        <TableWrapper>
                            <TableHeaderWrapper>
                                <FlexColumn>
                                    <span className='title'>시도 / 시군구</span>
                                </FlexColumn>
                                <FlexColumn>
                                    <span className='title'>기관명</span>
                                </FlexColumn>
                                <FlexColumn>
                                    <span className='title'>대표번호</span>
                                </FlexColumn>
                            </TableHeaderWrapper>
                            {data && data.length > 0 ? (
                                data
                                    .filter(
                                        item =>
                                            (item.addr1 && item.addr1.includes(keyword)) ||
                                            (item.addr2 && item.addr2.includes(keyword)) ||
                                            (item.name && item.name.includes(keyword)) ||
                                            (item.contact && item.contact.includes(keyword)),
                                    )
                                    .map((item, index) => (
                                        <TableRowWrapper key={'emergency-corporation-item-' + index.toString()}>
                                            <FlexColumn>
                                                <span className='value'>{item.addr1 + ' ' + item.addr2}</span>
                                            </FlexColumn>
                                            <FlexColumn>
                                                <span className='value'>{item.name}</span>
                                            </FlexColumn>
                                            <FlexColumn>
                                                <span className='value'>{item.contact}</span>
                                            </FlexColumn>
                                        </TableRowWrapper>
                                    ))
                            ) : (
                                <EmptyTable />
                            )}
                        </TableWrapper>
                    </FlexFullBox>
                </FlexWrapper>

                <BlueButton onClick={visibleHandler} style={{ marginBottom: '24px' }}>
                    확인
                </BlueButton>
            </ModalLargeBlock>
        </Container>
    );
};

class CardModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        <Top>
                            <Title>카드결제 안내</Title>
                        </Top>
                        <Bottom>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                <img src='/assets/images/popup_img/withmate_card.png' style={{ width: '400px', height: 'auto' }} />
                                {/* <PopupImage /> */}
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                <MessageRow>
                                    <a
                                        onClick={() => {
                                            this.onClose();
                                            this.props.history.push('/user/mypage/info');
                                        }}
                                    >
                                        결제카드 등록하기 {'>'}
                                    </a>
                                </MessageRow>
                                {/* <MessageRow>
                                    <a
                                        href='https://docs.google.com/forms/d/e/1FAIpQLSfX6N8E0-fPVHXDFQN0iKUCjpZNP_cHl4e1kDRwEORk9NmtCw/viewform'
                                        target='_blank'
                                    >
                                        쿠폰(설문 조사) 링크 {'>'}
                                    </a>
                                </MessageRow> */}
                            </div>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

class ManagerModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        {/* <Top>
                            <Title>위드메이트 X 국립암센터 암특화 병원동행 매니저 자격과정 모집</Title>
                        </Top> */}
                        <Bottom>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                <img src='/assets/images/popup_img/withmate_manager.png' style={{ width: '300px', height: 'auto' }} />
                                {/* <PopupImage /> */}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <MessageRow>
                                    <a
                                        onClick={() => {
                                            this.onClose();
                                            this.props.history.push({
                                                pathname: `/newsEvent/info/63d9f951397216d7ff7672a8`,
                                                state: { id: '63d9f951397216d7ff7672a8' },
                                            });
                                        }}
                                    >
                                        자세히 보기 {'>'}
                                    </a>
                                </MessageRow>
                                {/* <MessageRow>
                                    <a
                                        href='https://docs.google.com/forms/d/e/1FAIpQLSfX6N8E0-fPVHXDFQN0iKUCjpZNP_cHl4e1kDRwEORk9NmtCw/viewform'
                                        target='_blank'
                                    >
                                        쿠폰(설문 조사) 링크 {'>'}
                                    </a>
                                </MessageRow> */}
                            </div>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

class EventModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        {/* <Top>
                            <Title>위드메이트 X 국립암센터 암특화 병원동행 매니저 자격과정 모집</Title>
                        </Top> */}
                        <Bottom>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                                <img src='/assets/images/popup_img/event_img.png' style={{ width: '400px', height: 'auto' }} />
                                {/* <PopupImage /> */}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <MessageRow>
                                    <a
                                        onClick={() => {
                                            this.onClose();
                                            this.props.history.push({
                                                pathname: `/newsEvent/info/645dc777f0316a44d06a1fdb`,
                                                state: { id: '645dc777f0316a44d06a1fdb' },
                                            });
                                        }}
                                    >
                                        자세히 보기 {'>'}
                                    </a>
                                </MessageRow>
                            </div>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

class NoticeModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    onCloseToday2 = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        <Top>
                            <Title>서버오류로 인한 데이터 복구작업 안내</Title>
                        </Top>
                        <Bottom>
                            <ContentRow>
                                <br />
                                <div style={{ fontSize: '16px', textAlign: 'center' }}>
                                    서버 오류가 발생하여 손실된 데이터 복구작업 진행중입니다.
                                    <br />
                                    이에 관련하여 문의사항은 고객센터로 연락을 주시면 <br />
                                    빠르게 해결해드리겠습니다.
                                    <br />
                                    위드메이트를 이용하시는데 불편을 드려 죄송합니다.
                                    <br />
                                    <br />
                                    <br />
                                    <b>
                                        ⏰ 운영시간 : 오전 9시 - 오후 6시 (토·일 제외)
                                        <br />
                                        👩🏻‍💻 문의방법 : 채널톡 및 카카오톡 혹은 전화(070-4300-9075)
                                    </b>
                                </div>
                            </ContentRow>
                            <div style={{ marginTop: '15px' }}>
                                <MessageRow>
                                    {/* <a
                                        onClick={() => {
                                            this.onClose();
                                            this.props.history.push(
                                                {
                                                    pathname: `/newsEvent/info/63c0ec49fe7889400f150b97`,
                                                    state: { id: '63c0ec49fe7889400f150b97' },
                                                });
                                        }}
                                    >
                                        자세히 보기 {'>'}
                                    </a> */}
                                </MessageRow>
                            </div>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday2}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday2}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

class MetlifeModal extends React.PureComponent {
    state = {
        modal: false,
    };

    componentDidMount = () => {
        this.modalValidation();
    };

    modalValidation = () => {
        const { lastTime } =
            localStorage.getItem('radiation-modal') &&
            typeof JSON.parse(localStorage.getItem('radiation-modal')) === 'object' &&
            JSON.parse(localStorage.getItem('radiation-modal')) !== null
                ? JSON.parse(localStorage.getItem('radiation-modal'))
                : {
                      lastTime: moment().subtract('day', 1).toDate(),
                  };

        if (!lastTime || moment(lastTime).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            this.setState({ modal: true });
        }
    };

    onClose = () => {
        localStorage.setItem('radiation-modal', null);
        this.setState({ modal: false });
    };

    onCloseToday = () => {
        localStorage.setItem('radiation-modal', JSON.stringify({ lastTime: moment().toDate() }));
        this.setState({ modal: false });
    };

    render() {
        if (this.state.modal) {
            return (
                <Container id='event-popup'>
                    <ModalBlock>
                        {/* <Top>
                            <Title>위드메이트 X 국립암센터 암특화 병원동행 매니저 자격과정 모집</Title>
                        </Top> */}
                        <Bottom>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                                <img src='/assets/images/popup_img/metlife_popup.png' style={{ width: '400px', height: 'auto' }} />
                                {/* <PopupImage /> */}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <MessageRow>
                                    <a
                                        onClick={() => {
                                            this.onClose();
                                            this.props.history.push('/360F/nemt/service/#types');
                                        }}
                                    >
                                        서비스 종류 보기 {'>'}
                                    </a>
                                </MessageRow>
                            </div>
                        </Bottom>
                        <EndBottom>
                            <CloseToday>
                                <span onClick={this.onCloseToday}>
                                    <i className='fas fa-check' />
                                </span>
                                <span onClick={this.onCloseToday}>오늘은 그만보기</span>
                            </CloseToday>
                            <CloseNow onClick={this.onClose}>닫기</CloseNow>
                        </EndBottom>
                    </ModalBlock>
                </Container>
            );
        } else {
            return <></>;
        }
    }
}

export const CustomModal = ({ children }) => {
    return <CustomContaienr>{children}</CustomContaienr>;
};

const TableWrapper = styled.div`
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    overflow-x: hidden;
    overflow-y: scroll;

    margin-bottom: 30px;
`;

const TableHeaderWrapper = styled.div`
    border-bottom: 1px solid #e6e6e6;
    padding: 6px 22px;

    display: flex;
`;

const TableRowWrapper = styled.div`
    border-bottom: 1px solid #e6e6e6;
    padding: 9.5px 22px;

    display: flex;
`;

const EmptyTable = styled.div`
    height: 100px;
`;

const FlexColumn = styled.div`
    flex: 1;

    text-align: left;

    > span.title {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 18px;

        letter-spacing: -0.2px;
        color: rgba(0, 0, 0, 0.4);
    }

    > span.value {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;

        letter-spacing: -0.2625px;
        color: rgba(0, 0, 0, 0.8);
    }
`;

const FlexWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

const FlexBox = styled.div`
    flex: 1;

    width: calc(50% - 10px);

    &:first-child {
        margin-right: 20px;
    }

    > p.label {
        margin-bottom: 4px;

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;

        color: #a7a7a7;
    }

    > input {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.8);

        margin-bottom: 20px;

        height: 40px;
        line-height: 40px;

        color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        width: 100%;

        &:first-child {
            margin-right: 0px;
        }
    }
`;

const FlexFullBox = styled(FlexBox)`
    width: 100%;

    &:first-child {
        margin-right: 0px;
    }

    > input {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.8);

        margin-bottom: 20px;

        height: 40px;
        line-height: 40px;

        color: rgba(0, 0, 0, 0.8);
    }

    > textarea.ant-input {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.8);

        margin-bottom: 20px;

        height: 100px;
        line-height: 20px;

        color: rgba(0, 0, 0, 0.8);
    }
`;

export const EventPopup = withRouter(EventModal);
export const ManagerPopup = withRouter(ManagerModal);
export const CardPopup = withRouter(CardModal);
export const NoticePopup = withRouter(NoticeModal);
export const MetlifePopup = withRouter(MetlifeModal);

const Container = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 100;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
        overflow: hidden;
    }
`;

const CustomContaienr = styled(Container)`
    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

const ModalBlock = styled.div`
    margin-top: -46px;
    // margin-right: 400px;
    width: 100%;
    min-width: 430px;
    max-width: 450px;
    max-height: 90%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    background-color: #fdfdfd;
    border-radius: 4px;

    @media (max-width: 768px) {
        min-width: auto;
        max-width: 380px;
        margin-right: 0px;
    }
`;

const ModalBlock2 = styled.div`
    margin-top: -46px;
    margin-left: 600px;
    width: 100%;
    min-width: 430px;
    max-width: 450px;
    max-height: 90%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    background-color: #fdfdfd;
    border-radius: 4px;

    @media (max-width: 768px) {
        min-width: auto;
        max-width: 380px;
        margin-left: 0px;
    }
`;

const ModalLargeBlock = styled(ModalBlock)`
    max-width: 740px;
    padding: 32px;

    @media (max-width: 1120px) {
        max-width: 540px;
    }

    @media (max-width: 768px) {
        max-width: 440px;
    }

    @media (max-width: 430px) {
        max-width: 380;
    }
`;

const ModalTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.03px;
    color: rgba(0, 0, 0, 0.87);

    position: relative;

    margin-top: 24px;
    margin-bottom: 24px;
`;

const ModalFlexbox = styled.div`
    display: flex;
    align-items: flex-start;

    > div {
        flex: 1;
    }
`;

const ModalCloseButtons = styled.img`
    position: absolute;
    top: 0px;
    right: 24px;

    display: inline-block;
    width: 18px;
    height: 18px;

    cursor: pointer;
`;

const ModalLabel = styled.p`
    font-size: 12px;
    color: #b6b6b6;
    margin-bottom: 4px;
    font-weight: bold;

    padding-left: 32px;
    padding-right: 32px;
`;

const ModalPaddingBlock = styled.div`
    padding-left: 32px;
    padding-right: 32px;

    margin-bottom: 24px;
`;

const ModalInfo = styled.p`
    font-size: 13px;
    color: #565656;
    margin-bottom: 12px;
    font-weight: bold;

    padding-left: 32px;
    padding-right: 32px;
`;

const WhiteButton = styled.div`
    width: 100%;
    max-width: calc(100% - 64px);
    height: 40px;
    line-height: 40px;

    border-radius: 16px;

    border: 1px solid ${mainColor};
    color: ${mainColor}

    font-size: 13px;
    text-align: center;

    margin: 0 auto;
    margin-bottom: 24px;

    cursor: pointer;
    &:hover {
        transition: all 0.5s;
        background-color: ${mainColor};
        color: white;
    }
`;

const BlueButton = styled.div`
    
    width: calc(100% - 64px);
    max-width: 360px;
    height: 40px;
    line-height: 40px;

    border-radius: 16px;

    background-color: ${mainColor};
    color: #ffffff

    font-size: 13px;
    text-align: center;

    margin: 0 auto;

    cursor: pointer;
    &:hover {
        transition: all 0.5s;
        opacity: 0.85;
    }
`;

const Top = styled.div`
    // border-bottom: 3px solid ${mainColor};

    height: 42px;
    line-height: 42px;
    border-bottom: 2px solid #e9e9e9;
`;

const Title = styled.p`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.03px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0px;
`;

const Bottom = styled.div`
    position: relative;
    // padding-top: 20px;
    padding-bottom: 20px;
`;

const BottomImage = styled.div`
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // top: 0px;
    // left: 0px;

    width: 100%;
    height: 170px;
    opacity: 0.86;

    margin-bottom: 10px;

    background-image: url('/assets/images/main_logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const PopupImage = styled.div`
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // top: 0px;
    // left: 0px;

    width: 100%;
    height: 170px;
    opacity: 0.86;

    margin-bottom: 10px;

    background-image: url('/assets/images/icons/manager.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const ContentRow = styled.p`
    // font-weight: 600;
    margin: 0px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
    padding: 0px 16px;
    text-indent: 4px;

    word-break: keep-all;

    > span {
        color: ${Pallete.blue100};
        font-weight: bold;
    }
`;

const WarningRow = styled.p`
    font-size: 13px;
    font-weight: 600;
    color: red;
    padding: 0px 16px;
    margin-top: 8px;
`;

const WarningContentRow = styled.p`
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    // font-weight: bold;
    padding: 0px 16px;
`;

const EndBottom = styled.div`
    padding: 6px 16px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    background-color: #f5f5f5;
`;

const CloseToday = styled.p`
    flex: 1;

    > span {
        font-size: 13px;
        margin-right: 4px;
        cursor: pointer;
    }

    > span:last-child {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }
`;

const CloseNow = styled.p`
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
`;

const MessageRow = styled.p`
    font-size: 13px;
    font-weight: bold;
    color: #6e82d6;
    padding: 0px 16px;

    word-break: keep-all;

    a,
    span {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        color: #6e82d6;
    }

    // color: ${mainColor};
`;

const Info = styled.p`
    font-size: 12px;
    fong-weight: bold;
    // margin-top: 16px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0px 16px;
`;
