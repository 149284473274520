import React from 'react';

class TermsComponent extends React.PureComponent {
    render() {
        return (
            <div className='terms-container'>
                <div className='inner-container'>
                    <div className='main-context'>
                        <h2>위드메이트 서비스 이용약관</h2>
                    </div>

                    <div className='context-block'>
                        <h3>제 1조(목적)</h3>
                        <p>
                            본 약관은 ㈜블루카멜(이하 “회사”라 한다)가 제공하는 위드메이트 병원 동행 서비스와 관련하여 회사와 회원과의 권리, 
                            <br/>의무 및 책임 사항, 그리고 기타 필요한 사항을 규정함을 목적으로 합니다.
                        </p>
                        <br/>
                        <p>
                            본 서비스 이용약관은 2021년 12월 20일부터 시행됩니다.
                        </p>
                    </div>

                    <div className='context-block'>
                        <h3>제 2조(정의)</h3>
                        <h5>1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</h5>

                        <div className='info'>
                            <p className='title'>“위드메이트 서비스” 혹은 “서비스”라 함은 당사가 제공하는 ‘병원 동행 서비스’를 의미합니다.</p>
                            <p className='title'>
                                “회원”이라 함은 회사가 제공하는 서비스를 이용하기 위하여 본 약관에 동의하고 회사의 회원으로 가입하는 자를 말합니다. (단,
                                메이트와 관련된 사실 관계에 한하여, 메이트도 본 약관에서 “회원”에 포함될 수 있습니다)
                            </p>
                            <p className='title'>
                                “메이트”라 함은 회사가 회원에게 서비스를 제공하기 위해 직접 선별하고 교육하여 ‘프리랜서’의 형태로 간접 고용한 인원을 말합니다.
                            </p>
                            <p className='title'>
                                “운송사업자"라 함은 여객자동차 운수사업법 제4조에 따른 면허를 받은 여객자동차 운송사업자로서, 회사(메이트 인력)의 중개 또는 회원
                                스스로를 통해 택시 등 그 면허를 받은 운송 수단을 이용하여 운송서비스를 제공하는 자를 의미합니다.
                            </p>
                            <p className='title'>
                                “매칭”이라 함은 회원이 플랫폼(웹사이트 포함)에서 출발지와 목적지를 입력하고, “예약하기" 버튼을 통해 서비스 이용을 요청(이하
                                “이용 요청”이라고 합니다)하면, 요청 시점에 서비스 제공이 가능한 메이트가 플랫폼(웹사이트 포함)을 통하여
                                수락함으로써(매칭됨으로써), 회원과 회사 사이의 메이트 알선 계약 및 회원과 운송사업자 간의 운송 계약이 각 체결된 상태를
                                의미합니다.
                            </p>
                            <p className='title'>
                                “플랫폼(웹사이트 포함)”이라 함은 회사가 회원에게 서비스를 제공하기 위하여 개발한 위드메이트 애플리케이션과 웹사이트를
                                의미합니다.
                            </p>
                            <p className='title'>
                                “이용요금”이라 함은
                                <br />
                                (i) 메이트가 제공하는 의료 동행 서비스에 대한 비용
                                <br />
                                (ii) 회원이 택시 등 운송사업자의 운송 수단을 예약하거나 호출하여 이동하는 비용,
                                <br />
                                (iii) 기타 비용(고객 소유 자가용 대리 운전 비용, 예약 취소 및 변경 수수료 등)을 총칭하여 의미합니다.
                            </p>
                        </div>

                        <h5 style={{ marginTop: '56px' }}>
                            2. 본 약관에서 사용하는 용어의 정의는 본 조제 1항에서 정하는 것을 제외하고는 관계 법령에서 정하는 바에 의합니다. 관계 법령에서
                            정하지 않는 것은 일반적인 상 관례에 의합니다.
                        </h5>
                    </div>

                    <div className='context-block'>
                        <h3>제 3조(약관의 효력 및 변경)</h3>
                        <h5>1.</h5>
                        <h6>
                            약관은 애플리케이션 소프트웨어 및 해당 애플리케이션과 웹사이트의 모든 패치, 업데이트, 업그레이드 또는 새로운 버전에 적용되며, 가장
                            최신 버전이 모든 기존 버전에 우선합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            본 약관은 서비스 이용계약의 성격상 회사의 웹사이트 또는 서비스 이용을 위한 애플리케이션 내에 본 약관을 명시하고, 이용자가
                            애플리케이션을 설치 및 실행 혹은 웹사이트 열람과 동시에 효력이 발생합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>회사는 관계 법령 또는 상관습에 위배되지 않는 범위에서 본 약관을 개정할 수 있습니다.</h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회사는 관련 법령의 변경이나 이용자의 권리 및 의무사항, 서비스 등을 개선하기 위해 본 약관을 변경할 수 있으며, 변경된 경우에 약관의
                            내용과 적용일을 정하여, 적용일 7일 전 회사 공식 홈페이지나 공식 블로그, 제공하는 애플리케이션 등을 통해 이용자에게 고지하고
                            적용일부터 효력이 발생합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            이용자는 변경된 약관에 대해 거부할 권리가 있습니다. 본 약관의 변경에 대해 이의가 있는 이용자는 서비스 이용을 중단하고 이용을
                            해지(탈퇴 및 삭제)할 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>6.</h5>
                        <h6>
                            회사가 본 조 4항에 따라 변경된 약관을 공시 또는 고지하면서 이용자가 기간 내의 의사표시를 하지 않으면 변경된 약관에 동의한 것으로
                            간주합니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 4조(약관 외 준칙)</h3>
                        <h5>1.</h5>
                        <h6>
                            회사는 필요한 경우 특정 서비스에 관하여 적용될 사항을 위해 개별 약관이나 이용 정책을 정하여 운영할 수 있으며, 해당 내용은 홈페이지
                            등을 통해 공지합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 개별 약관, 이용 정책, 서비스 이용 화면을 통하여 제시된 개별 이용 조건(이용
                            요금 등, 이하 “개별계약“이라고 합니다)에서 정한 바에 따릅니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            본 약관과 개별 약관, 개별 계약의 내용이 충돌할 경우 본 약관에 별도의 규정이 없는 한 개별 계약 또는 개별 약관에서 정한 바에 따릅니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회원은 서비스 이용 시 개별 계약 또는 개별 약관의 내용을 확인하여야 하며, 이를 확인하지 않아 발생한 손해에 대해 회사는 책임을
                            부담하지 않습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 5조(이용 계약의 성립)</h3>
                        <h5>1.</h5>
                        <h6>
                            이용 계약은 이용자가 본 이용 약관에 대한 동의 또는 회사의 애플리케이션을 다운로드 받거나 실행하여 이용하는 경우, 그리고 웹사이트
                            회원 가입을 완료한 경우 이 약관에 동의한 것으로 간주합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>이용자는 동의하지 않는 경우 애플리케이션이나 웹사이트 계정 로그아웃 및 삭제와 함께 이를 철회할 수 있습니다.</h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회사는 제 1항에 따른 회원 가입 신청에 대해 승낙함을 원칙으로 합니다. 다만, 다음 각 호의 경우 회사는 회원 가입 신청을 승낙하지 않거나
                            사후에 회원 계약을 해지할 수 있습니다.
                        </h6>

                        <div className='info'>
                            <p className='title'>신청자가 본 약관 위반 등의 사유로 이전에 회원 자격이 상실 또는 회원 계약이 해지되었던 경우</p>
                            <p className='title'>자신의 귀책으로 이용 정지를 당한 회원이 그 이용 정지 기간 중에 이용 계약을 임의 해지하고 재신청을 하는 경우</p>
                            <p className='title'>본 약관에 위배되거나 위법 또는 사회상규상 부당한 가입 신청임이 확인된 경우</p>
                            <p className='title'>허위의 정보를 기재한 경우, 회사가 제시하는 내용을 기재하지 않은 경우 또는 오기 등이 있는 경우</p>
                            <p className='title'>미성년자 등 법령상 행위 무능력자인 경우</p>
                            <p className='title'>기타 신청자의 귀책 사유로 인하여 승낙이 불가능하거나 기타 회사가 정한 제반 사항을 위반한 자의 신청</p>
                        </div>
                    </div>

                    <div className='context-block'>
                        <h3>제 6조(회원 계약의 해제 및 해지 등)</h3>
                        <h5>1.</h5>
                        <h6>
                            회원은 언제든지 회원 계약의 해지를 신청할 수 있으며, 회사는 관련 법령에서 정하는 바에 따라 이를 즉시 처리하여야 합니다. 다만,
                            회원에게 미수금이 있을 경우에는 미수금에 대한 결제를 완료한 이후에 해지 신청이 가능합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회원이 회원 계약을 해지할 경우, 회사는 관련 법령 개인정보처리방침 또는 내부관리 계획 등에 따라 회원 정보를 보유하는 경우를
                            제외하고는 해지 즉시 회원의 모든 정보를 삭제합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회원 계약 해지로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담해야 하며, 회사는 회원에게 부가적으로 제공한 각종 무상 혜택을
                            회수할 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회사가 회원 계약을 해지하는 경우 회사는 회원에게 해지 사유를 밝혀 해지 의사를 통지합니다. 이 경우 회사는 해지를 하기 전에 상당한
                            기간을 정하여 회원에게 이의 신청의 기회를 부여합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>회원 계약이 회사에 의해 해지되는 경우 회원의 재이용 신청에 대해 회사는 승낙을 거절할 수 있습니다.</h6>
                        <h5 style={{ marginTop: '48px' }}>6.</h5>
                        <h6>
                            회원의 귀책 사유로 인해 회원 계약이 해지됨으로써 발생한 손해는 당해 회원이 부담하여야 하며, 회사는 관련 법령에 규정이 없는 한 책임을
                            부담하지 않습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 7조(서비스 내용)</h3>
                        <h5>1.</h5>
                        <h6>회사가 제공하는 병원 동행 서비스의 내용은 다음과 같습니다.</h6>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>병원 동행 Pro 서비스 : </span>응급 상황에 처하지는 않았으나 정기적, 혹은 비정기적으로 의료 기관에 방문해
                                적절한 조치를 받아야 하는 고객을 위한 서비스.
                            </p>
                            <p className='title'>
                                <span className='bold'>병원 동행 Basic 서비스 & 수면내시경 보호자 서비스 :</span>보호자 동행이 법적 의무는 아니나 보호자가 없을 경우 시술이 어려운 수면
                                내시경 등과 같은 의료 조치를 받아야 하는 고객을 위한 보호자 대행 서비스.
                            </p>
                            <p className='title'>
                                <span className='bold'>병원 동행 서비스 제공을 위한 메이트 알선 서비스 : </span>회원의 요청에 따라 적격 자격을 갖춘 인원을
                                회사가 직접 선별하고 교육한 후, 프리랜서 형태로 간접 고용하여 회원이 병원 동행 서비스를 위한 동행자를 알선 받을 수 있도록
                                중개해주는 서비스.
                            </p>

                            <p className='title'>
                                <span className='bold'>고객 소유 자가용 대리 운전 서비스 : </span>회원의 요청에 따라 알선된 메이트가 고객 소유의 자가용을 운행해
                                병원과 자택을 이동하는 서비스
                            </p>
                            <p className='title'>
                                <span className='bold'>택시 등 여객자동차 운송 중개 서비스 :</span>회원의 요청에 따라 알선된 메이트가 택시 등 여객자동차
                                운송사업자의 운송 수단을 호출하여 동행할 수 있도록 중개해주는 서비스.
                            </p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>회사는 제1항의 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다.</h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회사가 제공하는 서비스와 플랫폼(웹사이트 포함)의 사용은 무료입니다. 단, 회사가 제공한 서비스를 통해 메이트 알선을 포함한 병원 동행
                            서비스가 제공되는 경우 사용자는 해당 서비스에 대한 이용 요금을 지불할 의무를 가집니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회사는 회원과 메이트 사이의 병원 동행 서비스 혹은 메이트 알선 계약 및 회원과 여객자동차 운송사업자 사이의 운송 계약 체결을 중개하고
                            제 8조 1항에 명시된 ‘병원 동행 서비스 비용’을 수취하는 회사입니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>회사의 메이트는 고객에게 어떠한 형태의 의료 서비스나 치료, 구급차 등의 의료 운송 서비스를 제공하지 않습니다.</h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 8조(이용 요금 등)</h3>
                        <h5>1.</h5>
                        <h6>
                            위드메이트 서비스 이용 요금은 ‘병원 동행 서비스 비용’으로 구성되어 있습니다. 단, 상황에 따라 이동 중에 발생하는 운송 교통 비용이
                            고객 본인 부담으로 발생할 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회사는 이용 요금을 애플리케이션 혹은 웹사이트 내의 예약하기 페이지에 게시하거나, 예약 완료 시에 별도로 회원에게 안내합니다. 다만
                            최종 이용 요금은 다양한 변수에 따라서 서비스 종료 후 추가 부과가 될 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            메이트가 회원이 지정한 출발지에 도착하여 회원에게 도착을 안내하였음에도 회원이 사전 연락 없이 일정 시간(20분) 이내에 나타나지
                            아니하거나 계약을 취소하는 경우 회사는 제 9조에 명시된 취소 수수료를 회원에게 부과할 수 있습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            [병원 동행 Pro] 서비스 이용 옵션 중 ‘고객 소유의 자차 운행’을 선택한 경우 예약일에 맞춰 본인의 차량 보험사가 제공하는 ‘임시 운전자
                            특약’에 가입을 해야 합니다. 이때 발생하는 보험 비용은 고객 본인 부담이며, 임시 운전자 특약에 가입을 하지 않고 메이트에게 운전을
                            위임하여 사고가 발생한 경우 모든 책임은 고객 본인에게 있습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            서비스와 관련하여 발생한 미납경비, 손해배상 등 회원의 회사에 대한 채무가 모두 정산될 때까지 해당 회원의 서비스 사용이 중지되며, 그
                            채무가 일정기간 계속하여 변제되지 않을 경우에는 해당 회원의 회원자격이 박탈될 수 있습니다. 서비스 사용 중지 또는 회원자격 박탈에
                            대한 판단 기준은 회사의 약관 및 이용정책에 근거합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>6.</h5>
                        <h6>
                            회원이 본 약관 및 위 각 조항을 준수하지 않았을 경우, 회사는 본 약관 및 이용 정책에 따라 손해배상 금액 등 각종 수수료를 부과하는 것
                            외에 계약을 해지할 수 있는 권리를 가집니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>7.</h5>
                        <h6>
                            회사는 본 서비스와 관련하여 발생한 미납경비, 손해배상 등 회원의 회사에 대한 채무가 정산되지 않을 경우 회원을 상대로 보전처분,
                            본안소송제기, 강제집행절차의 착수 등 채무 변제를 위한 법적 조치에 들어갈 수 있습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 9조(계약 등의 취소)</h3>
                        <h5>1. 회원이 정상적으로 매칭된 병원 동행 서비스 계약을 취소 및 변경하는 경우에는 아래와 같이 수수료가 발생합니다.</h5>

                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>서비스 예약 날짜 기준 48시간 이내 변경 및 취소</span>수수료 없이 변경 & 취소 가능
                            </p>
                            <p className='title'>
                                <span className='bold'>서비스 예약 전날 18:00 이전 변경 및 취소</span>취소 시 수수료 10,000원 발생. 단, 메이트와 변경 협의 성공
                                시에는 수수료 부과 X 수수료 부과 X
                            </p>
                            <p className='title'>
                                <span className='bold'>당일 예약 변경 및 취소</span>취소 시 수수료 15,000원 발생. 단, 메이트와 변경 협의 성공 시에는 수수료 부과
                                X
                            </p>
                            <p className='title'>
                                <span className='bold'>서비스 시작 2시간 전 이내 변경 및 취소</span>서비스 수수료 20,000원 발생. 단, 메이트와 변경 협의 성공
                                시에는 수수료 부과 X 수수료 부과 X
                            </p>
                            <p className='title'>
                                <span className='bold'>노쇼(No-Show)의 경우</span>회원 자격 정지 및 민/형사상 책임 소지를 물을 수 있음
                            </p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>
                            2. 회사가 서비스 계약을 취소하는 경우 회원에게 사유를 설명하고 이용요금 전액을 청구하지 아니하며, 회원이 입은 피해에 대한 보상을
                            협의하고 지급합니다.
                        </h5>
                    </div>

                    <div className='context-block'>
                        <h3>제 10조(서비스 제공 밎 중단)</h3>

                        <h5>1.</h5>
                        <h6>
                            회사는 서비스를 주 5일 (월~금), 1일 10시간 (오전 9시~오후 7시) 제공함을 원칙으로 합니다. 특수 예약에 대해서는 검토 후 해당 시간
                            외에도 서비스를 제공할 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회사는 서비스의 제공에 필요한 경우 정기 점검을 실시할 수 있으며, 정기 점검 시간은 플랫폼(웹사이트 포함)의 서비스 제공 화면에 공지한
                            바에 따릅니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회사는 파산 또는 회생 절차 개시 등 정상적인 영업 활동이 곤란한 경우 또는 회사의 영업상 필요한 경우 서비스 내용을 변경하거나 서비스를
                            종료할 수 있습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>회사는 다음 각 호의 경우 당해 서비스의 전부 또는 일부의 제공을 중단하거나 제한할 수 있습니다.</h6>

                        <div className='info'>
                            <p className='title'>
                                천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제, 테러, 해킹, DDOS, 기타 회사의 합리적인 노력으로 제어할 수 없는
                                사유가 발생하거나 발생할 우려가 있는 경우
                            </p>
                            <p className='title'>기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우</p>
                            <p className='title'>서비스용 설비의 장애 및 정기 점검, 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우</p>
                            <p className='title'>서비스가 제3자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에 따라 변경되거나 중지되는 경우</p>
                            <p className='title'>기타 서비스의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            본 조 제3항, 제4항의 사유로 서비스 내용이 변경 또는 중단되는 경우, 회사는 그로 인해 회원이 입은 손해에 대하여 고의 또는 중과실이
                            없는 한 배상하지 않습니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>6.</h5>
                        <h6>회사는 본 조 상기된 사유가 발생한 경우 최대한 빠른 시간 내에 서비스를 재개하도록 최선의 노력을 다합니다.</h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 11조(서비스의 변경)</h3>

                        <h5>1.</h5>
                        <h6>
                            회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 서비스를 변경할 수 있습니다. 다만,
                            변경된 내용이 중대하거나 회원에게 불리한 경우에는 회사가 해당 서비스를 제공받는 회원에게 통지합니다.
                        </h6>
                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회사는 전항에 따른 서비스 변경에 대한 동의를 거절한 회원에 대하여는 해당 서비스의 제공을 중지하거나 회원 이용 계약을 해지할 수
                            있습니다. 이 때 회원 역시 회원 계약을 종료할 권한을 가집니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 12조(회원에 대한 서비스 이용 제한 등)</h3>

                        <h5>1.</h5>
                        <h6>회사는 다음 각 호에 해당하는 경우 회원에 대하여 서비스 제공을 제한하거나 중지할 수 있습니다.</h6>
                        <div className='info'>
                            <p className='title'>폭언, 폭행, 그 밖에 적정 범위를 벗어난 신체적ㆍ정신적 고통을 유발하는 행위를 통해, 회원이 서비스의 정상적인 운영을 방해한 경우</p>
                            <p className='title'>법정 감염병 환자, 법정 감염병 의사 환자, 감염병 병원체보유자 등, 환자가 각종 매개체를 통해 메이트에게 질병을 전염 및 유발할 것으로 우려되는 경우</p>
                            <p className='title'>회원이 타인의 명의를 도용하여 회원가입을 한 경우</p>
                            <p className='title'>
                                이용 요금을 지급하지 아니하거나 이용 요금을 결제함에 있어서 타인 명의 결제 도용, 전화번호 도용 등 불법적인 방법을 사용한 경우
                            </p>
                            <p className='title'>
                                불법프로그램의 제공 및 운영 방해, 정보통신망법을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이
                                회원이 관련 법령을 위반한 경우
                            </p>
                            <p className='title'>회원이 계속해서 1년 이상 로그인하지 않는 경우</p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            본 조에 따라 서비스 이용을 제한하거나 중지하는 경우에는 회사는 사전에 이를 회원에게 통지하거나 공지합니다. 다만 부득이한 경우 사후에
                            통지하거나 공지할 수 있습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 13조(개인정보의 관리 및 변경)</h3>

                        <h6>
                            이용자는 본 서비스의 이용을 위해 자신의 개인 정보를 성실히 관리해야 하며, 개인정보에 변동사항이 있을 경우 이를 변경해야 합니다. 본
                            서비스를 이용하면서 이용자의 개인정보 변경이 지연되거나 누락, 이용자에 의해 유출되어 발생하는 손해는 이용자의 책임으로 합니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 14조(정보의 제공 및 광고의 게재)</h3>

                        <h5>1.</h5>
                        <h6>
                            회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항 또는 서비스 화면, 메시지, 전자우편 등의 방법으로
                            회원에게 제공할 수 있습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>회사는 서비스의 운영과 관련하여 서비스 화면 등에 광고를 게재할 수 있습니다.</h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 15조(회사의 의무)</h3>

                        <h5>1.</h5>
                        <h6>회사는 관련법과 본 약관의 금지하는 행위를 하지 않으며, 계속적이고 안정적인 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>회사는 이용자의 개인정보 보호를 위한 보안 의무에 최선을 다합니다.</h6>

                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회사는 이용자로부터 제기되는 의견이나 불만이 정당하고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만,
                            즉시 처리가 불가한 경우에는 이용자에게 그 사유와 처리 일정을 통보하여야 합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에 기해 발생한 경우에 한하여 회사에서
                            책임을 부담합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>회사는 정보통신망법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법령을 준수합니다.</h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 16조(회원의 의무)</h3>

                        <h5>1.</h5>
                        <h6>
                            회원은 최종 요금 등의 서비스 조건을 확인한 후 이용 요청을 하여야 하고, 제 8조 2항에 따라 서비스 종료 이후 추가 요금이 발생할 수
                            있다는 사실을 인지해야 합니다. 서비스 조건을 확인하지 않고 이용 요청을 하여 발생한 손실, 손해에 대한 책임은 회원 본인이 부담하여야
                            합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회원은 본 약관 및 회사가 서비스와 관련하여 고지하는 내용을 준수하여야 하며, 약관 및 고지 내용을 위반하거나 이행하지 아니하여
                            발생하는 모든 손실, 손해에 대하여 책임을 부담합니다. 이용 요청과 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과
                            불이익은 전적으로 회원이 부담하여야 합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회원은 택시 혹은 승합자동차의 대여 및 이동 서비스 이용 시 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제 수단을 임의로
                            사용해서는 안 됩니다. 타인의 결제수단을 임의로 사용함으로써 발생하는 회사, 결제 수단의 적법한 소유자, 전자결제대행 또는 이동서비스
                            제공자 등의 손실과 손해에 대한 모든 책임은 회원에게 있습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>
                            회원은 회사가 서비스를 안전하게 제공할 수 있도록 회사에 협조하여야 하며, 회사가 회원의 본 약관 위반행위를 발견하여 회원에게 해당
                            위반행위에 대하여 소명을 요청할 경우 회원은 회사의 요청에 적극 응하여야 합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            회원은 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히 임하여야 하며, 분쟁 해결에 대한 회원의 불성실로 인하여 이동 서비스 제공자 또는
                            회사에 손해가 발생한 경우 회원은 이에 대한 책임을 부담하여야 합니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 17조(회원의 금지 행위)</h3>

                        <h5>1.</h5>
                        <h6>
                            회사는 서비스의 신뢰성을 제고하고 안전한 거래가 이뤄질 수 있도록 회원에게 특정한 행위를 금지하며 이를 금지 행위로 규정하고 있습니다.
                            금지 행위의 항목은 수시로 갱신될 수 있으며 항목 추가 및 변경 시 별도로 고지하지 않습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회원이 위드메이트 서비스로 중개 받은 메이트 또는 기타 운송 수단 탑승 중 메이트, 차량, 혹은 제 3자에 대하여 손해를 입혔을 경우 회원은
                            이를 배상하여야 하며 구체적인 금액은 아래와 같습니다. 이에 관하여 제휴사의 기사 알선 포함 승합자동차 대여 서비스 이용약관에서 정한
                            사항이 있다면 그에 따릅니다.
                        </h6>
                        <div className='info'>
                            <p className='title'>
                                <span className='bold'>메이트를 폭행하거나 정신적인 피해를 끼친 경우 :</span>치료비 전액 배상 및 영업손실비
                            </p>
                            <p className='title'>
                                <span className='bold'>차량을 오염시킨 경우: </span>30만원 이내에서 세차 실비 및 영업손실비
                            </p>
                            <p className='title'>
                                <span className='bold'>차량 및 차내 기물 파손 비용:</span> 원상 복구 비용 및 영업손실비
                            </p>
                            <p className='title'>
                                <span className='bold'>목적지 도착 후 하차 거부:</span>경찰서 등의 인계 시까지의 운임 및 영업손실비
                            </p>
                            <p className='title'>
                                <span className='bold'>분실물 발견 후 배달 요청 :</span>배차 중지 후 분실물 배달을 위한 영업손실비
                            </p>
                        </div>

                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>
                            회사는 회원이 본 조의 금지행위를 하는 경우 서비스 이용을 제한할 수 있으며, 이 경우 발생하는 모든 책임은 회원이 부담합니다. 회사는
                            필요한 경우 회원의 금지 행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.
                        </h6>
                    </div>

                    <div className='context-block'>
                        <h3>제 18조(책임 제한)</h3>

                        <h5>1.</h5>
                        <h6>
                            회사는 회원과 메이트 간, 회원과 운송사업자 간 거래를 중개하는 시스템의 운영 관리를 제공하며, 회원, 메이트, 운송사업자를 대리하지
                            않습니다. 회원과 메이트, 그리고 운송사업자 사이에 성립된 거래에 따라 회원 또는 메이트, 혹은 운송사업자가 제공한 정보에 대한 책임은
                            회원과 메이트, 그리고 운송사업자가 부담합니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>
                            회원이 자신의 개인 정보 및 계정 등을 타인에게 제공하거나, 회원의 관리 소홀로 유출됨으로써 발생하는 피해에 대해서 책임을 부담하지
                            않습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>3.</h5>
                        <h6>회사는 회원의 귀책 사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</h6>

                        <h5 style={{ marginTop: '48px' }}>4.</h5>
                        <h6>회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 부담하지 않습니다.</h6>

                        <h5 style={{ marginTop: '48px' }}>5.</h5>
                        <h6>
                            회사는 서비스간 회원의 귀책 사유로 발생한 메이트와의 갈등(물리적 폭행, 언어 폭력, 부적절한 신체 접촉 등)으로 인해 발생한 피해에
                            대해서 책임을 부담하지 않습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>6.</h5>
                        <h6>
                            회사는 관계 법령의 변경, 천재지변, 디도스(DDOS)공격, IDC 장애, 기간통신사업자의 회선 장애 또는 이에 준하는 불가항력으로 인하여
                            서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>7.</h5>
                        <h6>
                            회사는 회원의 서비스 이용과 직접적으로 관련이 없는 분쟁에 관여하지 않으며, 회원과 운전용역 제공자 및 운송사업자 또는 회원과 제3자
                            간의 분쟁을 포함하여 도난, 폭행, 성범죄 등에 대해서는 회사의 과실이 없는 한 책임을 부담하지 않습니다.
                        </h6>

                        <h5 style={{ marginTop: '48px' }}>8.</h5>
                        <h6>
                            회사는 스마트기기 환경으로 인하여 발생하는 제반 문제 또는 회사의 귀책 사유가 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서
                            책임을 지지 않습니다.
                        </h6>
                    </div>

                    <div className='context-block' style={{ marginBottom: '100px' }}>
                        <h3>제 19조(분쟁의 해결)</h3>

                        <h5>1.</h5>
                        <h6>본 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 해당 규정에 따릅니다.</h6>

                        <h5 style={{ marginTop: '48px' }}>2.</h5>
                        <h6>서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</h6>
                    </div>

                    <div className='context-block'>
                        <div className='privacy-justify'>
                            <p className='version'>개인정보 처리방법 버전 번호 : v.1.2</p>
                            <p className='date'>제정일자 : 2021년 12월 20일</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TermsComponent;
