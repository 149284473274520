import { message } from 'antd';
import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2';
import Withmate from '../renew/img/new-gov-care.png';

import { PartnerListRow } from '../../home.styled';

export const UserSiteBanner = ({ }) => {
    
    // const baseUrl = '/assets/images/partners/';
    // const fstRow = [
    //     baseUrl + 'new-goyang.png',
    //     baseUrl + 'ajuuniv.png',
    //     baseUrl + 'new-gov-care.png',
    //     baseUrl + 'new-goyang-happy.png',
    //     baseUrl + 'new-goyang-society.png',
    // ];
    // const sndRow = [
    //     baseUrl + 'new-junglang-woman.png',
    //     baseUrl + 'new-paju_woman.png',
    //     baseUrl + 'new-beatus.png',
    //     baseUrl + 'new-cheongdam.png',
    //     baseUrl + 'new-hyo-hospital.png',
    // ];

    return (
        <UserMainContainer>
            <UserMainInnerContainer>
                <PartnerTitle>
                    <span>위드메이트 파트너</span>
                </PartnerTitle>

                <PartnerListRow>
                    <BannerButton type="button" onClick={() => {
                            //window.location.href = "http://www.naver.com" // 현재 페이지에서 열기
                            window.open("http://www.naver.com") //새로운 창에서 열기
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.daum.net"
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.google.com"
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.nate.com"
                    }}/>
                </PartnerListRow>
                <br/><br/>
                <PartnerListRow>
                    <BannerButton type="button" onClick={() => {
                            window.location.href = "http://www.naver.com"
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.naver.com"
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.naver.com"
                    }}/>
                    <BannerButton type="button" onClick={() => {
                        window.location.href = "http://www.naver.com"
                    }}/>
                </PartnerListRow>
            </UserMainInnerContainer>
        </UserMainContainer>
    );
};

const UserMainContainer = styled.div`
    width: 100%;
    height: 350px;

    background: #fff;

    @media (max-width: 768px) {
        height: 598px;
    }
`;

const UserMainInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: 22px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding-top: 52px;
        align-items: center;
        background: transparent;
    }
`;

const ButtonCommon = styled.div`
    display: flex;
    padding: 20px 24px;
    width: 210px;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;
    margin-right: 70px;
    cursor: pointer;

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 4px 10px 24px -4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

    @media (max-width: 768px) {
    }
`;

const BannerButton = styled(ButtonCommon)`
    background-image: url(${Withmate});
    width: 210px;
    height: 65px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
    }
`;

export const PartnerTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;

    align-items: center;
    margin-top: 10px;
    margin-bottom: 46px;

    > span {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.5px;
        text-align: center;
        color: #3bc1ff;
    }

    @media (max-width: 1120px) {
        width: 100%;
        text-align: left;
    }
`;

