import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2';

// components
import {
    GuideDetailContainer,
    InnerContainer,
    GuideConceptTitle,
    GuideDetailServiceCardWrapper,
    GuideDetailServiceCard,
    GuideCardImage,
    GuideCardName,
    GuideCardType,
    GuideCardDescription,
    GuideCardSpecTitle,
    GuideCardSpecRow,
} from '../guide.styled';

export const GuideDetail = () => {
    return (
        <GuideDetailContainer id='types'>
            <InnerContainer>
                <GuideConceptTitle>서비스 종류</GuideConceptTitle>
                <GuideDetailServiceCardWrapper>
                    <GuideCard
                        source={'/assets/images/art_pro.png'}
                        name='병원 동행'
                        type='Pro'
                        desc={
                            <>
                                <>
                                    위드메이트 동행 전문가가 고객님의
                                    <br />
                                    <span>자택에서 병원까지 </span>동행하고, 병원 업무를
                                    <br />
                                    마친 뒤 <span>다시 자택까지</span> 동행합니다.
                                </>
                                <br />
                                <br />
                                <>
                                    <b>요금</b> <br />
                                    시간당 25,000원 (추가 30분마다 12,500원)
                                </>
                            </>
                        }
                        spec1={
                            <>
                                위드메이트 소속 동행 전문가가 <br />
                                자택에서 병원까지 동행
                            </>
                        }
                        spec2={
                            <>
                                대중교통, 고객님 소유 자차, 택시 중<br />
                                선택하여 이동
                            </>
                        }
                        spec3={
                            <>
                                서비스 종료 이후
                                <br />
                                서비스 보고서 열람 가능
                            </>
                        }
                        spec4={
                            <>
                                서비스 진행 단계별 카카오톡
                                <br />
                                실시간 알림 업데이트 제공
                            </>
                        }
                    />
                    <GuideCard
                        source={'/assets/images/art_basic.png'}
                        name='병원 동행'
                        type='Basic'
                        desc={
                            <>
                                <>
                                    위드메이트 베이직 서비스는 위드메이트 동행
                                    <br />
                                    전문가가 고객님께서 지정한 의료 시설 혹은
                                    <br />
                                    <span>병원 내에서 보호자 대행 업무</span>를 수행합니다.
                                </>
                                <br />
                                <br />
                                <>
                                    <b>요금</b> <br />
                                    시간당 15,000원 (추가 30분마다 7,500원)
                                </>
                            </>
                        }
                        spec1={
                            <>
                                고객님의 진료 일정에 맞춰 위드메이트 <br />
                                소속 동행 전문가가 의료 시설 도착
                            </>
                        }
                        spec2={
                            <>
                                지정된 의료 시설에서 고객님 접선 후 <br />
                                진료 시작부터 끝까지 동행
                            </>
                        }
                        spec3={
                            <>
                                진료 종료 후 고객님의 상태를 <br />
                                파악하고 이상 없을 시 서비스 종료
                            </>
                        }
                        spec4={
                            <>
                                서비스 진행 단계별 카카오톡
                                <br />
                                실시간 알림 업데이트 제공
                            </>
                        }
                    />
                    <GuideCard
                        source={'/assets/images/art_zzz.png'}
                        name='보호자 동행'
                        type='수면 내시경'
                        desc={
                            <>
                                <>
                                    {/* 위드메이트 동행 전문가가 고객님께서
                                <br />
                                <span>자택에서 병원까지 </span>동행하고, 병원 내에서
                                <br />
                                <span>보호자 대행 업무</span>를 수행합니다. */}
                                    고객님께서 수면내시경 검사를 받는
                                    <br />
                                    <span>병원 내에서 </span>위드메이트 동행 전문가가
                                    <br />
                                    <span>보호자 대행 업무</span>를 수행합니다.
                                </>
                                <br />
                                <br />
                                <>
                                    <b>요금</b> <br />
                                    시간당 15,000원 (추가 30분마다 7,500원)
                                </>
                            </>
                        }
                        spec1={
                            <>
                                고객님의 진료 일정에 맞춰 위드메이트 <br />
                                소속 동행 전문가가 의료 시설 도착
                            </>
                        }
                        spec2={
                            <>
                                지정된 의료 시설에서 고객님 접선 후 <br />
                                수면 내시경 검사 시작부터 끝까지 동행
                            </>
                        }
                        spec3={
                            <>
                                수면 내시경 검사 종료 후 고객님의 <br />
                                상태를 파악하고 이상 없을 시 종료
                            </>
                        }
                        spec4={
                            <>
                                서비스 진행 단계별 카카오톡 <br />
                                실시간 알림 업데이트 제공
                            </>
                        }
                    />
                </GuideDetailServiceCardWrapper>
            </InnerContainer>
        </GuideDetailContainer>
    );
};

const GuideCard = ({ source, name, type, desc, spec1, spec2, spec3, spec4 }) => (
    <GuideDetailServiceCard>
        <GuideCardImage src={source} />
        <GuideCardName>{name}</GuideCardName>
        <GuideCardType>{type}</GuideCardType>
        <GuideCardDescription>{desc}</GuideCardDescription>
        <GuideCardSpecTitle>서비스 구성</GuideCardSpecTitle>

        <GuideCardSpecRow>
            <span>01</span>
            <p>{spec1}</p>
        </GuideCardSpecRow>

        <GuideCardSpecRow>
            <span>02</span>
            <p>{spec2}</p>
        </GuideCardSpecRow>

        <GuideCardSpecRow>
            <span>03</span>
            <p>{spec3}</p>
        </GuideCardSpecRow>

        <GuideCardSpecRow>
            <span>04</span>
            <p>{spec4}</p>
        </GuideCardSpecRow>
    </GuideDetailServiceCard>
);
