import styled from 'styled-components';
import { mainColor, sizeHandler } from '../../theme';

const StyledCompanyContainer = styled.div`
    padding-top: 55px;

    .snd-section .inner-container .block:nth-child(2) {
        align-items: flex-end;
    }

    .block {
        display: flex;
        align-items: center;
        justify-content: center;

        .img-block {
            flex: 1;
            margin-bottom: 30px;
            position: relative;
            display: flex;
            align-items: flex-start;

            img {
                width: 65%;
            }

            &.right {
                // justify-content: flex-end;
            }
        }

        .text-block {
            flex: 1;
            margin-bottom: 30px;

            .title {
                p {
                    padding: 0px;
                    margin: 0px;
                    font-size: ${sizeHandler(36)};
                    font-weight: bold;
                    margin-bottom: 20px;
                    color: rgba(0, 0, 0, 0.8);
                    letter-spacing: -1px;
                }
            }

            .content {
                p {
                    padding: 0px;
                    margin: 0px;
                    font-size: ${sizeHandler(14)};
                    line-height: ${sizeHandler(24)};
                    letter-spacing: -0.3px;
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 20px;
                }
            }
        }
    }

    .fst-section {
        background-image: url('/assets/images/company-main.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .inner-container {
            padding-top: 166px;
            padding-bottom: 166px;
        }

        .text-block {
            p {
                margin: 0px;
                padding: 0px;
                text-align: center;
                color: white;

                &.title {
                    color: #161616;
                    font-size: ${sizeHandler(28)};
                    font-weight: bold;
                    margin-bottom: 8px;
                    letter-spacing: -0.6px;
                }

                &.content {
                    font-size: ${sizeHandler(32)};
                    font-weight: bold;
                    letter-spacing: -0.38px;
                }
            }
        }
    }

    .trd-section {
        background-image: url('/assets/images/hug.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;


        .inner-container {
            padding-top: 144px;
            padding-bottom: 88px;
        }

        .text-block {
            p {
                text-align: center;
                margin: 0px;
                padding: 0px;
                color: white;

                &.title {
                    font-size: ${sizeHandler(34)};
                    font-weight: bold;
                    letter-spacing: -1px
                    margin-bottom: 12px;
                }

                &.content {
                    font-size: ${sizeHandler(14)};
                    line-height: ${sizeHandler(22)};
                    letter-spacing: -0.3px;
                }
            }
        }
    }

    .fth-section {
        margin-bottom: 40px;
        border-bottom: 1px solid #e6e6e6;
        
        .title-block {
            margin-bottom: 20px;

            p {
                text-align: center;
                font-size: ${sizeHandler(36)};
                font-weight: bold;
                margin-bottom: 20px;
                color: rgba(0, 0, 0, 0.8);
                letter-spacing: -1px;
            }
        }

        .items-block {
            .item {
                float: left;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33.3333%;
                height: 124px;

                @media(max-width: 768px) {
                    width: 100%;
                    padding: 0px 20px;
                }

                .img-wrapper {
                    width: 30%;
                    float: left;

                    img {
                        width: 70%;
                    }
                }

                .text-wrapper {
                    width: 70%;
                    float: left;

                    .name {
                        font-size: ${sizeHandler(20)};
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);
                      
                    }

                    .value {
                        font-size: ${sizeHandler(16)};
                        font-weight: normal;
                        letter-spacing: -0.3px;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }

    .fifth-section {

        .inner-container {
            padding-top: 200px;
            padding-bottom: 65px;

            .text-block {
                .title {
                    text-align: center;
                    font-size: ${sizeHandler(36)};
                    font-weight: bold;
                    margin-bottom: 20px;
                    color: rgba(0, 0, 0, 0.8);
                    letter-spacing: -1px;
                }

                .content {
                    text-align: center;
                    font-size: ${sizeHandler(14)};
                    line-height: ${sizeHandler(24)};
                    letter-spacing: -0.3px;
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 20px;
                }

                .news {
                    text-align: center;
                    margin-bottom: 80px;
                }
            }

            // .logo-block {
                
            //     .three {
                    
            //         .logo-wrapper {
            //             float: left;
            //             width: 20%;

            //             display: flex;
            //             align-items: center;
            //             justify-content: center;
                        
            //             margin-bottom: 16px;

            //             img {
            //                 height: 76px;
            //             }
            //         }
            //     }
            // }
        }
    }

    @media (max-width: 768px) {
        .block {
            flex-direction: column;

            &.fst {
                order: 1;
                
                .img-block {
                    align-items: center;
                    justify-content: center;
                }
            }

            .snd {
                order: 2;
            }

            .trd {
                order: 4;
            }

            .fth {
                order: 3;
                margin-top: 20px;
            }
            
            .img-block {
                // display: none;
                // margin-bottom: 20px;
                // justify-content: center;

                // &.right {
                //     justify-content: center;
                // }

                // img {
                //     width: 25%;
                // }
            }
        }

        .fth-section {            
            .items-block {
                flex-direction: column;

                .row {
                    flex-direction: row;

                    .item {
                        flex: 1;
                        justify-content: flex-start;

                        .text-block {
                            flex: 1;
                        }
                    }
                }
            }
        }

    }


    .sth-container {
        .img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 156px;
            margin-bottom: 34px;

            img {
                height: 100%;
            }
        }

        .text-wrapper {
            .title {
                font-size: ${sizeHandler(36)};
                font-weight: bold;
                letter-spacing: -1px;
                text-align: center;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 16px;
            }

            .content {
                font-size: ${sizeHandler(14)};
                font-weight: normal;
                letter-spacing: -0.3px;
                text-align: center;
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }
`;
export default StyledCompanyContainer;
