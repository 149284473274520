import { message } from 'antd';
import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const UserMain = ({ history }) => {
    return (
        <UserMainContainer>
            <UserMainInnerContainer>
                <WithmateSpecRow>
                    <img src='/assets/images/collaboration/withmate_metlife_main.png' style={{ paddingTop: '40px', width: '380px' }} alt='메인 이미지' />
                </WithmateSpecRow>
                <BottomSection>
                    <HighLight>
                        메트라이프 360Future <br />
                        회원만을 위한 <br />
                        <p style={{ fontSize: '30px', color: '#3bc1ff' }}>병원동행서비스</p>
                    </HighLight>
                    <ButtonWrapper>
                        <ReserveButton
                            onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.bluecamel.withmate');
                            }}
                        >
                            위드메이트 이용하기
                        </ReserveButton>
                        <ReserveButton
                            onClick={() => {
                                if (!localStorage.getItem('id')) {
                                    message.warning('병원 동행서비스 예약을 이용하시려면 회원가입 후 이용해주세요.');
                                    return history.push('/user/register?url_source=metlife');
                                }

                                history.push('/reserve/items');
                            }}
                        >
                            동행서비스 예약
                            <img src='/assets/images/main_right_blue.png' alt='방향표' />
                        </ReserveButton>
                        <div style={{ paddingBottom: '8px' }} />
                        <CallButton
                            onClick={() => {
                                swal.fire({
                                    title: '위드메이트 고객센터',
                                    text: '☎ 070-4300-9075',
                                    icon: 'info',
                                    confirmButtonText: '전화걸기',
                                    cancelButtonText: '확인',
                                    showCancelButton: true,
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        document.location.href = 'tel:070-4300-9075';
                                    }
                                });
                            }}
                        >
                            전화 상담하기
                            <img src='/assets/images/icons/phone.png' alt='전화기' />
                        </CallButton>
                    </ButtonWrapper>
                </BottomSection>
            </UserMainInnerContainer>
        </UserMainContainer>
    );
};

const UserMainContainer = styled.div`
    width: 100%;
    height: 628px;

    background: linear-gradient(to top, #ffffff, #eeeeee);

    @media (max-width: 768px) {
        // height: 598px;
        height: 650px;
    }
`;

const UserMainInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;

    background-image: url('/assets/images/w_main.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    padding-top: 72px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding-top: 52px;
        align-items: center;
        background: transparent;
    }
`;

const WithmateSpecRow = styled.div`
    display: flex;
    align-items: flex-end;

    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    line-height: 1.48;
    letter-spacing: -0.7px;
    text-align: left;
    color: #ffffff;

    > span {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.7px;
        text-align: left;
        color: #ffffff;

        margin-left: 4px;
    }

    > span.big {
        font-size: 64px;
        line-height: 1.2;
        position: relative;
        margin-right: 6px;

        > d {
            font-size: 28px;
            position: absolute;
            top: -16px;
            left: calc(50% - 12px);
        }
    }

    > span.normal {
        font-size: 40px;
        line-height: 1.2;
        position: relative;
        margin-right: 6px;

        > d {
            font-size: 18px;
            position: absolute;
            top: -16px;
            left: calc(50% - 12px);
        }
    }

    margin-bottom: 4px;

    @media (max-width: 768px) {
        margin-bottom: 8px;

        font-size: 22px;

        > span {
            font-size: 22px;
        }

        > span.big {
            font-size: 48px;
            line-height: 1.2;
            position: relative;
            margin-right: 4px;

            > d {
                font-size: 20px;
                position: absolute;
                top: -16px;
                left: calc(50% - 8px);
            }
        }
    }
`;

const BottomSection = styled.div`
    margin-top: 28px;

    @media (max-width: 768px) {
        margin-top: 43px;
    }
`;

const HighLight = styled.p`
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.2px;
    text-align: left;
    color: black;

    margin-bottom: 64px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        > div:first-child {
            margin-bottom: 8px;
        }
    }
`;

const ButtonCommon = styled.div`
    display: flex;
    padding: 20px 24px;
    width: 210px;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 4px 10px 24px -4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

    @media (max-width: 768px) {
    }
`;

const GPButtonCommon = styled.div`
    display: flex;
    padding: 20px 24px;
    width: 210px;
    align-items: center;

    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    box-shadow: 2px 4px 16px -4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 4px 10px 24px -4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

    @media (max-width: 768px) {
    }
`;

const ReserveButton = styled(ButtonCommon)`
    background-color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
    }
`;

const CallButton = styled(ButtonCommon)`
    border: 1px solid #ffffff;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    background-color: white;

    @media (max-width: 768px) {
    }
`;

const GooglePlayButton = styled(GPButtonCommon)`
    background-color: #ffffff;
    border-color: #ffffff;
    border-style: solid;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    height: 60px;

    @media (max-width: 768px) {
    }
`;

const MobileBr = styled.br`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;
