import React from 'react';
import moment from 'moment';
import { Button, Popover } from 'antd';
import _ from 'lodash';

import { UNEMER, ASSIST } from '../../Lib/variables';
import { currencyFormatter, colorParserByStatus } from '../../Lib/utils';

export const callColumn = ({ onAccept, onCancel, onComplete }) => {
    return [
        {
            title: '예약번호',
            align: 'left',
            width: '110px',
            render: ({ reserve }) => <span>{reserve && reserve.length > 0 ? reserve[0].reserveCode : '-'}</span>,
        },
        {
            title: '이용자 성함',
            align: 'left',
            ellipsis: true,
            width: '72px',
            render: (_id, { reserve }) => <span>{!reserve ? '-' : reserve[0].patient}</span>,
        },
        {
            title: '진행 현황',
            align: 'center',
            ellipsis: true,
            width: '100px',
            render: ({ status }) =>
                status === '결제대기' ? (
                    <Button className='button' style={{ backgroundColor: '#3bc1ff' }}>
                        잔금 결제
                    </Button>
                ) : (
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: colorParserByStatus(status),
                        }}
                    >
                        {status}
                    </span>
                ),
        },
        {
            title: '서비스',
            dataIndex: 'reserve',
            align: 'left',
            ellipsis: true,
            width: '100px',
            render: reserve => (
                <span>{!reserve ? '-' : reserve[0].service === UNEMER ? '병원 동행 Pro' : reserve[0].service === ASSIST ? '병원 동행 Basic' : '-'}</span>
            ),
        },
        {
            title: '병원 위치',
            align: 'left',
            ellipsis: true,
            width: '140px',
            render: (createdAt, { reserve }) => <span>{!reserve ? '-' : reserve[0].starting}</span>,
        },
        {
            title: '예약 일자',
            align: 'left',
            ellipsis: true,
            width: '140px',
            render: ({ reserve }) => <span>{moment(reserve[0].reserveDate).format('YYYY년 MM월 DD일 - HH:mm A')}</span>,
        },
    ];
};

export const reserveColumn = ({ onMate, onCancel, onModify, onDetailModify, onServiceReport }) => {
    return [
        {
            title: '예약번호',
            dataIndex: 'reserveCode',
            width: '110px',
            render: reserveCode => <span>{reserveCode ? reserveCode : '-'}</span>,
        },
        {
            title: '서비스',
            dataIndex: 'service',
            align: 'left',
            ellipsis: true,
            width: '100px',
            render: service => <span>{service === UNEMER ? '병원 동행 Pro' : service === ASSIST ? '병원 동행 Basic' : '-'}</span>,
        },
        {
            title: '이용자 성함',
            dataIndex: 'patient',
            align: 'left',
            ellipsis: true,
            width: '90px',
            render: patient => <span>{patient}</span>,
        },
        {
            title: '병원 위치',
            dataIndex: 'starting',
            align: 'left',
            ellipsis: true,
            width: '140px',
            render: starting => <span>{starting}</span>,
        },
        {
            title: '예약 일자',
            dataIndex: 'reserveDate',
            align: 'left',
            ellipsis: true,
            width: '140px',
            render: reserveDate => <span>{moment(reserveDate).format('YYYY년 MM월 DD일 - HH:mm A')}</span>,
        },
        {
            title: '진행 현황',
            // dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            width: '100px',
            render: ({ call }) =>
                call[0].status === '결제대기' ? (
                    <Button className='button' style={{ backgroundColor: '#3bc1ff' }}>
                        잔금 결제
                    </Button>
                ) : (
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: colorParserByStatus(call[0].status),
                        }}
                    >
                        {call[0].status}
                    </span>
                ),
        },
        {
            title: '결제',
            align: 'center',
            width: '70px',
            ellipsis: true,
            render: ({ paidStatus, call }) => (
                <span style={{ color: paidStatus ? '#606060' : '#ff5500' }}>{call[0].status === '환불' ? '-' : paidStatus ? '완료' : '대기'}</span>
            ),
        },
        {
            title: '담당자',
            align: 'center',
            width: '70px',
            ellipsis: true,
            render: ({ call }) => (
                <span
                    onClick={() => {
                        if (!_.isEmpty(call) && !_.isEmpty(call[0].mate)) onMate(call[0]);
                    }}
                    style={{ cursor: !_.isEmpty(call) && !_.isEmpty(call[0].mate) ? 'pointer' : undefined }}
                >
                    {!_.isEmpty(call) && !_.isEmpty(call[0].mate) ? call[0].mate[0].name : '-'}
                </span>
            ),
        },
        {
            title: '-',
            align: 'center',
            width: '50px',
            render: ({ _id, call, paidStatus, callId, service }) =>
                call[0].status === '서비스 종료' ? (
                    <Popover
                        placement='bottomRight'
                        content={
                            <div className='popover-content-wrapper'>
                                <p
                                    style={{ borderBottom: '1px solid #606060' }}
                                    onClick={() => {
                                        onServiceReport(call[0], service);
                                    }}
                                >
                                    서비스 리포트
                                </p>
                            </div>
                        }
                    >
                        <span
                            style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                height: '24px',
                                width: '24px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                border: '1px solid #e6e6e6',
                                borderRadius: '12px',
                            }}
                        >
                            <i style={{ fontSize: '11px', fontWeight: 300, color: '#3bc1ff' }} className='fas fa-ellipsis-h' />
                        </span>
                    </Popover>
                ) : call[0].status === '접수대기' || (call[0].status === '예약완료' && paidStatus === false) ? (
                    <Popover
                        placement='bottomRight'
                        content={
                            <div className='popover-content-wrapper'>
                                <p
                                    style={{ borderBottom: '1px solid #606060' }}
                                    onClick={() => {
                                        call[0].status === '접수대기' ? onDetailModify(_id) : onModify(_id);
                                    }}
                                >
                                    예약수정
                                    <i className='fas fa-pen' color='#606060' style={{ marginLeft: '20px' }} />
                                </p>
                                <p
                                    onClick={() => {
                                        onCancel(callId);
                                    }}
                                >
                                    예약취소
                                    <i className='fas fa-times' color='#606060' style={{ marginLeft: '20px' }} />
                                </p>
                            </div>
                        }
                    >
                        <span
                            style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                height: '24px',
                                width: '24px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                border: '1px solid #e6e6e6',
                                borderRadius: '12px',
                            }}
                        >
                            <i style={{ fontSize: '11px', fontWeight: 300, color: '#3bc1ff' }} className='fas fa-ellipsis-h' />
                        </span>
                    </Popover>
                ) : (
                    '-'
                ),
        },
    ];
};

export const payHistoryColumn = [
    {
        title: '서비스',
        dataIndex: 'service',
        align: 'left',
        ellipsis: true,
        width: '100px',
        render: service => <span>{service === UNEMER ? '병원 동행 Pro' : service === ASSIST ? '병원 동행 Basic' : '-'}</span>,
    },
    {
        title: '요청사항',
        dataIndex: 'type',
        align: 'left',
        ellipsis: true,
        width: '60px',
        render: type => (
            <span
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    letterSpacing: '-0.3px',
                    color: type === 'paid' ? '#3bc1ff' : '#ff5500',
                }}
            >
                {type === 'paid' ? '결제' : '환불'}
            </span>
        ),
    },
    {
        title: '금액',
        dataIndex: 'price',
        align: 'left',
        ellipsis: true,
        width: '120px',
        render: price => <span>{currencyFormatter(price)} KRW</span>,
    },
    {
        title: '요청시간',
        dataIndex: 'createdAt',
        align: 'left',
        ellipsis: true,
        width: '140px',
        render: createdAt => <span>{moment(createdAt).format('YYYY년 MM월 DD일 - hh:mm A')}</span>,
    },
    {
        title: '상태',
        dataIndex: 'status',
        align: 'left',
        ellipsis: true,
        width: '80px',
        render: status => <span style={{ fontSize: '14px', letterSpacing: '-0.3px', color: '#a7a7a7' }}>{status === true ? '완료' : '진행중'}</span>,
    },
];

export const newsEvent = [
    {
        title: '번호',
        dataIndex: 'index',
        align: 'center',
        width: '80px',
    },
    {
        title: '제목',
        dataIndex: 'title',
        align: 'left',
        
    },
    {
        title: '등록일',
        dataIndex: 'createdAt',
        align: 'center',
        width: '100px',
        render: createdAt => <span>{moment(createdAt).format('YY.MM.DD') || '0'}</span>
    },
];