import React from 'react';
import { message as Message } from 'antd';

import { MATE } from '../../Lib/variables';
import axios from '../../Lib/axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// section
import Reserve from './Section/reserve';
import { UserMain as RenewMain } from './Section/renew/main';
import { UserVideoSection } from './Section/renew/video';
import { UserGuideSection } from './Section/renew/guide';
import { UserMainBanner } from './Section/renew/banner';
import { UserSiteBanner } from './Section/renew/siteBanner';
import { UserNumberCount } from './Section/renew/numberCount';

// import Main from './Section/userMain';
import { WithmatePartners } from './Section/partners';
// import { Services } from './Section/services';
// import { Introduce } from './Section/introduce';
// import { WithmateSpecs } from './Section/spec';

// modal
import { EmergencyCorpSearchModal, ShareCarRequestModal } from '../../Components/modal';
import { ManagerPopup } from '../../Components/modal';

// import MateMain from './Section/mateMain';
// import Review from './Section/review';
// import { CoCompany } from '../Company/Section/fifth';

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
};

export default class HomePage extends React.PureComponent {
    state = {
        searchModal: false,
        emergencyList: [],
        emergencyKeyword: '',
        requestModal: false,
        requestInfo: {
            corp: '',
            address: '',
            manager: '',
            contact: '',
            message: '',
        },
    };

    componentDidMount = async () => {
        const { data } = await axios.get(`/v1/corp/list`);
        if (!data) return null;

        if (data && Array.isArray(data)) {
            this.setState({
                emergencyList: data,
            });
        }
    };

    onShareRequest = async () => {
        const {
            requestInfo: { corp, address, manager, contact, message },
        } = this.state;
        if (!corp || !address || !manager || !contact || !message) return Message.info('정보를 기입해주세요.');

        const { data } = await axios.post('/v1/share/add', {
            corp,
            address,
            manager,
            contact,
            message,
        });

        if (!data) {
            return Message.error('일시적인 서버장애가 발생했습니다. 다시 시도해주세요.');
        }

        if (data === 'check parameters') {
            return Message.warning('정보를 모두 기입해주세요.');
        }

        if (data === 'success') {
            Message.success('셔틀 셰어링 서비스를 요청하였습니다.');

            this.setState({
                requestModal: false,
                requestInfo: {
                    corp: '',
                    address: '',
                    manager: '',
                    contact: '',
                    message: '',
                },
            });
        }
    };

    render() {
        const { requestInfo, emergencyKeyword, emergencyList } = this.state;
        const { userType } = this.props.auth;

        return (
            <div id='#main'>
                {/* <Slider {...settings}>
                    <div>
                        <UserSiteBanner />
                    </div>
                    <div>
                        <RenewMain {...this.props} />
                    </div>
                </Slider> */}
                {userType === MATE ? (
                    <>
                        <Reserve {...this.props} />
                    </>
                ) : (
                    <>
                        {/* <ManagerPopup /> */}
                        <RenewMain {...this.props} />
                        <UserNumberCount />
                        {/* <UserSiteBanner /> */}
                        <UserVideoSection />
                        <UserGuideSection />
                        <WithmatePartners />
                        <UserMainBanner
                            goPage={() => {
                                this.props.history.push('/nemt/service');
                            }}
                        />
                        {/* <Main {...this.props} /> */}
                        {/* <Introduce /> */}
                        {/* <WithmateSpecs /> */}
                    </>
                )}

                {this.state.searchModal ? (
                    <EmergencyCorpSearchModal
                        data={emergencyList}
                        keyword={emergencyKeyword}
                        inputHandler={value => {
                            this.setState({ emergencyKeyword: value });
                        }}
                        visibleHandler={() => {
                            this.setState({ searchModal: false });
                        }}
                    />
                ) : null}

                {this.state.requestModal ? (
                    <ShareCarRequestModal
                        data={requestInfo}
                        visibleHandler={() => {
                            this.setState({ requestModal: false });
                        }}
                        inputHandler={({ type, value }) => {
                            this.setState(prevState => ({
                                requestInfo: {
                                    ...prevState.requestInfo,
                                    [type]: value,
                                },
                            }));
                        }}
                        onSave={this.onShareRequest}
                    />
                ) : null}
            </div>
        );
    }
}
