import axios from '../axios';

export const getUserInfo = ({ id, userType }) => {
    return axios.get(`/v1/user/${id}/${userType}`);
};

export const modifyUserInfo = async ({ id, userType, info }) => {
    if (info.photo) {
        const { data } = await imageUpload(info.photo);
        info.photo = data;
    }

    return axios.post('/v1/user/modify/info', { id, userType, info });
};

export const serviceInfo = ({ code }) => {
    return axios.get('/v1/service/product/list', { params: { code } });
};

export const buyTicket = ({ userId, info }) => {
    return axios.post('/v1/service/buy/ticket', { userId, info });
};

export const serviceReserve = ({ userId, info }) => {
    return axios.post('/v1/service/reserve', { userId, info });
};

export const reserveList = ({ userId }) => {
    return axios.get('/v1/service/user/reserve/history', { params: { userId } });
};

export const getReserve = ({ reserveId, userId }) => {
    return axios.get('/v1/service/user/reserve', { params: { reserveId, userId } });
};

export const getReserveList = ({ userId }) => {
    return axios.get('/v1/service/user/reserve/list', { params: { userId } });
};

export const serviceRequest = ({ impUid, buyerId, info }) => {
    // return axios.post('/v1/service/request', { impUid, buyerId, info });
};

export const payHistory = ({ userId }) => {
    return axios.get('/v1/service/user/pay/history', { params: { userId } });
};

export const mateReserveList = ({ mateId }) => {
    return axios.get('/v1/service/mate/call/list', { params: { mateId } });
};

export const mateCallReserveList = ({ mateId, page }) => {
    return axios.get('/v1/service/mate/reserve/list', { params: { mateId, page } });
};

export const acceptCall = ({ callId, mateId }) => {
    return axios.put('/v1/service/mate/accept/call', { callId, mateId });
};

export const completeCall = ({ callId }) => {
    return axios.put('/v1/service/mate/complete/call', { callId });
};

export const modifyReserve = ({ reserveId, info }) => {
    return axios.put('/v1/service/user/reserve/modify', { reserveId, info });
};

export const cancelReserve = ({ callId }) => {
    return axios.put('/v1/service/user/reserve/cancel', { callId });
};

export const cancelCall = ({ callId }) => {
    return axios.put('/v1/service/mate/reserve/cancel', { callId });
};

export const imageUpload = file => {
    const formData = new FormData();
    formData.append('photo', file);
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post('/v1/common/image/upload', formData, header);
};

export const getPartnerServices = () => {
    return axios.get('/v1/partner/service/list');
};

export const getContractServices = ({ service, mateId }) => {
    return axios.get(`/v1/partner/${service}/reserve/list`, {
        params: {
            mateId,
        },
    });
};
