import React from 'react';
import styled from 'styled-components';

export const GuideBanner = () => {
    return <GuideBannerContainer>병원동행 서비스는 위드메이트에게!</GuideBannerContainer>;
};

const GuideBannerContainer = styled.div`
    width: 100%;
    height: 235px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.7px;
    text-align: center;
    color: #ffffff;

    background-image: url('/assets/images/guide_title_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
        font-size: 22px;
        background-position: left;
    }
`;
