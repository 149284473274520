import styled from 'styled-components';
import { borderColor, mainBoldColor, mainColor, fontSize, sizeHandler } from '../../../theme';
const NavStyledContainer = styled.div`
    .nav-container {
        width: 100vw;
        height: 56px;
        background-color: white;
        border-bottom: 1px solid ${borderColor};
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;

        .nav-inner {
            display: flex;
            flex-direction: row;
            max-width: 1120px;
            height: 100%;
            margin: 0 auto;
            position: relative;

            .logo {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    width: 80%;
                }
            }

            .nav {
                flex: 1;
                padding-right: 12px;

                .mobile-menu {
                    display: none;
                }

                ul.nav-items {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0px;
                    padding-left: 32px;

                    li.item {
                        padding-left: 13px;
                        padding-right: 13px;
                        position: relative;
                        
                        > a {
                            font-size: 13px;
                            line-height: 21px;
                        }

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.item-apply {
                            padding-left: 0px;
                            padding-right: 0px;

                            margin-left: 12px;

                            .underline {
                                bottom: -12px;
                            }
                        }

                        &.item-login {
                            padding-left: 0px;
                            padding-right: 0px;
                            margin-left: 12px;

                            .underline {
                                bottom: -12px;
                            }
                        }

                        &.item-register {
                            padding-left: 0px;
                            padding-right: 0px;

                            margin-left: 12px;

                            .underline {
                                bottom: -12px;
                            }
                        }

                        &.item-logout {
                            border-left: 1px solid #e6e6e6;
                        }

                        &.item-name {
                            border-left: 1px solid #e6e6e6;
                            border-right: 1px solid #e6e6e6;
                        }

                        @media (max-width: 1080px) {
                            padding-left: 8px;
                            padding-right: 8px;

                            > a {
                                font-size: 13px;
                                line-height: 18px;
                            }
                        }

                        @media (max-width: 900px) {
                            padding-left: 6px;
                            padding-right: 6px;

                            > a {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }

                    .tickets {
                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        letter-spacing: -0.18px;
                        color: #606060;
                    }

                    .nav-item {
                        font-weight: bold;
                        font-size: 12px;
                        color: #606060;

                        &.block {
                            padding: 9.25px 30px;
                            
                            line-height: 18px;
                            text-align: center;
                            border: 1px solid ${mainColor}
                            font-weight: bold;
                            border-radius: 2px;
                        }

                        &.login, &.register {
                            color: ${mainColor};
                        }

                        &.apply {
                            background-color: ${mainColor};
                            color: white;
                        }

                        @media (max-width: 1080px) {
                            &.block {
                                padding: 8px 24px;
                            }
                        }

                        @media (max-width: 900px) {
                            &.block {
                                padding: 6px 18px;
                            }
                        }
                    }

                    .active {
                        color: ${mainColor};
                        font-weight: bold;

                        .underline {
                            display: inline-block;
                            position: absolute;
                            bottom: -18.5px;
                            left: 0px;
                            width: 100%;
                            height: 3px;
                            background-color: ${mainColor};
                        }
                    }
                }

                

                @media (max-width: 768px) {
                    padding-right: 0px;
                    
                    .mobile-menu {
                        height: 100%;
                        width: 100%;
                        display: block;
                        position: relative;

                        span {
                            position: absolute;
                            right: 32px;
                            top: 16px;
                            cursor: pointer;
                            color: ${mainColor};

                            svg {
                                font-size: ${fontSize.b20};
                            }
                        }
                    }

                    ul.nav-items {
                        display: none;

                        li.item {
                            
    
                            &.item-logout {
                                border-left: 0px;
                            }
    
                            &.item-name {
                                border-left: 0px;
                                border-right: 0px;
                            }
                        }
                    }

                    ul.nav-items.active {
                        position: absolute;
                        background-color: white;
                        z-index: 9999;
                        top: 56px;
                        left: 0px;
                        width: 100%;
                        height: auto;
                        display: block;
                        padding: 0px;
                        padding: 0px 12px;

                        li.item {
                            width: 100%;
                            min-height: 40px;
                            margin: 0;
                            border-bottom: 1px solid ${borderColor};
                            background-color: white;
                            color: #606060;

                            &.item-login {
                                margin-left: 0px;
                            }
    
                            &.item-register {
                                margin-left: 0px;
                            }

                            a {
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                line-height: 40px;
                                // color: #606060;
                            }
                        }

                        .nav-item {
                            &.block {
                                margin: 6px 0px;
                                line-height: 32px;
                            }
                        }

                        .active .underline {
                            height: 0px;
                        }
                    }
                }
            }
        }
    }
`;

export default NavStyledContainer;
