import React from 'react';
import { connect } from 'react-redux';
import StyledListContainer from './list.styled';
import CommentList from './list';

import { getReviewList, editReview } from '../../../Reducer/Modules/review';

class CommentListPage extends React.PureComponent {
    render() {
        return (
            <StyledListContainer>
                <CommentList {...this.props} />
            </StyledListContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, review }) => ({
    app,
    auth,
    review,
});

const mapDispatchToProps = dispatch => ({
    // getReviewList: page => dispatch(getReviewList({ page })),
    editReview: ({ type, value }) => dispatch(editReview({ type, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentListPage);
