import React from 'react';
import { NavLink } from 'react-router-dom';
import { MobileBr } from '../../../Pages/Guide/guide.styled';

export default class FooterComponent extends React.PureComponent {
    render() {
        const { location } = this.props.history;
        const { pathname } = this.props.location;
        const darkMode =
            pathname === '/' || pathname === '/user/mate/pending' || pathname === '/faq' || pathname === '/company' || pathname.indexOf('/user') !== -1;

        return (
            <div className={`footer-container ${darkMode ? 'darkmode' : ''}`}>
                <div className='inner-container'>
                    <div className='default-info-wrapper'>
                        <div className='right'>
                            <p>2019 Withmate Org, All rights reserved.</p>
                        </div>
                        <div className='policy'>
                            {location.pathname.startsWith('/360F') ? (
                                <>
                                    <NavLink to='/360F/policy/terms'>이용약관</NavLink>
                                    <NavLink to='/360F/policy/privacy'>개인정보 처리방침</NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink to='/policy/terms'>이용약관</NavLink>
                                    <NavLink to='/policy/privacy'>개인정보 처리방침</NavLink>
                                </>
                            )}
                            {/* <a
                                onClick={() => {
                                    window.open('https://www.notion.so/FAQ-cfe01db37c67472086b414a044ed6370', '_blank');
                                }}
                            >
                                메이트 매뉴얼
                            </a> */}
                            <MobileBr />
                            <MobileBr />
                            <a style={{ marginLeft: '70px' }}></a>
                            <a href='https://blog.naver.com/PostList.naver?blogId=pond909' name='naver-blog' target='_blank' rel='noreferrer noopener'>
                                <img
                                    style={{ border: '0px currentColor', width: '30px', verticalAlign: 'top' }}
                                    alt='네이버 블로그'
                                    src='/assets/images/icons/naverblog_icon.png'
                                    loading='lazy'
                                />
                            </a>
                            <a
                                href='https://www.facebook.com/%EC%9C%84%EB%93%9C%EB%A9%94%EC%9D%B4%ED%8A%B8-%EB%B3%91%EC%9B%90%EB%8F%99%ED%96%89%EC%84%9C%EB%B9%84%EC%8A%A4-103107278930429'
                                name='facebook'
                                target='_blank'
                                rel='noreferrer noopener'
                                style={{ marginLeft: '-15px' }}
                            >
                                <img
                                    style={{ border: '0px currentColor', width: '30px', verticalAlign: 'top' }}
                                    alt='페이스북'
                                    src='/assets/images/icons/facebook_icon.png'
                                    loading='lazy'
                                />
                            </a>
                            <a
                                href='https://www.instagram.com/withmate_official'
                                name='naver-blog'
                                target='_blank'
                                rel='noreferrer noopener'
                                style={{ marginLeft: '-15px' }}
                            >
                                <img
                                    style={{ border: '0px currentColor', width: '30px', verticalAlign: 'top' }}
                                    alt='인스타그램'
                                    src='/assets/images/icons/instagram_icon.png'
                                    loading='lazy'
                                />
                            </a>
                        </div>
                    </div>

                    <div className='footer-info'>
                        <div className='info-block'>
                            <div className='row-wrapper'>
                                <p className='infoTitle'>회사 정보</p>
                                <p className='content'></p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>법인명</p>
                                <p className='content'>㈜블루카멜</p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>대표자</p>
                                <p className='content'>지승배</p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>사업자 등록번호</p>
                                <p className='content'>153-88-00622</p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>통신판매업 신고번호</p>
                                <p className='content'>2021-서울 서대문-2081</p>
                            </div>
                        </div>
                        <div className='info-block'>
                            <div className='row-wrapper'>
                                <p className='contactTitle'>연락처</p>
                                <p className='content'></p>
                            </div>
                            <div className='row-wrapper address'>
                                <p className='title'>주소</p>
                                <p className='content'>서울시 서대문구 경기대로 11길 26-6 우진빌딩 비동 203호</p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>전화번호</p>
                                <p className='content'>070-4300-9075</p>
                            </div>
                            <div className='row-wrapper'>
                                <p className='title'>이메일</p>
                                <p className='content'>ceo@withmate.kr</p>
                            </div>
                            <div className='row-wrapper2' />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
