import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../theme';

// components
import { PartnerContainer, PartnerInnerContainer, PartnerTitle, PartnerListRow, PartnerItem } from '../home.styled';

export const WithmatePartners = ({}) => {
    const baseUrl = '/assets/images/partners/';
    const fstRow = [
        baseUrl + 'new-gov-care.png',
        baseUrl + 'new-goyang.png',
        baseUrl + 'new-cancel.png',
        baseUrl + 'aju.png',
        baseUrl + 'new-goyang-happy.png',
    ];
    const sndRow = [
        baseUrl + 'new-junglang-woman.png',
        baseUrl + 'new-paju_woman.png',
        baseUrl + 'new-beatus.png',
        baseUrl + 'new-cheongdam.png',
        baseUrl + 'new-hyo-hospital.png',
    ];

    const trdRow = [baseUrl + 'ssn.png', baseUrl + 'limo-korea.png', baseUrl + 'help-you.png', baseUrl + 'errichi.png', baseUrl + 'new-imagine-us.png'];

    const fthRow = [baseUrl + 'iu-hosp.png', baseUrl + 'new-gpcop.png', baseUrl + 'goyohanM.png', baseUrl + 'carehouse.jpeg', baseUrl + 'bravo.png'];

    const ftfRow = [baseUrl + 'dlive.png', baseUrl + 'sungmo.png', baseUrl + 'metlife.png', baseUrl + 'aju_nursing_hospital.png', baseUrl + 'yongin.png'];

    return (
        <PartnerContainer id='partners'>
            <PartnerInnerContainer>
                <PartnerTitle>
                    <span>
                        <img src='/assets/images/partners_map.png' style={{ width: '32px', height: 'auto', paddingBottom: '10px' }} /> 파트너사
                    </span>
                    <p style={{ color: '#a9a9a9', fontSize: '18px' }}>위드메이트와 함께하는 협력 파트너사입니다.</p>
                </PartnerTitle>
                <PartnerListRow>
                    <PartnerItem>
                        <img src={fstRow[0]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fstRow[1]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fstRow[2]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fstRow[3]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fstRow[4]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={sndRow[0]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={sndRow[1]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={sndRow[2]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={sndRow[3]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={sndRow[4]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={trdRow[0]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={trdRow[1]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={trdRow[2]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={trdRow[3]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={trdRow[4]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fthRow[0]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fthRow[1]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fthRow[2]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fthRow[3]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={fthRow[4]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={ftfRow[0]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={ftfRow[1]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={ftfRow[2]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={ftfRow[3]} />
                    </PartnerItem>
                    <PartnerItem>
                        <img src={ftfRow[4]} />
                    </PartnerItem>
                </PartnerListRow>
            </PartnerInnerContainer>
        </PartnerContainer>
    );
};
