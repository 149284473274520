import styled from 'styled-components';
import { fontSize, mainColor, bgDarkColor, sizeHandler } from '../../../theme';

const FooterStyledContainer = styled.div`
    .footer-container {
        background-color: #f9f9f9;
        border-top: 1px solid #e6e6e6;

        .inner-container {
            padding-top: 52px;
            padding-bottom: 52px;

            .default-info-wrapper {
                display: flex;

                .right {
                    flex: 1;

                    p {
                        font-size: ${sizeHandler(16)};
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                .policy {
                    flex: 1;
                    text-align: center;

                    a {
                        font-size: ${sizeHandler(12)};
                        font-weight: bold;
                        letter-spacing: -0.26px;
                        color: rgba(0, 0, 0, 0.7);
                        text-decoration: underline;
                        margin-right: 32px;
                    }
                }
            }

            .footer-info {
                display: flex;
                align-items: center;

                .info-block {
                    margin-top: 40px;
                    flex: 1;
                    margin-right: 20px;

                    .row-wrapper2 {
                        height: 42px;
                        line-height: 42px;
                        display: flex;
                        borderColor: rgba(41 41 41);
                    }

                    .row-wrapper {
                        height: 42px;
                        line-height: 42px;
                        border-bottom: 1px solid #e6e6e6;
                        display: flex;

                        &.address {
                            height: 42px;
                        }

                        p {
                            &.title {
                                width: 140px;
                                font-size: ${sizeHandler(10)};
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.7);
                            }

                            &.infoTitle {
                                width: 140px;
                                font-size: ${sizeHandler(14)};
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.7);
                            }

                            &.contactTitle {
                                width: 140px;
                                font-size: ${sizeHandler(14)};
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.7);
                            }

                            &.content {
                                flex: 1;
                                font-size: ${sizeHandler(12)};
                                letter-spacing: -0.26px;
                                color: rgba(0, 0, 0, 0.7);
                            }

                        }
                    }
                }
            }

            @media (max-width: 1120px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            @media (max-width: 768px) {
		.info-block .row-wrapper {
		    min-height: 42px;
		    height: auto !important;
		    line-height: 24px !important;
		}

                .default-info-wrapper {
                    flex-direction: column;

                    .right {
                        margin-bottom: 12px;
                    }

                    .policy {
                        text-align: left;

                        a {
                            font-size: ${sizeHandler(10)};
                        }
                    }
                }

                .footer-info {
                    flex-direction: column;

                    .info-block {
                        margin-right: 0px;
                        width: 100%;

                        .row-wrapper {
                            p {
                                &.content {
                                    font-size: ${sizeHandler(10)};
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-container.darkmode {
        background-color: #292929;

        .inner-container {
            .default-info-wrapper {
                .right {
                    p {
                        color: #e6e6e6;
                    }
                }

                .policy {
                    a {
                        color: #e6e6e6;
                    }
                }
            }

            .footer-info {
                .info-block {
                    .row-wrapper {
                        border-bottom: 1px solid #606060;

                        p {
                            &.title {
                                color: #ffffff;
                            }

                            &.content {
                                color: #a7a7a7;
                            }

                            &.infoTitle {
                                color: #ffffff;
                            }

                            &.contactTitle {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default FooterStyledContainer;
