import React from 'react';
import _ from 'lodash';

// components
import { GuideNav } from './Section/nav';
import { GuideConcept } from './Section/concept';
import { GuideDetail } from './Section/detail';
import { GuideUsage } from './Section/usage';
import { WithmatePartners } from '../../Home/Section/partners';
import { Merit } from './Section/merit';
import { Withmate } from './Section/withmate';
import { GuideBanner } from './Section/banner';

import { Container } from './guide.styled';

class GuidePage extends React.PureComponent {
    state = {
        activeTab: 'concept',
        reserveModal: false,
        reserveType: null,
        step: 1,
        mobile_menu: false,
        addHalfTime: false,
    };

    inputHandler = ({ type, value }) => {
        this.setState({ [type]: value });
    };

    inputDeepHandler = ({ type, deepType, value }) => {
        this.setState(prevState => ({
            [type]: {
                ...prevState[type],
                [deepType]: value,
            },
        }));
    };

    onTab = tab => {
        this.setState({
            activeTab: tab,
        });
    };

    onRoute = path => {
        this.props.history.push(path);
    };

    render() {
        const { activeTab } = this.state;

        return (
            <Container>
                <GuideBanner />
                <GuideNav
                    activeTab={activeTab}
                    onTab={this.onTab}
                    onReserve={serviceType => {
                        this.setState({
                            reserveModal: true,
                            temp: {
                                page: 1,
                                service: 'unemergency',
                                addTime: '60',
                                payMethod: 'transfer',
                                sex: '남',
                                transport: 'common',
                                report: false,
                            },
                        });
                    }}
                />
                <Withmate />
                <GuideConcept />
                <GuideDetail />
                <Merit />
                <GuideUsage />
                <WithmatePartners />
            </Container>
        );
    }
}

export default GuidePage;
