import React from 'react';
import styled from 'styled-components';
import { Select, ConfigProvider, DatePicker } from 'antd';
import holidayKR from 'holiday-kr';
import moment from 'moment';

import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/ko_KR';

// components
import {
    DivisionLine,
    WithmateLabel,
    WithmateInput,
    WithamteInputWithButton,
    HalfWithmateInput,
    WithmateTextarea,
    WithmateSelect,
    FlexWrapper,
    HalfWithmateSelect,
    WithmateDatePicker,
    BorderDivisionLine,
    StyledHotFix,
    WithmateValue,
    WithmateValueTip,
} from '../Common/input';
import { H1, H2, H3, H4, H5, H6, Text } from '../Common/text';
import { Container } from '../Common/container';
import { TabContainer, Tab } from '../Common/tab';
import { FullWidthButton } from '../Common/button';

// utils
import Pallete from '../../theme';
import { isNotNumber, celluarValidate } from '../../Lib/regex';
import { ASSIST } from '../../Lib/variables';

export const EditStep1 = ({ data, inputDeepHandler, setDestination, setStarting }) => {
    const { service, sex, relate, patient, contact, destination, starting, status } = data;

    const onTab = tab => {
        inputDeepHandler({ type: 'temp', deepType: 'service', value: tab });
    };

    const onSex = sex => {
        inputDeepHandler({ type: 'temp', deepType: 'sex', value: sex });
    };

    const onRelation = relate => {
        inputDeepHandler({ type: 'temp', deepType: 'relate', value: relate });
    };

    const destinationHandler = ev => {
        inputDeepHandler({ type: 'temp', deepType: 'destination', value: ev.target.value });
    };

    const startingHandler = ev => {
        inputDeepHandler({ type: 'temp', deepType: 'starting', value: ev.target.value });
    };

    const onNextStep = () => {
        const fix = [];
        if (!relate) fix.push('relate');
        if (!contact || !celluarValidate(contact)) fix.push('contact');
        if (!patient) fix.push('patient');
        if (!starting) fix.push('starting');
        if (service === 'unemergency' && !destination) fix.push('destination');

        if (!data.disabled) {
            inputDeepHandler({ type: 'temp', deepType: 'reserveHotFix', value: fix });
            if (fix.length > 0) return null;
        }

        inputDeepHandler({ type: 'temp', deepType: 'step', value: 2 });
    };

    return (
        <Container>
            <H1 bold style={{ color: Pallete.dark80 }}>
                병원 동행 예약을
                <br className='mobile-exist' />
                확인해주세요
            </H1>

            <DivisionLine small />

            <WithmateLabel required>서비스 선택</WithmateLabel>
            <TabContainer>
                <Tab
                    active={data && data.service === 'unemergency'}
                    onClick={() => {
                        if (data && !data.disabled) onTab('unemergency');
                    }}
                >
                    Pro
                </Tab>
                <Tab
                    active={data && data.service === 'assist'}
                    onClick={() => {
                        if (data && !data.disabled) onTab('assist');
                    }}
                >
                    Basic
                </Tab>
            </TabContainer>

            <DivisionLine small />

            <FlexWrapper withHalf>
                <WithmateLabel required>이용자 성함 / 성별</WithmateLabel>
                <WithmateLabel required>관계</WithmateLabel>
            </FlexWrapper>
            <FlexWrapper withHalf>
                <RelativeWrapper>
                    <WithmateInput
                        disabled={data.disabled}
                        type='text'
                        placeholder='ex) 홍길동'
                        value={(data && data.patient) || undefined}
                        onChange={ev => {
                            if (ev.target.value && ev.target.value.length > 10) return null;

                            inputDeepHandler({ type: 'temp', deepType: 'patient', value: ev.target.value });
                        }}
                    />

                    <SigleButtonWrapper>
                        <SigleButton
                            active={data && data.sex === '남'}
                            onClick={() => {
                                if (data && !data.disabled) onSex('남');
                            }}
                        >
                            남
                        </SigleButton>
                        <SigleButton
                            active={data && data.sex === '녀'}
                            onClick={() => {
                                if (data && !data.disabled) onSex('녀');
                            }}
                        >
                            녀
                        </SigleButton>
                    </SigleButtonWrapper>
                </RelativeWrapper>
                <WithmateSelect placeholder='-' value={(data && data.relate) || undefined} onChange={onRelation}>
                    <Select.Option value='본인'>본인</Select.Option>
                    <Select.Option value='부모'>부모</Select.Option>
                    <Select.Option value='자매'>자매</Select.Option>
                    <Select.Option value='친구'>친구</Select.Option>
                    <Select.Option value='지인'>지인</Select.Option>
                    <Select.Option value='기타'>기타</Select.Option>
                </WithmateSelect>
            </FlexWrapper>
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'patient') && (
                <StyledHotFix>이용자 성함을 입력해주세요.</StyledHotFix>
            )}

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'relate') && (
                <StyledHotFix>환자와의 관계를 선택해주세요.</StyledHotFix>
            )}
            <DivisionLine small />

            <WithmateLabel required>연락처(휴대전화번호)</WithmateLabel>
            <WithmateInput
                type='text'
                disabled={data.disabled}
                placeholder='ex) 01029101234 ("-" 없이 입력해주세요)'
                value={(data && data.contact) || undefined}
                onChange={ev => {
                    const numValue = parseFloat(ev.target.value);
                    if (typeof numValue !== 'number') return null;
                    if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                        ev.preventDefault();
                        return null;
                    }
                    if (ev.target.value.length > 11) return null;

                    inputDeepHandler({ type: 'temp', deepType: 'contact', value: ev.target.value });
                }}
            />

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'contact') && (
                <StyledHotFix>올바른 휴대전화번호를 입력해주세요.</StyledHotFix>
            )}

            <DivisionLine small />

            <WithmateLabel required>병원 위치</WithmateLabel>
            <WithamteInputWithButton
                buttonName='검색'
                inputConfig={{
                    type: 'text',
                    value: (data && data.destination) || undefined,
                    placeholder: 'ex) 서울특별시 강남 을지병원',
                    onChange: () => {},
                    disabled: true,
                }}
            />

            <DivisionLine small />

            <WithmateLabel required={data && data.service === 'unemergency'}>자택 주소</WithmateLabel>
            <WithamteInputWithButton
                buttonName='검색'
                inputConfig={{
                    type: 'text',
                    value: (data && data.starting) || undefined,
                    placeholder: 'ex) 서울특별시 강남 을지병원',
                    onChange: () => {},
                    disabled: true,
                }}
            />

            <DivisionLine />

            <FullWidthButton bg={Pallete.blue100} onClick={onNextStep}>
                다음으로
            </FullWidthButton>
        </Container>
    );
};

export const EditStep2 = ({ data, inputDeepHandler, onClose, onModify }) => {
    const { date, time, minute, sick, comment } = data;
    const onNextStep = () => {
        const fix = [];
        if (!date) fix.push('date');
        if (!sick) fix.push('sick');
        if (!comment) fix.push('comment');
        if (!time) fix.push('time');
        if (time && time >= 18) fix.push('overtime');
        if (!minute) fix.push('minute');

        if (!data.disabled) {
            inputDeepHandler({ type: 'temp', deepType: 'reserveHotFix', value: fix });
            if (fix.length > 0) return null;
        }

        // onModify();
    };

    const onCancelReserve = () => {};

    const onPrevStep = () => {
        inputDeepHandler({ type: 'temp', deepType: 'step', value: 1 });
    };

    const onCalendar = value => {
        inputDeepHandler({ type: 'temp', deepType: 'date', value });
    };

    const sickHandler = value => {
        inputDeepHandler({ type: 'temp', deepType: 'sick', value });
    };

    const commentHandler = value => {
        inputDeepHandler({ type: 'temp', deepType: 'comment', value });
    };

    const getFee = () => {
        const _transport = data.transport && data.transport === 'own' ? 20000 : 0;
        const _service =
            data.productInfo &&
            data.service &&
            (data.service === 'unemergency' ? data.productInfo.proPrice : data.productInfo.basicPrice ? data.productInfo.basicPrice : 0) * data.usageTime;

        const _point = (data.usingPoint || 0) * -1;

        return _transport + _service + _point;
    };

    const addTimeSelector = value => {
        inputDeepHandler({ type: 'temp', deepType: 'addTime', value });
    };

    const transportHandler = value => {
        inputDeepHandler({ type: 'temp', deepType: 'transport', value });
    };

    return (
        <Container>
            <H1 bold style={{ color: Pallete.dark80 }}>
                병원 동행 예약을
                <br className='mobile-exist' />
                확인해주세요
            </H1>

            {data.meetDate && (
                <>
                    <WithmateLabel required>{data.service === ASSIST ? '병원 도착 요청 일자' : '자택 도착 희망 일자'}</WithmateLabel>
                    <ConfigProvider locale={locale}>
                        <WithmateDatePicker
                            inputReadOnly
                            disabled={data.disabled}
                            style={{ width: '100%', height: '36px' }}
                            value={(data && data.meetDate) || undefined}
                            placeholder='예약일자를 설정해주세요.'
                            format='YYYY-MM-DD'
                            onChange={onCalendar}
                            disabledDate={current =>
                                current < moment().add(0, 'day') ||
                                current.get('day') === 0 ||
                                current.get('day') === 6 ||
                                holidayKR.isSolarHoliday(moment(current).toDate())
                            }
                        />
                    </ConfigProvider>
                    <DivisionLine />
                    <WithmateLabel required>{data.service === ASSIST ? '병원 도착 요청 시간' : '자택 도착 희망 시간'}</WithmateLabel>
                    <FlexWrapper withHalf>
                        <WithmateInput
                            disabled={data.disabled}
                            type='text'
                            placeholder='ex) 16'
                            value={(data && data.meetTime) || undefined}
                            style={{ marginRight: '4px' }}
                            onChange={ev => {
                                if (ev.target.value && ev.target.value.length > 10) return null;
                                const numValue = parseFloat(ev.target.value);
                                if (typeof numValue !== 'number') return null;
                                if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                                    ev.preventDefault();
                                    return null;
                                }
                                if (ev.target.value.length > 2 || numValue > 23) return null;

                                inputDeepHandler({ type: 'temp', deepType: 'meetTime', value: ev.target.value });
                            }}
                            suffix='시'
                        />
                        <WithmateInput
                            disabled={data.disabled}
                            type='text'
                            placeholder='ex) 30'
                            value={(data && data.meetMinute) || undefined}
                            onChange={ev => {
                                if (ev.target.value && ev.target.value.length > 10) return null;
                                const numValue = parseFloat(ev.target.value);
                                if (typeof numValue !== 'number') return null;
                                if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                                    ev.preventDefault();
                                    return null;
                                }
                                if (ev.target.value.length > 2 || numValue > 60) return null;

                                inputDeepHandler({ type: 'temp', deepType: 'meetMinute', value: ev.target.value });
                            }}
                            suffix='분'
                        />
                    </FlexWrapper>
                    <DivisionLine />
                </>
            )}

            <WithmateLabel required>예약일자</WithmateLabel>
            <ConfigProvider locale={locale}>
                <WithmateDatePicker
                    inputReadOnly
                    disabled={data.disabled}
                    style={{ width: '100%', height: '36px' }}
                    value={(data && data.date) || undefined}
                    placeholder='예약일자를 설정해주세요.'
                    format='YYYY-MM-DD'
                    onChange={onCalendar}
                    disabledDate={current =>
                        current < moment().add(0, 'day') ||
                        current.get('day') === 0 ||
                        current.get('day') === 6 ||
                        holidayKR.isSolarHoliday(moment(current).toDate())
                    }
                />
            </ConfigProvider>

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'date') && (
                <StyledHotFix>예약일자를 선택해주세요.</StyledHotFix>
            )}

            <DivisionLine />
            <WithmateLabel required>병원 진료 예약 시간</WithmateLabel>
            <FlexWrapper withHalf>
                <WithmateInput
                    disabled={data.disabled}
                    type='text'
                    placeholder='ex) 16'
                    value={(data && data.time) || undefined}
                    style={{ marginRight: '4px' }}
                    onChange={ev => {
                        if (ev.target.value && ev.target.value.length > 10) return null;
                        const numValue = parseFloat(ev.target.value);
                        if (typeof numValue !== 'number') return null;
                        if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                            ev.preventDefault();
                            return null;
                        }
                        if (ev.target.value.length > 2 || numValue > 23) return null;

                        inputDeepHandler({ type: 'temp', deepType: 'time', value: ev.target.value });
                    }}
                    suffix='시'
                />
                <WithmateInput
                    disabled={data.disabled}
                    type='text'
                    placeholder='ex) 30'
                    value={(data && data.minute) || undefined}
                    onChange={ev => {
                        if (ev.target.value && ev.target.value.length > 10) return null;
                        const numValue = parseFloat(ev.target.value);
                        if (typeof numValue !== 'number') return null;
                        if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                            ev.preventDefault();
                            return null;
                        }
                        if (ev.target.value.length > 2 || numValue > 60) return null;

                        inputDeepHandler({ type: 'temp', deepType: 'minute', value: ev.target.value });
                    }}
                    suffix='분'
                />
            </FlexWrapper>
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'time' || r === 'minute') && (
                <StyledHotFix>서비스 이용을 위한 예약 시간을 정확히 입력해주세요.</StyledHotFix>
            )}
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'overtime') && (
                <StyledHotFix>18시 이후의 서비스 예약은 불가능합니다.</StyledHotFix>
            )}

            <DivisionLine />

            <WithmateLabel required>추가 요청 사항</WithmateLabel>
            <WithmateTextarea
                type='text'
                disabled={data.disabled}
                placeholder='병원 이름 및 상세 내용을 입력해주세요. 특히, 병원 이름을 반드시 기재해주세요 (ex. 서울대학교 병원)'
                value={(data && data.comment) || undefined}
                onChange={ev => {
                    commentHandler(ev.target.value);
                }}
            />
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'comment') && (
                <StyledHotFix>병원 이름 및 상세내용을 입력해주세요.</StyledHotFix>
            )}

            {data && data.service === 'unemergency' && (
                <>
                    <DivisionLine small />

                    <WithmateLabel required>이동 수단 선택</WithmateLabel>
                    <WithmateSelect
                        disabled={data.disabled}
                        value={(data && data.transport) || undefined}
                        placeholder='서비스 이용간 이동 수단을 선택해주세요'
                        onChange={transportHandler}
                    >
                        <Select.Option value='common'>대중교통 이용 (택시・버스 등)</Select.Option>
                        <Select.Option value='own'>본인소유 자차 이용</Select.Option>
                        {data.disabled && <Select.Option value='withcar'>위드메이트 차량 호출</Select.Option>}
                        {data.transport && data.transport === 'own' && <AlertMessage>* 자차 이용시, 운전자 보험 변경 필수</AlertMessage>}
                    </WithmateSelect>
                </>
            )}

            <DivisionLine />

            <WithmateLabel required bg={Pallete.blue50}>
                예상 소요 시간
            </WithmateLabel>
            <WithmateSelect
                disabled={data.disabled}
                value={(data && data.addTime) || undefined}
                placeholder='예상 소요시간을 선택해주세요'
                onChange={addTimeSelector}
            >
                <Select.Option value='60'>1시간</Select.Option>
                <Select.Option value='90'>1시간 30분</Select.Option>
                <Select.Option value='120'>2시간</Select.Option>
                <Select.Option value='150'>2시간 30분</Select.Option>
                <Select.Option value='180'>3시간</Select.Option>
                <Select.Option value='210'>3시간 30분</Select.Option>
                <Select.Option value='240'>4시간</Select.Option>
                <Select.Option value='270'>4시간 30분</Select.Option>
                <Select.Option value='300'>5시간</Select.Option>
                <Select.Option value='330'>5시간 30분</Select.Option>
                <Select.Option value='360'>6시간</Select.Option>
                <Select.Option value='390'>6시간 30분</Select.Option>
                <Select.Option value='420'>7시간</Select.Option>
                <Select.Option value='450'>7시간 30분</Select.Option>
                <Select.Option value='480'>8시간</Select.Option>
            </WithmateSelect>

            <DivisionLine />

            <WithmateLabel>총 예상 비용 (실제 비용과 다를 수 있습니다)</WithmateLabel>
            <WithmateValue>₩ {getFee()}</WithmateValue>
            <WithmateValueTip>(VAT 10% 포함된 가격입니다)</WithmateValueTip>

            <DivisionLine />

            {/* {data && !data.disabled && ( */}
            {data && (
                <>
                    <FullWidthButton bg={Pallete.blue100} onClick={onNextStep}>
                        예약 변경
                    </FullWidthButton>
                    <BorderDivisionLine small />
                </>
            )}

            <FullWidthButton bg={Pallete.dark80} onClick={onClose}>
                확인완료
            </FullWidthButton>
        </Container>
    );
};

export const ContractEditStep_1 = ({ data, inputDeepHandler, setDestination, onDropoReserve }) => {
    const { service, sex, relate, patient, contact, destination, starting } = data;

    const onTab = tab => {
        inputDeepHandler({ type: 'temp', deepType: 'service', value: tab });
    };

    const onSex = sex => {
        inputDeepHandler({ type: 'temp', deepType: 'sex', value: sex });
    };

    const onRelation = relate => {
        inputDeepHandler({ type: 'temp', deepType: 'relate', value: relate });
    };

    const onNextStep = () => {
        const fix = [];
        if (!relate) fix.push('relate');
        if (!contact || !celluarValidate(contact)) fix.push('contact');
        if (!patient) fix.push('patient');
        if (!destination) fix.push('destination');

        inputDeepHandler({ type: 'temp', deepType: 'reserveHotFix', value: fix });
        if (fix.length > 0) return null;

        inputDeepHandler({ type: 'temp', deepType: 'step', value: 2 });
    };

    return (
        <Container>
            <H1 bold style={{ color: Pallete.dark80 }}>
                예약을
                <br className='mobile-exist' />
                확인해주세요
            </H1>

            <DivisionLine small />

            <FlexWrapper withHalf>
                <WithmateLabel required>이용자 성함 / 성별</WithmateLabel>
                <WithmateLabel required>관계</WithmateLabel>
            </FlexWrapper>
            <FlexWrapper withHalf>
                <RelativeWrapper>
                    <WithmateInput
                        type='text'
                        disabled
                        placeholder='ex) 홍길동'
                        value={(data && data.patient) || undefined}
                        onChange={ev => {
                            if (ev.target.value && ev.target.value.length > 10) return null;

                            inputDeepHandler({ type: 'temp', deepType: 'patient', value: ev.target.value });
                        }}
                    />

                    <SigleButtonWrapper>
                        <SigleButton
                            disabled
                            active={data && data.sex === '남'}
                            onClick={() => {
                                onSex('남');
                            }}
                        >
                            남
                        </SigleButton>
                        <SigleButton
                            disabled
                            active={data && data.sex === '녀'}
                            onClick={() => {
                                onSex('녀');
                            }}
                        >
                            녀
                        </SigleButton>
                    </SigleButtonWrapper>
                </RelativeWrapper>
                <WithmateSelect disabled placeholder='-' value={(data && data.relate) || undefined} onChange={onRelation}>
                    <Select.Option value='본인'>본인</Select.Option>
                    <Select.Option value='부모'>부모</Select.Option>
                    <Select.Option value='자매'>자매</Select.Option>
                    <Select.Option value='친구'>친구</Select.Option>
                    <Select.Option value='지인'>지인</Select.Option>
                    <Select.Option value='기타'>기타</Select.Option>
                </WithmateSelect>
            </FlexWrapper>
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'patient') && (
                <StyledHotFix>이용자 성함을 입력해주세요.</StyledHotFix>
            )}

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'relate') && (
                <StyledHotFix>환자와의 관계를 선택해주세요.</StyledHotFix>
            )}
            <DivisionLine small />

            <WithmateLabel required>연락처(휴대전화번호)</WithmateLabel>
            <WithmateInput
                type='text'
                disabled
                placeholder='ex) 01029101234 ("-" 없이 입력해주세요)'
                value={(data && data.contact) || undefined}
                onChange={ev => {
                    const numValue = parseFloat(ev.target.value);
                    if (typeof numValue !== 'number') return null;
                    if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                        ev.preventDefault();
                        return null;
                    }
                    if (ev.target.value.length > 11) return null;

                    inputDeepHandler({ type: 'temp', deepType: 'contact', value: ev.target.value });
                }}
            />

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'contact') && (
                <StyledHotFix>올바른 휴대전화번호를 입력해주세요.</StyledHotFix>
            )}

            <DivisionLine small />

            <WithmateLabel required>병원 위치</WithmateLabel>
            <WithamteInputWithButton
                buttonName='검색'
                inputConfig={{
                    type: 'text',
                    disabled: true,
                    value: (data && data.starting) || undefined,
                }}
            />

            <DivisionLine small />

            <WithmateLabel required={data && data.service === 'unemergency'}>자택 주소 (고향시 내 주소지만 서비스 진행 가능)</WithmateLabel>
            <WithamteInputWithButton
                buttonName='검색'
                inputConfig={{
                    type: 'text',
                    disabled: true,
                    value: (data && data.destination) || undefined,
                    placeholder: 'ex) 경기 고양시 덕양구 고양시청로',
                }}
            />

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'starting') && (
                <StyledHotFix>귀가시 자택 주소를 입력해주세요.</StyledHotFix>
            )}

            {data && data.service === 'dropcare' && (
                <>
                    <DivisionLine small />

                    <FlexWrapper withHalf>
                        <WithmateLabel required>거주 유형</WithmateLabel>
                        <WithmateLabel required>주택 유형</WithmateLabel>
                    </FlexWrapper>
                    <FlexWrapper withHalf>
                        <WithmateSelect placeholder='-' disabled>
                            <Select.Option value='아파트'>아파트</Select.Option>
                            <Select.Option value='단독'>단독</Select.Option>
                            <Select.Option value='다가구'>다가구</Select.Option>
                        </WithmateSelect>
                        <WithmateSelect placeholder='-' disabled>
                            <Select.Option value='자가'>자가</Select.Option>
                            <Select.Option value='전.월세'>전.월세</Select.Option>
                            <Select.Option value='기타'>기타</Select.Option>
                        </WithmateSelect>
                    </FlexWrapper>

                    <DivisionLine small />
                    <FlexWrapper withHalf>
                        <WithmateLabel required>장기 요양 등급</WithmateLabel>
                        <WithmateLabel required>확정</WithmateLabel>
                    </FlexWrapper>
                    <FlexWrapper withHalf>
                        <WithmateSelect placeholder='-' disabled>
                            <Select.Option value='1등급'>1등급</Select.Option>
                            <Select.Option value='2등급'>2등급</Select.Option>
                            <Select.Option value='3등급'>3등급</Select.Option>
                            <Select.Option value='4등급'>4등급</Select.Option>
                            <Select.Option value='5등급'>5등급</Select.Option>
                        </WithmateSelect>
                        <WithmateSelect placeholder='-' disabled>
                            <Select.Option value='해피케어'>해피케어</Select.Option>
                            <Select.Option value='장기요양'>장기요양</Select.Option>
                            <Select.Option value='렌탈'>렌탈</Select.Option>
                        </WithmateSelect>
                    </FlexWrapper>
                </>
            )}

            <DivisionLine />

            <FullWidthButton bg={Pallete.blue100} onClick={onNextStep}>
                다음으로
            </FullWidthButton>
        </Container>
    );
};

export const ContractEditStep_2 = ({ data, inputDeepHandler, onClose }) => {
    const onCalendar = value => {
        inputDeepHandler({ type: 'temp', deepType: 'date', value });
    };

    const sickHandler = value => {
        inputDeepHandler({ type: 'temp', deepType: 'sick', value });
    };

    const commentHandler = value => {
        inputDeepHandler({ type: 'temp', deepType: 'comment', value });
    };

    const getFee = () => {
        const _transport = data.transport && data.transport === 'own' ? 20000 : 0;
        const _service =
            data.productInfo &&
            data.service &&
            (data.service === 'unemergency' ? data.productInfo.proPrice : data.productInfo.basicPrice ? data.productInfo.basicPrice : 0) * data.usageTime;

        const _point = (data.usingPoint || 0) * -1;

        return _transport + _service + _point;
    };

    return (
        <Container>
            <H1 bold style={{ color: Pallete.dark80 }}>
                예약을
                <br className='mobile-exist' />
                확인해주세요
            </H1>

            <WithmateLabel required>예약일자</WithmateLabel>
            <ConfigProvider locale={locale}>
                <WithmateDatePicker
                    inputReadOnly
                    disabled
                    style={{ width: '100%', height: '36px' }}
                    value={(data && data.date) || undefined}
                    placeholder='예약일자를 설정해주세요.'
                    format='YYYY-MM-DD'
                    onChange={onCalendar}
                    disabledDate={current =>
                        current < moment().add(0, 'day') ||
                        current.get('day') === 0 ||
                        current.get('day') === 6 ||
                        holidayKR.isSolarHoliday(moment(current).toDate())
                    }
                />
            </ConfigProvider>

            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'date') && (
                <StyledHotFix>예약일자를 선택해주세요.</StyledHotFix>
            )}

            <DivisionLine />

            <WithmateLabel required>예약 시간</WithmateLabel>
            <FlexWrapper withHalf>
                <WithmateInput
                    type='text'
                    disabled
                    placeholder='ex) 16'
                    value={(data && data.time) || undefined}
                    style={{ marginRight: '4px' }}
                    onChange={ev => {
                        if (ev.target.value && ev.target.value.length > 10) return null;
                        const numValue = parseFloat(ev.target.value);
                        if (typeof numValue !== 'number') return null;
                        if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                            ev.preventDefault();
                            return null;
                        }
                        if (ev.target.value.length > 2 || numValue > 23) return null;

                        inputDeepHandler({ type: 'temp', deepType: 'time', value: ev.target.value });
                    }}
                    suffix='시'
                />
                <WithmateInput
                    type='text'
                    disabled
                    placeholder='ex) 30'
                    value={(data && data.minute) || undefined}
                    onChange={ev => {
                        if (ev.target.value && ev.target.value.length > 10) return null;
                        const numValue = parseFloat(ev.target.value);
                        if (typeof numValue !== 'number') return null;
                        if (ev.nativeEvent.data && isNotNumber(ev.nativeEvent.data)) {
                            ev.preventDefault();
                            return null;
                        }
                        if (ev.target.value.length > 2 || numValue > 60) return null;

                        inputDeepHandler({ type: 'temp', deepType: 'minute', value: ev.target.value });
                    }}
                    suffix='분'
                />
            </FlexWrapper>
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'time' || r === 'minute') && (
                <StyledHotFix>서비스 이용을 위한 예약 시간을 정확히 입력해주세요.</StyledHotFix>
            )}
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'overtime') && (
                <StyledHotFix>18시 이후의 서비스 예약은 불가능합니다.</StyledHotFix>
            )}

            <DivisionLine />

            <WithmateLabel required>추가 요청 사항</WithmateLabel>
            <WithmateTextarea
                type='text'
                disabled
                placeholder={`서비스 진행자에게 추가적으로 요청할 사항을 적어주세요.
                ex) 이동 간 낙상 방지에 각별히 신경써주세요.`}
                value={(data && data.comment) || undefined}
                onChange={ev => {
                    commentHandler(ev.target.value);
                }}
            />
            {data && data.reserveHotFix && data.reserveHotFix.length > 0 && data.reserveHotFix.some(r => r === 'comment') && (
                <StyledHotFix>추가 요청 사항을 입력해주세요.</StyledHotFix>
            )}

            <DivisionLine />

            <WithmateLabel required>이동 수단 선택</WithmateLabel>
            <WithmateValue>
                {data.transport === 'own'
                    ? '본인소유 자차 이용'
                    : data.transport === 'common'
                    ? '대중교통 이용 (택시・버스 등)'
                    : data.transport === 'withcar'
                    ? '위드메이트 차량 호출'
                    : '-'}
            </WithmateValue>

            <DivisionLine />

            <WithmateLabel required bg={Pallete.blue50}>
                예상 소요 시간
            </WithmateLabel>
            <WithmateValue>{parseInt(data.addTime || 0, 10) / 60} 시간</WithmateValue>

            <DivisionLine />

            <WithmateLabel>총 예상비용</WithmateLabel>
            <WithmateValue>₩ {getFee()}</WithmateValue>
            <WithmateValueTip>(VAT 10% 포함된 가격입니다)</WithmateValueTip>

            <DivisionLine />

            <FullWidthButton bg={Pallete.dark80} onClick={onClose}>
                확인완료
            </FullWidthButton>
        </Container>
    );
};
const RelativeWrapper = styled.div`
    position: relative;

    > input {
        width: 100% !important;
    }
`;

const SigleButtonWrapper = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
`;

const SigleButton = styled.span`
    display: inline-block;
    width: 43px;
    height: 100%;
    line-height: 43px;
    // border-radius: 4px;

    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 11px;
    color: ${props => (props.active ? '#ffffff' : Pallete.dark60)};
    text-align: center;

    background-color: ${props => (props.active ? Pallete.blue50 : '#ffffff')};
`;

const AlertMessage = styled.p`
    margin-bottom: 0px;
    margin-top: 4px;

    font-size: 11px;
    color: #565656;
`;
