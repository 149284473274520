import React from 'react';
import styled from 'styled-components';

export const UserGuideSection = () => {
    return (
        <UserGuideContainer>
            <UserGuideInnerContainer>
                <UserGuideTitle>서비스 이용 방법</UserGuideTitle>
                <UserGuideStepWrapper>
                    <TextStep>서비스 신청</TextStep>
                    <ImgStep src='/assets/images/main_right_gray.png' alt='방향표' />
                    <TextStep>동행 인력 매칭</TextStep>
                    <ImgStep src='/assets/images/main_right_gray.png' alt='방향표' />
                    <TextStep>병원 방문</TextStep>
                    <ImgStep src='/assets/images/main_right_gray.png' alt='방향표' />
                    <TextStep>서비스 결과 확인</TextStep>
                    <ImgStep src='/assets/images/main_right_gray.png' alt='방향표' />
                    <TextStep>후불 결제</TextStep>
                    <ImgStep src='/assets/images/main_right_gray.png' alt='방향표' />
                    <TextStep>혜택 적립</TextStep>
                </UserGuideStepWrapper>

                <MainStepRow>
                    <MainStep
                        webArrow
                        mArrow
                        source='/assets/images/main_step_1.png'
                        description={`위드메이트 플랫폼에 회원 가입 후\n병원 동행 서비스 종류를 선택하고\n신청해주세요!`}
                        num='01'
                        name='신청하세요!'
                    />
                    <MainStep
                        webArrow
                        mArrow
                        source='/assets/images/main_step_2.png'
                        description={`접수하신 서비스는 24시간 내에\n매칭 가능 여부가 결정됩니다. 동행 인력이\n매칭을 시도하면 고객님께서 매칭 수락\n여부를 결정하시면 됩니다!`}
                        num='02'
                        name='매칭되세요!'
                    />
                    <MainStep
                        className='uniq'
                        mArrow
                        source='/assets/images/main_step_3.png'
                        description={`동행 인력 매칭 성공 후 예정된 진료일에\n병원에 다녀오세요! 병원에서 하실 일은\n진료를 받는 것 밖에 없습니다.\n진료 동행, 보호자 대행, 병원 행정 보조 등\n 나머지는 위드메이트가 해드립니다.`}
                        num='03'
                        name='다녀오세요!'
                    />
                </MainStepRow>

                <MainStepRow>
                    <MainStep
                        webArrow
                        mArrow
                        source='/assets/images/main_step_4.png'
                        description={`위드메이트는 동행 서비스 진행 과정을\n실시간 카카오 알림톡으로 알려드리고,\n서비스 종료 후 진료 결과와 특이사항 등의\n내용이 정리된 서비스 리포트를 제공합니다`}
                        num='04'
                        name='확인하세요!'
                    />
                    <MainStep
                        webArrow
                        mArrow
                        source='/assets/images/main_step_5.png'
                        description={`위드메이트는 정확한 시간 계산이 불가능한\n병원 진료 특성상, 서비스 요금의 투명성을\n위해 후불 결제를 진행하고 있습니다.\n서비스 시간은 초 단위로 자동 계산되니,\n안심하고 서비스를 받으세요!`}
                        num='05'
                        name='후불이에요!'
                    />
                    <MainStep
                        source='/assets/images/main_step_6.png'
                        description={`회원 가입만 해도 2,000 포인트를\n지급해드리며, 매번 서비스 후기 작성을\n할 때마다 최대 3,000 포인트를 지급해드려요!\n적립된 포인트로 더 저렴하게 서비스를\n이용해보세요!`}
                        num='06'
                        name='받아가세요!'
                    />
                </MainStepRow>
            </UserGuideInnerContainer>
        </UserGuideContainer>
    );
};

const MainStep = ({ webArrow, mArrow, source, num, name, description, className }) => {
    return (
        <MainStepWrapper className={className || ''}>
            <MainStepImageSection>
                <img className='bg' src={source} alt='thumbnail' />
            </MainStepImageSection>

            <MainStepTextSection>
                <StepIndex>{num}</StepIndex>
                <StepTitle>{name}</StepTitle>
                <StepDescription>
                    {description.split('\n').map((word, index) => (
                        <span key={'word-break-down-' + index.toString() + num}>
                            {word}
                            <KeepBr />
                        </span>
                    ))}
                </StepDescription>
            </MainStepTextSection>
            <img className={`arrow ${webArrow && 'web'} ${mArrow && 'mobile'}`} src='/assets/images/w_main_stepper.png' />
        </MainStepWrapper>
    );
};

const UserGuideContainer = styled.div`
    width: 100%;

    background-color: #ffffff;

    padding-top: 103px;
    padding-bottom: 162px;

    @media (max-width: 768px) {
        padding-top: 90px;
        padding-bottom: 130px;
    }
`;

const UserGuideInnerContainer = styled.div`
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
`;

const UserGuideTitle = styled.h4`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);

    margin-top: 0px;
    margin-bottom: 32px;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 1.42;
        letter-spacing: -0.1px;
        margin-bottom: 24px;
    }
`;

const UserGuideStepWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 90px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 77px;
    }
`;

const TextStep = styled.span`
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
`;

const ImgStep = styled.img`
    margin-left: 16px;
    margin-right: 16px;

    width: 24px;
    height: 24px;
    object-fit: contain;

    @media (max-width: 768px) {
        transform: rotate(90deg);

        width: 16px;
        height: 16px;

        margin-left: 0px;
        margin-right: 0px;

        margin-top: 8px;
        margin-bottom: 8px;
    }
`;

const MainStepRow = styled.div`
    display: flex;
    align-items: flex-start;

    > div:first-child {
        border-left: 0px;
    }

    > div:last-child {
        border-right: 0px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        > div:last-child {
            border-bottom: 0px;
        }

        > div.uniq {
            border-bottom: 1px solid #f2f2f2;
        }
    }
`;

const MainStepWrapper = styled.div`
    padding-left: 47px;
    padding-right: 52px;
    border-right: 1px solid #f2f2f2;

    position: relative;

    > img.arrow {
        width: 24px;
        height: 24px;

        position: absolute;
        top: calc(90px - 12px);
        right: -11.5px;

        display: none;
    }

    > img.mobile {
        display: none;
    }

    > img.web {
        display: block;
    }

    @media (max-width: 768px) {
        border-right: 0px;
        border-bottom: 1px solid #f2f2f2;

        > img.arrow {
            width: 24px;
            height: 24px;

            top: unset;
            right: unset;
            bottom: -10px;
            left: calc(50% - 12px);

            transform: rotate(90deg);
        }

        > img.web {
            display: none;
        }

        > img.mobile {
            display: block;
        }
    }

    @media (max-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 44px;
    }
`;

const MainStepImageSection = styled.div`
    width: 288px;
    height: 180px;

    margin-bottom: 24px;

    > img.bg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;

const MainStepTextSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StepIndex = styled.span`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #afb0b2;
    margin-bottom: 8px;

    @media (max-width: 768px) {
    }
`;

const StepTitle = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.1px;
    text-align: left;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
    }
`;

const StepDescription = styled.span`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);

    > span {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.3px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
    }
    word-break: keep-all;

    @media (max-width: 768px) {
    }
`;

const KeepBr = styled.br`
    display: block;
    @media (max-width: 768px) {
        display: block;
    }
`;
