import styled from 'styled-components';
import { sizeHandler, mainColor } from '../../../../theme';
const TermsStyledContainer = styled.div`
    .inner-container {
        ${props => props.component && `padding: 0px !important;`}
    }
    .main-context {
        width: ${props => (props.component ? '100%' : '60%')};

        h2 {
            ${props => props.component && `display: none;`}
            font-size: ${sizeHandler(44)};
            font-weight: bold;
            letter-spacing: -0.6px;
            color: #000000;
            margin-bottom: 24px;
        }

        p {
            font-size: ${sizeHandler(14)};
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.8);
            padding: 0px;
            margin: 0px;
        }
    }

    .context-block {
        margin-top: ${props => (props.component ? '24px' : '100px')};
        padding-top: 24px;
        border-top: 1px solid #e6e6e6;
        width: ${props => (props.component ? '100%' : '60%')};

        > h3 {
            font-size: ${sizeHandler(24)};
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 16px;
        }

        > h6 {
            font-size: ${sizeHandler(16)};
            font-weight: normal;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 24px;
        }

        > h5 {
            font-size: ${sizeHandler(18)};
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
            margin-top: 20px;
            margin-bottom: 12px;
        }

        > p {
            font-size: ${sizeHandler(14)};
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.7);
        }

        > .privacy-justify {
            display: flex;
            align-items: center;
            ${props => props.component && `display: block;`}

            p {
                flex: 1;
                font-size: ${sizeHandler(14)};
                font-weight: bold;
                color: rgba(0, 0, 0, 0.7);

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                    ${props => props.component && `text-align: left;`}
                }
            }
        }

        > .info {
            margin-top: 8px;
            .title {
                border-bottom: 1px solid #e6e6e6;
                padding: 24px 0px;
                font-size: ${sizeHandler(14)};
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.7);
                margin: 0px;

                .bold {
                    font-size: ${sizeHandler(14)};
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.7);
                    margin: 0px;
                    padding: 0px;
                    margin-bottom: 4px;
                    display: block;
                }
            }

            .content {
                font-size: ${sizeHandler(14)};
                font-weight: bold;
                color: rgba(0, 0, 0, 0.7);
                padding: 20px 0px;
                border-bottom: 1px solid #e6e6e6;
            }
        }

        > .info-block {
            margin-top: 68px;

            .title {
                font-size: ${sizeHandler(18)};
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 8px;
            }

            .content {
                font-size: ${sizeHandler(14)};
                line-height: ${sizeHandler(24)};
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    @media (max-width: 1120px) {
        .inner-container {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media (max-width: 768px) {
        .main-context {
            width: 100%;

            h2 {
                font-size: ${sizeHandler(38)};
            }
        }

        .context-block {
            width: 100%;
        }

        .privacy-justify {
            flex-direction: column;

            p {
                &:first-child {
                    text-align: center;
                }
                &:last-child {
                    text-align: center;
                }
            }
        }
    }
`;
export default TermsStyledContainer;
