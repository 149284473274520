import React from 'react';
import styled from 'styled-components';

import { sizeHandler, mainColor } from '../../../theme';

export const UserStatistics = ({ auth, pro, basic }) => (
    <UserContainer>
        <div className='default-info'>
            <p>
                {auth.name},
                <br />
                안녕하세요
            </p>
        </div>

        <div className='reserve-info'>
            <div className='title'>
                <p>이용 통계</p>
            </div>
            <div className='value-wrapper'>
                <div className='value-block'>
                    <div className='title'>
                        <img src='/assets/images/unemergency-small.png' alt='small-icon' />
                        <span>병원 동행 Pro</span>
                    </div>
                    <div className='value'>
                        <p>{pro}회 이용</p>
                    </div>
                </div>

                <div className='value-block'>
                    <div className='title'>
                        <img src='/assets/images/withassist-small.png' alt='small-icon' />
                        <span>병원 동행 Basic</span>
                    </div>
                    <div className='value'>
                        <p>{basic}회 이용</p>
                    </div>
                </div>
            </div>
        </div>
    </UserContainer>
);

const UserContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 82px 30px;

    .default-info {
        flex: 1;
        display: flex;
        align-items: center;

        div {
            width: 110px;
            height: 110px;
            border-radius: 55px;
            margin-right: 20px;
            background-color: #e6e6e6;
            position: relative;
            overflow: hidden;

            img {
                height: 110px;
                position: absolute;
                margin: auto;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
            }

            span {
                display: inline-block;
                position: absolute;
                margin: auto;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                line-height: 110px;
                text-align: center;
                color: #ada5a5;
            }
        }

        p {
            flex: 1;
            font-size: ${sizeHandler(38)};
            font-weight: bold;
            letter-spacing: -0.6px;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .reserve-info {
        flex: 1;

        .title {
            margin-bottom: 12px;

            p {
                font-size: ${sizeHandler(12)};
                font-weight: bold;
                color: rgba(145, 145, 145, 0.8);
            }
        }

        .value-wrapper {
            display: flex;
            align-items; center;

            .value-block {
                flex: 1;

                .title {
                    img {
                        height: 14px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: ${sizeHandler(14)};
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }

                .value {
                    font-size: ${sizeHandler(16)};
                    font-weight: bold;
                    color: #3bc1ff;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        .reserve-info {
            margin-top: 40px;
        }

        .value-wrapper {
            flex-direction: column;

            .value-block:last-child {
                margin-top: 20px;
            }
        }
    }
        
`;
