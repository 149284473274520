import React from 'react';
import StyledFaqContainer from './faq.styled';
import FAQ from './faq';

class FAQContainer extends React.PureComponent {
    render() {
        return (
            <StyledFaqContainer>
                <FAQ {...this.props} />
            </StyledFaqContainer>
        );
    }
}
export default FAQContainer;
